const colors = {
  black: '#262A30',
  backgroundGrey: '#F3F6F8',
  darkGrey: '#262A30',
  darkGreyTransparent: 'rgba(38,42,48,0.5)',
  mediumGrey: '#4F5050',
  lightGrey: '#f3f6f8',
  lighterGrey: '#f5f5f5',
  red: '#CA3746',
  purple: '#7D26CC',
  white: '#FFFFFF'
}

colors.textColor = colors.black
colors.lightTextColor = '#979797'
colors.disabledTextColor = '#dcdcdc'
colors.summaryTextColor = '#757575'
colors.placeholderTextColor = colors.summaryTextColor
colors.appTabBar = '#484848'

colors.primary = colors.black
colors.secondary = colors.darkGrey
colors.supporting = colors.red

colors.error = colors.red
colors.success = '#dff0d8'

export default colors
