import uiConstants from './constants'
import key from './key'
import { delay } from 'src/utility'

const openNav = ({ tabIndex } = {}) => ({
  type: key,
  [uiConstants.IS_NAV_OPEN]: true,
  [uiConstants.NAV_TAB_INDEX]: tabIndex || 0
})
// for clarity of action intent
const setNavTabIndex = openNav
const closeNav = () => ({
  type: key,
  [uiConstants.IS_NAV_OPEN]: false,
  [uiConstants.NAV_TAB_INDEX]: undefined
})

const openModal = ({ id }) => ({
  type: key,
  [uiConstants.IS_MODAL_OPEN]: true,
  [uiConstants.MODAL_ID]: id || null
})

const closeModal = ({ id }) => ({
  type: key,
  [uiConstants.IS_MODAL_OPEN]: false,
  [uiConstants.MODAL_ID]: id || null
})

const setPageTitle = (pageTitle) => {
  return async (dispatch) => {
    // this delay is so I can set page title to be empty
    // whenever the page changes without overriding
    // other things that are setting it to an actual value
    if (pageTitle) await delay(0)
    dispatch({
      type: key,
      [uiConstants.PAGE_TITLE]: pageTitle
    })
  }
}

const setPageMeta = (meta) => {
  return async (dispatch) => {
    dispatch({
      type: key,
      [uiConstants.META]: meta
    })
  }
}

const hideOnboardingScreen = () => {
  return {
    type: key,
    [uiConstants.SHOW_ONBOARDING]: false
  }
}

const updatePushPermissionsCheckbox = (checked) => {
  return {
    type: key,
    [uiConstants.PUSH_PROMPT_CHECKED]: checked
  }
}

const setMapJourneyDuration = (duration) => {
  return {
    type: key,
    [uiConstants.MAP_JOURNEY_DURATION]: duration
  }
}

export {
  openNav,
  closeNav,
  setNavTabIndex,
  setPageTitle,
  openModal,
  closeModal,
  hideOnboardingScreen,
  setPageMeta,
  updatePushPermissionsCheckbox,
  setMapJourneyDuration
}
