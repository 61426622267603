import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import styled from 'styled-components'
import media from 'src/styling/media'
import colors from 'src/styling/colors'
import Button from 'src/components/Button'
import TextField from 'src/components/Fields/Text'
import SearchSvg from 'src/assets/Search.svg'
import { useInternationalisation } from 'src/context'

const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  ${(p) => (p.align ? `align-items: flex-start;` : '')}
  ${media.greaterThan('md')`
    max-width: 650px;
  `}
  ${media.greaterThan('lg')`
    max-width: 650px;
  `}
`
const H2 = styled.h2`
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  color: ${colors.textColor};
  padding-bottom: 33px;

  ${media.greaterThan('lg')`
    font-size: 75px;
    letter-spacing: -1.7px;
  `}
`

const H2H = styled.h2`
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  color: ${colors.textColor};
  padding-bottom: 33px;

  ${media.greaterThan('lg')`
    font-size: 65px;
    letter-spacing: -1.7px;
  `}
`

const SearchFieldContainer = styled.div`
  width: 100%;
  > * {
    display: flex;
    width: 100%;
  }
`

const ContentWithAddressLookup = ({
  title,
  contentPage,
  children,
  align,
  history
}) => {
  const [address, setAddress] = React.useState('')

  const { translate, translateUrl } = useInternationalisation()

  const handleAddressSubmit = (e) => {
    const address = _get(e, 'target.value', '')
    setAddress(address)
  }

  const Title = contentPage === 'home' ? H2H : H2

  return (
    <Container align={align}>
      <Title>{title}</Title>
      {children}
      <SearchFieldContainer>
        <TextField
          value={address}
          onChange={handleAddressSubmit}
          label={translate('ENTER_POSTCODE')}
          placeholder={translate('ENTER_POSTCODE')}
          leftAlign
          iconSvg={SearchSvg}
          onKeyPress={(event) => {
            event.key === 'Enter' &&
              history.push(translateUrl(`/getting-here?address=${address}`))
          }}
        />
        <Button to={`/getting-here?address=${address}`} buttonType={`primary`}>
          {translate('BUTTON_LABEL_SUBMIT')}
        </Button>
      </SearchFieldContainer>
    </Container>
  )
}

export default ContentWithAddressLookup
