import key from './key'

const actionTypes = {
  LOGIN: `${key}/LOGIN`,
  LOGOUT: `${key}/LOGOUT`,
  RESTORE: `${key}/RESTORE`,
  SIGN_UP: `${key}/SIGN_UP`,
  UPDATE: `${key}/UPDATE`,
  CREATE_SAVED: `${key}/CREATE_SAVED`,
  REMOVE_SAVED: `${key}/REMOVE_SAVED`,
  CREATE_USER_EVENT: `${key}/CREATE_USER_EVENT`,
  CREATE_USER_PROMOTION: `${key}/CREATE_USER_PROMOTION`,
  FETCH_SAVED_PRODUCTS: `${key}/FETCH_SAVED_PRODUCTS`
}

export default actionTypes
