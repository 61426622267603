import React, { useCallback } from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import media from 'src/styling/media'
import vars from 'src/styling/vars'
import { closeModal } from 'src/store/ui/actionCreators'

import { useSelector, useDispatch } from 'react-redux'
import uiSelector from 'src/store/ui/selector'
import uiConstants from 'src/store/ui/constants'
import Container from 'src/components/Container'
import CloseSvg from 'src/assets/Close.svg'

const CloseButtonWrapper = styled.div`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  text-align: right;
`

const CloseButton = styled.button`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  top: 57px;
  z-index: 9;
  ${media.greaterThan('md')`
    top: 73px;
  `}
`

const ContentWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`

const { IS_MODAL_OPEN, MODAL_ID } = uiConstants

const BaseModal = ({ id, children }) => {
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    dispatch(closeModal({ id }))
  }, [])

  const isModalOpen = useSelector(uiSelector(IS_MODAL_OPEN))
  const modalId = useSelector(uiSelector(MODAL_ID))

  return (
    <DialogOverlay
      isOpen={isModalOpen && modalId === id}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.darkGrey,
        zIndex: vars.zIndex.modalOverlay
      }}
    >
      <Container>
        <CloseButtonWrapper>
          <CloseButton onClick={onClose}>
            <CloseSvg aria-label={'Close'} color={colors.white} />
          </CloseButton>
        </CloseButtonWrapper>
        <ContentWrapper>
          <DialogContent
            style={{
              width: '100%',
              padding: 0,
              backgroundColor: colors.darkGrey,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {children}
          </DialogContent>
        </ContentWrapper>
      </Container>
    </DialogOverlay>
  )
}

export default BaseModal
