import React from 'react'
import fp from 'lodash/fp'
import { Helmet } from 'react-helmet-async'
import { getCentreConfig } from 'src/config'
import { useCentre } from 'src/store/resources/hooks'

const OurHelmet = () => {
  const centre = useCentre()
  const language = fp.compose(
    // If the code is like 'en-gb', truncate to 'en'.
    fp.first,
    fp.split('-'),
    // fallback to english
    fp.defaultTo('en'),
    // failing that get locale from contentful
    // unfortunately due to changes over time, it appears this never selects french.
    fp.defaultTo(fp.get('sys.locale', centre)),
    // get local from config
    fp.get('defaultLocale')
  )(getCentreConfig())

    return (
      <Helmet>
        <html lang={language} />
      </Helmet>
    )
}

export default OurHelmet
