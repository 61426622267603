import React from 'react'
import { getHost } from 'src/config'
import _ from 'lodash'
import TagManager from 'react-gtm-module'
import { Redirect } from 'src/components/RouterDom'
import env, { isBrowser, isCordova } from 'src/env'
import { useCentre } from 'src/store/resources/hooks'
import fp from 'lodash/fp'
import { getFields } from 'src/utility'
import { useInternationalisation } from 'src/context'
import IframeMap from './IframeMap'
import qs from 'query-string'

/**
 * A wrapper around an iframe with props to control the pointr 'sdk'.
 * @param {object} props
 * @param {string} props.poiId unique ID for shops and other facilities.
 * @param {number} props.initialZoomLevel Starting zoom level.
 * @param {boolean} props.enableSearch Allow users to search for facilities within the map.
 */
const PointrWebMap = () => {
  const centre = useCentre()
  const ref = React.createRef()
  const { isFrenchCentre } = useInternationalisation()
  React.useEffect(() => {
      if (isCordova && window.PointrPlugin) {
          window.PointrPlugin.setPreferredLanguage(fp.noop, isFrenchCentre ? 'fr' : 'en')
          window.PointrPlugin.showMap()
      }
  }, [isCordova])
  
  if (isCordova) {
    return <Redirect to='/' />
  }

  const pointrConfiguration = fp.compose(
    fp.pick([
      'mapInitialZoomLevel',
      'mapInitialLocation',
      'mapInitialLocationZoom'
    ]),
    getFields
  )(centre)

  const host = getHost()

  const params = {
    initialZoomLevel: pointrConfiguration.mapInitialZoomLevel,
    initialMapLocation: pointrConfiguration.mapInitialLocation,
    initialMapLocationZoom: pointrConfiguration.mapInitialLocationZoom,
  }

  if (_.isUndefined(host)) {
    return null
  }
  
  return (
    <IframeMap
      ref={ref}
      title="Pointr Map"
      // src={`http://localhost:8000/centre-maps-service?${qs.stringify(params)}`}
      src={`https://${env.envKey === 'prod' ? 'www.' : ''}${getHost()}/centre-maps-service?${qs.stringify(params)}`}
    />
  )
}

export default PointrWebMap
