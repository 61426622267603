import React from 'react'
import ContentLoader from 'react-content-loader'
import BaseTile from './BaseTile'
import times from 'lodash/times'
import random from 'lodash/random'
import TilesWrapper from './TilesWrapper'

const loadingTile = () => (
  <TilesWrapper>
    {times(random(1, 4), (index) => (
      <BaseTile id={index} key={index}>
        <ContentLoader
          height={300}
          width={300}
          speed={3}
          primaryColor='#f3f3f3'
          secondaryColor='#ecebeb'
        >
          <rect x='0' y='0' rx='0' ry='0' width='300' height='180' />
          <rect x='50' y='205' rx='0' ry='0' width='210' height='15' />
          <rect x='40' y='230' rx='0' ry='0' width='230' height='15' />
          <rect x='50' y='255' rx='0' ry='0' width='210' height='15' />
        </ContentLoader>
      </BaseTile>
    ))}
  </TilesWrapper>
)

export default loadingTile
