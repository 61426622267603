import React, { useCallback } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { Link, withRouter } from 'src/components/RouterDom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import CarouselContainer from 'src/components/Carousel/CarouselContainer'
import {
  getEventDatesRangeString,
  getAssetSrc,
  getFields,
  showHeartResourceTypes,
  getUrl
} from 'src/utility'
import { createSaved, removeSaved } from 'src/store/account/actionCreators'

import { isCordova } from 'src/env'
import SavedHeart from 'src/components/SavedHeart'
import { attributesSelector } from 'src/store/account/selectors'
import Placeholder from 'src/assets/Placeholder.png'
import { useInternationalisation } from 'src/context'
import { PlusExclusiveBadge } from 'src/components/Badges'
import ClampLines from 'src/components/ClampLines'
import HorizontalScollDisabler from './HorizontalScrollDisabler'

const FeatureItemWrapper = styled.div`
  width: 90vw !important;
`

const FeatureItemInner = styled.div`
  background-color: white;
  box-shadow: 0px 8px 30px 0 rgba(66, 66, 66, 0.1);
  margin-left: 1rem;
  margin-bottom: 1rem;
`

const ImageWrapper = styled.div`
  height: 0%;
  padding-top: 52%;
  position: relative;
`

const Image = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${({ image }) => image});
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: ${(p) => (p.contain ? 'contain' : 'cover')};
  background-position: center center;
  background-color: #e6e6e6;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px;
`

const DetailsWrapper = styled.div`
  padding: 10px;
`

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
`

const Date = styled.span`
  display: block;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  margin-bottom: 1rem;
  &:after {
    content: '';
    width: 50%;
    height: 2px;
    background-color: #262a30;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%);
  }
`
const DateText = styled.span`
  display: block;
  max-width: 45%;
  font-size: 14px;
`

const TitleText = styled(ClampLines).attrs((p) => ({
  text: p.children || '',
  lines: 2,
  buttons: false,
  ellipsis: '...',
  innerElement: 'h3'
}))`
  display: block;
  height: 58px;
  font-size: 22px;
  margin-bottom: 1rem;
`

const defaultSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  swipeToSlide: true,
  touchThreshold: 100,
  arrows: false,
  variableWidth: true,
  lazyLoad: 'progressive'
}

const Slide = (props, index) => {
  const { resource, onClick } = props
  const { moment, defaultLocale } = useInternationalisation()
  const { title, image, date, isPlusExclusive } = React.useMemo(() => {
    const { title, startDate, endDate, listImage, isPlusExclusive } = getFields(
      resource
    )
    const image = getAssetSrc(listImage)
    const date = getEventDatesRangeString({
      moment,
      from: startDate,
      to: endDate,
      localeCode: defaultLocale
    })
    return { title, image, date, isPlusExclusive }
  }, [resource])

  let { id, content_id, savedId, content_type } = resource || {}
  const to =
    content_type === 'product'
      ? `/saved/${_.get(resource, 'id')}`
      : getUrl(resource)

  const showSavedHeart = showHeartResourceTypes.includes(content_type)

  return (
    <FeatureItemWrapper key={index}>
      <FeatureItemInner>
        <StyledLink to={to}>
          <ImageWrapper>
            <Image image={image || Placeholder} contain={!image}>
              {showSavedHeart && isCordova && (
                <SavedHeart
                  size={40}
                  saved={savedId}
                  handleOnHeartClick={() =>
                    props.handleOnHeartClick({
                      savedId,
                      content_id,
                      content_type
                    })
                  }
                  position={'relative'}
                />
              )}
              {isPlusExclusive && <PlusExclusiveBadge position={'relative'} />}
            </Image>
          </ImageWrapper>

          <DetailsWrapper>
            <Date>
              <DateText>{date}</DateText>
            </Date>
            <TitleText>{title}</TitleText>
          </DetailsWrapper>
        </StyledLink>
      </FeatureItemInner>
      {/* {content_type === 'product' && (
        <ProductModal
          {...getFields(resource)}
          imageSrc={image}
          id={_.get(resource, 'id')}
        />
      )} */}
    </FeatureItemWrapper>
  )
}

const FeaturesCarousel = (props) => {
  const { resources, history, slideLimit } = props

  const userAttributes = useSelector(attributesSelector())
  const { translateUrl } = useInternationalisation()

  const dispatch = useDispatch()
  const handleOnHeartClick = useCallback(
    ({ savedId, content_id, content_type }) => {
      if (!userAttributes) history.push(translateUrl('/saved'))
      if (!savedId) {
        dispatch(
          createSaved({
            content_id,
            content_type
          })
        )
      } else {
        dispatch(
          removeSaved({
            id: savedId
          })
        )
      }
    },
    []
  )

  let resourcesForSlides = _.filter(resources, (resource) =>
    _.get(resource, 'fields.title')
  )
  if (slideLimit) resourcesForSlides = resourcesForSlides.slice(0, slideLimit)

  return (
    <HorizontalScollDisabler>
      <CarouselContainer fixedHeight={false}>
        <Slider {...defaultSettings}>
          {_.map(resourcesForSlides, (resource, index) => (
            <Slide
              resource={resource}
              key={index}
              handleOnHeartClick={handleOnHeartClick}
            />
          ))}
        </Slider>
      </CarouselContainer>
    </HorizontalScollDisabler>
  )
}

export default React.memo(withRouter(FeaturesCarousel))
