import React, { Fragment, useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import _isError from 'lodash/isError'
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage as FormikErrorMessage
} from 'formik'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import _ from 'lodash'

import TextField from 'src/components/Fields/Text'
import Button from 'src/components/Button'
import ErrorMessage from 'src/components/ErrorMessage'
import { Wrapper as FieldBaseWrapper } from 'src/components/Fields/common/FieldBase'
import Message from 'src/components/Message'
import { useInternationalisation } from 'src/context'
import { renderURLInText } from 'src/utility'

const FormWrapper = styled.div`
  width: 100%;
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;

  & > * {
    padding: 0 10px;
    margin-bottom: 20px;
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`

const CentredErrorMessage = styled(ErrorMessage)`
  text-align: center;
  padding-bottom: 20px;
`

const Form = (props) => {
  const {
    fieldSchema,
    validationSchema,
    initialValues: initialValuesProp,
    onSubmit,
    children,
    buttonLabel,
    parseError,
    noSubmitButton,
    successMessage,
    resetOnSuccess
  } = props

  const [succeeded, setSucceeded] = React.useState(false)
  const { translate } = useInternationalisation()

  const initialValues = useMemo(() => {
    const initialValuesFromSchema = fieldSchema.reduce((acc, fieldConfig) => {
      acc[fieldConfig.name] = fieldConfig.initialValue || ''
      return acc
    }, {})
    const allInitialValues = {
      ...initialValuesFromSchema,
      ...initialValuesProp
    }
    return allInitialValues
  }, [fieldSchema])

  const renderForm = useCallback(
    (formProps) => {
      const { values, errors, isSubmitting } = formProps
      const submitError = _get(errors, 'submit')
      const submitErrorText =
        typeof submitError === 'string'
          ? submitError
          : submitError && submitError.message
      return (
        <FormikForm>
          <FieldsWrapper>
            {fieldSchema.map((fieldConfig) => {
              const {
                autoComplete,
                component,
                name,
                label,
                translateConfig,
                type,
                helpText
              } = fieldConfig

              const fieldProps = {
                autoComplete: autoComplete || 'off',
                component: component || TextField,
                key: name,
                name,
                label: _.isObject(label) ? label : translate(label),
                helpText: translate(helpText),
                type: type || 'text',
                value: values[name],
                ..._pick(fieldConfig, [
                  'inlineRight',
                  'iconSvg',
                  'options',
                  'readOnly',
                  'width',
                  'rows',
                  'autoFocus',
                  'hidePlaceholder',
                  'disabled',
                  'required',
                  'onClick'
                ])
              }
              return <Field {...fieldProps} />
            })}
          </FieldsWrapper>
          {submitErrorText && (
            <CentredErrorMessage
              children={renderURLInText({
                text: submitErrorText,
                url: '/contact-us'
              })}
            />
          )}
          {!noSubmitButton && (
            <SubmitButton
              type='submit'
              children={translate(buttonLabel)}
              buttonType='primary'
              fullWidth
              isLoading={isSubmitting}
            />
          )}
        </FormikForm>
      )
    },
    [fieldSchema, buttonLabel]
  )

  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting, resetForm }) => {
      return Promise.resolve()
        .then(() => {
          const submitPayload = validationSchema
            ? validationSchema.cast(values)
            : values
          return onSubmit(submitPayload)
        })
        .then(() => {
          setSucceeded(true)
          if (resetOnSuccess) resetForm()
        })
        .catch((error) => {
          if (error) {
            setErrors({
              submit: parseError
                ? translate(parseError(error))
                : translate('ERROR_GENERIC')
            })
            setSucceeded(false)
          }
        })
        .then(() => {
          setSubmitting(false)
        })
    },
    [onSubmit, parseError, validationSchema]
  )

  const formikProps = {
    onSubmit: handleSubmit,
    render: renderForm,
    initialValues,
    validationSchema
  }

  const successMessageEl =
    succeeded && successMessage ? (
      <Message text={successMessage} type={'success'} />
    ) : null

  return (
    <FormWrapper>
      <Formik {...formikProps} />
      {successMessageEl}
      {children}
    </FormWrapper>
  )
}

export default Form
