import React from 'react'
import _get from 'lodash/get'
import { useInternationalisation } from 'src/context'
import ShareSvg from 'src/assets/Share.svg'
import styled from 'styled-components'
import AssetIcon from 'src/components/AssetIcon'
import { isBrowser } from 'src/env'

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 1rem;
`

const ButtonText = styled.span`
  margin-left: 6px;
  margin-top: 6px;
  text-decoration: underline;
`

const NativeShareButton = ({ url, message }) => {
  const { translate } = useInternationalisation()
  return (
    <Button
      onClick={() => {
        const socialsharing = isBrowser && _get(window, 'plugins.socialsharing')
        if (socialsharing) {
          const options = {
            url,
            message
          }
          socialsharing.shareWithOptions(options)
        }
      }}
    >
      <AssetIcon svg={ShareSvg} size={40} />
      <ButtonText>{translate('SHARE_TITLE')}</ButtonText>
    </Button>
  )
}

export default NativeShareButton
