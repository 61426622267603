import colors from 'src/styling/colors'
import media from 'src/styling/media'
import styled from 'styled-components'

const H3 = styled.h3`
  max-width: 100%;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1.2;
  color: ${colors.darkGrey};
  ${media.greaterThan('lg')`
    font-size: 25px;
    margin-left: 8px;
  `}
`

export default H3
