import React, { useCallback } from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import media from 'src/styling/media'
import vars from 'src/styling/vars'
import { closeModal } from 'src/store/ui/actionCreators'

import { useSelector, useDispatch } from 'react-redux'
import uiSelector from 'src/store/ui/selector'
import uiConstants from 'src/store/ui/constants'
import Container from 'src/components/Container'
import CloseSvg from 'src/assets/Close.svg'

const CloseButtonWrapper = styled.div`
  position: fixed;
  top: 10px;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  right: 10px;
  z-index: 9;
`

const ContentWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`

const ContentContainer = styled(Container)`
  // Container has auto horizontal margins by default
  // this stops centering using flex from working in IE11
  margin: 0;
`

const DefaultModal = ({ type, children, onClose, isModalOpen }) => {
  return (
    <DialogOverlay
      isOpen={isModalOpen}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.darkGrey,
        zIndex: vars.zIndex.modalOverlay
      }}
    >
      <ContentContainer>
        <CloseButtonWrapper>
          {onClose && (
            <button onClick={onClose}>
              <CloseSvg aria-label={'Close'} color={colors.white} />
            </button>
          )}
        </CloseButtonWrapper>
        <ContentWrapper>
          <DialogContent
            style={{
              width: '100%',
              padding: 0,
              backgroundColor: colors.darkGrey,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 0
            }}
          >
            {children}
          </DialogContent>
        </ContentWrapper>
      </ContentContainer>
    </DialogOverlay>
  )
}

export default DefaultModal
