import React, { Fragment, useState, useCallback } from 'react'
import * as Yup from 'yup'
import cognitoService from 'src/services/cognitoService'
import validators from 'src/validators'

import EmailSvg from 'src/assets/Email.svg'
import Form from 'src/components/Form'
import FieldsWrapper from './FieldsWrapper'

import { useInternationalisation } from 'src/context'

const validationSchema = Yup.object({
  email: validators.email()
})

const fieldSchema = [
  {
    name: 'email',
    label: 'INPUT_EMAIL',
    autoComplete: 'email',
    type: 'email',
    iconSvg: EmailSvg
  }
]

const ForgotPasswordForm = (props) => {
  const { translate } = useInternationalisation()

  const onSubmit = useCallback((values) => {
    const { email } = values
    return cognitoService.requestPasswordReset({ email }).catch((error) => {
      if (error.code !== 'UserNotFoundException') throw error
    })
  }, [])

  const formProps = {
    onSubmit,
    buttonLabel: 'BUTTON_LABEL_SUBMIT',
    title: 'INPUT_FORGOT_PASSWORD',
    fieldSchema,
    validationSchema,
    parseError: cognitoService.parseError,
    successMessage: translate('FORGOT_PASSWORD_FORM_SUCCESS')
  }
  return <FieldsWrapper children={<Form {...formProps} />} />
}

export default ForgotPasswordForm
