import React from 'react'
import styled from 'styled-components'
import Button from 'src/components/Button'
import H2 from 'src/components/H2'
import { useInternationalisation } from 'src/context'
import colors from 'src/styling/colors'

const Container = styled.div`
  display: flex;
  overflow-wrap: break-word;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: ${colors.white};
  height: 35vh;
  border-radius: 10px;
  padding: 20px;
  max-width: 90vw;
  h2 {
    overflow-wrap: break-word;
  }
`

const RedemptionError = ({ title, children, onClose }) => {
  const { translate } = useInternationalisation()
  return (
    <Container>
      <H2 small>{title}</H2>
      {children}
      <Button buttonType='primary' onClick={onClose}>
        {translate('DISMISS')}
      </Button>
    </Container>
  )
}

export default RedemptionError
