import React from 'react'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import { renderURLInText } from 'src/utility'

const Content = styled.div`
  background-color: ${(p) =>
    p.type === 'success' ? colors.success : colors.error};
  padding: 1rem;
  margin-top: 2rem;
  text-align: center;
`

const Message = ({ type, text }) => {
  return (
    <Content type={type}>
      <p>{renderURLInText({ text, url: '/contact-us' })}</p>
    </Content>
  )
}

export default Message
