import React from 'react'
import _ from 'lodash'
const TRANSLATION = 'translation'
const INTERACTION_TYPE = 'interactionType'
const CONTINOUS = 'continous'
const configuration = {
  Offer: {
    name: 'offer'
  },
  Competition: {
    name: 'competition',
    actions: [{ type: INTERACTION_TYPE, payload: 'enter' }]
  },
  Reward: {
    name: 'reward',
    actions: [{ type: CONTINOUS, payload: true }]
  }
}

export const useContentTypeCustomisations = ({ categoryType }) => {
  if (!categoryType) return {}
  const config = configuration[categoryType]
  return [
    { type: TRANSLATION, payload: 'BUTTON_SIGN_UP' },
    { type: TRANSLATION, payload: 'BUTTON_REDEEM' },
    { type: TRANSLATION, payload: 'BUTTON_SCAN_REDEEM' },
    { type: TRANSLATION, payload: 'BUTTON_LOADING' },
    { type: TRANSLATION, payload: 'BUTTON_REDEEMED' },
    { type: TRANSLATION, payload: 'BUTTON_REMAINING' },
    { type: INTERACTION_TYPE, payload: 'redeem' },
    { type: TRANSLATION, payload: 'BUTTON_REDEEMED_TODAY' },
    { type: TRANSLATION, payload: 'BUTTON_REDEEMED_UNIVERSALLY' },
    { type: TRANSLATION, payload: 'BUTTON_BEFORE_START_DATE' },
    ..._.get(config, 'actions', [])
  ].reduce((memo, action = {}) => {
    const { type, payload } = action || {}
    switch (type) {
      case INTERACTION_TYPE:
        memo[_.camelCase(INTERACTION_TYPE)] = payload
        break
      case TRANSLATION:
        memo[_.camelCase(payload)] = `PROMOTION_${_.upperCase(
          config.name
        )}_${payload}`
        break
      case CONTINOUS:
        memo[_.camelCase(CONTINOUS)] = payload
        break
      default:
        break
    }
    return memo
  }, {})
}
