import React from 'react'

import { useInternationalisation } from 'src/context'
import { getCentreConfig } from 'src/config'
import env, { isBrowser } from 'src/env'
import Page, { getPageData } from 'src/components/Page'


const CritzrCustomerSatPage = (props) => {
  const { critizrApiKey } = env
  const { critizrCentreKey } = getCentreConfig() || {}
  const { isFrenchCentre } = useInternationalisation()

  const renderQuizForm = () => {
    if (isBrowser) {
      if (critizrCentreKey && critizrApiKey) {
        return (
          <a
            className="critizr-widget"
            href={`https://critizr.com/widgets/hammerson/${critizrCentreKey}/`}
            data-type="store_button:stars"
            data-params={`{"tag":"post-visit","mode":"quiz","lang":${isFrenchCentre ? '"fr"' : '"en"'}}`}>Critizr: Send feedback</a>
        )
      }
    }
    return null
  }

  return (
    <Page box inner>
      {renderQuizForm()}
    </Page>
  )
}

CritzrCustomerSatPage.getData = (props) => {
  return getPageData(props)
}

export default CritzrCustomerSatPage
