import React, { Fragment } from 'react'
import _flow from 'lodash/flow'
import _ from 'lodash'
import qs from 'query-string'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'src/components/RouterDom'
import { useRequest } from 'src/store/resources/hooks'
import { fetchResources } from 'src/store/resources/actionCreators'
import {
  getIdFromParams,
  getFirstResource,
  getFields,
  getResourceId,
  getFirstResourceFields,
  getAssetSrc,
  getResources,
  getEnableRegistrationLogIn
} from 'src/utility'
import usePromiseState from 'src/hooks/usePromiseState'
import contentfulService from 'src/services/contentfulService'
import DetailsPage from 'src/components/DetailsPage'
import NotFound from 'src/pages/NotFound'
import Button from 'src/components/Button'
import ErrorMessage from 'src/components/ErrorMessage'
import Spacing from 'src/components/Spacing'
import { attributesSelector } from 'src/store/account/selectors'
import { createUserEvent } from 'src/store/account/actionCreators'
import { useInternationalisation } from 'src/context'
import { getCentreConfig } from 'src/config'
import Head from 'src/components/Head'
import Page from 'src/components/Page'
import { isCordova } from 'src/env'
import LoadingPlaceholder from 'src/pages/Promotion/loading'
import PlusPromoTile from 'src/components/PlusPromoTile'

const resourceType = 'event'

let RsvpButton = (props) => {
  const { translate } = useInternationalisation()
  const { event, location } = props
  const attributes = useSelector(attributesSelector())
  const dispatch = useDispatch()
  const isLoggedIn = !!attributes
  const pathname = _.get(location, 'pathname')
  const eventId = getResourceId(event)

  const { isLoading, error, wrapPromise } = usePromiseState()

  const buttonProps = React.useMemo(() => {
    const { totalCapacity, totalRsvps } = getFields(event)

    let buttonText
    let onClick
    let to
    let disabled

    if (isLoggedIn) {
      const alreadyRsvpd =
        _.filter(attributes.user_events, (userEvent) => {
          const { interaction_type, event_id } = userEvent || {}
          return interaction_type === 'rsvp' && event_id === eventId
        }).length > 0

      if (alreadyRsvpd) {
        buttonText = translate('EVENT_ALREADY_RSVPD')
        disabled = true
      } else {
        let capacityText = ''
        let isThereSpace = true
        if (totalCapacity) {
          const spacesRemaining = Math.max(
            Number(totalCapacity) - Number(totalRsvps),
            0
          )
          isThereSpace = spacesRemaining > 0
          capacityText = ` (${spacesRemaining}/${totalCapacity} ${translate(
            'EVENT_SPACES_REMAINING'
          )})`
        }

        buttonText =
          translate(isThereSpace ? 'EVENT_RSVP' : 'EVENT_SOLD_OUT') +
          capacityText
        if (isThereSpace) {
          onClick = () => {
            const payload = {
              event_id: eventId,
              interaction_type: 'rsvp',
              guest_count: 1
            }
            wrapPromise(
              dispatch(createUserEvent(payload)).catch((err) => {
                throw new Error(translate('ERROR_GENERIC'))
              })
            )
          }
        } else {
          disabled = true
        }
      }
    } else {
      buttonText = translate('EVENT_LOGIN_TO_RSVP')
      to = `/login?${qs.stringify({ redirectTo: pathname })}`
    }

    return { children: buttonText, onClick, to, disabled }
  }, [event, attributes, pathname])

  return (
    <React.Fragment>
      <Button buttonType='primary' isLoading={isLoading} {...buttonProps} />
      {error ? <ErrorMessage children={error} /> : null}
      <Spacing height={20} />
    </React.Fragment>
  )
}
RsvpButton = withRouter(RsvpButton)

const Event = (props) => {
  const id = getIdFromParams(props)
  const request = useRequest({
    resourceType,
    requestKey: id
  })
  const { translate } = useInternationalisation()
  const { description, title = '', isRsvpRequired } = getFirstResourceFields(
    request
  )
  const resource = getFirstResource(request)
  const checkAttachmentExists = _.get(getFields(resource), 'eventAttachment')
  const isEventDownloadAllowed = _.get(getFields(resource), 'anonymousEventDownloadAllowed')
  const enableRegistrationLogIn = getEnableRegistrationLogIn(props)

  if (!resource && !request._status.pending) {
    return <NotFound />
  }

  return (
    <>
      <Head
        resource={resource}
        description={description}
        title={isCordova ? _.upperFirst(translate(_.toUpper(resourceType))) : title}
      />
      <DetailsPage request={request} LoadingPlaceholder={LoadingPlaceholder}>
        {resource && (
          <>
            {enableRegistrationLogIn && (isRsvpRequired || _.isUndefined(isRsvpRequired)) ? (
              _.get(resource, 'fields.isPlusExclusive') && !isCordova ? (
                <React.Fragment>
                  <PlusPromoTile />
                  <Spacing height={20} />
                </React.Fragment>
              ) : (
                  <RsvpButton event={resource} />
                )
            ) : (
                []
              )}

            {contentfulService.renderRichText(resource.fields.about)}
            {isEventDownloadAllowed && checkAttachmentExists ? (
              <Button
                {...resource}
                onClick={() => {
                  let ctaDownloadUrl = getAssetSrc(
                    _.get(resource, 'fields.eventAttachment')
                  )
                  return window.open(ctaDownloadUrl, '_blank')
                }}
                buttonType='primary'
              >
                {translate('EVENT_DOWNLOAD')}
              </Button>
            ) : null}
          </>
        )}
      </DetailsPage>
    </>
  )
}

Event.getData = async (props) => {
  const id = getIdFromParams(props)
  const { augmentWithApi = true } = getCentreConfig() || {}
  const { dispatch, host, locale } = props
  return dispatch(
    fetchResources({
      locale,
      where: { 'fields.urlName': id },
      resourceType,
      requestKey: id,
      host,
      augmentWithApi,
      include: 2
    })
  )
}
Event.identifier = 'EVENT'

export default Event
