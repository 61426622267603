import React, { useEffect } from 'react'
import _map from 'lodash/map'
import styled, { ThemeProvider } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import VisuallyHidden from '@reach/visually-hidden'
import * as Yup from 'yup'
import uiSelector from 'src/store/ui/selector'
import uiConstants from 'src/store/ui/constants'
import _get from 'lodash/get'
import { withRouter } from 'src/components/RouterDom'
import { getFields, getFirstResource } from 'src/utility'
import _flow from 'lodash/flow'

import validators from 'src/validators'
import Form from 'src/components/Form'
import UserSvg from 'src/assets/User.svg'
import EmailSvg from 'src/assets/Email.svg'
import SendSvg from 'src/assets/Send.svg'
import { darkTheme } from 'src/styling/themes'
import AssetIcon from 'src/components/AssetIcon'
import colors from 'src/styling/colors'
import _noop from 'lodash/noop'
import { iconSize } from 'src/components/Fields/common/vars'
import { useCentre } from 'src/store/resources/hooks'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import * as common from './common'
import { useRequest } from 'src/store/resources/hooks'

import { useInternationalisation } from 'src/context'

const validationSchema = Yup.object({
  first_name: validators.requiredString(),
  email: validators.email()
})

const getFormProps = ({ dispatch, translate, setIsSubmitted }) => ({
  onSubmit: async (values) => {
    await common.signUpForNewsletter(values)
    setIsSubmitted(true)
  },
  enableReinitialize: true,
  fieldSchema: [
    {
      name: 'first_name',
      label: translate('INPUT_FIRST_NAME'),
      autoComplete: 'given-name',
      iconSvg: UserSvg,
      required: true
    },
    {
      name: 'email',
      label: translate('INPUT_EMAIL'),
      autoComplete: 'email',
      type: 'email',
      iconSvg: EmailSvg,
      required: true,
      inlineRight: {
        component: 'button',
        props: {
          type: 'submit',
          children: (
            <>
              <VisuallyHidden>
                {translate('BUTTON_LABEL_SUBMIT')}
              </VisuallyHidden>
              <AssetIcon
                svg={SendSvg}
                size={iconSize}
                color={colors.disabledTextColor}
              />
            </>
          )
        }
      }
    }
  ],
  validationSchema,
  noSubmitButton: true
})

const TextArea = styled.section`
  text-align: center;
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  line-height: 2em;
`

const Terms = styled.section`
  font-size: 0.75em;
  color: ${colors.disabledTextColor};
  text-align: center;
  padding-top: 0.5em;
`

const SubmissionConfirmation = styled.div`
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.75em;
  padding: 0 10% 0 10%;
  text-align: center;
  font-size: 16px;
  padding: 5em;
  p {
    padding-top: 2em;
  }
`

const Container = styled.div`
  padding: 1em 0em 2em;
`

const NewsletterSignUpWidget = (props) => {
  const dispatch = useDispatch()
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const { translate } = useInternationalisation()
  const { name } = _flow([useCentre, getFields])()
  const { newsletterConsentText } = _flow([
    useRequest,
    getFirstResource,
    getFields
  ])({
    requestKey: 'layout',
    resourceType: 'layout'
  })

  React.useEffect(() => {
    if (isSubmitted) setIsSubmitted(false)
  }, [props.location.key])

  const formProps = React.useMemo(() => {
    return getFormProps({ dispatch, translate, setIsSubmitted })
  }, [dispatch, translate, setIsSubmitted])

  return !isSubmitted ? (
    <ThemeProvider theme={darkTheme}>
      <Container>
        <TextArea>
          <Title>{translate('FOOTER_DONT_MISS')}</Title>
          <p>{translate('FOOTER_DESCRIPTION')}</p>
        </TextArea>
        <Form key={props.location.key} {...formProps} />
        <Terms>{documentToReactComponents(newsletterConsentText)}</Terms>
      </Container>
    </ThemeProvider>
  ) : (
      <SubmissionConfirmation>
        <AssetIcon svg={SendSvg} size={64} color={colors.white} />
        <p>{translate('FOOTER_CONFIRMATION', { centre: name })}</p>
      </SubmissionConfirmation>
    )
}

export default withRouter(NewsletterSignUpWidget)
