import _ from 'lodash'
import env, { isCordova } from 'src/env'
import { getCentreKey } from 'src/config'

import { apiFetch, setAccessToken, setRefreshToken } from '../apiService'
/**
 * @typedef {object} ISession
 * @property {'twitter' | 'apple' | 'facebook'} providerId The social provider's name.
 * @property {string} accessToken Access token from a subclass
 */
/**
 * @typedef {object} IAuthContext
 * @property {string} token The social provider's token
 */
/**
 * An Abstract class to be implemented by sign-on services.
 */
export class AbstractAuthService {
    constructor() {
        /**
         * @type{boolean}
         * @description Check whether the auth service has been initalised.
         * The property acts as a guard so the function is not called where it can not run properly,
         * like on the server or when the cordova plug-in is not avaliable.
         */
        this.isInitialised = false
        this.instance = this.constructor
    }
    /**
    * @abstract
    * @description Starts the service and plug-in.
    * The initialise method is called by the {@link authenticate} / {@link authenticateHelper}.
    * However it should be implemented seperately,
    * as a plug-in might have other features like the FB plug-in can deal with marketing events as well as sign on.
    * So you might want to call it in `cordovaInit`.
    */
    initialise = async () => {
        throw new Error(`must be implemented by subclass ${this.instance.name}!`)
    }
    /**
    * @abstract
    * @description When implemented, this when called, should produce a log-in prompt for the user.
    * @returns {IAuthContext}
    */
    logIn = async () => {
        throw new Error(`must be implemented by subclass ${this.instance.name}!`)
    }
    /**
     * This function is called by the main sign-on code.
    * @abstract
    */
    authenticate = async () => {
        throw new Error(`must be implemented by subclass ${this.instance.name}!`)
    }
    canBeInitialised = () => {
        switch (true) {
            case !!env.fakeCordova:
                // console.log(`${this.instance.name} is not initialised (fake cordova)`)
                return false
            case !isCordova:
                // console.log(`${this.instance.name} is not initialised (not cordova)`)
                return false
            default:
                // console.log(`${this.instance.name} can be initialised`)
                return true
        }

    }
    /**
     * A helper function to implement the {@link authenticate} method.
     * @param {(authContext: IAuthContext) => ISession} fn A function that formats the returned auth object from the plugIn as a compatible session object.
     * @returns { Promise<{accessToken: string}>}
     */
    authenticateHelper = (fn) => async () => {
        await this.initialise()
        let session = null
        try {
            try {
                session = await this.logIn()
            } catch (error) {
                console.info(`caught error while calling logIn in authenticateHelper for ${this.instance.name} `)
                console.error(error)
                throw error
            }

            let providerId = ''
            let accessToken = ''
            try {
                ;({
                    providerId,
                    accessToken
                } = await fn(session))
            } catch (error) {
                console.info(`caught error while calling HOF in authenticateHelper for ${this.instance.name} `)
                console.error(error)
                throw error
            }
            const centreKey = getCentreKey()
            /**
             * Tokens returned from our payload
             */
            const authPayload = await apiFetch({
                path: `/auth/${centreKey}/${providerId}-token`,
                attemptRefresh: false,
                extraHeaders: {
                    /**
                     * Passport.js wil recognise and process this header.
                     */
                    'access_token': accessToken,
                }
            })
            setAccessToken(authPayload.token)
            setRefreshToken(authPayload.refreshToken)
            return { accessToken: authPayload.token }
        } catch (error) {
            // console.error(error)
            throw error
        }
    }
}