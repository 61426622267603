import React from 'react'
import {
  BrowserRouter,
  HashRouter,
  StaticRouter
} from 'src/components/RouterDom'

import { isBrowser, isCordova } from '../env'
import renderRoutes from './renderRoutes'

let RouterComponent = StaticRouter // server
if (isBrowser) RouterComponent = BrowserRouter // browser
if (isCordova) RouterComponent = HashRouter // cordova

const Router = (props) => {
  const { host, ...rest } = props
  return <RouterComponent {...rest}>{renderRoutes(host)}</RouterComponent>
}

export default Router
