import * as constants from './constants'
import apiService from 'src/services/apiService'

class ShopperTrakActions {
  readOccupancy = () => {
    return {
      type: constants.READ_OCCUPANCY,
      promise: () => {
        return apiService.readOccupancy()
      }
    }
  }
}

export default new ShopperTrakActions()