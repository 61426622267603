import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'

import DropdownSvg from 'src/assets/DropdownArrow.svg'
import FieldBase from './common/FieldBase'
import InputBase from './common/InputBase'
import SelectInput from './common/SelectInput'

const renderField = (props) => {
  return <InputBase {...props} component={SelectInput} iconSvg={DropdownSvg} />
}

const Select = (props) => {
  return <FieldBase {...props} renderField={renderField} />
}

export default Select
