import _ from 'lodash'
import { isCordova } from 'src/env'

const defaultOptions = {
  preferFrontCamera: false, // iOS and Android
  showFlipCameraButton: true, // iOS and Android
  showTorchButton: true, // iOS and Android
  torchOn: false, // Android, launch with the torch switched on (if available)
  saveHistory: false, // Android, save scan history (default false)
  resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
  formats: 'QR_CODE', // default: all but PDF_417 and RSS_EXPANDED
  orientation: 'landscape', // Android only (portrait|landscape), default unset so it rotates with the device
  disableAnimations: false, // iOS
  disableSuccessBeep: false // iOS and Android
}

export const promptForPermissions = () => {
  // to do
}

export const scan = (options = defaultOptions) => {
  return new Promise((resolve, reject) => {
    if (!isCordova) {
      throw new Error('QR_SCANNER_UNAVALIABLE_ON_WEB')
    }

    if (!_.has(window, 'cordova.plugins.barcodeScanner')) {
      throw new Error('QR_SCANNER_INACCESSIBLE')
    }

    window.cordova.plugins.barcodeScanner.scan(
      resolve,
      reject,
      _.defaults(options, defaultOptions)
    )
  })
}
