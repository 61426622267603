import React from 'react'
import styled from 'styled-components'

import media from 'src/styling/media'

const FieldsWrapper = styled.div`
  width: 100%;
  ${media.forEach({
    values: { sm: 300, md: 375 },
    getStyle: (x) => `max-width: ${x}px;`
  })}
  margin: 0 auto;
`

export default FieldsWrapper
