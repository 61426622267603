import React from 'react'
import i18next from 'i18next'
import _ from 'lodash'
import en from 'src/config/configFiles/translations/en'
import fr from 'src/config/configFiles/translations/fr'
import urlsEn from 'src/config/configFiles/urls/en'
import urlsFr from 'src/config/configFiles/urls/fr'
import { customMoment, replaceWords } from 'src/utility'
import { getCentreConfig } from 'src/config'

const InternationalisationContext = React.createContext()

export const getUrlTranslators = ({ host }) => {
  const { defaultLocale } = getCentreConfig({ host }) || {}
  const [defaultLang] = _.split(defaultLocale, '-')
  const urlsDict = { en: urlsEn, fr: urlsFr }[defaultLang || 'en']
  if (!defaultLang) {
    console.log(
      `WARNING: no default lang. using english for URLs. Host: ${host}`
    )
  }

  const transformUrl = (dict) => (path) => {
    return replaceWords({
      input: path,
      separators: ['/', '?', '#'],
      replacer: (word, wordIndex) => {
        if (!wordIndex) {
          return dict[word] || word
        } else {
          return word
        }
      }
    })
  }

  const translateUrl = transformUrl(urlsDict)
  const untranslateUrl = transformUrl(_.invert(urlsDict))

  return { translateUrl, untranslateUrl }
}

export const useInternationalisation = () => {
  return React.useContext(InternationalisationContext)
}

export const InternationalisationProvider = (props) => {
  const { locale, host } = props
  const { defaultLocale } = getCentreConfig({ host }) || {}
  const [language] = locale.split('-')
  const translate = React.useMemo(() => {
    i18next.init({
      lng: language,
      fallbackLng: defaultLocale,
      debug: false,
      keySeparator: false,
      resources: {
        en: { translation: en },
        fr: { translation: fr }
      }
    })

    return (key, options) => i18next.t(key, options)
  }, [language])

  const moment = React.useMemo(() => {
    const moment = customMoment({ host })
    return moment
  }, [host])

  const { translateUrl, untranslateUrl } = React.useMemo(() => {
    return getUrlTranslators({ host })
  }, [host])

  const isFrenchCentre = React.useMemo(() => {
    const { defaultLocale } = getCentreConfig({ host }) || {}
    return _.includes(defaultLocale, 'fr')
  }, [host])

  return (
    <InternationalisationContext.Provider
      value={{
        translate,
        locale,
        moment,
        defaultLocale,
        translateUrl,
        untranslateUrl,
        isFrenchCentre
      }}
    >
      {props.children}
    </InternationalisationContext.Provider>
  )
}
