import _ from 'lodash'
import accountKey from 'src/store/account/key'
import deviceKey from 'src/store/device/key'
import { getEmail } from 'src/store/account/selectors'
import { getRegistrationId, getPointrId } from 'src/store/device/selectors'
import hybridServices from 'src/services/hybridService'




const registerDeviceMiddleware = ({ getState, dispatch }) => {
  return (next) => (action) => {
    const { type } = action
    if (type.startsWith(accountKey) || type.startsWith(deviceKey)) {
      const stateBefore = getState()
      const emailBefore = getEmail(stateBefore)
      const registrationIdBefore = getRegistrationId(stateBefore)
      const pointrIdBefore = getPointrId(stateBefore)
      next(action)
      const stateAfter = getState()
      const emailAfter = getEmail(stateAfter)
      const registrationIdAfter = getRegistrationId(stateAfter)
      const pointrIdAfter = getPointrId(stateAfter)
      const hasDataChanged = !(
        registrationIdAfter === registrationIdBefore &&
        emailAfter === emailBefore &&
        pointrIdBefore === pointrIdAfter
      )
      if (hasDataChanged) {
        return hybridServices.syncUserDevice({ dispatch, state: stateAfter })
      }
    } else {
      next(action)
    }
  }
}

export default registerDeviceMiddleware
