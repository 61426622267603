import React from 'react'
import styled from 'styled-components'
import colors from 'src/styling/colors'

const Content = styled.div`
  color: ${colors.textColor};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
  justify-content: center;
  margin: 15px auto;

  h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    li {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '•';
        color: ${colors.darkGrey};
      }
    }
  }
`
const Text = ({ children, textAlign }) => {
  return children ? <Content textAlign={textAlign}>{children}</Content> : null
}
export default Text
