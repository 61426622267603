import React from 'react'
import _isNil from 'lodash/isNil'
import _filter from 'lodash/filter'
import { useInternationalisation } from 'src/context'
import {
    getFields,
    getHasFields
  } from 'src/utility'

export const getValidResources = (resources) => {
    const { moment } = useInternationalisation()
    return _filter(resources, (resource) => {
        const hasFields = getHasFields(resource)
        if (!hasFields) return
        const { endDate } = getFields(resource)
        const canExpire = !_isNil(endDate)
        const hasExpired = canExpire && moment(endDate).isSameOrBefore(new Date()) 
        return !hasExpired
    })
}