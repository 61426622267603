import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'src/components/RouterDom'
import styled from 'styled-components'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _capitalize from 'lodash/capitalize'
import Head from 'src/components/Head'

import apiService from 'src/services/apiService'

import { showHeartResourceTypes } from 'src/utility'
import SavedHeart from 'src/components/SavedHeart'
import Button from 'src/components/Button'
import { useInternationalisation } from 'src/context'
import Container from 'src/components/Container'
import Box from 'src/components/Box'
import { savedSelector } from 'src/store/account/selectors'
import SafeImage from 'src/components/SafeImage'
import WebViewLink from 'src/components/WebViewLink'
import FullImageModal from './FullImageModal'
import {
  fileSelector,
  fileUrlSelector,
  categorySelector,
  cropSelector,
  weightsSelector,
  productSelector,
  isLoadingSelector,
  currentPathSelector
} from 'src/store/styleSeeker/selectors'
import {
  setSimilar,
  setIsLoading,
  setCrop,
  setFile,
  setFileUrl,
  setCategory
} from 'src/store/styleSeeker/actionCreators'
import { createSaved, removeSaved } from 'src/store/account/actionCreators'

import { openModal } from 'src/store/ui/actionCreators'

import media from 'src/styling/media'
import colors from 'src/styling/colors'

const LinkButton = styled(Button)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`
const marginTop = 167
const tileSize = 265

const Content = styled.div`
  width: 100%;
  ${media.greaterThan('lg')`
    width: 500px;
    min-height: ${marginTop + tileSize}px;
  `}
`

const Title = styled.h2`
  font-size: 22px;
  font-weight: 700;
  padding: 15px;
`

const Details = styled.div`
  font-size: 16px;
  margin: 0;
  padding-bottom: 15px;
`

const ImageLink = styled(Link)`
  display: block;
  font-size: 11px;
  word-break: break-word;
  padding-bottom: 5px;
  margin-bottom: 10px;
`

const StockText = styled.p`
  font-size: 11px;
  padding-bottom: 15px;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 auto;
  height: 70px;
`

const ProductDetails = (props) => {
  const { history, product, onProductSave } = props || {}
  const dispatch = useDispatch()
  const { translate } = useInternationalisation()

  const saved = useSelector(savedSelector())
  const storedCategory = useSelector(categorySelector())
  const weights = useSelector(weightsSelector())

  const id = _get(product, 'id')
  const metadata = _get(product, 'metadata')
  const imageUrl = _get(product, 'imageUrl')
  const category = _get(product, 'categoryAlias')

  const {
    title,
    Retailer: retailer,
    brandName: brand,
    price: rawPrice,
    Currency: currency,
    description
  } = metadata || {}
  const price = rawPrice
    ? new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(rawPrice)
    : null

  const showSavedHeart = showHeartResourceTypes.includes('product')

  const savedId = _get(
    _find(
      saved,
      (item) => item.content_id === id && item.content_type === 'product'
    ),
    'id',
    null
  )

  const handleOnImageClick = useCallback(() => {
    dispatch(openModal({ id }))
  }, [id])

  return (
    <Container topGutter bottomGutter>
      <Head
        title={translate('PAGE_STYLE_SEEKER')}
      />
      <Box>
        <Content>
          {showSavedHeart && (
            <SavedHeart
              size={40}
              saved={savedId}
              handleOnHeartClick={() =>
                onProductSave({
                  savedId,
                  content_id: id,
                  content_type: 'product',
                  content_category: category
                })
              }
            />
          )}
          <div onClick={handleOnImageClick}>
            <SafeImage src={imageUrl} height={200} width={''} alt='Product' />
          </div>
          <FullImageModal imageSrc={imageUrl} id={id} />
          <Title>{title}</Title>
          <Details>{translate('STYLE_SEEKER_BRAND', { name: brand })}</Details>
          <Details>
            {translate('STYLE_SEEKER_RETAILER', { name: retailer })}
          </Details>
          <Details>{price}</Details>
          <StyledButton
            buttonType={'primary'}
            onClick={() => {
              dispatch(setIsLoading(true))
              history.goBack()
              dispatch(setCrop(null))
              dispatch(setFileUrl(imageUrl))
              return apiService
                .styleSeekerSimilar({
                  fileUrl: imageUrl,
                  categoryAlias: storedCategory,
                  crop: null,
                  weights,
                  content_id: null
                })
                .then(({ similarProducts, categoryAlias }) => {
                  dispatch(setSimilar(similarProducts))
                  dispatch(setCategory(categoryAlias))
                  dispatch(setIsLoading(false))
                })
                .catch((err) => {
                  console.log(err)
                  dispatch(setIsLoading(false))
                })
            }}
          >
            {translate('STYLE_SEEKER_FIND_SIMILAR')}
          </StyledButton>
          <LinkButton buttonType='primary' to={`/shop`}>
            {translate('STYLE_SEEKER_VIEW_STORES')}
          </LinkButton>
          <WebViewLink url={imageUrl} fontSize={11}>
            {imageUrl}
          </WebViewLink>
          <StockText>{translate('STYLE_SEEKER_STOCK_TEXT')}</StockText>
        </Content>
      </Box>
    </Container>
  )
}

export default ProductDetails
