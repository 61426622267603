import { createClient } from 'contentful'
import _ from 'lodash'
import map from 'lodash/map'
import get from 'lodash/get'
import _identity from 'lodash/identity'

import { getCentreConfig } from 'src/config'
import apiService from 'src/services/apiService'

const contentfulClient = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  host: process.env.REACT_APP_CONTENTFUL_HOST || 'cdn.contentful.com'
})

const transformEntry = (entry) => {
  const id = get(entry, 'sys.id')
  return {
    ...entry,
    id
  }
}

const resourceTypesToFetchFromAPI = ['event', 'promotion']

export const fetchResource = async ({
  id,
  locale,
  resourceType,
  augmentWithApi = false
}) => {
  if (!id) throw new Error('id required to fetch resource')
  return contentfulClient
    .getEntry(id, { locale })
    .then(transformEntry)
    .then(async (result) => {
      if (
        augmentWithApi &&
        resourceTypesToFetchFromAPI.includes(resourceType)
      ) {
        const resourceFromApi = await apiService.readResource({
          id,
          resourceType
        })
        const { id: _id, ...extraFields } = resourceFromApi
        return { ...result.fields, ...extraFields }
      } else {
        return result
      }
    })
}

export const fetchResources = ({
  resourceType,
  where,
  select,
  order,
  skip,
  limit,
  include,
  centreId,
  centreKey,
  locale,
  augmentWithApi = false
}) => {
  const params = {
    locale,
    content_type: resourceType,
    skip,
    limit,
    include,
    order,
    select,
    ...where
  }

  if (resourceType !== 'centre') {
    params['fields.centre.sys.id'] = centreId
  }

  const res = contentfulClient
    .getEntries(params)
    .then((result) => {
      // avoid passing includes into the redux store bc its a huge circular object
      const { items, includes, sys, ...rest } = result || {}
      return {
        ...rest,
        resources: map(items, transformEntry)
      }
    })
    .then((result) => {
      if (
        augmentWithApi &&
        resourceTypesToFetchFromAPI.includes(resourceType)
      ) {
        return Promise.all(
          _.map(result.resources, async (resource) => {
            const resourceFromApi = await apiService.readResource({
              id: resource.id,
              resourceType,
              centreKey,
              query: resource.query
            })
            const { id, ...extraFields } = resourceFromApi
            resource.fields = { ...resource.fields, ...extraFields }
          })
        ).then(() => result)
      } else {
        return result
      }
    })
    .then(async (result) => {
      const urlName = params['fields.urlName']
      // check if page exists in draft mode.
      if (urlName && resourceType === 'page' && result.total === 0 && process.env.REACT_APP_CONTENTFUL_HOST !== 'preview.contentful.com') {
        const statusFromAPI = await apiService.readResourceStatus({
          resourceType,
          urlName,
          centreKey,
        })
        const { status } = statusFromAPI || {}
        result = { ...result, contentStatus: status }
      }
      return result
    })
    .then((result) => result)
  return res
}
