import env, { isBrowser, isCordova } from 'src/env'
import fp from 'lodash/fp'

/**
 *
 * @param {string} url
 * @param {object?} options
 * @param {number?} options.height Defaults to `400`.
 * @param {number?} options.width Defaults to `400`.
 * @param {boolean?} options.centrePopUp Whether the Pop-up Window should be centered on the screen. Defaults to `false`.
 * @param {Function} callback
 */
const open = (url, options, callback) => {
  if (!isBrowser) return
  const { height = 400, width = 400, centrePopUp = false } = options || {}
  const webOptions = { toolbar: 0, menubar: 0, height, width }
  if (centrePopUp) {
    webOptions.top = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
    webOptions.left = window.top.outerWidth / 2 + window.top.screenX - (height / 2);
  }

  const webOptionArgs = fp.compose(
    fp.join(','),
    fp.map(fp.join('=')),
    fp.toPairs
  )(webOptions)

  const openOpts = isCordova
    ? `location=no,footer=no,enableViewportScale=yes,keyboardDisplayRequiresUserAction=no,usewkwebview=yes`
    : webOptionArgs
  const opened = window.open(url, '_blank', openOpts)
  let close = () => { }
  const messageListener = (event) => {
    if ((event && event.source === opened) || fp.get('data.type', event) === 'AUTH') {
      try {
        callback && callback({ type: 'message', event, close, url })
      } catch (err) {
        console.error(err)
      }
    }
  }
  window.addEventListener('message', messageListener, false)
  opened.addEventListener('message', messageListener, false)
  close = () => {
    opened.close()
    window.removeEventListener('message', messageListener, false)
    // opened.removeEventListener('message', messageListener, false)
  }
  return { close }
}

const popupService = { open }
export default popupService
