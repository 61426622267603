import React from 'react'
import _map from 'lodash/map'
import _castArray from 'lodash/castArray'
import styled from 'styled-components'
import media from 'src/styling/media'
import colors from 'src/styling/colors'
import Button from 'src/components/Button'

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  ${(p) => (p.align ? `align-items: flex-start;` : '')}
`
const H2 = styled.h2`
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  color: ${colors.textColor};
  padding-bottom: 33px;

  ${media.greaterThan('lg')`
    font-size: 65px;
    letter-spacing: -1.7px;
  `}
`

const H2H = styled.h2`
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  color: ${colors.textColor};
  padding-bottom: 33px;

  ${media.greaterThan('lg')`
    font-size: 65px;
    letter-spacing: -1.7px;
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  ${media.forEach({
    values: { sm: 'column', md: 'row', lg: 'row' },
    getStyle: (val) => `flex-direction: ${val};`
  })}
`

const ButtonContainerCentre = styled(ButtonContainer)`
  display: flex;
  ${media.forEach({
    values: { sm: 'column', md: 'row', lg: 'row' },
    getStyle: (val) => `flex-direction: ${val};`
  })}
  justify-content: center;
`

const ButtonContainerLeft = styled.div`
  display: flex;
  ${media.forEach({
    values: { sm: 'column', md: 'column', lg: 'row' },
    getStyle: (val) => `flex-direction: ${val};`
  })}
  justify-content: flex-start;
  &:last-child {
    margin-right: 0;
  }
  > div {
    ${media.forEach({
      values: { sm: 0, md: 0, lg: 30 },
      getStyle: (val) => `margin-right: ${val}px;`
    })}
  }
`

const ButtonWrapper = styled.div`
  width: $100%;
  ${media.greaterThan('md')`
    width: ${(p) => p.buttonWidth ? `${p.buttonWidth}px` : `initial`};
  `}
`

const renderButton = (button) => {
  const {
    link: buttonLink,
    title: buttonTitle,
    type: buttonType,
    fullWidth,
    width: buttonWidth,
    onClick: buttonOnClick
  } = button || {}
  if (!buttonTitle) return
  return (
    <ButtonWrapper
      key={buttonTitle}
      buttonWidth={buttonWidth}
      fullWidth={fullWidth}
    >
      <Button
        to={buttonLink}
        buttonType={buttonType}
        fullWidth={fullWidth}
        customWidth={buttonWidth}
        onClick={buttonOnClick}
      >
        {buttonTitle}
      </Button>
    </ButtonWrapper>
  )
}

const ContentWithButtons = ({
  id,
  title,
  contentPage,
  children,
  buttons,
  align
}) => {
  const ButtonContainer =
    align === 'left' ? ButtonContainerLeft : ButtonContainerCentre

  const Title = contentPage === 'home' ? H2H : H2
  return (
    <Container align={align} id={id}>
      {title && <Title>{title}</Title>}
      {children}
      {buttons ? (
        <ButtonContainer>
          {_map(_castArray(buttons), renderButton)}
        </ButtonContainer>
      ) : null}
    </Container>
  )
}

export default ContentWithButtons
