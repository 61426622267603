import { useSelector } from 'react-redux'
import {
  resourceSelector,
  requestSelector,
  centreSelector,
  crisisSelector,
  resourcesSelector
} from 'src/store/resources/selectors'

export const useResource = ({ resourceType, id }) => {
  return useSelector(resourceSelector({ resourceType, id }))
}

export const useRequest = ({ resourceType, requestKey }) => {
  return useSelector(requestSelector({ resourceType, requestKey }))
}

export const useCentre = () => {
  return useSelector(centreSelector())
}

export const useCrisis = () => {
  return useSelector(crisisSelector())
}

export const useResources = ({ resourceType, ids }) => {
  return useSelector(resourcesSelector({ resourceType, ids }))
}
