import _upperFirst from 'lodash/upperFirst'
import _isArray from 'lodash/isArray'
import qs from 'query-string'
import _get from 'lodash/get'

export const parseQueries = ({ location }) => {
  const { search, store, letter, tab } = qs.parse(
    _get(location, 'search', {})
  )
  return { search, store, letter, tab }
}

// used to identify the urls old format
// e.g:  /shop?category=3cEognkMiQZgOyOlJDYxq8
export const getQueryCategory = ({ location }) => qs.parse(_get(location, 'search', {})).category
