import _get from 'lodash/get'
import _ from 'lodash'
import qs from 'query-string'

import { getCentreKey, api as apiConfig } from 'src/config'
import env, { isCordova } from 'src/env'
import { goodFetch, parseUrl, logTrace } from 'src/utility'
import popupService from 'src/services/popupService'
import { errors, jwt } from 'src/config'
const socialLoginEmailExistsError = errors.socialLoginEmailExistsError

const baseUrl = _get(apiConfig, [env.envKey, 'url'])

const refreshTokenKey = 'refreshToken'
const accessTokenKey = 'accessToken'

export const setRefreshToken = value => localStorage.setItem(refreshTokenKey, value)
export const setAccessToken = value => localStorage.setItem(accessTokenKey, value)
const removeAccessToken = () => localStorage.removeItem(accessTokenKey)
const removeRefreshToken = () => localStorage.removeItem(refreshTokenKey)
const getRefreshToken = () => localStorage.getItem(refreshTokenKey)
export const getAccessToken = () => localStorage.getItem(accessTokenKey)

export const apiFetch = async ({
  path,
  token,
  method,
  body,
  file,
  extraHeaders,
  localOverride,
  centreKey,
  attemptRefresh = true
}) => {
  let headers = {
    'hammerson-centre-key': centreKey || getCentreKey(),
    'hammerson-platform': isCordova ? 'app' : 'web',
    'X-Hammerson-Version': env.version,
    ...extraHeaders
  }

  if (token) {
    if (attemptRefresh) {
      token = await refreshToken(token)
    }
    headers.authorization = `Bearer ${token}`
    headers.credentials = 'omit'
  }

  return goodFetch(`${localOverride || baseUrl}/api${path}`, {
    method,
    headers,
    ...(body ? { body: JSON.stringify(body) } : file ? { body: file } : {})
  })
}

const refreshToken = async (token) => {
    if (!jwt.isSocialToken({ bearerToken: token })) {
      return token
    }
    const decodedToken = jwt.decodeToken({ bearerToken: token })
    if (decodedToken.exp < new Date().getTime() / 1000) {
      console.log('is not valid')
      const refreshToken = getRefreshToken()
      const payload = await apiFetch({
        path: `/users/@me/refreshToken`,
        method: 'post',
        token,
        body: { refreshToken },
        attemptRefresh: false
      })
      const replacedToken = payload.token
      setAccessToken(replacedToken)
      return replacedToken
    }
    return token
}

const fetchProfile = async (args) => {
  logTrace('apiService.fetchProfile')
  const { token } = args || {}
  return apiFetch({
    path: `/users/@me`,
    token,
    method: 'get'
  })
}

const updateProfile = async (args) => {
  logTrace('apiService.updateProfile')
  const { token, payload } = args || {}
  return apiFetch({
    path: `/users/@me`,
    token,
    method: 'put',
    body: payload
  })
}

export const signUpForNewsletter = async (args) => {
  logTrace('apiService.signUpForNewsletter')
  return apiFetch({
    path: `/newsletter`,
    method: 'post',
    body: args
  })
}

const postContactForm = async (args) => {
  logTrace('apiService.postContactForm')
  const { message, name, email } = args
  return apiFetch({
    path: `/email/contact-form`,
    method: 'post',
    body: { message, name, email }
  })
}

const socialLoginWithToken = async (args) => {
  logTrace('apiService.socialLoginWithToken')
  const { accessToken, provider, secretToken, twitterId } = args || {}
  if (!accessToken || !provider) {
    throw new Error('accessToken and provider required')
  }
  const centreKey = getCentreKey()
  let query
  if (provider === 'facebook') {
    query = {
      access_token: accessToken
    }
  }
  if (provider === 'twitter') {
    query = {
      oauth_token: accessToken,
      oauth_token_secret: secretToken,
      user_id: twitterId
    }
  }
  query = qs.stringify(query)

  return apiFetch({
    path: `/auth/${centreKey}/${provider}-token?${query}`,
    method: 'get'
  })
}



const socialLogin = async (args) => {
  logTrace('apiService.socialLogin')
  const { provider } = args || {}
  return new Promise((resolve, reject) => {
    const centreKey = getCentreKey()
    const loginUrl = `${baseUrl}/api/auth/${centreKey}/${provider}`
    const successPath = `/api/auth/success`
    const failurePath = `/api/auth/failure`
    popupService.open(loginUrl, { height: 650, width: 650, centrePopUp: true }, (params) => {
      const { type, close, url, event } = params
      if (type === 'message') {
        if (event.origin === parseUrl(url).origin || event.data.type === 'AUTH') {
          close()
          if (event.data.success) {
            const accessToken = event.data.token
            const refreshToken = event.data.refreshToken
            setAccessToken(accessToken)
            setRefreshToken(refreshToken)
            resolve({ accessToken })
          } else {
            if (event.data.error === socialLoginEmailExistsError.message) {
              reject(socialLoginEmailExistsError)
            } else {
              reject()
            }
          }
        }
      } else if (type === 'loadstop') {
        const parsed = parseUrl(url)
        const pathname = parsed.pathname
        if (pathname === successPath) {
          close()
          resolve()
        } else if (pathname === failurePath) {
          const search = parsed.search
          const errorMessage = _.get(qs.parse(search), 'error')
          close()
          if (errorMessage === socialLoginEmailExistsError.message) {
            reject(socialLoginEmailExistsError)
          } else {
            reject()
          }
        }
      }
    })
  })
}

const socialLogout = async () => {
  logTrace(`apiService.socialLogout`)

  const refreshToken = getRefreshToken()
  const token = getAccessToken()
  return apiFetch({
    path: `/auth/logout`,
    method: 'post',
    token,
    body: { refreshToken }
  }).finally(() => {
    removeAccessToken()
    removeRefreshToken()
  })
}

const readResource = async (args) => {
  logTrace(`apiService.readResource`)
  const { id, resourceType, centreKey } = args || {}
  return apiFetch({
    path: `/${resourceType}s/${id}`,
    method: 'get',
    centreKey
  })
}

const readResourceStatus = async (args) => {
  logTrace(`apiService.readResourceStatus`)
  const { urlName, resourceType, centreKey } = args || {}
  return apiFetch({
    path: `/${resourceType}s/${urlName}/status`,
    method: 'get',
    centreKey
  })
}

const createUserEvent = async (args) => {
  logTrace(`apiService.createUserEvent`)
  const { token, payload } = args || {}
  return apiFetch({
    path: `/users/@me/events`,
    token,
    method: 'post',
    body: payload
  })
}

const createUserDevice = async (args) => {
  logTrace(`apiService.createUserDevice`)
  const { token, payload } = args
  return apiFetch({
    path: `/users/@me/devices`,
    token,
    method: 'post',
    body: payload
  })
}

const deleteUserDevices = async (args) => {
  logTrace(`apiService.deleteUserDevices`)
  const { token, payload } = args
  return apiFetch({
    path: `/users/@me/devices`,
    token,
    method: 'delete',
    body: payload
  })
}

const readUserDevice = async ({ pointrId }) => {
  logTrace(`apiService.readUserDevice`)
  const query = qs.stringify({
    pointrId
  })
  return apiFetch({
    path: `/users/@me/devices?${query}`,
    method: 'get',
  }).catch((error) => {
    console.log(`read user device error: `, error)
    return null
  })
}

const createUserPromotion = async (args) => {
  logTrace(`apiService.createUserPromotion`)
  const { token, payload } = args || {}
  return apiFetch({
    path: `/users/@me/promotions`,
    token,
    method: 'post',
    body: payload
  })
}

const createAnalyticEvents = async (args) => {
  logTrace(`apiService.createAnalyticEvents`)
  const { token, payload } = args || {}
  return apiFetch({
    path: `/analytic-events`,
    token,
    method: 'post',
    body: payload
  })
}

const readAllSaved = async (args) => {
  logTrace('apiService.readAllSaved')
  const { token } = args || {}
  return apiFetch({
    path: `/saved`,
    token,
    method: 'get'
  })
}

const createSaved = async (args) => {
  logTrace('apiService.createSaved')
  const { token, content_id, content_type, content_category } = args || {}
  return apiFetch({
    path: `/saved`,
    token,
    method: 'post',
    body: { content_id, content_type, content_category }
  })
}

const removeSaved = async (args) => {
  logTrace('apiService.createSaved')
  const { token, id } = args || {}
  return apiFetch({
    path: `/saved/${id}`,
    token,
    method: 'delete'
  })
}

const styleSeekerUpload = async ({ fileName, file }) => {
  logTrace('apiService.styleSeekerUpload')
  const query = qs.stringify({
    fileName
  })
  return apiFetch({
    path: `/styleseeker/upload?${query}`,
    method: 'post',
    file,
    extraHeaders: {
      'Content-Type': 'image/jpeg'
    }
  })
}

const styleSeekerSimilar = async ({
  fileUrl,
  categoryAlias,
  crop,
  weights,
  content_id
}) => {
  logTrace('apiService.styleSeekerSimilar')
  const query = qs.stringify({
    fileUrl,
    categoryAlias,
    crop: JSON.stringify(crop),
    weights: JSON.stringify(weights),
    content_id
  })
  return apiFetch({
    path: `/styleseeker/similar?${query}`,
    method: 'post'
  })
}

const styleSeekerProducts = async ({ productIds }) => {
  logTrace('apiService.styleSeekerProducts')
  let productResourcePromises = _.map(productIds, (productId) => {
    const query = qs.stringify({ productId })
    return apiFetch({
      path: `/styleseeker/product?${query}`,
      method: 'get'
    }).catch((error) => {
      console.log(`Product with id ${productId} was not found!`)
      return null
    })
  })
  return Promise.all(productResourcePromises).then((results) =>
    _.compact(results)
  )
}

const readOccupancy = async () => {
  logTrace('apiService.readShopperTrak')
  const centreKey = getCentreKey()
  return apiFetch({
    path: `/shoppertrak/${centreKey}`,
    method: 'get'
  })
}

const createUserCentreEvent = async ({
  deviceTimeStamp,
  pointrId,
  userId
}) => {
  logTrace('apiService.createUserCentreEvent')
  const centreKey = getCentreKey()
  return apiFetch({
    path: `/users/@me/user-centre-event`,
    method: 'post',
    body: {
      deviceTimeStamp,
      pointrId,
      centreKey,
      userId
    }
  }).catch((error) => {
    console.log(`create user centre event error: `, error)
    return null
  })
}

const readUserCentreEvent = async ({
  limit,
  offset,
  updatedSince
}) => {
  logTrace('apiService.readUserCentreEvent')
  const query = qs.stringify({
    limit,
    offset,
    updatedSince
  })
  return apiFetch({
    path: `/integration/user-centre-event?${query}`,
    method: 'get'
  }).catch((error) => {
    console.log(`read user centre event error: `, error)
    return null
  })
}

const apiService = {
  createAnalyticEvents,
  createSaved,
  createUserDevice,
  createUserEvent,
  createUserPromotion,
  deleteUserDevices,
  fetchProfile,
  postContactForm,
  readAllSaved,
  readResource,
  readResourceStatus,
  removeSaved,
  signUpForNewsletter,
  socialLogin,
  socialLoginWithToken,
  socialLogout,
  styleSeekerProducts,
  styleSeekerSimilar,
  styleSeekerUpload,
  updateProfile,
  readOccupancy,
  readUserDevice,
  createUserCentreEvent,
  readUserCentreEvent
}

export default apiService
