import React from 'react'
import _capitalize from 'lodash/capitalize'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import AssetIcon from 'src/components/AssetIcon'
import TickSvg from 'src/assets/Tick.svg'

const Item = styled.li`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 64px;
  font-size: 14px;
  padding: 22px;
  border-bottom: 1px solid ${colors.lighterGrey};
`

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.darkGrey};
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CategoryItem = ({ label, isActive, onClick }) => (
  <Item onClick={onClick}>
    <Label>{_capitalize(label)}</Label>
    {isActive && (
      <IconWrapper>
        <AssetIcon svg={TickSvg} size={25} color={colors.darkGrey} />
      </IconWrapper>
    )}
  </Item>
)

export default CategoryItem
