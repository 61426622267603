import React from 'react'

const DateTime = ({ from, to, seperator, datetime }) => (
  <>
    {from && <time dateTime={datetime.from}>{from}</time>}
    {seperator && ` ${seperator} `}
    {to && <time dateTime={datetime.to}>{to}</time>}
  </>
)

export default DateTime
