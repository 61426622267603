import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'
import { isBrowser, isCordova } from 'src/env'
/**
 * @type {function(): void} Allows Instagram to display images and other rich content on the page when embedded in an iframe.
 * [reference](https://medium.com/@ekeitho/react-instagram-embedding-1646e05c2bb)
 */
const InstagramEmbed = () => {
    if (isBrowser) {
        _.invoke(window, 'instgrm.Embeds.process')
    }
    return (
        <Helmet>
            <script async src={`${isCordova ? 'https:' : ''}//www.instagram.com/embed.js`} />
        </Helmet>
    )
}

export default InstagramEmbed