import React, { Fragment } from 'react'
import { useInternationalisation } from 'src/context'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _flow from 'lodash/flow'
import _startCase from 'lodash/startCase'
import _isNil from 'lodash/isNil'
import _ from 'lodash'
import Container from 'src/components/Container'
import Box from 'src/components/Box'
import Button from 'src/components/Button'
import PageBanner from 'src/components/PageBanner'
import { withRouter } from 'src/components/RouterDom'
import contentfulService from 'src/services/contentfulService'
import { useRequest, useCentre } from 'src/store/resources/hooks'
import { fetchResources } from 'src/store/resources/actionCreators'
import appTabsConfig from 'src/routing/appTabsConfig'
import {
  getFirstResource,
  getIdFromParams,
  getFirstPath,
  getAssetSrc,
  getResources,
  getFields,
  getHasFields
} from 'src/utility'
import Head from 'src/components/Head'
import { TilesWrapper, ResourceTile } from 'src/components/Tile'
import vars from 'src/styling/vars'
import { isCordova } from 'src/env'
import Spacing from 'src/components/Spacing'
import { getValidResources } from 'src/components/Tile/getValidResources'

const resourceType = 'page'
const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Wrapper = ({ children, inner, box }) => {
  switch (true) {
    case inner && box:
      return (
        <Fragment>
          <Box>
            <Inner>{children}</Inner>
          </Box>
        </Fragment>
      )
    case box:
      return <Box>{children}</Box>
    case inner:
      return <Inner>{children}</Inner>
    default:
      return <Fragment>{children}</Fragment>
  }
}

const Relative = styled.div`
 position: relative;
 display: flex;
 width: 100%;
 flex-direction: column;
`

const Page = (props) => {
  const {
    box,
    children,
    inner,
    requestKey,
    propsTitle,
    match,
    showChildrenAboveContentfulContent = false,
    edgeToEdge = false,
    relative
  } = props
  const { translate, moment } = useInternationalisation()
  const resource = _flow([useRequest, getFirstResource])({
    resourceType,
    requestKey: requestKey || (!_.get(match, 'params.category') && getIdFromParams(props)) || getFirstPath(props)
  })
  const appTabTitle = React.useMemo(() => {
    if (isCordova && match.url) {
      const matchingTab = _.find(appTabsConfig, ({ to }) => to === match.url)
      if (matchingTab) return translate(matchingTab.label)
    }
  }, [match.url])
  const centre = useCentre()
  const { title: fieldsTitle, body, headerImage, relatedContent, headerImageMobile } = getFields(
    resource
  )
  const titleToUse = fieldsTitle || propsTitle || appTabTitle || ''
  const ChildWrapper = relative ? Relative : React.Fragment

  const wrapperProps = { inner, box }
  const isPageAttachmentAllowed = _.get(getFields(resource), 'pageDownloadAllowed')
  const attachmentExists = _.get(getFields(resource), 'pageAttachment')
  const validRelatedContent = getValidResources(relatedContent)
  return (
    <>
      <Head
        resource={resource}
        title={titleToUse}
      />
      <PageBanner text={titleToUse} bannerMediaDesktop={headerImage} bannerMediaMobile={headerImageMobile} hide={isCordova} />
      <Container verticalGutter edgeToEdge={edgeToEdge}>
        {body || children ? (
          <Wrapper {...wrapperProps}>
            <ChildWrapper>
              {showChildrenAboveContentfulContent && children}
              {contentfulService.renderRichText(body)}
              {!showChildrenAboveContentfulContent && children}
            </ChildWrapper>
            {isPageAttachmentAllowed && attachmentExists ? (
              <Button
                {...resource}
                buttonType='primary'
                onClick={() => {
                  let ctaDownloadUrl = getAssetSrc(
                    _.get(resource, 'fields.pageAttachment')
                  )
                  return window.open(ctaDownloadUrl, '_blank')
                }}
              >
                {translate('PAGE_DOWNLOAD')}
              </Button>
            ) : null}
          </Wrapper>
        ) : null}
        {!_.isEmpty(validRelatedContent) ? (
          <>
            <Spacing height={vars.pageGutter.sm} />
            <TilesWrapper>
              {_map(validRelatedContent, (resource) => {
                 return <ResourceTile resource={resource} />
              })}
            </TilesWrapper>
          </>
        ) : null}
      </Container>
    </>
  )
}

export const getPageData = (props, { urlName } = {}) => {
  const identifier = urlName || getIdFromParams(props) || getFirstPath(props)
  const { dispatch, host, locale } = props
  return dispatch(
    fetchResources({
      locale,
      where: {
        'fields.urlName': identifier,
        ...(isCordova
          ? { 'fields.publishOnPlusApp': true }
          : { 'fields.publishOnWebsite': true })
      },
      resourceType,
      requestKey: identifier,
      host
    })
  )
}

export default withRouter(Page)
