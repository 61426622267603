import React from 'react'
import { Route } from "react-router-dom"
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _startCase from 'lodash/startCase'
import { useRequest } from 'src/store/resources/hooks'
import NotFound from 'src/pages/NotFound'
import Page, { getPageData } from 'src/components/Page'
import { getIdFromParams, getFirstPath } from 'src/utility'
import { List as Placeholder } from 'react-content-loader'

const ContentPage = (props) => {
  const request = useRequest({
    resourceType: 'page',
    requestKey: getIdFromParams(props) || getFirstPath(props)
  })
  // Pages in draft mode should redirect to the home page.
  if (request.total === 0 && request.contentStatus === 'DRAFT') {
    return (
      <Route render={({ staticContext }) => {
          if (staticContext) {
              staticContext.status = 301;
              staticContext.url = '/';
          }
      }}/>
    )
  }

  if (request._status.failed || request.total === 0) {
    return <NotFound />
  }

  if (request._status.pending) {
    return (
      <Page box inner>
        <Placeholder />
      </Page>
    )
  }
  return (
    <article>
      <Page box inner />
    </article>
  )
}
ContentPage.identifier = 'CONTENT_PAGE'
ContentPage.getData = async (props) => {
  return getPageData(props)
}

export default ContentPage
