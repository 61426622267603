import { useInternationalisation } from 'src/context'
import { getFields, createFormatTime } from 'src/utility'
import _filter from 'lodash/filter'
import _slice from 'lodash/slice'
import _head from 'lodash/head'
import _map from 'lodash/map'
import _lowerCase from 'lodash/lowerCase'

const useOpeningTimes = (centre) => {
  const { translate, moment, defaultLocale } = useInternationalisation()
  const { centreOpeningHours } = getFields(centre)
  const toShowOpeningHoursArray = _filter(centreOpeningHours, (resource) => {
    const { showOnHeaderBanner } = getFields(resource)
    return showOnHeaderBanner
  })

  const openingHoursFieldsArray = _map(toShowOpeningHoursArray, getFields)
  const openingHoursList = _slice(openingHoursFieldsArray, 0, 2)
  const openingHoursFirst = _head(openingHoursList)

  const todayKey = _lowerCase(moment().locale('en').format('ddd'))

  return {
    todayKey,
    openingHoursList,
    openingHoursFirst
  }
}

export default useOpeningTimes