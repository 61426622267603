import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _find from 'lodash/find'
import _groupBy from 'lodash/groupBy'
import _capitalize from 'lodash/capitalize'
import { getCentreConfig } from 'src/config'
import Button from 'src/components/Button'
import H2 from 'src/components/H2'
import Head from 'src/components/Head'
import vars from 'src/styling/vars'
import { useInternationalisation } from 'src/context'
import styleSeekerCategories from './../categoryConfig.json'
import { useDispatch, useSelector } from 'react-redux'
import {
  cropSelector,
  fileSelector,
  fileUrlSelector
} from 'src/store/styleSeeker/selectors'
import { setCurrentPath } from 'src/store/styleSeeker/actionCreators'

import colors from 'src/styling/colors'

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 8%
    ${(p) => (p.padBottom ? 'calc(110px + env(safe-area-inset-bottom))' : 0)} 8%;
`

const Title = styled(H2)`
  font-size: 22px;
  padding: 15px;
`

const GarmentSelectionButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  padding: 15px 15px calc(${vars.appTabBarHeight}px + 15px + 10px) 15px;
  background-color: white;
`

const StyledButton = styled(Button)`
  margin: 0;
  height: 70px;
`

const GarmentTypeList = styled.ul``

const GarmentTypeGroupName = styled.h4`
  font-size: 18px;
  font-weight: 700;
  margin: 15px 0;
`

const GarmentTypeItem = styled.li``

const GarmentTypeButton = styled.button`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  text-transform: none;
  margin: 0;
  padding: 5px;
  height: initial;
`

const GarmentSelection = (props) => {
  const { file, category, onSelectGarment, onFindSimilar } = props || {}

  const crop = useSelector(cropSelector())
  const fileUrl = useSelector(fileUrlSelector())
  const dispatch = useDispatch()

  const { translate } = useInternationalisation()
  const { defaultLocale } = getCentreConfig() || {}
  const canvasRef = useRef(null)

  const groupCategoriesByGender = useMemo(
    () =>
      _groupBy(
        styleSeekerCategories,
        defaultLocale === 'fr' ? 'french_gender' : 'english_gender'
      ),
    []
  )

  useEffect(() => {
    if (file && crop) {
      const canvas = canvasRef.current
      const scaleX = file.naturalWidth / crop.imageWidth
      const scaleY = file.naturalHeight / crop.imageHeight
      canvas.width = crop.width
      canvas.height = crop.height
      const ctx = canvas.getContext('2d')

      ctx.drawImage(
        file,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      )
    }
  }, [canvasRef, file, crop])

  const Item = ({ item, index }) => {
    const { english_category, french_category, alias } = item
    const categoryLabel =
      defaultLocale === 'fr' ? french_category : english_category
    return (
      <GarmentTypeButton
        key={`${alias}_${index}`}
        style={{
          color: alias === category ? colors.red : colors.darkGrey
        }}
        onClick={() => onSelectGarment({ category: alias })}
      >
        {_capitalize(categoryLabel)}
      </GarmentTypeButton>
    )
  }

  const getImagePreview = () => {
    const style = {
      height: '100%',
      maxHeight: 220,
      maxWidth: 220,
      margin: '20px 0px'
    }
    if (file && crop) {
      return <canvas ref={canvasRef} style={style} />
    } else if (!crop && fileUrl) {
      return <img src={fileUrl} style={style} alt='' />
    } else {
      // this shouldnt happen but who knows with styleseeker
      return <div style={style} />
    }
  }

  return (
    <Wrapper padBottom>
      <Head title={translate('PAGE_STYLE_SEEKER')} />
      {getImagePreview()}
      <Title>{translate('STYLE_SEEKER_SELECT_GARMENT_TITLE')}</Title>
      <GarmentTypeList>
        {_map(groupCategoriesByGender, (groupItems, groupName) => {
          if (groupName && groupName !== 'undefined') {
            return (
              <React.Fragment key={groupName}>
                <GarmentTypeGroupName>
                  {_capitalize(groupName)}
                </GarmentTypeGroupName>
                <GarmentTypeItem key={groupName}>
                  {_map(groupItems, (item, index) => (
                    <Item key={index} item={item} index={index} />
                  ))}
                </GarmentTypeItem>
              </React.Fragment>
            )
          }
        })}
      </GarmentTypeList>
      <GarmentSelectionButtons>
        <StyledButton
          disabled={!category}
          buttonType={'primary'}
          onClick={() => onFindSimilar()}
          style={{ flex: '1 1 auto' }}
        >
          {translate('STYLE_SEEKER_FIND_SIMILAR')}
        </StyledButton>
      </GarmentSelectionButtons>
    </Wrapper>
  )
}

export default GarmentSelection
