import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _map from 'lodash/map'
import _isNil from 'lodash/isNil'
import _isObject from 'lodash/isObject'
import {
  getFields,
  getHasFields,
  getResourceId
} from 'src/utility'
import { ResourceTile } from 'src/components/Tile'

import media from 'src/styling/media'

import { useInternationalisation } from 'src/context'

const ItemListing = styled.ul`
  display: flex;  
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
  ${media.greaterThan('lg')`
    padding-top: 0;
    padding-bottom: 0;
  `}
`

const FeaturedItemsListing = ({ items }) => {
  const { moment } = useInternationalisation()
  const availableItems = items && items.filter(entry => {
    const hasFields = getHasFields(entry)
    const { endDate } = getFields(entry)
    /**
     * @type {boolean} `true` if the item can expire i.e, events, offers.
     */
    const canExpire = !_isNil(endDate)
    /**
     * @type {boolean}
     * Resolves to `true` if:
     * * The item can not expire, i.e. news items.
     * * The item has an expiration date that has been reached.
     */
    const hasExpired = canExpire && moment(endDate).isSameOrBefore(new Date())
    return hasFields && !hasExpired
  })

  return (
    <ItemListing>
      {_map(availableItems, (entry) => {
        const hasFields = getHasFields(entry)
        if (!hasFields) return null
        const id = getResourceId(entry)
        return (
            <ResourceTile key={id} resource={entry} isWhatsOn />
        )
      })}
    </ItemListing>
  )
}

export default FeaturedItemsListing
