import * as Yup from 'yup'
import _ from 'lodash'

const requiredBool = () =>
  Yup.bool()
    .nullable()
    .required('FORM_VALIDATION_REQUIRED')
    .oneOf([true], 'FORM_VALIDATION_REQUIRED')

const requiredString = () =>
  Yup.string('hello')
    .nullable()
    .trim()
    .required('FORM_VALIDATION_REQUIRED')

const email = () => requiredString().email('FORM_VALIDATION_EMAIL')

const password = () => requiredString().min(8, 'FORM_VALIDATION_PASSWORD')

const confirmPassword = (passwordFieldName) =>
  requiredString().oneOf(
    [Yup.ref(passwordFieldName), null],
    'FORM_VALIDATION_CONFIRM_PASSWORD'
  )

const requiredMobileNumber = (smsCheckboxField) =>
  Yup.string().when(smsCheckboxField, {
    is: true,
    then: Yup.string()
      .nullable()
      .trim()
      .required('FORM_VALIDATION_MOBILE_NUMBER_REQUIRED')
  })

const requiredMobileNumberOnCheckBox = (mobilePhoneField) =>
  Yup.bool().when(mobilePhoneField, {
    is: (val) => !val,
    then: Yup.bool().oneOf([false], 'FORM_VALIDATION_MOBILE_NUMBER_REQUIRED')
  })

const date = () =>
  Yup.string()
    .nullable()
    .test('YYYY-MM-DD date', 'FORM_VALIDATION_DATE', (value) => {
      if (typeof value === 'undefined') return true
      const [yyyy, mm, dd] = _.split(value, '-')
      return Number(yyyy) && Number(mm) && Number(dd)
    })

const validators = {
  requiredBool,
  requiredString,
  email,
  password,
  confirmPassword,
  date,
  requiredMobileNumber,
  requiredMobileNumberOnCheckBox
}

export default validators
