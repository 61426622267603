import React, { useCallback } from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import _get from 'lodash/get'
import styled from 'styled-components'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import { Link } from 'src/components/RouterDom'
import { useSelector, useDispatch } from 'react-redux'

import AccountIcon from 'src/assets/Account.svg'
import UserSvg from 'src/assets/User.svg'
import SearchSvg from 'src/assets/Search.svg'
import Container from 'src/components/Container'
import AssetIcon from 'src/components/AssetIcon'
import Nav from './Nav'
import Banner from '../Banner'
import vars from 'src/styling/vars'
import { attributesSelector } from 'src/store/account/selectors'
import { logout } from 'src/store/account/actionCreators'
import { openModal } from 'src/store/ui/actionCreators'
import { getAssetTitle, getFields, getEnableRegistrationLogIn } from 'src/utility'
import { isBrowser } from 'src/env'

import { useInternationalisation } from 'src/context'

const Block = styled.div`
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  padding: 30px 15px;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
`

const Edge = styled(Block)`
  width: 25%;
`

const Left = styled(Edge)`
  flex: 1 1 auto;
  justify-content: flex-start;
`

const Right = styled(Edge)`
  flex: 1 1 auto;
  justify-content: flex-end;
`

const Center = styled(Block)`
  align-items: center;
  justify-content: center;
`

const contentHeight = 40

const IconButton = styled.button`
  height: ${contentHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border: 0;
  background: transparent;
  color: currentColor;
  text-transform: uppercase;
  line-height: 1;
  cursor: pointer;
  span {
    font-size: 14px;
    padding: 0 10px;
  }
  a:hover,
  a:active,
  a:focus,
  button:hover,
  button:active,
  button:focus {
    font-weight: bold;
  }
`

const LogoLink = styled(Link)`
  height: ${contentHeight}px;
  display: block;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogoImg = styled.img`
  width: 100%;
  height: auto;
`

const OpeningTimesWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
`

const HeaderWrapper = styled.div`
  position: fixed;
  z-index: ${vars.zIndex.header};
  top: 0;
  top: constant(safe-area-inset-top); /* iOS 11.0 */
  top: env(safe-area-inset-top); /* iOS 11.2 */
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #fff;
  &[data-fixed='true'] {
    background-color: #fff;
    color: #262a30;
    transition: color 0.3s linear;
    ${OpeningTimesWrapper} {
      background-color: #f3f6f8;
    }
    ${Center}, ${IconButton} span {
      display: none;
    }
    ${Content} {
      padding: 0;
      height: 0;
      position: relative;
      -webkit-transform: translate3d(0, 0, 0);
    }
    ${Left}, ${Right} {
      position: absolute;
      top: 0;
      width: 40px;
    }
    ${Left} {
      left: 15px;
    }
    ${Right} {
      right: 15px;
    }
    ${Nav} ul {
      padding-left: 75px;
      padding-right: 75px;
    }
  }
`

const LogOut = styled.button`
  color: inherit;
  background-color: transparent;
  display: inline;
  text-transform: uppercase;
  text-decoration: underline;
`

const renderAccountText = ({ attributes, logOut, translate }) => {
  if (!attributes) {
    return
  }

  const isAnonymous = _get(attributes, 'email_type') === 'anonymous'
  const name = _get(attributes, 'given_name')
  const email = _get(attributes, 'email')
  let accountHandle = ''
  switch (true) {
    case !!name:
      accountHandle = name
      break
    case !!email && !isAnonymous:
      accountHandle = email
      break
    default:
      accountHandle = ''
      break
  }

  const logOutText = accountHandle ? `(${translate('LOGOUT')})` : translate('LOGOUT')

  return (
    <span>
      <Link to='/account'>
        {accountHandle}
      </Link>{' '}
      <LogOut onClick={logOut}>{logOutText}</LogOut>
    </span>
  )
}

const DesktopHeader = (props) => {
  const { y } = useWindowScroll()
  const fixedOffset = 25
  const isFixed = y > fixedOffset

  const { translate } = useInternationalisation()

  const { centre, crisis } = props
  const { url: logoImage } = _get(centre, 'fields.logo.fields.file', {})
  const enableRegistrationLogIn = getEnableRegistrationLogIn(centre)
  const { logo } = getFields(centre)
  const logoAlt = getAssetTitle(logo)

  const updateFixedStatus = useCallback((node) => {
    if (node !== null) {
      node.dataset.fixed = y > fixedOffset
    }
  }, [])

  const attributes = useSelector(attributesSelector())
  const dispatch = useDispatch()
  const logOut = useCallback(() => {
    dispatch(logout())
  }, [])
  const search = useCallback(() => {
    dispatch(openModal({ id: 'search' }))
  }, [])

  return (
    <HeaderWrapper ref={updateFixedStatus} data-fixed={isFixed}>
      <Banner centre={centre} crisis={crisis} />
      <Container>
        <Content>
          <Left>
            {enableRegistrationLogIn && <IconButton as='div'>
              <Link to='/account'>
                <VisuallyHidden>{translate('ACCOUNT')}</VisuallyHidden>
                <AssetIcon svg={UserSvg} size={22} strokeWidth={3} />
              </Link>
              {isBrowser && (renderAccountText({ attributes, logOut, translate }) || (
                <span>
                  <Link to='/account'>
                    {translate('LOGIN')} / {translate('SIGN_UP')}
                  </Link>
                </span>
              ))}
            </IconButton>}
          </Left>
          <Center>
            <LogoLink to={'/'}>
              <LogoImg src={logoImage} alt={logoAlt} />
            </LogoLink>
          </Center>
          <Right>
            <IconButton onClick={search}>
              {isBrowser && <span>{translate('SEARCH')}</span>}
              <AssetIcon svg={SearchSvg} size={22} strokeWidth={3} />
            </IconButton>
          </Right>
        </Content>
      </Container>
      <Nav />
    </HeaderWrapper>
  )
}

export default DesktopHeader
