import styled from 'styled-components'

const HiddenText = styled.span`
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
`

export default HiddenText
