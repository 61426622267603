import React, { Fragment } from 'react'
import { scroller } from 'react-scroll'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _capitalize from 'lodash/capitalize'
import _lowerCase from 'lodash/lowerCase'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import media from 'src/styling/media'
import { getCentreConfig } from 'src/config'
import { createFormatTime } from 'src/utility'

import AssetIcon from 'src/components/AssetIcon'

import CarParkingSvg from 'src/assets/CarParking.svg'
import WalletSvg from 'src/assets/Wallet.svg'
import ClockSvg from 'src/assets/Clock.svg'
import DisabledSpacesSvg from 'src/assets/Disabled.svg'
import FamilySpacesSvg from 'src/assets/Family.svg'
import ElectricVehicleSvg from 'src/assets/Electric.svg'
import CarWashSvg from 'src/assets/Carwash.svg'
import ShopMobilitySvg from 'src/assets/Mobility.svg'
import PinSvg from 'src/assets/Pin.svg'

import { useInternationalisation } from 'src/context'

const GeneralInformationBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.greaterThan('lg')`
    flex-direction: row;
  `}
`

const InformationItem = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding: 10px 0;
  background-color: ${colors.lightGrey};
  margin-bottom: 5px;
  ${p => p.onClick && `cursor: pointer` }
`

const Label = styled.h2`
  color: ${colors.darkGrey};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
`

const P = styled.p`
  color: ${colors.darkGrey};
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1.2;
  text-transform: uppercase;
  ${media.greaterThan('lg')`
    font-size: 25px;
    margin-left: 8px;
  `}
`

const InformationItemValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  ${media.greaterThan('lg')`
    flex-direction: row;
  `};
`

const SpacesAndFacilitiesBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 15px 0;
  border-bottom: 2px solid ${colors.lightGrey};
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  max-width: 80px;
  ${media.greaterThan('lg')`
    max-width: 120px;
  `};
  > span {
    font-size: 14px;
    line-height: 1.3;
    padding-top: 10px;
  }
`

const IconGeneralInfo = styled.div`
  width: 45px;
  height: 45px;
  margin-right: 0;
  background-color: ${colors.lightGrey};
  ${media.greaterThan('lg')`
    margin-right: 10px;
  `};
`

const IconSpacesFacilities = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: ${colors.lightGrey};
`

const FacilitiesText = styled.span`
  text-transform: uppercase;
`

const renderGeneralInformation = (props) => {
  const { tariffChargesApply, openingTimes, postcode } = props || {}
  const { timeFormat = {} } = getCentreConfig() || {}

  const { translate, moment, defaultLocale } = useInternationalisation()

  const _getTodayOpeningHours = ({ openingTime, closingTime }) => {
    const formatTime = createFormatTime({ moment })({
      originFormat: 'HH:mm',
      outputFormat: timeFormat.parkingOpeningTime
    })
    const opening = formatTime(openingTime)
    const closing = formatTime(closingTime)
    return `${opening} - ${closing}`
  }

  const _scrollTo = (elementId) => {
    scroller.scrollTo(elementId, {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: -100
    })
  }

  const _getTabsConfig = ({ tariffChargesApply, openingTimes, postcode }) => {
    const todayKey = _lowerCase(moment().locale('en').format('ddd'))
    moment().locale(defaultLocale)

    const {
      openingTime,
      closingTime,
      closed,
      allDay
    } = openingTimes
      ? openingTimes[todayKey] || {}
      : {}

    return [
      {
        label: closed ? translate('CLOSED_TODAY') : translate('OPEN_TODAY'),
        value:
          openingTime && closingTime && !closed
            ? allDay
              ? translate('OPEN_24HOURS')
              : _getTodayOpeningHours({ openingTime, closingTime })
            : null,
        icon: ClockSvg,
        onClick: () => _scrollTo('opening-hours-container')
      },
      {
        label: translate('PARKING_TARIFF'),
        value: tariffChargesApply
          ? translate('PARKING_CHARGE_APPLY')
          : translate('PARKING_NO_CHARGE'),
        icon: WalletSvg,
        onClick: () => _scrollTo('parking-tariff-container')
      },
      {
        label: translate('LOCATION'),
        value: postcode,
        icon: PinSvg
      }
    ]
  }

  const itemsArray = _getTabsConfig({ tariffChargesApply, openingTimes, postcode })
  return (
    <GeneralInformationBar>
      {_map(itemsArray, ({ label, value, icon, onClick }) => {
        if (value) {
          return (
            <InformationItem key={label} onClick={onClick}>
              <Label>{label}</Label>
              <InformationItemValue>
                <AssetIcon svg={icon} size={24} />
                <P>{value}</P>
              </InformationItemValue>
            </InformationItem>
          )
        }
      })}
    </GeneralInformationBar>
  )
}

const renderSpacesAndFacilities = (props) => {
  const {
    facilitiesCarWashing,
    facilitiesElectricCharging,
    facilitiesShopMobility,
    spacesDisabled,
    spacesFamily,
    spacesTotal
  } = props || {}

  const { translate } = useInternationalisation()

  const itemsArray = [
    {
      label: translate('PARKING_ALL_SPACES'),
      value: spacesTotal,
      icon: CarParkingSvg,
      item: Item
    },
    {
      label: translate('PARKING_DISABLE_SPACES'),
      value: spacesDisabled,
      icon: DisabledSpacesSvg,
      item: Item
    },
    {
      label: translate('PARKING_FAMILY_SPACES'),
      value: spacesFamily,
      icon: FamilySpacesSvg,
      item: Item
    },
    {
      label: translate('PARKING_FACILITIES_ELECTRIC'),
      value: facilitiesElectricCharging,
      icon: ElectricVehicleSvg,
      item: Item
    },
    {
      label: translate('PARKING_FACILITIES_WASHING'),
      value: facilitiesCarWashing,
      icon: CarWashSvg,
      item: Item
    },
    {
      label: translate('PARKING_MOBILITY'),
      value: facilitiesShopMobility,
      icon: ShopMobilitySvg,
      item: Item
    }
  ]

  return (
    <SpacesAndFacilitiesBar>
      {_map(itemsArray, ({ label, value, icon: Icon, item: Item }) => {
        return value ? (
          <Item key={label}>
            <IconSpacesFacilities>
              <Icon />
            </IconSpacesFacilities>
            <FacilitiesText>
              {`${label}${typeof value === 'number' ? `: ${value}` : ''}`}
            </FacilitiesText>
          </Item>
        ) : null
      })}
    </SpacesAndFacilitiesBar>
  )
}

const InformationBar = ({ fields, id }) => {
  return (
    <Fragment>
      {renderGeneralInformation(fields)}
      {renderSpacesAndFacilities(fields)}
    </Fragment>
  )
}

export default InformationBar
