import React from 'react'
import _ from 'lodash'
import fp from 'lodash/fp'
import { Helmet } from 'react-helmet-async'
import WebShareButton from './WebShareButton'
import TwitterSvg from 'src/assets/Twitter.svg'
import NativeShareButton from './NativeShareButton'
import AssetIcon from 'src/components/AssetIcon'
import { isCordova, isBrowser } from 'src/env'
import { getHost } from 'src/config'
import styled from 'styled-components'
import { useInternationalisation } from 'src/context'
import { withRouter } from 'src/components/RouterDom'
import FacebookSvg from 'src/assets/Facebook.svg'
import env from 'src/env'
import {
  getAssetSrc
} from 'src/utility'
const Title = styled.h3`
  text-decoration: underline;
  margin-bottom: 5px;
`
const List = styled.ul`
  display: flex;
`
const ListItem = styled.li`
  margin: 0 5px;
`

const SocialShareButtons = (props) => {
  const { pageTitle, image, message } = props
  const { translate, defaultLocale } = useInternationalisation()
  const shareUrl = `https://${env.envKey === 'prod' ? 'www.' : ''}${getHost()}${props.location.pathname}`
  if (isCordova) {
    return <NativeShareButton url={shareUrl} message={message} />
  }
  // Facebook Open Graph Image Properties
  const ogWidth = 1200
  const ogHeight = 628
  const ogImageAlt = _.get(image, 'fields.description')
  const ogImageUrl = fp.isUndefined(getAssetSrc(image)) ? '' : `${getAssetSrc(image)}&fit=pad&w=${ogWidth}&h=${ogHeight}&fm=jpg`


  const shareButtons = [
    {
      url: `https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(message)}&u=${encodeURI(shareUrl)}`,
      target: 'facebookShareWindow',
      children: <AssetIcon
        svg={FacebookSvg}
        size={40}
        alt={translate('FACEBOOK_ICON_ALT_TEXT')}
      />
    },
    {
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(`${message} ${shareUrl}`)}`,
      target: 'twitterShareWindow',
      children: <AssetIcon
        svg={TwitterSvg}
        size={40}
        alt={translate('TWITTER_ICON_ALT_TEXT')}
      />
    }
  ]
  return (
    <React.Fragment>
      {_.some([pageTitle, image]) && (
        < Helmet >
          {pageTitle && <meta property='og:title' content={pageTitle} />}
          {ogImageUrl && <meta property='og:image' content={ogImageUrl} />}
          {ogImageUrl && <meta property='og:image:secure_url' content={ogImageUrl} />}
          {ogImageUrl && <meta property="og:image:type" content="image/jpeg" />}
          {ogImageUrl && <meta property='og:image:width' content={ogWidth} />}
          {ogImageUrl && <meta property='og:image:height' content={ogHeight} />}
          {ogImageAlt && <meta property='og:image:alt' content={ogImageAlt} />}
          {defaultLocale && <meta property='og:locale' content={defaultLocale} />}
        </Helmet>
      )}
      <Title>{translate('SHARE_TITLE')}</Title>
      <List>
        {_.map(shareButtons, (({ target, children, url }) => (
          <ListItem key={url}>
            <WebShareButton target={target} url={url}>
              {children}
            </WebShareButton>
          </ListItem>
        )))}
      </List>
    </React.Fragment >
  )
}

export default withRouter(SocialShareButtons)


