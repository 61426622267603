import React, { useCallback } from 'react'
import _ from 'lodash'
import styled, { ThemeProvider } from 'styled-components'
import qs from 'query-string'
import ClampLines from 'react-clamp-lines'
import media from 'src/styling/media'
import colors from 'src/styling/colors'
import BaseModal from 'src/components/Modal/BaseModal'
import TextField from 'src/components/Fields/Text'
import SearchSvg from 'src/assets/Search.svg'
import { closeModal } from 'src/store/ui/actionCreators'
import { Link } from 'src/components/RouterDom'
import { useInternationalisation } from 'src/context'
import { queriedLinkLookUpSelector } from 'src/store/resources/selectors'
import { useSelector } from 'react-redux'
import _get from 'lodash/get'

import {
  setSearchResults,
  getSearchResults,
  getResourceName,
  getUrl
} from 'src/utility'

const searchModalTheme = {
  backgroundColor: 'transparent',
  borderColor: 'white',
  caretColor: 'white',
  color: 'white',
  error: 'white',
  focusColor: 'white',
  pushLeft: 10
}

const H2 = styled.h2`
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -1.58px;
  line-height: 1.15;
  text-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  color: ${colors.white};
  text-align: center;
  ${media.greaterThan('md')`
    font-size: 65px;
  `}
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  background-color: ${colors.darkGrey};
  margin-top: 20px;
  ${media.greaterThan('md')`
    max-width: 715px;
    margin-top: 80px;
  `}
`

const InputWrapper = styled.div`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  display: flex;
  flex: 1 1 auto;
`

const Input = styled(TextField)``

const SuggestionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  ${media.greaterThan('md')`
    max-width: 715px;
  `}
`

const Suggestions = styled.ul`
  min-height: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 0;
  background-color: ${colors.white};
  padding: 5px 55px;
`
const Item = styled.li`
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 5px;
  color: ${colors.darkGrey};
`
const SuggestionItem = styled(Item)`
  cursor: pointer;
`

const NoResultsItem = styled(Item)``

const QuickLinksContainer = styled.ul`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.greaterThan('md')`
    margin-top: 30px;
    flex-direction: row;
  `}
`
const QuickLinkWrapper = styled.li`
  cursor: pointer;
  display: flex;
  margin: 15px;
  border-bottom: 2px solid ${colors.white};
  ${media.greaterThan('md')`
    margin: 25px;
    flex-direction: column;
  `}
  a {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.white};
  }
`

const SearchModal = (props) => {
  const { dispatch, history, location, host } = props

  let { search } = qs.parse(_.get(location, 'search')) || {}
  search = _.unescape(search)

  const [query, setQuery] = React.useState(search || '')

  const { translate, translateUrl, locale } = useInternationalisation()
  const queriedLinks = useSelector(queriedLinkLookUpSelector)
  const quickLinks = [
    {
      to: '/getting-here',
      title: translate('PAGE_GETTING_HERE')
    },
    {
      to: '/opening-hours',
      title: translate('PAGE_OPENING_HOURS')
    },
    {
      to: _get(queriedLinks, '/parking', '/parking'),
      title: translate('PAGE_PARKING')
    }
  ]

  const handleSubmit = useCallback(({ search }) => {
    dispatch(closeModal({ type: 'search' }))
    return history.push(
      translateUrl(`/search?filter=all&${qs.stringify({ search })}`)
    )
  }, [])

  const handleClose = useCallback(() => {
    dispatch(closeModal({ type: 'search' }))
  }, [])

  const onChange = useCallback((e) => {

    const query = e.target.value
    setQuery(query)

    if (query) {
      return setSearchResults({
        dispatch,
        query,
        locale,
        host
      })
    }
  }, [])

  const handleSuggestionClick = (url) => {
    history.push(translateUrl(url))
    dispatch(closeModal({ type: 'search' }))
  }

  const { topResults, total, status, message } = getSearchResults({
    searchString: query,
    filterString: 'all'
  })

  return (
    <ThemeProvider theme={searchModalTheme}>
      <BaseModal id={'search'}>
        <H2>{translate('SEARCH_HELP_TEXT')}</H2>
        <Form action='#' onSubmit={(e) => {
          e.preventDefault()
          handleSubmit({ search: query })
        }}>
          <InputWrapper>
            <Input
              label={translate('SEARCH')}
              type={'search'}
              iconSvg={SearchSvg}
              value={query}
              onChange={onChange}
              showLabel={false}
              autoFocus={true}
              style={{ paddingLeft: 10 }}
              hidePlaceholder={true}
            />
          </InputWrapper>
        </Form>
        <SuggestionsWrapper>
          {_.size(query) > 2 && status.succeeded && (
            <Suggestions>
              {total > 0
                ? _.map(topResults, (resource) => {
                  const name = getResourceName(resource)
                  const url = getUrl(resource)
                  return (
                    <SuggestionItem
                      key={name}
                      onClick={() => handleSuggestionClick(url)}
                    >
                      <ClampLines
                        text={name}
                        id={name}
                        lines={1}
                        ellipsis='...'
                        buttons={false}
                      />
                    </SuggestionItem>
                  )
                })
                : !status.pending && (
                  <NoResultsItem key={'no-results'}>{message}</NoResultsItem>
                )}
            </Suggestions>
          )}
        </SuggestionsWrapper>
        <QuickLinksContainer>
          {_.map(quickLinks, ({ to, title }, index) => (
            <QuickLinkWrapper key={index}>
              <Link to={to} onClick={handleClose}>
                {title}
              </Link>
            </QuickLinkWrapper>
          ))}
        </QuickLinksContainer>
      </BaseModal>
    </ThemeProvider>
  )
}

export default SearchModal
