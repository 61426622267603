import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'

const DotDigitalPopup = (props) => {
    const { popUpCode } = props

    // as they are pasting the whole script code like <script src="..."></script>
    // here we find the src value in the code and use Helmet to append the script
    const regex = new RegExp(`src="(.*?)">`, 'gm')
    const src = regex.exec(popUpCode)[1]

    return (
        <Helmet>
            <script async src={src} />
        </Helmet>
    )
}

export default DotDigitalPopup