import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import media from 'src/styling/media'
import vars from 'src/styling/vars'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _compact from 'lodash/compact'
import {
  getFirstResource,
  getIdFromParams,
  getFields,
  getReferencedOpeningTimes,
  getAssetSrc
} from 'src/utility'
import _camelCase from 'lodash/camelCase'
import _toLower from 'lodash/toLower'
import DefaultModal from 'src/components/Modal/DefaultModal'
import Container from 'src/components/Container'
import PageBanner from 'src/components/PageBanner'
import SocialShareButtons from 'src/components/SocialShareButtons'
import { useRequest, useCentre } from 'src/store/resources/hooks'
import { fetchResources } from 'src/store/resources/actionCreators'
import BackBar from 'src/components/BackBar'
import FeaturedTiles from 'src/components/Tile/FeaturedTiles'
import ContentWithButtons from 'src/components/ContentWithButtons'
import OpeningTimes from 'src/components/OpeningTimes'
import Text from 'src/components/Text'
import Carousel from 'src/components/Carousel'
import Spacing from 'src/components/Spacing'
import { isCordova } from 'src/env'
import contentfulService from 'src/services/contentfulService'
import NotFound from 'src/pages/NotFound'
import { useInternationalisation } from 'src/context'
import Head from 'src/components/Head'
import { getValidResources } from 'src/components/Tile/getValidResources'
import { getHost, getCentreConfig } from 'src/config'

const Box = styled(Container)`
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.18);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.forEach({
  values: { sm: 20, md: 30, lg: 47 },
  getStyle: (val) => `padding: ${val}px`
})};
`

const resourceType = 'retailUnit'

function RetailUnit(props) {
  const host = getHost()
  const config = getCentreConfig({ host })
  const [isModalOpen, setModal] = useState(false)

  const setOpenModal = () => setModal(true)
  const setCloseModal = () => setModal(false)

  const { translate, translateUrl } = useInternationalisation()
  const urlName = getIdFromParams(props)
  const resources = useRequest({
    resourceType,
    requestKey: urlName
  })

  const resource = getFirstResource(resources)
  const fields = getFields(resource)

  const allowRetailUnitDownload = _.get(fields, 'retailUnitAttachmentAllowed')
  const checkAttachmentExists = _.get(fields, 'retailUnitAttachment')

  const {
    name,
    relatedRetailUnits = [],
    relatedOtherContent = [],
    gallery,
    parentCategory,
    info,
    telephone,
    website,
    headerImage,
    headerImageMobile,
    openingTimes,
    overwriteWithReferencedOpeningHours,
    referencedOpeningTimes,
    buttonText,
    buttonLink,
    centre,
    logoImage,
    pointrId,
    showStoreOpeningTimes
  } = fields || {}

  const { maps } = getFields(useCentre())

  const unitOpeningTimes =
    overwriteWithReferencedOpeningHours && referencedOpeningTimes
      ? getReferencedOpeningTimes(referencedOpeningTimes)
      : openingTimes
  const hasOpeningTimes = _.flatMap(unitOpeningTimes).find(el => !_.isEmpty(el.closingTime) && !_.isEmpty(el.openingTime))
  const isShowStoreOpeningTimes = (_.isUndefined(showStoreOpeningTimes) || showStoreOpeningTimes) && !_.isEmpty(hasOpeningTimes)
  
  const backLinkTexts = {
    eatery: 'DINE',
    shop: 'SHOP',
    leisure: 'OTHER'
  }

  const backLinks = {
    eatery: 'dine',
    shop: 'shop',
    leisure: null
  }

  const backLink = backLinks[_toLower(parentCategory)]
  const backLinkText = translate(
    `RETAIL_UNIT_BACK_${backLinkTexts[_toLower(parentCategory)] || 'OTHER'}`
  )
  const backText = translate('BACK_TO_LISTING', { type: backLinkText || '' })

  if (
    _get(resources, '_status.pending', null) === false &&
    Object.values(fields).length === 0
  ) {
    return <NotFound />
  }
  const { name: centreName } = getFields(centre)
  const shareMessage = _compact([name, centreName]).join(' - ')
  const validRelatedContent = getValidResources(relatedOtherContent)
  return (
    <Fragment>
      <Head
        resource={resource}
        title={name}
      />
      <PageBanner text={name} bannerMediaDesktop={headerImage} bannerMediaMobile={headerImageMobile} hide={isCordova} />
      {fields ? (
        <Container bottomGutter topGutter={!!isCordova || !backLink}>
          {isCordova || !backLink ? null : (
            <BackBar link={`/${backLink}`} text={backText} />
          )}
          <Box>
            {isShowStoreOpeningTimes && <ContentWithButtons title={translate('OPENING_TIMES')}>
              <OpeningTimes data={unitOpeningTimes} />
            </ContentWithButtons>}
            {buttonLink && buttonText && (
              <ContentWithButtons
                buttons={{
                  link: buttonLink,
                  title: buttonText,
                  type: 'primary',
                  width: 313
                }}
              />
            )}
            {gallery && gallery.length ? (
              <React.Fragment>
                <Spacing height={vars.pageGutter} />
                <Carousel images={gallery} limitWidth />
              </React.Fragment>
            ) : null}
            {info || telephone || website ? (
              <React.Fragment>
                <Spacing height={vars.pageGutter} />
                <ContentWithButtons
                  title={translate('INFO')}
                  buttons={
                    website
                      ? {
                        link: website,
                        title: translate('VISIT_SITE'),
                        type: 'primary',
                        width: 313,
                        fullWidth: true
                      }
                      : undefined
                  }
                >
                  <Text>
                    {info ? contentfulService.renderRichText(info) : null}
                    {telephone ? <p>{telephone}</p> : null}
                  </Text>
                </ContentWithButtons>
              </React.Fragment>
            ) : null}
            {isCordova
              ? null
              : <ContentWithButtons
                buttons={{
                  title: translate('VIEW_MAP'),
                  type: 'primary',
                  width: 313,
                  fullWidth: true,
                  link: translateUrl(`/centre-map${_.get(config, 'viadirectLink') ? `?destinationId=${_.get(resource, 'id')}` : ''}`)
                }}
              />}
            {allowRetailUnitDownload && checkAttachmentExists ? (
              <ContentWithButtons
                {...fields}
                buttons={{
                  title: translate('RETAIL_UNIT_DOWNLOAD'),
                  type: 'primary',
                  width: 313,
                  fullWidth: true,
                  onClick: () => {
                    let ctaDownloadUrl = getAssetSrc(
                      _.get(fields, 'retailUnitAttachment')
                    )
                    return window.open(ctaDownloadUrl, '_blank')
                  }
                }}
              />
            ) : null}
            <SocialShareButtons
              message={shareMessage}
              pageTitle={shareMessage}
              image={logoImage}
            />
            {isModalOpen && (
              <DefaultModal onClose={setCloseModal} isModalOpen={isModalOpen}>
                <Carousel
                  images={maps}
                  fixedHeight={false}
                  carouselHeight={{ sm: 500 }}
                  contain
                />
              </DefaultModal>
            )}
          </Box>
          {relatedOtherContent.length || relatedRetailUnits.length ? (
            <Spacing height={vars.pageGutter} />
          ) : null}
          {!_.isEmpty(validRelatedContent) ? (
            <FeaturedTiles
              title={translate('NEWS_AND_OFFERS')}
              resources={validRelatedContent}
            />
          ) : null}
          {relatedRetailUnits.length ? (
            <FeaturedTiles
              title={translate('RELATED_SHOPS')}
              resources={relatedRetailUnits}
            />
          ) : null}
        </Container>
      ) : null}
    </Fragment>
  )
}

RetailUnit.getData = async (props) => {
  const urlName = getIdFromParams(props)
  const { dispatch, host, locale } = props
  return dispatch(
    fetchResources({
      locale,
      resourceType,
      where: {
        'fields.urlName': urlName
      },
      requestKey: urlName,
      host,
      include: 2
    })
  )
}
RetailUnit.identifier = 'RETAIL_UNIT'

export default RetailUnit
