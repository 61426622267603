import React, { useCallback, useState } from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _uniqueId from 'lodash/uniqueId'
import styled from 'styled-components'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { useSelector, useDispatch } from 'react-redux'
import { useTransition, animated } from 'react-spring'

import uiConstants from 'src/store/ui/constants'
import uiSelector from 'src/store/ui/selector'
import { closeNav } from 'src/store/ui/actionCreators'
import vars from 'src/styling/vars'
import colors from 'src/styling/colors'
import { useNav } from 'src/pages/Root/Header/hooks'
import AssetIcon from 'src/components/AssetIcon'
import CrossSvg from 'src/assets/Cross.svg'
import DownArrowSvg from 'src/assets/DropdownArrow.svg'
import { isCordova } from 'src/env'
import MenuLink from 'src/components/MenuLink'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _size from 'lodash/size'

const NavInner = styled.div`
  height: 100%;
  overflow-y: auto;
  min-height: 100%;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  display: flex;
  flex-direction: column;
  > ul {
    -webkit-overflow-scrolling: touch;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    overflow-y: scroll;
    overscroll-behavior: contain;
    ul + ul {
      background-color: ${colors.lightGrey};
      position: sticky;
      bottom: 0px;
      li:not(:last-child) > a {
        border-bottom: solid ${colors.white} 1px;
      }
    }
  }
`

const NavHeader = styled.div`
  min-height: 75px;
  border-bottom: 1px solid ${colors.lightGrey};
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  & > * {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  & > *:nth-child(1) {
    left: 17px;
  }
  & > *:nth-child(2) {
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }
  & > *:nth-child(3) {
    right: 17px;
  }
`

const extraTapArea = 10
const IconButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${40 + extraTapArea * 2}px;
  margin-left: -${extraTapArea}px;
  margin-right: -${extraTapArea}px;
  border-radius: 50%;
`

const NavigationItem = (props) => {
  const { item, setDropdownCategory } = props
  const dispatch = useDispatch()
  const handleClick = useCallback(
    (e) => {
      if (item && item.dropdownCategory) {
        e.preventDefault()
        setDropdownCategory(item.dropdownCategory)
      } else {
        dispatch(closeNav())
      }
    },
    [item]
  )

  if (!item) return null
  if (isCordova && item.hideInApp) return null
  if (!isCordova && item.hideOnWeb) return null
  return (
    <li key={item.link}>
      <MenuLink
        as={item.isExternal && 'a'}
        to={item.link}
        href={item.link}
        onClick={handleClick}
        hideChevron={!item.dropdownCategory}
      >
        {item.label || item.title}
      </MenuLink>
    </li>
  )
}

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

const makeNavigationList = (items) => {
  if (!_size(items)) return null
  return (
    <ul>
      {_map(items, (item) => (
        <NavigationItem item={item} key={item.title} />
      ))}
    </ul>
  )
}

const NavigationOverlay = () => {
  const isNavOpen = useSelector(uiSelector(uiConstants.IS_NAV_OPEN))
  const dispatch = useDispatch()
  const categories = useNav()
  const [dropdownCategory, setDropdownCategory] = useState('')
  const handleBack = useCallback(() => setDropdownCategory(''), [])
  const handleCloseNav = useCallback(() => {
    dispatch(closeNav())
  }, [])

  const transitions = useTransition(isNavOpen, null, {
    from: { opacity: 0, transform: 'translateX(-100%)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: { opacity: 0, transform: 'translateX(-100%)' },
    onRest: (bool, key) => {
      if (bool && key === 'leave') {
        setDropdownCategory('')
      }
    }
  })

  const categoryLabel = React.useMemo(() => {
    const navItem = _find(categories, { dropdownCategory })
    return navItem ? navItem.label : null
  }, [dropdownCategory])
  return transitions.map(({ item, key, props: transitionProps }) => {
    const { opacity, transform } = transitionProps
    const chosenCategory = _find(categories, { dropdownCategory })

    return (
      item && (
        <AnimatedDialogOverlay
          style={{
            zIndex: vars.zIndex.navigationOverlay,
            opacity: opacity.interpolate([0, 0.25, 1], [0, 1, 1])
          }}
          key={key}
          onDismiss={handleCloseNav}
        >
          <AnimatedDialogContent
            style={{
              width: '90%',
              maxWidth: 360,
              height: '100%',
              margin: 0,
              padding: 0,
              transform
            }}
          >
            <NavInner dropdownCategory={dropdownCategory}>
              <NavHeader>
                {dropdownCategory ? (
                  <IconButton onClick={handleBack} aria-label={'Back'}>
                    <AssetIcon
                      svg={DownArrowSvg}
                      size={24}
                      style={{ transform: `rotate(90deg)` }}
                    />
                  </IconButton>
                ) : (
                    // empty div needed here for nth-child styling
                    <div />
                  )}
                <div>{categoryLabel}</div>
                <IconButton onClick={handleCloseNav} aria-label={'Close'}>
                  <AssetIcon svg={CrossSvg} size={24} />
                </IconButton>
              </NavHeader>
              {dropdownCategory ? (
                <ul key={dropdownCategory}>
                  {makeNavigationList(chosenCategory.subcategories)}
                  {makeNavigationList(chosenCategory.pages)}
                </ul>
              ) : (
                  <ul>
                    {categories.map((item) => {
                      return (
                        <NavigationItem
                          item={item}
                          key={item.label}
                          setDropdownCategory={setDropdownCategory}
                        />
                      )
                    })}
                  </ul>
                )}
            </NavInner>
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
    )
  })
}

export default NavigationOverlay
