import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
import _omit from 'lodash/omit'
import _map from 'lodash/map'
import _uniqueId from 'lodash/uniqueId'
import _filter from 'lodash/filter'
import _find from 'lodash/filter'
import _upperFirst from 'lodash/upperFirst'
import _keyBy from 'lodash/keyBy'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'src/components/RouterDom'
import { useRequest } from 'src/store/resources/hooks'
import { getFields } from 'src/utility'
import _ from 'lodash'

import vars from 'src/styling/vars'
import NavigationCategory from './NavigationCategory'
import CloseIcon from 'src/assets/Close.svg'
import uiSelector from 'src/store/ui/selector'
import uiConstants from 'src/store/ui/constants'
import { setNavTabIndex, closeNav } from 'src/store/ui/actionCreators'
import colors from 'src/styling/colors'
import HoverBold from 'src/components/HoverBold'
import { useNav } from 'src/pages/Root/Header/hooks'
import { isCordova } from 'src/env'
import { fetchResources } from 'src/store/resources/actionCreators'

import { useInternationalisation } from 'src/context'

const Container = styled.div``

const TabContainer = styled.div`
  [data-reach-tabs] {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
  }
  [data-reach-tab-panels] {
    min-height: 0;
    height: 100%;
  }
  [data-reach-tab-panel] {
    height: 100%;
  }
`

const Menu = styled(TabList)`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 12px 90px 12px 15px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const MenuItem = styled(Tab)`
  color: currentColor;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  background: none;
  border: 0;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus,
  &[data-selected] {
    font-weight: bold;
    color: ${colors.red};
  }
`

const CloseButton = styled.button`
  position: fixed;
  top: 7px;
  right: 8px;
  width: 44px;
  height: 44px;
  background: none;
  border: 0;
  padding: 0;
`

const dialogStyles = {
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0
}

const MenuTab = (props) => {
  const { children, item, ...rest } = props
  return (
    <MenuItem {...rest}>
      <HoverBold active={props['data-selected']}>{children}</HoverBold>
    </MenuItem>
  )
}

const NavigationOverlay = (props) => {
  const { isOpen, closeOverlay, history, host } = props
  const { locale, translateUrl } = useInternationalisation()
  const dispatch = useDispatch()
  const tabIndex = useSelector(uiSelector(uiConstants.NAV_TAB_INDEX))
  const navImagePages = useRequest({
    resourceType: 'page',
    requestKey: 'navImagePages'
  })
  const categories = useNav()
  const handleTabChange = useCallback(
    (tabIndex) => {
      const navItem = categories[tabIndex]
      const { subcategories, pages, link } = navItem || {}
      if (subcategories || (pages && pages.length)) {
        dispatch(setNavTabIndex({ tabIndex }))
      } else {
        dispatch(closeNav())
        history.push(translateUrl(link))
      }
    },
    [categories]
  )

  useEffect(() => {
    const pages = []
    // for (const item of categories) {
    for (let index = 0; index < categories.length; index++) {
      const item = categories[index]

      if (item.link && !item.image) {
        pages.push(item.link.replace('/', ''))
      }
      if (item.typeListings) {
        pages.push(
          ..._map(item.subcategories, ({ link }) => link.replace('/', ''))
        )
      }
    }
    dispatch(
      fetchResources({
        locale,
        resourceType: 'page',
        requestKey: 'navImagePages',
        where: { 'fields.urlName[in]': pages.join(',') },
        host
      })
    )
  }, [categories])

  useMemo(() => {
    const pages = _keyBy(
      navImagePages.resources,
      ({ fields }) => fields.urlName
    )
    // for (const item of categories) {
    for (let index = 0; index < categories.length; index++) {
      const item = categories[index]
      if (item.subcategories) {
        // for (const subcategory of item.subcategories) {
        for (let index = 0; index < item.subcategories.length; index++) {
          const subcategory = item.subcategories[index]
          if (!subcategory.image) {
            const urlName = subcategory.link.replace('/', '')
            if (pages[urlName]) {
              const { navImage } = getFields(pages[urlName])
              subcategory.image = navImage
            }
          }
        }
      }
    }
  }, [navImagePages, categories])

  const renderItems = () => {
    return categories
      .filter((item) => {
        if (isCordova && item.hideInApp) return false
        if (!isCordova && item.hideOnWeb) return false
        return true
      })
      .map((item) => {
        return (
          <MenuTab key={item.label} item={item}>
            {item.label}
          </MenuTab>
        )
      })
  }
  return (
    <Fragment>
      <Container>
        <DialogOverlay
          style={{ zIndex: vars.zIndex.navigationOverlay }}
          isOpen={isOpen}
        >
          <DialogContent style={dialogStyles}>
            <TabContainer>
              <Tabs onChange={handleTabChange} index={tabIndex}>
                <Menu>{renderItems()}</Menu>
                <CloseButton onClick={closeOverlay}>
                  <CloseIcon aria-label={'Close'} />
                </CloseButton>
                <TabPanels>
                  {categories.map((item, index) => (
                    <TabPanel key={index}>
                      <NavigationCategory
                        category={item.dropdownCategory}
                        closeOverlay={closeOverlay}
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </TabContainer>
          </DialogContent>
        </DialogOverlay>
      </Container>
    </Fragment>
  )
}

export default withRouter(NavigationOverlay)
