import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import _mapValues from 'lodash/mapValues'
import _debounce from 'lodash/debounce'
import _transform from 'lodash/transform'
import _has from 'lodash/has'
import { withRouter } from 'src/components/RouterDom'
import qs from 'query-string'

import vars from 'src/styling/vars'
import media from 'src/styling/media'
import colors from 'src/styling/colors'
import { useInternationalisation } from 'src/context'

const tileGutter = vars.pageGutter.sm

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const FieldContainer = styled.li`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 20px;
  ${(p) => (p.alignEnd ? 'margin-left: auto;' : '')}
  ${media.greaterThan('lg')`
    width: ${(p) => (p.widthPercentage ? `${p.widthPercentage}%` : `25%`)};
    ${(p) =>
      p.key === 'filter'
        ? `padding-left: ${tileGutter}px`
        : `padding-right: ${tileGutter}px`};
    ${(p) =>
      p.align === 'right'
        ? `padding-left: ${tileGutter}px`
        : `padding-right: ${tileGutter}px`};
    margin-bottom: 0;
  `}
`

const ResultsStatus = styled.div`
  width: 100%;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.1;
  padding-bottom: 8px;
  color: ${colors.darkGrey};
  ${media.greaterThan('lg')`
    width: 75%;
  `}
`

let Filters = (props) => {
  const { history, location, match, schema, status } = props
  const { parentCategory, category } = match.params || {}
  const queries = qs.parse(_get(location, 'search')) || {}
  const [fieldValues, setFieldValues] = React.useState({
    ...queries
  })
  const { translateUrl } = useInternationalisation()

  const changeHandlers = React.useMemo(() => {
    return _map(schema, (schemaItem) => {
      // slightly funky logic is because of react event pooling
      const { key, queryKey, debounce } = schemaItem
      let updateQueries = (queryStrings) => {
        let path = queryStrings[key]
        if (schemaItem.isLink && queryStrings[key]) {
          path = queryStrings[key]
        } else if (key === 'category') {
          path = queryStrings[key]
            ? `/${parentCategory}/${queryStrings[key]}`
            : `/${parentCategory}`
        } else {
          let newQueryStrings = {}
          Object.keys(queryStrings).forEach(qK => { if (queryStrings[qK] && queryStrings[qK] !== "") newQueryStrings[qK] = queryStrings[qK] })
          path = `?${qs.stringify(newQueryStrings)}`
        }
        history.replace(translateUrl(path))
      }

      const debounced = debounce
        ? _debounce(updateQueries, debounce)
        : updateQueries
      return (e) => {
        const value = e.target.value
        const newLocation = {
          ...fieldValues,
          ...(queryKey ? { [queryKey]: key } : { [key]: value })
        }
        setFieldValues(newLocation)
        return debounced(newLocation)
      }
    })
  }, [schema])

  React.useEffect(() => {
    /** @type {Object} An object containing param keys from the schema with empty values.
     * Otherwise, If the schema item appears on many page listings, it is not cleared from state as the param name is not in the URL.
     * */
    const emptyQueryKeys = _transform(schema, (acc, value) => {
      if (!_has(queries, value.key)) {
        acc[value.key] = ''
      }
    }, {})
    setFieldValues({ ...emptyQueryKeys, ...queries })
    // Check if the location key has changed first.
    // App doesn't have a location key, so pathname is provided as a fallback.
  }, [_get(location, 'key'), _get(location, 'pathname')])

  return (
    <Wrapper>
      {status ? <ResultsStatus>{status}</ResultsStatus> : null}
      {_map(schema, (schemaItem, index) => {
        const {
          component: Comp,
          props: fieldProps,
          key,
          queryKey,
          isLink,
          ...rest
        } = schemaItem
        const value = (() => {
          if (schemaItem.isLink) return match.url
          if (key === 'category') return category
          return queryKey ? fieldValues[queryKey] === key : fieldValues[key]
        })()

        if (isLink) {
          fieldProps.options = _mapValues(fieldProps.options, (option) => ({
            value: translateUrl(option.value),
            label: option.label
          }))
        }
        return (
          <FieldContainer key={key} {...rest}>
            <Comp
              value={value}
              onChange={changeHandlers[index]}
              {...fieldProps}
            />
          </FieldContainer>
        )
      })}
    </Wrapper>
  )
}

Filters = withRouter(Filters)
Filters = React.memo(Filters)

export default Filters

// example schema prop:
// [
//   {
//     component: TextField,
//     props: { label: 'Search', iconSvg: SearchSvg },
//     key: 'search'
//   },
//   {
//     component: SelectField,
//     props: {
//       label: 'All categories',
//       allowNullValue: true,
//       options: categoryOptions
//     },
//     key: 'category'
//   }
// ]
