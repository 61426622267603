import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _capitalize from 'lodash/capitalize'
import _first from 'lodash/first'
import _flow from 'lodash/flow'
import _property from 'lodash/property'

import {
  getFirstResource,
  getResourceId,
  getUrlName,
  getFields
} from 'src/utility'
import { useRequest } from 'src/store/resources/hooks'
import Page, { getPageData } from 'src/components/Page'
import Tabs from 'src/components/Tabs'
import NotFound from 'src/pages/NotFound'
import CarParkingSvg from 'src/assets/CarParking.svg'

import { useInternationalisation } from 'src/context'

import Panel from './panel'

const mapCarparkTabs = (carpark, suffix) => {
  const {
    name,
    urlName,
    spacesTotal
  } = _get(carpark, 'fields', {})
  return {
    label: `${name} - ${spacesTotal} ${suffix}`,
    to: `/parking/${urlName}`,
    icon: CarParkingSvg
  }
}

const Parking = (props) => {
  const { location, history, match } = props
  const { pathname } = location || {}
  const { translate, translateUrl } = useInternationalisation()
  
  const carparkURLName = _get(match, 'params.id')

  const request = useRequest({
    resourceType: 'layout',
    requestKey: 'layout'
  })

  const status = _get(request, '_status')
  
  const carParkResources = _flow(
    getFirstResource,
    _property('fields.carParks')
  )(request)

  const currentCarpark = carparkURLName
    ? _find(carParkResources, ['fields.urlName', carparkURLName])
    : _first(carParkResources)
  
  const currentCarparkId = getResourceId(currentCarpark)
  const currentCarparkURLName = getUrlName(currentCarpark)
  const fields = getFields(currentCarpark) || {}
  const showParkingAttachmentCTA = _get(fields, 'anonymousParkingDownloadAllowed')
  const attachmentExists = _get(fields, 'attachment')

  // set carparkURL in "/parking/:carparkURLName" if non is set.
  if (!carparkURLName && currentCarparkURLName) {
    history.replace({ pathname: `/${translateUrl('parking')}/${currentCarparkURLName}` })
  }
  const {
    location: carparkLocation,
    usefulInformation,
    tariffInformation,
    openingTimes
  } = fields || {}

  if (!carparkLocation && carparkURLName) {
    return <NotFound />
  }

  const mappedTabs = _map(
    carParkResources,
    (resource) => mapCarparkTabs(resource, translate('SPACES'))
  )

  const mappedCarparkOptions = _map(
    carParkResources,
    (carpark) => {
      const { name, urlName } = _get(carpark, 'fields', {})
      return {
        label: name,
        value: `/parking/${urlName}`
      }
    }
  )
  
  const mobileSelect = {
    options: mappedCarparkOptions,
    value: translateUrl(pathname),
    onChange: (e) => {
      history.replace(translateUrl(_get(e, 'target.value')))
    }
  }

  return (
    <Page requestKey={'parking'} box>
      <Tabs
        tabs={mappedTabs}
        panel={
          <Panel
            status={status}
            carparkLocation={carparkLocation}
            currentCarpark={currentCarpark}
            currentCarparkId={currentCarparkId}
            openingTimes={openingTimes}
            tariffInformation={tariffInformation}
            usefulInformation={usefulInformation}
            translate={translate}
            showParkingAttachmentCTA={showParkingAttachmentCTA}
            attachmentExists={attachmentExists}
          />
        }
        mobileSelect={mobileSelect}
      />
    </Page>
  )
}

Parking.getData = (props) => {
  return getPageData(props, { urlName: 'parking'})
}

Parking.identifier = 'PARKING'

export default Parking
