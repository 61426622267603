import React from 'react'
import { getHost, getCentreConfig, getPointrId } from 'src/config'
import _ from 'lodash'
import MappedInWebMap from './MappedInWebMap'
import PointrWebMap from './PointrWebMap'
import ViadirectWebMap from './ViadirectWebMap'


const WebMap = ({ fillMap }) => {

    const host = getHost()

    if (_.isUndefined(host)) {
        return null
    }

    const config = getCentreConfig({ host })
    const pointrId = getPointrId({ host })

    if (config.mappedIn) {
        return <MappedInWebMap fillMap={fillMap} />
    } else if (_.get(config, 'viadirectLink')) {
        return <ViadirectWebMap fillMap={fillMap} />
    } else if (pointrId) {
        return <PointrWebMap fillMap={fillMap} />
    } else return null
}

export default WebMap