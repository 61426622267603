import React, { Fragment } from 'react'
import styled from 'styled-components'

import env, { isCordova } from 'src/env'
import packageJson from '../../package.json'
import vars from 'src/styling/vars'

const boxBg = `rgba(0, 0, 0, 0.8)`

const Box = styled.aside`
  position: fixed;
  bottom: 0;
  ${isCordova
    ? `
  bottom: calc(${vars.appTabBarHeight}px + constant(safe-area-inset-bottom));
  bottom: calc(${vars.appTabBarHeight}px + env(safe-area-inset-bottom));
  `
    : ''}
  right: 0;
  background-color: ${boxBg};
  color: white;
  padding: 2px 4px;
  font-size: 10px;
  line-height: 10px;
  user-select: none;
  font-family: monospace;
  pointer-events: none;
`

const EnvironmentWrapper = (props) => {
  const { children } = props
  const isProd = env.envKey === 'prod'

  const environmentText = React.useMemo(() => {
    if (!env.envKey || !packageJson.version) return
    let environmentText = `${env.envKey} [v${packageJson.version}]`
    if (env.buildNumber) environmentText += ` [b${env.buildNumber}]`
    return environmentText
  }, [])

  React.useEffect(() => {
    console.log(`%c${environmentText}`, `background: ${boxBg}; color: white`)
  }, [])

  if (!environmentText) {
    return 'App unavailable. Missing environment variables.'
  }
  if (isProd) return children
  return (
    <Fragment>
      {children}
      <Box>{environmentText}</Box>
    </Fragment>
  )
}

export default EnvironmentWrapper
