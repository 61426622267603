const uiConstants = {
  IS_NAV_OPEN: `IS_NAV_OPEN`,
  NAV_TAB_INDEX: `NAV_TAB_INDEX`,
  MODAL_ID: `MODAL_ID`,
  IS_MODAL_OPEN: `IS_MODAL_OPEN`,
  PAGE_TITLE: `PAGE_TITLE`,
  META: `META`,
  SHOW_ONBOARDING: 'SHOW_ONBOARDING',
  PUSH_PROMPT_CHECKED: 'PUSH_PROMPT_CHECKED',
  MAP_JOURNEY_DURATION: 'MAP_JOURNEY_DURATION'
}

export default uiConstants
