import React, { Fragment, useState, useCallback } from 'react'
import styled from 'styled-components'
import { NavLink } from 'src/components/RouterDom'
import { useSelector, useDispatch } from 'react-redux'
import useWindowSize from 'react-use/lib/useWindowSize'

import Container from 'src/components/Container'
import uiSelector from 'src/store/ui/selector'
import uiConstants from 'src/store/ui/constants'
import { openNav, closeNav } from 'src/store/ui/actionCreators'
import { breakpoints } from 'src/styling/media'
import HoverBold from 'src/components/HoverBold'
import { useNav } from 'src/pages/Root/Header/hooks'

import NavigationOverlay from './NavigationOverlay'
import { isCordova } from 'src/env'

const ItemsWrapper = styled.div`
  ul {
    margin: 0 auto;
    padding: 12px 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  li:first-child:nth-last-child(-n + 3),
  li:first-child:nth-last-child(-n + 3) ~ li {
    margin: 0 auto;
  }
  li {
    padding: 0 15px;
  }
`

const NavItemLink = styled(NavLink)`
  color: currentColor;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  &:hover,
  &.active {
    font-weight: bold;
  }
`

const { IS_NAV_OPEN } = uiConstants

const NavItem = (props) => {
  const { item, index } = props

  const dispatch = useDispatch()
  const handleClick = useCallback(
    (e) => {
      if (item && item.dropdownCategory) {
        e.preventDefault()
        dispatch(openNav({ tabIndex: index }))
      }
    },
    [item]
  )

  if (isCordova && item.hideInApp) return null
  if (!isCordova && item.hideOnWeb) return null

  return (
    <li key={item.link}>
      <NavItemLink to={item.link} onClick={handleClick}>
        <HoverBold>{item.label}</HoverBold>
      </NavItemLink>
    </li>
  )
}

const Nav = (props) => {
  const { className } = props

  const dispatch = useDispatch()
  const isNavOpen = useSelector(uiSelector(IS_NAV_OPEN))
  const closeOverlay = useCallback(() => {
    dispatch(closeNav())
  }, [])
  const nav = useNav()

  const { width } = useWindowSize()

  return (
    <Fragment>
      <Container>
        <ItemsWrapper className={className}>
          <ul>
            {nav.map((item, index) => {
              return <NavItem item={item} index={index} key={item.label} />
            })}
          </ul>
        </ItemsWrapper>
      </Container>
      {width && width > breakpoints.header ? (
        <NavigationOverlay isOpen={isNavOpen} closeOverlay={closeOverlay} />
      ) : null}
    </Fragment>
  )
}

export default styled(Nav)``
