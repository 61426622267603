import _ from 'lodash'

import cognitoService from 'src/services/cognitoService'
import apiService from 'src/services/apiService'
import { logTrace } from 'src/utility'

import actionTypes from './actionTypes'
import { eventsAwaitingUploadSelector } from './selectors'

const createEvent = (payload) => {
  return {
    type: actionTypes.CREATE_EVENT,
    payload: {
      ...payload,
      event_date: new Date().toISOString()
    }
  }
}

const uploadEvents = () => {
  return async (dispatch, getState) => {
    try {
      const events = eventsAwaitingUploadSelector()(getState())
      if (events.length) {
        const token = await cognitoService.getToken()
        await apiService.createAnalyticEvents({ token, payload: events })
        dispatch({ type: actionTypes.UPLOAD_EVENTS })
        logTrace(`ANALYTIC EVENTS - ${events.length} UPLOADED`)
      } else {
        logTrace(`ANALYTIC EVENTS - NONE TO UPLOAD`)
      }
    } catch (err) {
      console.error(err)
      logTrace(`ANALYTIC EVENTS - FAILED TO UPLOAD`)
    }
  }
}

export { createEvent, uploadEvents }
