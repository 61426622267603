import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'

import {
  fileSelector,
  fileUrlSelector,
  categorySelector,
  weightsSelector,
  cropSelector,
  isCroppedSelector
} from 'src/store/styleSeeker/selectors'
import apiService from 'src/services/apiService'

import { useInternationalisation } from 'src/context'

import {
  setWeights,
  closeFiltersMenu,
  setSimilar,
  setIsLoading,
  clear,
  setCategory
} from 'src/store/styleSeeker/actionCreators'

import imageUploadService from 'src/pages/StyleSeeker/ImageUploadService'
import colors from 'src/styling/colors'

import CategoryItem from './CategoryItem'

const GroupHeader = styled.h4`
  font-size: 16px;
  font-weight: 700;
  padding: 20px 22px 20px 22px;
  text-transform: uppercase;
  border-top: 1px solid ${colors.lighterGrey};
`

const ItemsList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const weightOptions = {
  color: { 27: 100 },
  pattern: { 31: 100 }
}

const RefineBy = () => {
  const dispatch = useDispatch()

  const file = useSelector(fileSelector())
  const fileUrl = useSelector(fileUrlSelector())
  const crop = useSelector(cropSelector())
  const category = useSelector(categorySelector())
  const weights = useSelector(weightsSelector())

  const { translate } = useInternationalisation()

  const findSimilar = (weightsOption) => {
    dispatch(setIsLoading(true))
    dispatch(closeFiltersMenu())
    dispatch(setWeights(weightsOption))
    return apiService
      .styleSeekerSimilar({
        fileUrl,
        categoryAlias: category,
        crop,
        weights: weightsOption,
        content_id: null
      })
      .then(({ similarProducts, categoryAlias }) => {
        dispatch(setSimilar(similarProducts))
        dispatch(setCategory(categoryAlias))
        dispatch(setIsLoading(false))
      })
      .catch((err) => {
        console.log(err)
        dispatch(setIsLoading(false))
      })
  }

  return (
    <>
      <GroupHeader>{translate('STYLE_SEEKER_REFINE_BY')}</GroupHeader>
      <ItemsList>
        <CategoryItem
          label={translate('STYLE_SEEKER_COLOR')}
          isActive={weights === weightOptions.color}
          onClick={() => findSimilar(weightOptions.color)}
        />
        <CategoryItem
          label={translate('STYLE_SEEKER_PATTERN')}
          isActive={weights === weightOptions.pattern}
          onClick={() => findSimilar(weightOptions.pattern)}
        />
      </ItemsList>
    </>
  )
}

export default RefineBy
