import _ from 'lodash'
import { createHashHistory } from 'history'
import env, { isCordova } from 'src/env'
import { update } from 'src/store/account/actionCreators'
import { updatePushPermissionsCheckbox } from 'src/store/ui/actionCreators'
import { setDevice } from 'src/store/device/actionCreators'

/**
 * Create a clean delta of the device object passed, with no falsey values.
 * @param {object} device
 */
const createDeviceDelta = (device) => {
  return _.pickBy(device, _.identity)
}
/**
 * Store information provided by on-device push providers such as airship.
 * @param {object} parameters
 * @param {(import('redux').Dispatch)} parameters.dispatch
 * @returns
 */
const storePushDetailsFromEvent = ({ dispatch }) => (event) => {
  /**
   * An update to the device store containing push notification information
   */
  const deviceDelta = createDeviceDelta({
    deviceId: event.deviceToken,
    registrationId: event.registrationToken,
    airshipChannelId: event.channelID
  })
  dispatch(setDevice(deviceDelta))
}

const startAirship = ({ appKey, appSecret, dispatch }) => {

  window.UAirship.takeOff({
    production: {
      appKey,
      appSecret
    },
    development: {
      appKey,
      appSecret
    },
    site: 'EU' // Optional. Add if app uses Airship's EU cloud site.
  })

  // Configure iOS
  window.UAirship.setPresentationOptions(
    window.UAirship.presentationOptions.alert
  )

  // Restoring full Airship functionality by opting back into data collection can be accomplished with the following call:
  // https://docs.airship.com/platform/cordova/data-collection/#config-instructions
  syncPushNotificationSettingsWithOs({ dispatch })
  window.UAirship.enableFeature(['FEATURE_ALL'])
  window.UAirship.getChannelID((id) => {
    if (id) {
      console.log('AIRSHIP: Channel ID:', id)
      storePushDetailsFromEvent({ dispatch })({ channelID: id })
    }
  })

  addEventListeners({ dispatch })
}

const onPushReceived = (event) => {
  console.log('AIRSHIP: onPushReceived', event)
  if (event.message) {
    console.log("AIRSHIP: Received push: " + event.message)
  } else {
    console.log("AIRSHIP: No incoming message")
  }
}

const notificationOpened = (event) => {
  console.log('AIRSHIP: notificationOpened', event)
  console.log('AIRSHIP: notification extras', event.extras)
  if (event.message) {
    console.log("Notification opened: " + event.message)
  } else {
    console.log("No incoming message")
  }
}

const handleDeepLink = (event) => {
  console.log("AIRSHIP:handleDeepLink: " + event.deepLink)
  const history = createHashHistory()
  const deepLink = event.deepLink
  const [_app, location] = _.split(deepLink, 'app://', 2);
  console.log("AIRSHIP:handleDeepLink:Location: ", location)
  if (location) {
    history.push(`/${location}`)
  }
}

const initiateUI = () => {

  // Remove the tag from the current list of tags
  const addTag = (tag) => {
    window.UAirship.getTags((tags) => {
      console.log("Removing tag: " + tag)
      tags.splice(tags.indexOf(tag), 1)
      window.UAirship.setTags(tags)
    })
  }


  window.UAirship.isFeatureEnabled(['FEATURE_ALL'], (isEnabled) => {
    console.log(`AIRSHIP: Data Collection Enabled: ${isEnabled}`)
  })


  window.UAirship.getTags((tags) => {
    tags.forEach((tag) => {
      addTag(tag)
    })
  })
}

const addEventListeners = ({ dispatch }) => {
  const dispatchEventToDeviceStore = storePushDetailsFromEvent({ dispatch })
  // Register for any urban airship events
  document.addEventListener('urbanairship.registration', (event) => {
    if (event.error) {
      console.log('There was an error registering for push notifications')
    } else {
      console.log("Registered with ID: " + event.channelID)
    }
    dispatchEventToDeviceStore(event)
  })
  document.addEventListener('urbanairship.push', onPushReceived)
  document.addEventListener('urbanairship.notification_opened', notificationOpened)
  document.addEventListener('urbanairship.deep_link', handleDeepLink)
  document.addEventListener('urbanairship.inbox_updated', (event) => {
    console.log('urbanairship.inbox_updated:', event)
    if (event.message) {
      console.log("Inbox updated: " + event.message)
    } else {
      console.log("No inbox")
    }
  })

  // Handle resume
  document.addEventListener('resume', () => {
    console.log('Device resume!')
    window.UAirship.resetBadge()
    // Reregister for urbanairship events if they were removed in pause event
    document.addEventListener('urbanairship.registration', dispatchEventToDeviceStore)
    document.addEventListener('urbanairship.push', onPushReceived)
    syncPushNotificationSettingsWithOs({ dispatch })
  })

  // Handle pause
  document.addEventListener('pause', () => {
    console.log('Device pause!')
    // Remove urbanairship events.  Important on android to not receive push in the background.
    document.removeEventListener('urbanairship.registration', dispatchEventToDeviceStore)
    document.removeEventListener('urbanairship.push', onPushReceived)
  })

  // Initiate the UI
  initiateUI()
}

/**
 * Check the App's settings within the Operating System's settings app,
 * and sync the setting with the user's preferences within the application and the database.
 *
 * Performs no operation if the application isn't on device.
 */
const syncPushNotificationSettingsWithOs = ({ dispatch }) => {
  if (!window || !isCordova || env.fakeCordova) {
    return
  }
  window.cordova.plugins.diagnostic.isRemoteNotificationsEnabled(
    (isEnabled) => {
      console.log(`is notifications enabled: ${isEnabled}`)
      dispatch(updatePushPermissionsCheckbox(isEnabled))
      dispatch(update({ 'push_notifications_permitted': isEnabled }))
      const device = createDeviceDelta(window.device)
      dispatch(setDevice(device))
      window.UAirship.setUserNotificationsEnabled(isEnabled)
    },
    (error) => {
      console.error('The following error occurred: ' + error)
    }
  )
}

/**
 * Present the user with dialogue box redirecting the user to the settings of the app.
 * When the app resumes, the `'resume'` event handler will call `syncPushNotificationSettingsWithOs`.
 *
 * Performs no operation if the application isn't on device.
 */
const openNotificationSettings = () => {
  if (!window || !isCordova || env.fakeCordova) {
    return
  }
  const onConfirm = (buttonIndex) => {
    if (buttonIndex === 2) {
      window.cordova.plugins.diagnostic.switchToSettings()
    }
  }
  window.navigator.notification.confirm(
    'Change push notification settings', // message
    onConfirm, // callback
    'Push notifications', // title
    ['Cancel', 'Settings'] // buttonLabels
  )
}

const airshipService = {
  startAirship,
  syncPushNotificationSettingsWithOs,
  openNotificationSettings
}

export default airshipService
