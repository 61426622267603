import React, { Fragment } from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _ from 'lodash'

import Spacing from 'src/components/Spacing'
import PageBanner from 'src/components/PageBanner'
import vars from 'src/styling/vars'

import Stories from './Stories'
import { useRequest, useResources } from 'src/store/resources/hooks'
import { getFirstResourceFields, getResources } from 'src/utility'
import { fetchResources } from 'src/store/resources/actionCreators'
import FeaturesCarousel from 'src/components/FeaturesCarousel'
import FeaturesCarouselHeading from 'src/components/FeaturesCarousel/FeaturesCarouselHeading'

import { useSelector, useDispatch } from 'react-redux'
import { savedSelector } from 'src/store/account/selectors'

import { useInternationalisation } from 'src/context'
import useMounted from 'src/hooks/useMounted'

const defineRequest = (params) => {
  const { resourceType, requestKey } = params
  return {
    use: () => useRequest({ resourceType, requestKey }),
    fetch: (fetchParams) => {
      const { dispatch, ...rest } = fetchParams
      return dispatch(
        fetchResources({
          ...rest,
          ...params
        })
      )
    }
  }
}

const reduceItems = (saved, contentType) => {
  return saved.reduce((acc, item) => {
    if (item.content_type === contentType) {
      return [...acc, { id: item.id, content_id: item.content_id }]
    }
    return acc
  }, [])
}

const _layoutRequest = defineRequest({
  resourceType: 'layout',
  requestKey: 'layout'
})

const now = new Date().toISOString()

const _promotionsRequest = defineRequest({
  resourceType: 'promotion',
  requestKey: 'app-home/promotion',
  where: {
    'fields.publishOnPlusApp': true,
    'fields.endDate[gte]': now,
    'fields.displayFrom[lte]': now
  },
  order: 'fields.stickyOrdering,-fields.displayFrom'
})

const _newsRequest = defineRequest({
  resourceType: 'news',
  requestKey: 'app-home/news',
  where: {
    'fields.publishOnPlusApp': true,
    'fields.displayFrom[lte]': now
  },
  order: 'fields.stickyOrdering,-fields.displayFrom'
})

const _eventsRequest = defineRequest({
  resourceType: 'event',
  requestKey: 'app-home/event',
  where: {
    'fields.publishOnPlusApp': true,
    'fields.endDate[gte]': now,
    'fields.displayFrom[lte]': now
  },
  order: 'fields.stickyOrdering,-fields.displayFrom'
})

const AppHome = (props) => {
  const { translate } = useInternationalisation()
  const layoutRequest = _layoutRequest.use()
  const {
    appHomeLinks,
    appHomeBannerImage,
    appHomeBannerTitle
  } = getFirstResourceFields(layoutRequest)
  const mounted = useMounted()

  const saved = useSelector(savedSelector())

  const promotionsRequest = _promotionsRequest.use()
  const promotions = getResources(promotionsRequest).map((resource) => ({
    ...resource,
    content_id: resource.id,
    content_type: 'promotion',
    savedId: _get(
      _.find(saved, (item) => item.content_id === resource.id),
      'id',
      null
    )
  }))

  const newsRequest = _newsRequest.use()
  const news = getResources(newsRequest).map((resource) => ({
    ...resource,
    content_id: resource.id,
    content_type: 'news',
    savedId: _get(
      _.find(saved, (item) => item.content_id === resource.id),
      'id',
      null
    )
  }))

  const eventsRequest = _eventsRequest.use()
  const events = getResources(eventsRequest).map((resource) => ({
    ...resource,
    content_id: resource.id,
    content_type: 'event',
    savedId: _get(
      _.find(saved, (item) => item.content_id === resource.id),
      'id',
      null
    )
  }))

  const carouselProps = mounted ? {} : { slideLimit: 1 }
  return (
    <div>
      <Spacing height={vars.headerHeight.sm + 38} />
      <Stories links={appHomeLinks} />
      <PageBanner
        text={appHomeBannerTitle}
        noHeader
        bannerMediaDesktop={appHomeBannerImage}
        isHero={true}
      />
      <>
        <FeaturesCarouselHeading
          heading={translate('PAGE_PROMOTIONS')}
          to={'/promotions'}
        />
        {mounted ? (
          <FeaturesCarousel resources={promotions} {...carouselProps} />
        ) : null}
        <FeaturesCarouselHeading
          heading={translate('PAGE_NEWS')}
          to={'/news'}
        />
        {mounted ? (
          <FeaturesCarousel resources={news} {...carouselProps} />
        ) : null}
        <FeaturesCarouselHeading
          heading={translate('PAGE_EVENTS')}
          to={'/events'}
        />
        {mounted ? (
          <FeaturesCarousel resources={events} {...carouselProps} />
        ) : null}
      </>
    </div>
  )
}

AppHome.getData = async (props) => {
  const { host, dispatch, locale } = props

  return Promise.all(
    [_promotionsRequest, _newsRequest, _eventsRequest].map(({ fetch }) => {
      return fetch({ dispatch, host, locale })
    })
  )
}
AppHome.identifier = 'APP_HOME'

export default AppHome
