import _ from 'lodash'
import { isCordova } from 'src/env'
import gte from 'semver/functions/gte'
import coerce from 'semver/functions/coerce'
/**
 * Get the cordova IDFA plugin from the window.
 */
const getIdfaPlugin = () => {
    if (!isCordova) {
        return
    }
    return _.get(window, 'cordova.plugins.idfa')
}
/**
* `returns true` if the user hasn't answered the App Tracking Transparency modal before
*/
const getIsTrackingStatusUndetermined = async () => {
    const idfaPlugin = getIdfaPlugin()
    if (!idfaPlugin) {
        return false
    }
    const information = await idfaPlugin.getInfo()
    return information.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED
}
/**
 * The number of attempts the app will try to show the prompt.
 * A limit has been put on to reduce the chance of a memory leak.
 */
const MAX_ATTEMPTS = 25
/**
 * The interval each try before calling the App Tracking Transparency Prompt.
 */
const INTERVAL_MS = 5000
/**
 * Awaitable timeout function
 * @param {number} ms The wait time in MS. Defaults to {@link INTERVAL_MS}.
 */
const timeout = (ms = INTERVAL_MS) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
/**
 * Presents the App Policy Modal for Apple Devices running iOS 14 or above.
 */
export const presentAppPolicyModal = async () => {
    try {
        if (!isCordova) {
            return
        }
        /**
         * Android has some kind of equvilant but we don't need to support, so only show for iOS.
         */
        const isIos = _.get(window, 'device.platform') === 'iOS'

        if (!isIos) {
            return
        }
        /**
         * A SemVer object representation of the version.
         * Must be coerced, as performing an operation on a non semver version can cause errors.
         */
        const version = coerce(_.get(window, 'device.version'))
        // If we're unable to get the device's version then we want to quit out.
        // It could be web or not garaunteed to be above iOS 14.
        if (!version) {
            return
        }
        const idfaPlugin = getIdfaPlugin()
        /**
         * App Tracking Transparency was [added to iOS14](https://developer.apple.com/documentation/apptrackingtransparency).
         *
         * __NOTE__: The cordova plugin will reject a promise if it's an earlier version.
         */
        const hasTrackingTransparency = gte(version, '14.0.0')

        if (!idfaPlugin || !hasTrackingTransparency) {
            return
        }
        // iOS 15 handles system access prompts (notifications, bluetooth)
        // In a queue, which will ignore prompts if they are generated too early.
        // As a temporary fix, the app will continously retry.
        // This has been an [issue during app submission process](https://stackoverflow.com/questions/69418845/app-tracking-transparency-dialog-does-not-appear-on-ios)
        for (const attempt of _.range(MAX_ATTEMPTS)) {
            const isTrackingStatusUndetermined = await getIsTrackingStatusUndetermined()
            console.log(`AppTrackingTransparency - Is tracking status undetermined: ${isTrackingStatusUndetermined} on attempt ${attempt} of ${MAX_ATTEMPTS}`)
            //  Break out of loop if the isTrackingStatus enters a determined state (via input from the user)
            if (!isTrackingStatusUndetermined) {
                break
            }
            // This does return tracking IDs, but we don't use them.
            await idfaPlugin.requestPermission()
            // In the future we will want to honor the user's request.
            await timeout()
        }
    // Allow the plugin to fail silently
    } catch (error) {
        console.error(error)
    }
}
