import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import media from 'src/styling/media'

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.src})`};
  background-color: #eaeaea;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
const Text = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  font-size: 65px;
  letter-spacing: -1.18px;
  line-height: 1.5;
  font-weight: 700;
  color: ${colors.white};
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.25);


  ${media.greaterThan('md')`
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 25px;
    font-size: 80px;
  `}
  ${media.greaterThan('lg')`
    top: 35px;
    left: 35px;
    right: 35px;
    bottom: 35px;
    font-size: 100px;
  `}
`

const Wrapper = styled.div`
  padding-top: 100%;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`

const ImageWithText = ({ title, src }) => (
  <Wrapper>
    <Inner>
      <Image src={src} />
      <Text>{title}</Text>
    </Inner>
  </Wrapper>
)

export default ImageWithText
