import _get from 'lodash/get'
import _once from 'lodash/once'

import key from './key'

const deviceSelector = _once(() => (state) => _get(state, key))

const getRegistrationId = (state) => _get(state, [key, 'registrationId'])
const getPointrId = (state) => _get(state, [key, 'pointrId'])

export {
    deviceSelector,
    getPointrId,
    getRegistrationId
}
