import React from 'react'
import _ from 'lodash'
import IframeMap from './IframeMap'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { isCordova } from 'src/env'

const MapContainer = styled.div`
    position: relative;
    height: 600px;
`

const MappedInMap = ({ fillMap }) => {

    React.useEffect(() => {
        const initialisationScript = document.createElement('script')

        initialisationScript.type = "text/javascript"

        initialisationScript.innerHTML = `
         window.addEventListener("touchmove", (event) => event.preventDefault());
         window.mappedin = {
                miKey: {
                    id: "5f15bb22a5fdf5001a6b9713",
                    key: "60dFrCFaQiyLWEqtfRF61BLq0mwJ822OrTYuf4RbnwUk2nlx",
                },
                searchKey: {
                    id: "Zeeph6eisioZ7zoi6oolooj9aehair5R",
                    secret: "eiQu9iejoYaij8we",
                },
                venue: "hammerson-centrale-and-whitgift",
                vertical: "mall",
                language: "en"
            }
            `

        document.body.appendChild(initialisationScript)
        const sdkScript = document.createElement('script')
        sdkScript.src = 'https://d1p5cqqchvbqmy.cloudfront.net/web2/release/mappedin-web.js'
        document.body.appendChild(sdkScript)
        return () => {
            document.body.removeChild(initialisationScript)
            document.body.removeChild(sdkScript)
        }
    }, [])

    const iframeElement = React.useRef(null)

    if (isCordova) {
        return <
            IframeMap
            ref={iframeElement}
            fillMap={fillMap}
        />
    }

    return (
        <React.Fragment>
            <Helmet>
                <link href="https://d1p5cqqchvbqmy.cloudfront.net/web2/release/mappedin-web.css" rel="stylesheet"></link>
            </Helmet>
            <MapContainer
                className="map"
                id="mappedin-map"
                {...{
                    'data-key': "externalId",
                    'data-venue': "hammerson-centrale-and-whitgift"
                }}

            >
            </MapContainer>
        </React.Fragment>
    )
}

export default MappedInMap