import React, { useMemo } from 'react'

import _ from 'lodash'
import qs from 'query-string'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _uniq from 'lodash/uniq'
import _toUpper from 'lodash/toUpper'

import Page, { getPageData } from 'src/components/Page'
import { InfiniteTiles, ResourceTile } from 'src/components/Tile'
import NotFound from 'src/pages/NotFound'
import { useRequest } from 'src/store/resources/hooks'
import Spacing from 'src/components/Spacing'
import _filter from 'lodash/filter'
import _lowerCase from 'lodash/lowerCase'
import _slice from 'lodash/slice'
import _isNaN from 'lodash/isNaN'
import _invert from 'lodash/invert'
import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import _forEach from 'lodash/forEach'
import _castArray from 'lodash/castArray'
import _size from 'lodash/size'
import _toLower from 'lodash/toLower'
import _values from 'lodash/values'
import _compact from 'lodash/compact'
import _isEmpty from 'lodash/isEmpty'
import { usePromotionsTypes } from 'src/pages/Root/Header/hooks'
import styled from 'styled-components'

import { isCordova } from 'src/env'
import Filters from 'src/components/Filters'
import { useInternationalisation } from 'src/context'
import { getResourceId, convertCategoryTypeToUrl } from 'src/utility'
import vars from 'src/styling/vars'
import _flow from 'lodash/flow'

import _mapValues from 'lodash/mapValues'
import AtoZFilter from 'src/components/AtoZFilter'
import { Redirect } from 'src/components/RouterDom'

import { useSelector } from 'react-redux'

import { useAllNames, useUnitsToDisplay, useFilterSchema } from './hooks'
import { parseQueries, getQueryCategory } from './utils'
import {
  getResourceConfig,
  getRequestKey,
  fetchTiles,
  fetchCategories
} from './config'

import { attributesSelector } from 'src/store/account/selectors'
import Button from 'src/components/Button'

const LOAD_LISTINGS_SIZE = 12

const LinkButton = styled(Button)`
  width: 100%;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`

const Listing = (props) => {
  const { location, history, match } = props
  const { parentCategory, category } = match.params || {}
  const promotionTypes = usePromotionsTypes()
  const { untranslateUrl } = useInternationalisation()
  const {
    categoryModels,
    resourceType,
    aToZ,
    cordovaOnly,
    enableSearch,
    enableShopOnlineButton,
    references,
    userCollection,
    noResultText,
    categoryType,
    order
  } = getResourceConfig({
    match,
    location,
    promotionTypes,
    untranslateUrl
  })
  const queries = parseQueries({ location })
  const { letter, search, tab, ...filters } = queries
  const { translate } = useInternationalisation()
  const attributes = useSelector(attributesSelector())

  const requestKey = references
    ? resourceType
    : getRequestKey({ location, match, untranslateUrl })
  const unitRequest = useRequest({
    resourceType,
    requestKey
  })
  let unitResources = []
  let { _status: unitsStatus, total: unitsTotal } = unitRequest

  unitResources = _get(unitRequest, references || 'resources', [])
  const categoryResources = {}
  if (unitResources) {
    _forEach(categoryModels, (categoryModel, categoryModelName) => {
      categoryResources[categoryModelName] = _uniq(
        unitResources.reduce((acc, resource) => {
          /**
           * If the resource type uses itself as a filter.
           * For example, promotions can be filtered by subtypes - offers, competitions etc.
           */
          const selfFilter = _get(categoryModels, resourceType)
          if (selfFilter) {
            const reversedGroupByMapping = _invert(selfFilter.groupByMapping)
            const categoryType = _get(resource, selfFilter.groupBy)
            if (
              reversedGroupByMapping[untranslateUrl(parentCategory)] &&
              untranslateUrl(convertCategoryTypeToUrl(categoryType)) !==
              untranslateUrl(parentCategory)
            ) {
              return acc
            }
          }

          const categories = _get(
            resource,
            categoryModel.field || 'fields.categories',
            []
          )

          acc.push(..._castArray(categories))
          return acc
        }, [])
      )
      categoryResources[categoryModelName] = _map(
        categoryResources[categoryModelName],
        (category) => {
          return { id: _get(category, 'sys.id', null), ...category }
        }
      )
    })
  }

  const filterSchema = useFilterSchema({
    categoryModels,
    location,
    match,
    enableSearch,
    enableShopOnlineButton,
    categoryResources,
    untranslateUrl,
    resources: unitResources
  })
  
  // Handle old URLs (HRP-1004): if the URL is using category id as a query parameter.
  // e.g:  /shop?category=3cEognkMiQZgOyOlJDYxq8
  const redirectCategoryId = getQueryCategory({ location })
  if (!category && redirectCategoryId) {
    const categoryUrlToRedirect = _
      .chain(filterSchema)
      .keyBy('key')
      .get('category.props.options')
      .find({ id: redirectCategoryId })
      .get('value')
      .value()
    
    if (parentCategory && categoryUrlToRedirect) {
      history.replace(`${parentCategory}/${categoryUrlToRedirect}`)
    }
  }

  let unitsToDisplay = useUnitsToDisplay({
    resources: unitResources,
    categoryModels,
    parentCategory: untranslateUrl(parentCategory),
    letter,
    search,
    categoryFilter: category,
    filters,
    userCollection,
    attributes,
    categoryType,
    ordered: !!order
  })

  const hasMore = unitsTotal && unitsTotal > _size(unitResources)
  const allNames = useAllNames({
    request: unitRequest,
    clear: !!(hasMore || search || _size(_compact(_values(filters)))),
    letter
  })
  const [displayIndex, setDisplayIndex] = React.useState(LOAD_LISTINGS_SIZE)
  const loadMore = React.useCallback(() => {
    setDisplayIndex(displayIndex + LOAD_LISTINGS_SIZE)
  })

  const buttonText = userCollection && `${categoryType || resourceType}s`
  if (cordovaOnly && !isCordova) return <NotFound />
  const PageContainer = userCollection ? React.Fragment : Page
  return (
    <PageContainer>
      {!!_size(filterSchema) && (
        <>
          <Filters schema={filterSchema} key={letter} />
          <Spacing height={vars.pageGutter.sm} />
        </>
      )}
      {aToZ && <AtoZFilter allValues={allNames} />}
      {!userCollection && <Spacing height={vars.pageGutter.sm} />}
      {(unitsStatus.pending || unitsStatus.succeeded) && (
        <InfiniteTiles
          noResultText={noResultText}
          total={unitsToDisplay.length}
          loadMore={loadMore}
          hasMore={displayIndex < unitsToDisplay.length}
          status={unitsStatus}
        >
          {_map(_slice(unitsToDisplay, 0, displayIndex), (resource) => {
            return (
              <ResourceTile key={getResourceId(resource)} resource={resource} />
            )
          })}
        </InfiniteTiles>
      )}
      {buttonText && unitsStatus.succeeded && unitsToDisplay.length === 0 && (
        <LinkButton buttonType='primary' to={_toLower(`/${buttonText}`)}>
          {translate(_toUpper(buttonText))}
        </LinkButton>
      )}
    </PageContainer>
  )
}

Listing.getData = async (props) => {
  const { host, dispatch, location, match, hasLoaded, locale } = props
  const params = {
    host,
    dispatch,
    location,
    match,
    locale
  }
  const { search = '' } = parseQueries({ location })
  // We do not want to fetch new data when a search is happening.
  // This will cause it to re-render, and just do work for no reason.
  if (hasLoaded && !_isEmpty(search)) {
    return Promise.resolve()
  }

  return Promise.all([
    fetchTiles(params),
    ...fetchCategories(params),
    getPageData(props)
  ])
}
Listing.identifier = 'LISTING'

export default Listing
