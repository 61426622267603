import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Dialog, DialogOverlay, DialogContent } from '@reach/dialog'
import vars from 'src/styling/vars'
import CarouselContainer from 'src/components/Carousel/CarouselContainer'
import Slider from 'react-slick'
import _map from 'lodash/map'
import { isCordova } from 'src/env'
import Button from 'src/components/Button'
import { useInternationalisation } from 'src/context/internationalisation'
import { getCentreConfig } from 'src/config'

import WelcomeImage from 'src/assets/onboarding/welcome/Universal.png'
import EventsImageEn from 'src/assets/onboarding/events/En.png'
import EventsImageFr from 'src/assets/onboarding/events/Fr.png'
import MapsImage from 'src/assets/onboarding/maps/Universal.png'
import OffersImage from 'src/assets/onboarding/offers/Universal.png'

const defaultSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <div />,
  nextArrow: <div />
}

const OnboardingContent = styled(DialogContent)`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
  z-index: ${vars.zIndex.navigationOverlay};
  display: flex;
  flex: 1;
`

const Slide = styled.div`
  background-color: ${(props) => props.backgroundColor};
  background: radial-gradient(
    ${(props) => props.radialGradient[0]},
    ${(props) => props.radialGradient[1]}
  );
  display: flex !important;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: env(safe-area-inset-top, 20px) 0 env(safe-area-inset-bottom, 20px) 0;
`

const SlideImage = styled.div`
  display: block;
  padding: 10px;
  max-width: 100%;
  max-height: 55%;
`

const SlideText = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  padding: 20px;
`

const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
`

const Description = styled.h2`
  font-size: 16px;
`

const DotsSpacer = styled.div`
  height: 20px !important;
`

const Dots = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 64px;
  min-width: 64px;
  justify-content: space-between;
  align-items: center;
`

const Dot = styled.div`
  height: 10px;
  width: 10px;
  background-color: rgba(
    255,
    255,
    255,
    ${(props) => (props.active ? '1' : '0.25')}
  );
  border-radius: 100%;
`

const DotsContainer = styled.h2`
  height: 60px;
  width: calc(100% - 40px);
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ProgressionContainer = styled.h2`
  height: 64px;
  width: 100%;
  padding: 20px;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const OnboardingButton = styled.button`
  padding: 0;
  margin: 0;
  color: #ffffff;
  font-size: 18px !important;
  height: 100%;
  text-align: ${(props) => props.float || 'left'};
  font-weight: bold;
  z-index: 10;
  line-height: 18px;
`

const OnboardingImage = styled.img`
  max-height: 100%;
  height: auto;
`

const OnboardingImageWrapper = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
`

const GetStarted = styled.button`
  font-size: 16px;
  font-weight: bold;
  Text-transform: uppercase;
  margin: 10px 0;
  z-index: 10;
  padding: 10px 20px;
  border: 2px solid white;
`

const useOnboardingConfig = () => {
  const { translate, locale } = useInternationalisation()
  // if styleseeker returns, will need to destructure `hasStyleSeeker` from getCentreConfig.
  const { currency } = getCentreConfig() || {}

  const onboardingConfig = React.useMemo(() => {
    return [
      {
        title: 'Welcome',
        description: translate('ONBOARDING_DESCRIPTION_WELCOME'),
        backgroundColor: '#8a9ea7',
        radialGradient: ['#8a9ea7', '#8a9ea7'],
        image: WelcomeImage,
        leftButton: {
          text: ''
        },
        rightButton: {
          text: translate('ONBOARDING_NEXT')
        }
      },
      {
        title: 'Events',
        description: translate('ONBOARDING_DESCRIPTION_EVENTS'),
        backgroundColor: '#70876d',
        radialGradient: ['#839379', '#70876d'],
        image: locale === 'fr' ? EventsImageFr : EventsImageEn,
        leftButton: {
          text: translate('ONBOARDING_PREV')
        },
        rightButton: {
          text: translate('ONBOARDING_NEXT')
        }
      },
      {
        title: 'Centre Info/Map',
        description: translate('ONBOARDING_DESCRIPTION_MAPS'),
        backgroundColor: '#8a9ea7',
        radialGradient: ['#bdcbd4', '#647a85'],
        image: MapsImage,
        leftButton: {
          text: translate('ONBOARDING_PREV')
        },
        rightButton: {
          text: translate('ONBOARDING_NEXT')
        }
      },
      {
        title: 'Offers',
        description: translate('ONBOARDING_DESCRIPTION_OFFERS'),
        backgroundColor: '#e0cdc9',
        radialGradient: ['#e6d1ce', '#e0cdc9'],
        image: OffersImage,
        leftButton: {
          text: translate('ONBOARDING_PREV')
        },
        rightButton: {
          text: translate('ONBOARDING_CLOSE')
        },
        callToAction: true
      }
    ]
  }, [translate, locale, currency])

  return onboardingConfig
}

let slider = null

const Onboarding = (props) => {
  const { onClose } = props
  const { translate } = useInternationalisation()
  const [activeSlide, setActiveSlide] = React.useState(0)
  const onboardingConfig = useOnboardingConfig()
  const activeSlideConfig = onboardingConfig[activeSlide]

  const renderSlide = (screenConfig, index) => (
    <Slide
      backgroundColor={screenConfig.backgroundColor}
      radialGradient={screenConfig.radialGradient}
    >
      <SlideImage>
        <OnboardingImageWrapper>
          <OnboardingImage
            src={screenConfig.image}
            alt='Onboarding Screen Image'
          />
        </OnboardingImageWrapper>
      </SlideImage>
      <SlideText>
        {/* <Title>{screenConfig.title}</Title> */}
        <Title>{screenConfig.description}</Title>
        {activeSlide > 0 && screenConfig.callToAction ? (
          <GetStarted
            onClick={() => {
              onClose('/login?tab=sign-up')
            }}
          >
            {translate('SIGN_UP')}
          </GetStarted>
        ) : null}
      </SlideText>
      <DotsSpacer />
    </Slide>
  )

  return (
    <DialogOverlay
      style={{
        zIndex: vars.zIndex.navigationOverlay,
        overflow: 'hidden'
      }}
    >
      <OnboardingContent isOpen={true}>
        <CarouselContainer fullScreen={true} fixedHeight={true}>
          <Slider
            {...defaultSettings}
            afterChange={setActiveSlide}
            ref={(c) => (slider = c)}
          >
            {_map(onboardingConfig, renderSlide)}
          </Slider>
        </CarouselContainer>
        <ProgressionContainer>
          <OnboardingButton
            onClick={() => {
              slider.slickPrev()
            }}
          >
            {activeSlideConfig.leftButton.text}
          </OnboardingButton>
          <DotsContainer>
            <Dots>
              {_map(onboardingConfig, (config, index) => {
                return <Dot active={activeSlide === index} />
              })}
            </Dots>
          </DotsContainer>
          <OnboardingButton
            onClick={() => {
              activeSlide === onboardingConfig.length - 1
                ? onClose()
                : slider.slickNext()
            }}
            float={'right'}
          >
            {activeSlideConfig.rightButton.text}
          </OnboardingButton>
        </ProgressionContainer>
      </OnboardingContent>
    </DialogOverlay>
  )
}

export default Onboarding
