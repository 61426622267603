import React from 'react'
import styled from 'styled-components'
import { useInternationalisedAppStoreAssets } from 'src/store/ui/hooks'
import { useInternationalisation } from 'src/context'

import _get from 'lodash/get'
import _map from 'lodash/map'
import { getFirstResourceFields } from 'src/utility'
import { useRequest } from 'src/store/resources/hooks'
import H3 from 'src/components/H3'

const AppStoreButtonsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: center;
  flex-wrap: wrap;
  > li {
    display: block;
    padding: 10px;
  }
  > li:last-child {
    padding-bottom: 0;
  }
`

const Img = styled.img`
  width: 200px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f4f3f0;
  width: 100%;
  min-height: 200px;
  max-height: 500px;
  padding: 3vh;
  justify-content: space-between;
  p {
    padding: 5px 0px;
  }
`

const PlusPromoTile = () => {
  const { translate } = useInternationalisation()
  const request = useRequest({
    resourceType: 'centre',
    requestKey: 'centre'
  })
  const { appleStoreUrl, googlePlayUrl } = getFirstResourceFields(request)
  const { AppStorePNG = '', GooglePlayPNG = '' } = useInternationalisedAppStoreAssets()

  return (
    <Container>
      <H3>{translate('PLUS_PROMO_TITLE')}</H3>
      <p>{translate('PLUS_PROMO_TEXT')}</p>
      {
        <AppStoreButtonsWrapper>
          {(appleStoreUrl || googlePlayUrl) &&
            _map(
              [
                {
                  icon: AppStorePNG,
                  link: appleStoreUrl,
                  text: 'APPLE_STORE'
                },
                {
                  icon: GooglePlayPNG,
                  link: googlePlayUrl,
                  text: 'GOOGLE_STORE'
                }
              ],
              (store) => {
                if (!store.link) return null
                return (
                  <li key={store.link}>
                    <a
                      href={store.link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Img src={store.icon} alt={translate(store.text)} />
                    </a>
                  </li>
                )
              }
            )}
        </AppStoreButtonsWrapper>
      }
    </Container>
  )
}

export default PlusPromoTile
