import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'src/components/RouterDom'
import styled from 'styled-components'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _capitalize from 'lodash/capitalize'
import { getIdFromParams, getFields, getAssetSrc } from 'src/utility'
import { isCordova } from 'src/env'
import Head from 'src/components/Head'

import apiService from 'src/services/apiService'

import SavedHeart from 'src/components/SavedHeart'
import Button from 'src/components/Button'
import { useInternationalisation } from 'src/context'
import Container from 'src/components/Container'
import Box from 'src/components/Box'
import PageBanner from 'src/components/PageBanner'
import SafeImage from 'src/components/SafeImage'
import WebViewLink from 'src/components/WebViewLink'
import { openModal } from 'src/store/ui/actionCreators'
import FullImageModal from 'src/pages/StyleSeeker/Components/FullImageModal.js'

import {
  savedSelector,
  savedProductSelector,
  attributesSelector
} from 'src/store/account/selectors'
import {
  fetchSavedProducts,
  createSaved,
  removeSaved
} from 'src/store/account/actionCreators'
import {
  setCurrentPath,
  setIsLoading,
  setSimilar,
  setCategory,
  setFile,
  setCrop,
  setWeights,
  setFileUrl
} from 'src/store/styleSeeker/actionCreators'
import media from 'src/styling/media'
import { categorySelector } from 'src/store/styleSeeker/selectors'

import colors from 'src/styling/colors'

const LinkButton = styled(Button)`
  width: 100%;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin: 0 0 20px 0;
`

const Wrapper = styled.div`
  margin: 20px;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  text-decoration: none;
  background-color: ${colors.white};
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
`

const ImageWrapper = styled.div`
  width: 100%;
  padding: 15px;
`

const Details = styled.div`
  width: 100%;
  font-size: 16px;
  padding: 15px;
  text-align: center;
  h2 {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 20px;
  }

  > div {
    text-align: center;
    padding-bottom: 10px;
  }

  > p {
    text-align: center;
    padding-bottom: 15px;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin: 0;
  height: 70px;
  margin-bottom: 20px;
`

const StockText = styled.p`
  font-size: 11px;
  padding-bottom: 15px;
`

const ImageLink = styled(WebViewLink)`
  display: block;
  font-size: 11px;
  word-break: break-word;
  padding-bottom: 5px;
  margin-bottom: 10px;
`

const marginTop = 167
const tileSize = 265

const Content = styled.div`
  ${media.greaterThan('lg')`
    width: 500px;
    min-height: ${marginTop + tileSize}px;
  `}
`
const SavedProduct = (props) => {
  const { dispatch, history } = props
  const { translate, translateUrl } = useInternationalisation()
  const id = getIdFromParams(props)
  const product = useSelector(savedProductSelector(id))
  const category = useSelector(categorySelector(id))
  const productResource = _get(product, 'productResource')
  const { title, listImage, brand, price, summary, retailer, description } =
    getFields(productResource) || {}

  const imageUrl = getAssetSrc(listImage)

  const saved = useSelector(savedSelector())
  const savedId = _get(
    _find(
      saved,
      (item) => item.content_id === id && item.content_type === 'product'
    ),
    'id',
    null
  )

  const userAttributes = useSelector(attributesSelector())
  const onProductSave = useCallback(
    ({ savedId, content_id, content_type, content_category }) => {
      if (!userAttributes) history.push(translateUrl('/saved'))
      if (!savedId) {
        dispatch(
          createSaved({
            content_id,
            content_type,
            content_category
          })
        )
      } else {
        dispatch(
          removeSaved({
            id: savedId
          })
        )
      }
    },
    []
  )

  const handleOnImageClick = useCallback(() => {
    dispatch(openModal({ id }))
  }, [id])

  return (
    <React.Fragment>
      <Head title={translate('PAGE_SAVED')} />
      <PageBanner text={title} hide={isCordova} />
      <Container topGutter bottomGutter>
        <Box>
          <Content>
            <SavedHeart
              size={40}
              saved={savedId}
              handleOnHeartClick={() =>
                onProductSave({
                  savedId,
                  content_id: id,
                  content_type: 'product',
                  content_category: category
                })
              }
            />
            <ImageWrapper onClick={handleOnImageClick}>
              <SafeImage src={imageUrl} height={200} width={''} alt='Product' />
            </ImageWrapper>
            <FullImageModal imageSrc={imageUrl} id={id} />
            <Details>
              <h2>{title}</h2>
              {brand && <div>Brand: {brand}</div>}
              {retailer && <div>Retailer: {retailer}</div>}
              {price && <div>{price}</div>}
              {description && <p>{description}</p>}
              <StyledButton
                buttonType={'primary'}
                onClick={() => {
                  dispatch(setIsLoading(true))
                  history.push(translateUrl(`/style-seeker/similar-products`))
                  dispatch(setCrop(null))
                  dispatch(setWeights(null))
                  dispatch(setCategory(null))
                  dispatch(setFileUrl(imageUrl))
                  return apiService
                    .styleSeekerSimilar({
                      fileUrl: imageUrl,
                      categoryAlias: null,
                      crop: null,
                      weights: null,
                      content_id: id
                    })
                    .then(({ similarProducts, categoryAlias }) => {
                      dispatch(setSimilar(similarProducts))
                      dispatch(setCategory(categoryAlias))
                      dispatch(setIsLoading(false))
                    })
                    .catch((err) => {
                      console.log(err)
                      dispatch(setIsLoading(false))
                    })
                }}
              >
                {translate('STYLE_SEEKER_FIND_SIMILAR')}
              </StyledButton>
              <LinkButton buttonType='primary' to={`/shop`}>
                {`View Stores`}
              </LinkButton>
              <WebViewLink url={imageUrl} fontSize={11}>
                {imageUrl}
              </WebViewLink>
              <StockText>{translate('STYLE_SEEKER_STOCK_TEXT')}</StockText>
            </Details>
          </Content>
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default SavedProduct
