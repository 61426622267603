import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import styled from 'styled-components'
import { Link } from 'src/components/RouterDom'
import colors from 'src/styling/colors'
import tileVars from './vars'

export const Tile = styled.article`
  display: flex;
  width: ${(p) => p.isWhatsOn ? tileVars.homeWhatsOntileWidth : tileVars.tileWidth}px;
 
  &:after {
    content: '';
    display: block;
    padding-top: 100%;
  }
`
export const Content = styled(Link)`
  width: 100%;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  text-decoration: none;
  background-color: ${colors.white};
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
`

const BaseTile = ({ id, to, children, noPadding, isWhatsOn }) => {
  return (
    <Tile key={id} isWhatsOn={isWhatsOn}>
      <Content to={to} data-no-padding={noPadding}>
        {children}
      </Content>
    </Tile>
  )
}

export default BaseTile
