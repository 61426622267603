import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'src/components/RouterDom'
import _get from 'lodash/get'
import _map from 'lodash/map'

import colors from 'src/styling/colors'
import RightArrowSvg from 'src/assets/Arrow-Right.svg'
import DownArrowSvg from 'src/assets/DropdownArrow.svg'
import AssetIcon from 'src/components/AssetIcon'

const Wrapper = styled(Link)`
  overflow: hidden;
  display: block;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 19px;
  padding: 0 17px 0 23px;
  &:focus {
    outline: none;
    font-weight: 700;
  }
`

const MenuLink = (props) => {
  const { children, hideChevron, ...rest } = props
  return (
    <Wrapper {...rest}>
      <span>{children}</span>
      {!hideChevron && (
        <AssetIcon
          svg={DownArrowSvg}
          size={24}
          style={{ transform: 'rotate(270deg)' }}
        />
      )}
    </Wrapper>
  )
}

export default MenuLink
