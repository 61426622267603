import React from 'react'
import _isBoolean from 'lodash/isBoolean'
import _camelCase from 'lodash/camelCase'
import _map from 'lodash/map'
import _uniqBy from 'lodash/uniqBy'
import _filter from 'lodash/filter'

import { createSelector } from 'reselect'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _forEach from 'lodash/forEach'
import _compact from 'lodash/compact'
import upperFirst from 'lodash/upperFirst'
import _toUpper from 'lodash/toUpper'
import { navigations as navConfig } from 'src/config'
import { nav } from '@redant/hammerson-plus-config'
import enNavConfig from 'src/config/configFiles/navigations/en'
import frNavConfig from 'src/config/configFiles/navigations/fr'

import { useInternationalisation } from 'src/context'
import { requestSelector, queriedLinkLookUpSelector } from 'src/store/resources/selectors'
import { convertCategoryTypeToUrl } from 'src/utility'

import {
  getFirstResourceFields,
  getUrl,
  getResourceType,
  getFields
} from 'src/utility'
import _groupBy from 'lodash/groupBy'
import { isCordova } from 'src/env'

import { useSelector } from 'react-redux'

const promotionTypesSelector = createSelector(
  ({ locale }) => locale,
  ({ translate }) => translate,
  (locale, translate) =>
    createSelector(
      requestSelector({ resourceType: 'promotion', requestKey: 'promotion' }),
      (promotions) => {
        if (promotions._status.loading) return []
        const uniqueLinks = new Set()
        const promotionItems = []

        // for (const promotion of promotions.resources) {
        for (let index = 0; index < promotions.resources.length; index++) {
          const promotion = promotions.resources[index]
          const item = convertCategoryTypeToUrl(promotion.fields.categoryType)
          if (item && !uniqueLinks.has(item)) {
            uniqueLinks.add(item)
            promotionItems.push({
              title: upperFirst(translate(_toUpper(item))),
              link: `/${item}`
            })
          }
        }
        if (promotionItems.length > 1) {
          promotionItems.unshift({
            link: '/promotions',
            title: translate('ALL_PROMOTIONS')
          })
        }

        return promotionItems
      }
    )
)

const navCategoriesSelector = createSelector(
  ({ locale }) => locale,
  ({ translate }) => translate,
  (locale, translate) =>
    createSelector(
      [
        requestSelector({
          resourceType: 'retailUnitCategory',
          requestKey: 'retailUnitCategory'
        }),
        requestSelector({
          resourceType: 'layout',
          requestKey: 'layout'
        }),
        promotionTypesSelector({ locale, translate }),
        queriedLinkLookUpSelector
      ],
      (retailCategoryResources, layout, promotionTypes, queryLinks) => {
        const subcategories = _groupBy(
          retailCategoryResources.resources,
          'fields.parentCategory'
        )
        const typeListings = {
          promotions: [...promotionTypes]
        }
        if (layout._status.pending) return []
        const view = getFirstResourceFields(layout)
        const navItems = []
        const [language = 'en'] = (locale || '').split('-')
        const navConfig = { en: enNavConfig, fr: frNavConfig }[language] || nav
        for (const i in navConfig) {
          const hideOnPlatform = !isCordova && !!navConfig[i].hideOnWeb
          const hideNavItem = ![
            ...(view.displayNavItems || []),
            ..._map(_filter(navConfig, 'hideOnWeb'), 'label')
          ].includes(navConfig[i].key || navConfig[i].label)
          if (hideNavItem || hideOnPlatform) {
            navItems.push(null)
            continue
          }
          navItems.push(navConfig[i])
          if (navItems[i].typeListings) {
            navItems[i].subcategories =
              typeListings[navItems[i].dropdownCategory]
          }
          const items =
            view[`navigationList${upperFirst(navItems[i].dropdownCategory)}`] ||
            []

          if (items) {
            navItems[i].pages = []
            // for (const listItem of items) {
            for (let index = 0; index < items.length; index++) {
              const listItem = items[index]
              if (getResourceType(listItem) === 'page') {
                const showOnApp = listItem.fields.publishOnPlusApp
                const showOnBrowser = listItem.fields.publishOnWebsite
                if (isCordova ? !showOnApp : !showOnBrowser) continue
              }

              const {
                externalUrl,
                title,
                navTitle,
                name,
                navImage
              } = getFields(listItem)
              const url = _get(queryLinks, getUrl(listItem), getUrl(listItem))
              navItems[i].pages.push({
                link: externalUrl || url,
                title: title || navTitle || name,
                image: navImage,
                isExternal: !!externalUrl,
                updatedAt: listItem.sys.updatedAt
              })
            }
          }
          const categories =
            subcategories[upperFirst(navItems[i].dropdownCategory)]

          if (categories) {
            navItems[i].subcategories = []
            // for (const category of categories) {
            for (let index = 0; index < categories.length; index++) {
              const category = categories[index]
              const { image, title, showInNav } = getFields(category)
              if (!showInNav) continue
              navItems[i].subcategories.push({
                link: getUrl(category),
                image,
                title
              })
            }
            if (_size(navItems[i].subcategories) > 1 && navItems[i].plural) {
              navItems[i].subcategories.unshift({
                link: navItems[i].link,
                title: navItems[i].all
              })
            }
          }
          
          if (
            _size(navItems[i].subcategories) + _size(navItems[i].pages) ===
            1
          ) {
            const singleItem =
              (navItems[i].subcategories || [])[0] ||
              (navItems[i].pages || [])[0]
            if (singleItem) {
              navItems[i] = {
                link: singleItem.link,
                label: singleItem.title
              }
            }
          }
        }
        return _compact(navItems)
      }
    )
)

export const useNav = () => {
  let { translate = () => { }, locale, isFrenchCentre } = useInternationalisation() || {}
  if (isFrenchCentre) locale = 'fr'
  return useSelector(navCategoriesSelector({ locale, translate }))
}

export const usePromotionsTypes = () => {
  const { translate = () => { }, locale } = useInternationalisation() || {}
  return useSelector(promotionTypesSelector({ locale, translate }))
}
