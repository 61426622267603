import React, { useState } from 'react'
import Placeholder from 'src/assets/Placeholder.png'

const SafeImage = ({ fallback = Placeholder, src, alt, ...rest }) => {
  const [imageSrc, setImageSrc] = useState(src)

  return (
    <img
      src={imageSrc}
      alt={alt}
      onError={() => {
        setImageSrc(fallback)
      }}
      {...rest}
    />
  )
}

export default SafeImage
