import React from 'react'

import FieldBase from './common/FieldBase'
import InputBase from './common/InputBase'

const renderField = (props) => {
  return <InputBase {...props} component={'textarea'} />
}

const Textarea = (props) => {
  return <FieldBase {...props} renderField={renderField} />
}

export default Textarea
