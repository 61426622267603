import React, { Fragment, useEffect } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

import _get from 'lodash/get'
import _map from 'lodash/map'
import fp from 'lodash/fp'
import vars from 'src/styling/vars'
import { getCentreId } from 'src/config'
import { useInternationalisation } from 'src/context'
import { getFields, getFirstResource, getResourceId } from 'src/utility'

import { requestSelector } from 'src/store/resources/selectors'

import Spacing from 'src/components/Spacing'
import PageBanner from 'src/components/PageBanner'
import WhatsOn from 'src/components/WhatsOn'
import Head from 'src/components/Head'
import { useCentre } from 'src/store/resources/hooks'
import apiService from 'src/services/apiService'

import { DefaultBlock, DirectionsBlock, ApplicationBlock } from './Blocks'

const WebHome = (props) => {
  const { host } = props
  const { translate } = useInternationalisation()
  const centreId = getCentreId({ host })
  const getRequest = ({ resourceType, requestKey }) =>
    useSelector(
      requestSelector({
        resourceType,
        requestKey
      })
    )

  const request = getRequest({
    resourceType: 'layout',
    requestKey: 'layout'
  })
  const resource = getFirstResource(request)
  const {
    bannerTitle,
    bannerMedia,
    bannerLink,
    featuredListName,
    featuredList,
    blocksList,
    description,
    bannerMediaMobile
  } = getFields(resource)
  const centre = useCentre()
  /** @type {string}
   * The centre's name for the fallback page title for the home page
   */
  const centreName = fp.compose(
    fp.get('name'),
    getFields
  )(centre)

  const { 
    parentCategory: bannerParent,
    categoryType: bannerCategory,
    urlName: bannerUrl 
  } = getFields(bannerLink)
  const bannerContentType = _.get(bannerLink, 'sys.contentType.sys.id')
  const mapUrl = {
    event: 'events',
    offer: 'offers',
    competition: 'competitions',
    reward: 'rewards',
    eatery: 'dine',
    page: ''
  }
  const bannerLinkUrl = () => {
    switch (true) {
      case !_.isEmpty(bannerCategory):
        return `${_.get(mapUrl, [_.toLower(bannerCategory)], _.toLower(bannerCategory))}/${bannerUrl}`
      case !_.isEmpty(bannerParent):
        return `${_.get(mapUrl, [_.toLower(bannerParent)], _.toLower(bannerParent))}/${bannerUrl}`
      case !_.isEmpty(bannerContentType):
        return `${_.get(mapUrl, [_.toLower(bannerContentType)], _.toLower(bannerContentType))}/${bannerUrl}`
      case !_.isEmpty(bannerUrl):
        return `${bannerUrl}`
      default:
        return
    }
  }

  return (
    <Fragment>
      <Head
        resource={centre}
        description={description}
        title={centreName}
      />
      <PageBanner
        bannerMediaDesktop={bannerMedia}
        bannerMediaMobile={bannerMediaMobile}
        type='home-banner'
        text={bannerTitle}
        textTopMargin={-160}
        link={bannerLinkUrl()}
        isHero={true}
        centreName={centreName}
      />
      <WhatsOn
        title={featuredListName}
        events={featuredList}
      />
      <Spacing height={vars.pageGutter} />
      {_map(blocksList, (block, index) => {
        const { fields } = block || {}
        const { type, image } = fields || {}
        const blockProps = {
          ...fields,
          id: getResourceId(block),
          reverse: index % 2 !== 0,
          image,
          centreId,
          history: props.history
        }
        const blockContent = {
          Default: <DefaultBlock {...blockProps} />,
          Directions: <DirectionsBlock {...blockProps} />,
          Application: <ApplicationBlock {...blockProps} />
        }[type]

        return (
          <div key={index}>
            {blockContent}
            <Spacing height={vars.pageGutter} />
          </div>
        )
      })}
      <Spacing height={vars.pageGutter} />
    </Fragment>
  )
}

export default WebHome
