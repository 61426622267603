import { css } from 'styled-components'
import media from 'src/styling/media'
import vars from 'src/styling/vars'

export const horizontalMargin = (x) => `
  margin-left: ${x};
  margin-right: ${x};
`
export const horizontalPadding = (x) => `
  padding-left: ${x};
  padding-right: ${x};
`
export const verticalMargin = (x) => `
  margin-top: ${x};
  margin-bottom: ${x};
`
export const verticalPadding = (x) => `
  padding-top: ${x};
  padding-bottom: ${x};
`

export const plainList = `list-style-type: none;
  margin: 10px;
  padding: 0;
`

// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
// for making long urls inside a paragraph not extend outside of container
export const breakWords = `
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`
