import React from 'react'
import _ from 'lodash'
import {
  Link as RRLink,
  NavLink as RRNavLink,
  Redirect as RRRedirect
} from 'react-router-dom'
import { useInternationalisation } from 'src/context/internationalisation'

const withTranslatedUrl = (Comp) => (props) => {
  const { to: originalTo, ...rest } = props
  const { translateUrl } = useInternationalisation()
  let to = originalTo
  if (_.isString(originalTo)) {
    to = translateUrl(originalTo)
  } else if (_.isObject(originalTo)) {
    to = translateUrl(originalTo.pathname)
  }
  return <Comp {...rest} to={to} />
}

const Link = withTranslatedUrl(RRLink)
const NavLink = withTranslatedUrl(RRNavLink)
const Redirect = withTranslatedUrl(RRRedirect)

export * from 'react-router-dom'
export { Link, NavLink, Redirect }
