// THE PURPOSE OF THIS COMPONENT IS TO COMPENSATE FOR SVG FILES HAVING
// HUGE BUT CONSISTENT PADDING AROUND THEM
import React, { Fragment, useState } from 'react'
import _isString from 'lodash/isString'
import styled from 'styled-components'
const svgDefaultScale = 1.8
const svgDefaultSize = 64
const Wrapper = styled.div`
  overflow: ${(p) => p.overflow || 'visible'};
  width: ${(p) => p.width || p.size}px;
  height: ${(p) => p.size}px;
  ${({ color }) => (color ? `color: ${color};` : '')}
  svg {
    ${(p) => {
      const m = 0.5 * p.size * (p.scale - 1)
      return `
    margin-top: -${m}px;
    margin-left: -${p.marginLeft || m}px;
      `
    }}
    g {
      ${(p) => (p.strokeWidth ? `stroke-width: ${p.strokeWidth}` : '')}
    }
  }
`
const AssetIcon = (props) => {
  const { svg, size, strokeWidth, color, alt, onClick, overflow, svgScale, ...rest } = props
  const Svg = svg || 'div'
  const scale = svgScale || svgDefaultScale
  const wrapperProps = {
    size,
    scale,
    onClick,
    color,
    overflow,
    as: onClick ? 'button' : undefined,
    ...rest
  }
  return (
    <Wrapper {...wrapperProps}>
      <Svg height={scale * size} width={scale * size} aria-label={alt} />
    </Wrapper>
  )
}
export default AssetIcon
