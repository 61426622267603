import _ from 'lodash'
import { analyticEventTypes } from 'src/config'
import env, { isCordova } from 'src/env'
import { setDevice } from 'src/store/device/actionCreators'
import { createEvent } from 'src/store/analytics/actionCreators'
import pointrService from 'src/services/pointrService'
import airshipService from 'src/services/airshipService'
import { presentAppPolicyModal } from 'src/services/appServices/appTrackingPrivacyService'

const cordovaInit = ({ store }) => {
  if (!isCordova) return

  const { dispatch } = store || {}
  const getState = store.getState

  presentAppPolicyModal()
  // for InAppBrowser v4+
  if (!env.fakeCordova) {
    window.open = window.cordova.InAppBrowser.open
  }
  // set stuff like model and platform and uuid
  // https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-device/
  const deviceDetails = window.device
  dispatch(setDevice(deviceDetails))
  airshipService.syncPushNotificationSettingsWithOs({ dispatch })
  dispatch(createEvent({ type: analyticEventTypes.APP_OPEN }))

  // initialise pointr
  if (window.PointrPlugin) {
    pointrService.startPointr({ dispatch })
    pointrService.registerToGeofenceManagerEnterCallback({ getState })
  }

  const appKey = process.env.REACT_APP_AIRSHIP_APP_KEY
  const appSecret = process.env.REACT_APP_AIRSHIP_APP_SECRET
  // initialise airship
  if (window.UAirship && appKey) {
    airshipService.startAirship({
      appKey,
      appSecret,
      dispatch
    })
  }
}

export default cordovaInit
