import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import env, { isBrowser, isCordova } from 'src/env'
import popupService from 'src/services/popupService'

const WVL = styled.button`
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  font-size: ${({ fontSize }) => `${fontSize}px` || '1em'};
  word-break: break-word;
`

const WebViewLink = ({ children, url, fontSize }) => {
  if (isCordova && !env.fakeCordova) {
    return (
      <WVL onClick={() => popupService.open(url)} fontSize={fontSize}>
        {children}
      </WVL>
    )
  } else {
    return (
      <a href={url} target='_self' rel='noopener noreferrer'>
        {children}
      </a>
    )
  }
}

export default WebViewLink
