import React from 'react'
import { isBrowser, isCordova } from 'src/env'
import cookieActions, { oneYear, getInitialCookiesState, cookies, cookieIds } from './service'
import transformCookieState from './stateTransformer'
import onStateChange from './onStateChange'

const useCookies = () => {
  const [state, dispatch] = React.useReducer(transformCookieState, getInitialCookiesState())
  React.useEffect(() => {
    if (isBrowser && !isCordova && state.userSetPreference === true) {
      cookies.set(cookieIds.__RA_COOKIES_HAS_SET_PREF__, true, { path: '/', maxAge: oneYear })
      cookies.set(cookieIds.__RA_PERSONALISED_ADS_COOKIES_OPT_IN__, state.personalisedAdCookiesOptedIn, { path: '/', maxAge: oneYear })
      cookies.set(cookieIds.__RA_AUDIENCE_MEASUREMENT_COOKIES_OPT_IN__, state.audienceMeasurementCookiesOptedIn, { path: '/', maxAge: oneYear })
      onStateChange(state)
    }
  }, [state.savedAt])

  const actions = {
    acceptAllCookies: () => {
      dispatch({ type: cookieActions.ALL_COOKIES_ACCEPT })
      dispatch({ type: cookieActions.SAVE_PREFERENCES })
    },
    rejectAllCookies: () => {
      dispatch({ type: cookieActions.ALL_COOKIES_REJECT })
      dispatch({ type: cookieActions.SAVE_PREFERENCES })
    },
    toggleAudienceMeasurement: () => {
      dispatch({ type: cookieActions.AUDIENCE_MEASUREMENT_TOGGLED })
    },
    togglePersonalisedCookies: () => {
      dispatch({ type: cookieActions.PERSONALISED_COOKIES_TOGGLED })
    },
    savePreferences: () => {
      dispatch({ type: cookieActions.SAVE_PREFERENCES })
    },
    undoChanges: () => {
      dispatch({ type: cookieActions.UNDO_CHANGES })
    }
  }
  return [state, actions]
}

export default useCookies