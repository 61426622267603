import { requestStatuses as statuses } from 'redux-resource'
import _ from 'lodash'

import cognitoService from 'src/services/cognitoService'
import facebookAuthService from 'src/services/authServices/facebookAuthService'
import apiService from 'src/services/apiService'
import actionTypes from './actionTypes'
import { delay } from 'src/utility'
import { attributesSelector } from './selectors'
import { deviceSelector } from '../device/selectors'
import { isCordova } from 'src/env'

const login = ({ email, password, provider }) => {
  const type = actionTypes.LOGIN
  const actionProps = { type, email, provider }
  return async dispatch => {
    dispatch({ ...actionProps, status: statuses.PENDING })
    try {
      let session = {}
      switch (true) {
        case !provider:
          session = await cognitoService.login({ email, password })
          break;
        case provider.plugIn !== null && provider.plugIn.canBeInitialised():
          session = await provider.plugIn.authenticate()
          break
        default:
          session = await apiService.socialLogin({ provider: provider.providerId })
      }

      const { accessToken } = session || {}
      const attributes = await apiService.fetchProfile({ token: accessToken })
      dispatch({
        ...actionProps,
        attributes,
        status: statuses.SUCCEEDED
      })
    } catch (error) {
      dispatch({ ...actionProps, error, status: statuses.FAILED })
      throw error
    }
  }
}

const signUp = ({ email, password, ...otherAttributes }) => {
  const type = actionTypes.SIGN_UP
  return (dispatch, getState) => {
    dispatch({ type, status: statuses.PENDING })
    return cognitoService.signUp({ email, password, ...otherAttributes })
  }
}

const restore = () => {
  return (dispatch, getState) => {
    const type = actionTypes.RESTORE
    dispatch({ type, status: statuses.PENDING })

    const existingAttributes = attributesSelector()(getState()) || {}

    // case 1: cognito session in local storage
    // - cognitoService.getToken will return token
    // - apiService.fetchProfile will succeed using token

    // case 2: social provider session in cookie
    // - cognitoService.getToken will NOT return token
    // - apiService.fetchProfile will succeed because cookie

    // case 3: social provider session in local storage (eg: after restarting cordova ios app)
    // - cognitoService.getToken will NOT return token
    // - apiService.fetchProfile will fail
    // - apiService.socialLoginWithToken will succeed and re-establish cookie
    // - apiService.fetchProfile will succeed

    // case 4: no session in cookie or local storage
    // - cognitoService.getToken will NOT return token
    // - apiService.fetchProfile will fail
    // - accessToken won’t exist in redux so if condition isn’t met
    // - error is thrown

    return cognitoService
      .getToken()
      .then((token) => {
        if (!token) {
          throw new Error('No Token')
        }
        return apiService.fetchProfile({ token })
      })
      .then((attributes) => {
        dispatch({ type, attributes, status: statuses.SUCCEEDED })
      })
      .catch((error) => {
        const { session } = existingAttributes || {}
        const { accessToken, provider } = session || {}
        const isFacebook = provider === 'facebook'
        const isTwitter = provider === 'twitter'
        const isApple = provider === 'apple'
        if (accessToken && (isFacebook || isTwitter || isApple)) {
          return apiService
            .socialLoginWithToken(session) // re-establish cookies
            .then(() => {
              return apiService.fetchProfile({ token: accessToken })
            })
            .then((attributes) => {
              dispatch({ type, attributes, status: statuses.SUCCEEDED })
            })
        } else {
          throw error
        }
      })
      .catch((error) => {
        dispatch({ type, error, attributes: null, status: statuses.FAILED })
        throw error
      })
  }
}

const logout = () => {
  return async (dispatch, getState) => {
    const type = actionTypes.LOGOUT
    dispatch({ type, status: statuses.PENDING })
    if (isCordova) {
      const token = await cognitoService.getToken()
      const deviceId = _.get(deviceSelector()(getState()), 'uuid')
      if (deviceId) {
        await apiService
          .deleteUserDevices({
            token,
            payload: { device_id: deviceId }
          })
          .catch((err) => { })
      }
    }
    if (facebookAuthService.canBeInitialised()) {
      await facebookAuthService.attemptLogOut()
    }
    return Promise.all([
      cognitoService.logout(),
      apiService.socialLogout().catch((err) => {
        // ignore errors about no session existing
      })

    ])
      .then(() => {
        dispatch({ type, attributes: null, status: statuses.SUCCEEDED })
      })
      .catch((error) => {
        dispatch({ type, error, status: statuses.FAILED })
        throw error
      })
  }
}

const updateUser = ({ actionType: type, apiFunc }) => (payload) => {
  return async (dispatch) => {
    await dispatch({ type, status: statuses.PENDING })

    try {
      const token = (await cognitoService.getToken()) || localStorage.getItem('context')
      await apiFunc({ token, payload })
      const attributes = await apiService.fetchProfile({ token })
      return dispatch({ type, attributes, status: statuses.SUCCEEDED })
    } catch (error) {
      await dispatch({ type, error, status: statuses.FAILED })
      throw error
    }
  }
}

const update = updateUser({
  actionType: actionTypes.UPDATE,
  apiFunc: apiService.updateProfile
})

const createUserPromotion = updateUser({
  actionType: actionTypes.CREATE_USER_PROMOTION,
  apiFunc: apiService.createUserPromotion
})

const createUserEvent = updateUser({
  actionType: actionTypes.CREATE_USER_EVENT,
  apiFunc: apiService.createUserEvent
})

const createSaved = (args) => {
  const { content_id, content_type, content_category } = args
  return (dispatch, getState) => {
    const type = actionTypes.CREATE_SAVED
    dispatch({ type, status: statuses.PENDING })
    return cognitoService
      .getToken()
      .then((token) => {
        return apiService.createSaved({
          token,
          content_id,
          content_type,
          content_category
        })
      })
      .then((attributes) => {
        dispatch({ type, attributes, status: statuses.SUCCEEDED })
      })
      .catch((error) => {
        dispatch({ type, error, attributes: null, status: statuses.FAILED })
        throw error
      })
  }
}

const removeSaved = (args) => {
  const { id } = args
  return (dispatch, getState) => {
    const type = actionTypes.REMOVE_SAVED
    dispatch({ type, status: statuses.PENDING })
    return cognitoService
      .getToken()
      .then((token) => {
        return apiService.removeSaved({ token, id })
      })
      .then((attributes) => {
        dispatch({ type, attributes, status: statuses.SUCCEEDED })
      })
      .catch((error) => {
        dispatch({ type, error, attributes: null, status: statuses.FAILED })
        throw error
      })
  }
}

const fetchSavedProducts = (productIds) => {
  return (dispatch, getState) => {
    const type = actionTypes.FETCH_SAVED_PRODUCTS
    dispatch({ type, status: statuses.PENDING })
    return apiService
      .styleSeekerProducts({ productIds })
      .then((attributes) => {
        const compactAttributes = _.compact(attributes)
        dispatch({
          type,
          attributes: compactAttributes,
          status: statuses.SUCCEEDED
        })
      })
      .catch((error) => {
        dispatch({ type, error, attributes: null, status: statuses.FAILED })
        throw error
      })
  }
}

export {
  login,
  signUp,
  restore,
  update,
  logout,
  createSaved,
  removeSaved,
  createUserEvent,
  createUserPromotion,
  fetchSavedProducts
}
