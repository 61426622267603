import React from 'react'
import styled from 'styled-components'
import { NavLink, withRouter } from 'src/components/RouterDom'
import _get from 'lodash/get'
import _map from 'lodash/map'

import AssetIcon from 'src/components/AssetIcon'
import Spacing from 'src/components/Spacing'
import { getAppTabs } from 'src/routing/appTabsConfig'
import colors from 'src/styling/colors'
import vars from 'src/styling/vars'

import { getCentreConfig } from 'src/config'
import { useInternationalisation } from 'src/context'

const FakeAppTabBar = styled.div`
  height: ${vars.appTabBarHeight}px;
  height: calc(${vars.appTabBarHeight}px + constant(safe-area-inset-bottom));
  height: calc(${vars.appTabBarHeight}px + env(safe-area-inset-bottom));
`

const Wrapper = styled.nav`
  background-color: ${colors.appTabBar};
  position: fixed;
  z-index: ${vars.zIndex.appTabBar};
  bottom: 0;
  left: 0;
  right: 0;
  height: ${vars.appTabBarHeight}px;
  height: calc(${vars.appTabBarHeight}px + constant(safe-area-inset-bottom));
  height: calc(${vars.appTabBarHeight}px + env(safe-area-inset-bottom));
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  user-select: none;
  &:before {
    display: ${(props) => (props.hasStyleSeeker ? 'block' : 'none')};
    content: '';
    position: absolute;
    left: 50%;
    transform: translate(-50%, -17px);
    border-radius: 50%;
    background-color: ${colors.appTabBar};
    width: 110px;
    height: 100px;
  }
`

const TabButton = styled(NavLink)`
  width: ${(p) => (p['data-emphasise'] ? 73 : 48)}px;
  height: ${vars.appTabBarHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  color: white;
  font-size: 11px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  /* white-space: nowrap; */
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  opacity: 0.67;
  transition: 0.2s linear ease-out;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 0.999;
  }
  ${(p) => (p['data-active'] ? 'opacity: 0.999;' : '')}
`

const TabIcon = styled(AssetIcon)`
  ${(p) =>
    p['data-emphasise']
      ? `
  transform: translateY(-8px) scale(1.15);
`
      : ''}
`

const Label = styled.span`
  white-space: nowrap;
`

const AppTabBar = (props) => {
  const { pathname } = props.location
  const { translate } = useInternationalisation()
  let activeTabPaths = [
    '/',
    '/saved',
    '/style-seeker',
    '/shop-and-dine',
    '/map'
  ]
  const { hasStyleSeeker } = getCentreConfig() || {}
  const tabs = React.useMemo(() => getAppTabs(translate), [translate])
  return (
    <React.Fragment>
      <FakeAppTabBar />
      <Wrapper hasStyleSeeker={hasStyleSeeker}>
        {tabs.map(({ label, icon, to, emphasise }, index) => {
          return (
            <TabButton
              key={label}
              data-emphasise={emphasise}
              data-active={index === activeTabPaths.indexOf(pathname)}
              to={to}
              exact
            >
              <TabIcon svg={icon} size={30} data-emphasise={emphasise} />
              <Spacing height={2} />
              <Label>{label}</Label>
            </TabButton>
          )
        })}
      </Wrapper>
    </React.Fragment>
  )
}

export default withRouter(AppTabBar)
