import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link, Redirect } from 'src/components/RouterDom'
import * as Yup from 'yup'
import VisuallyHidden from '@reach/visually-hidden'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { login } from 'src/store/account/actionCreators'
import validators from 'src/validators'

import EmailSvg from 'src/assets/Email.svg'
import LockSvg from 'src/assets/Lock.svg'
import cognitoService from 'src/services/cognitoService'

import Form from 'src/components/Form'
import PasswordField from 'src/components/Fields/Password'
import CheckboxField from 'src/components/Fields/Checkbox'
import Spacing from 'src/components/Spacing'
import FieldsWrapper from './FieldsWrapper'
import SocialLogin from './SocialLogin'

import { useInternationalisation } from 'src/context'

import { isBrowser } from 'src/env'

const ForgotPasswordLink = styled(Link)`
  font-size: 14px;
  line-height: 23px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  display: block;
`

const validationSchema = Yup.object({
  email: validators.email(),
  password: validators.password()
})

const fieldSchema = [
  {
    name: 'email',
    label: 'INPUT_EMAIL',
    autoComplete: 'email',
    type: 'email',
    iconSvg: EmailSvg,
    required: true
  },
  {
    name: 'password',
    label: 'INPUT_PASSWORD',
    autoComplete: 'current-password',
    type: 'password',
    iconSvg: LockSvg,
    required: true,
    component: PasswordField
  },
  {
    name: 'keepMeLoggedIn',
    label: 'KEEP_ME_LOGGED_IN',
    component: CheckboxField
  }
]

const LoginForm = (props) => {
  const { redirectAfterLogin } = props

  const dispatch = useDispatch()
  const { translate } = useInternationalisation()

  const onSubmit = React.useCallback((values) => {
    const { email, password, keepMeLoggedIn } = values
    if (isBrowser) {
      localStorage.setItem('keepCognitoSession', keepMeLoggedIn)
    }

    return dispatch(login({ password, email })).then(redirectAfterLogin)
  }, [])

  const formProps = {
    onSubmit,
    buttonLabel: 'BUTTON_LABEL_SUBMIT',
    fieldSchema,
    validationSchema,
    parseError: cognitoService.parseError
  }
  return (
    <React.Fragment>
      <VisuallyHidden><h1>{translate('LOGIN')}</h1></VisuallyHidden>
      <FieldsWrapper>
        <Form {...formProps} />
      </FieldsWrapper>

      <Spacing height={20} />

      <ForgotPasswordLink to='/forgot-password'>
        {translate('INPUT_FORGOTTEN_PASSWORD')}
      </ForgotPasswordLink>

      <SocialLogin redirectAfterLogin={redirectAfterLogin} />
    </React.Fragment>
  )
}

export default LoginForm
