import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

import { login } from 'src/store/account/actionCreators'

import Button from 'src/components/Button'
import Message from 'src/components/Message'
import Spacing from 'src/components/Spacing'
import { useInternationalisation } from 'src/context'
import { useCentre } from 'src/store/resources/hooks'

import { errors } from 'src/config'
import FieldsWrapper from './FieldsWrapper'
import facebookService from 'src/services/authServices/facebookAuthService'
const socialLoginEmailExistsError = errors.socialLoginEmailExistsError

const SocialLoginHeading = styled.h2`
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.83px;
  line-height: 45px;
  text-align: center;
`

const SocialSignOnRow = styled(FieldsWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SocialButtonPadding = styled.div`
padding-right: 7.5px;
padding-left: 7.5px;
`

const SocialLogin = (props) => {
  const { redirectAfterLogin, isSignUp } = props
  const [ providers, setProviders ] = useState([])
  const dispatch = useDispatch()
  const { translate } = useInternationalisation()
  const centre = useCentre()
  const socialProviders = {
    facebook: {
      providerId: 'facebook',
      plugIn: facebookService,
      enabled: _.get(centre, 'fields.enableFacebookLogin', true)
    },
    apple: {
      providerId: 'apple',
      plugIn: null,
      enabled: _.get(centre, 'fields.enableAppleLogin', true)
    },
    twitter: {
      providerId: 'twitter',
      plugIn: null,
      enabled: _.get(centre, 'fields.enableTwitterLogin', true)
    }
  }

  useEffect(() => {
    const allowedProviders = _.reduce(socialProviders, (enabledProviders, provider) => {
      if (provider.enabled) {
        enabledProviders.push(provider.providerId)
      }
      return enabledProviders
    }, [])

    setProviders(allowedProviders)
  }, [socialProviders.apple.enabled, socialProviders.twitter.enabled, socialProviders.facebook.enabled])

  const [errorMessage, setErrorMessage] = React.useState(false)

  const buttonEls = React.useMemo(() => {
    const getClickHandler = (providerName) => async () => {

      setErrorMessage(false)
      const provider = socialProviders[providerName]
      return dispatch(login({ provider }))
        .then(redirectAfterLogin)
        .catch(error => {
          let translationKey = `${_.toUpper(provider.providerId)}_${isSignUp ? 'SIGN_UP' : 'LOGIN'}_ERROR`
          if (error.message === socialLoginEmailExistsError.message) {
            translationKey += '_EXISTING_EMAIL'
          }
          const errorMessage = translate(translationKey)
          setErrorMessage(errorMessage)
        })
    }
    const buttonEls = providers.map((provider) => {
      return (
        <SocialButtonPadding>
          <Button
            buttonType={provider}
            key={provider}
            onClick={getClickHandler(provider)}
            customWidth={70}
          />
        </SocialButtonPadding>
      )
    })
    return buttonEls
  }, [redirectAfterLogin, isSignUp, providers])

  const headingText = isSignUp ? translate('SOCIAL_SIGN_UP') : translate('SOCIAL_LOGIN')

  return (
    <React.Fragment>
      <Spacing height={55} />
      {!_.isEmpty(providers) && (<SocialLoginHeading>{headingText}</SocialLoginHeading>)}
      <Spacing height={25} />
      <SocialSignOnRow>{buttonEls}</SocialSignOnRow>
      {errorMessage ? <Message type='error' text={errorMessage} /> : null}
    </React.Fragment>
  )
}

export default SocialLogin
