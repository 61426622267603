import _pick from 'lodash/pick'

export default (props) => {
  const {
    iconSvg,
    errorMessage,
    label,
    name,
    date,
    required,
    hidePlaceholder,
    ...rest
  } = props
  return {
    ..._pick(rest, [
      'value',
      'onChange',
      'options',
      'type',
      'autoComplete',
      'readOnly',
      'disabled',
      'rows',
      'allowNullValue',
      'autoFocus',
      'onKeyPress'
    ]),
    'data-has-icon': !!iconSvg,
    'aria-invalid': !!errorMessage,
    'aria-label': label,
    'aria-required': !!required,
    placeholder: hidePlaceholder ? '' : !date && label,
    name
  }
}
