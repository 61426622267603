import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import qs from 'qs'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _find from 'lodash/find'
import _groupBy from 'lodash/groupBy'
import _capitalize from 'lodash/capitalize'
import apiService from 'src/services/apiService'
import Cropper from 'src/components/Cropper'
import Button from 'src/components/Button'
import PageBanner from 'src/components/PageBanner'
import Spacing from 'src/components/Spacing'
import H2 from 'src/components/H2'
import AssetIcon from 'src/components/AssetIcon'
import Text from 'src/components/Text'
import Spinner from 'src/components/Spinner'
import { Tile, Content } from 'src/components/Tile/BaseTile'
import StyleSeekerSvg from 'src/assets/Styleseeker.svg'
import env, { isBrowser, isCordova } from 'src/env'
import Head from 'src/components/Head'
import vars from 'src/styling/vars'
import { useSelector, useDispatch } from 'react-redux'
import { useInternationalisation } from 'src/context'
import Crop from 'react-image-crop'
import SavedHeart from 'src/components/SavedHeart'
import { savedSelector, attributesSelector } from 'src/store/account/selectors'
import { showHeartResourceTypes } from 'src/utility'
import { createSaved, removeSaved } from 'src/store/account/actionCreators'

import imageUploadService from './../ImageUploadService'

import {
  fileSelector,
  categorySelector,
  cropSelector,
  weightsSelector,
  similarProductsSelector,
  isCroppedSelector,
  isLoadingSelector
} from 'src/store/styleSeeker/selectors'
import {
  setFile,
  setCategory,
  setCrop,
  setSimilar,
  setIsCropped,
  setIsLoading,
  clear,
  openFiltersMenu,
  setCurrentPath,
  setFileUrl
} from 'src/store/styleSeeker/actionCreators'
import colors from 'src/styling/colors'

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 8%
    ${(p) => (p.padBottom ? 'calc(85px + env(safe-area-inset-bottom))' : 0)} 8%;
`

const Title = styled(H2)`
  font-size: 22px;
  padding: 15px;
`

const IntroductionDescription = styled(Text)`
  font-size: 16px;
`

const IntroductionButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledButton = styled(Button)`
  margin: 0;
  height: 70px;
`

const ProductSearch = (props) => {
  const { history } = props || {}

  const dispatch = useDispatch()
  const { translate, translateUrl } = useInternationalisation()

  const filepicker = useRef(null)

  const [imageCrop, setImageCrop] = useState({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    unit: 'px',
    imageHeight: 0,
    imageWidth: 0
  })

  const [image, setImage] = useState(null)

  const _openImagePickerLibrary = () => {
    return imageUploadService.openImagePickerLibrary((file) => {
      dispatch(setIsLoading(true))
      dispatch(setFile(file))
      history.push(translateUrl(`/style-seeker/image-crop`))
      imageUploadService.getFileBlob(file).then((blob) => {
        return apiService
          .styleSeekerUpload({
            file: blob,
            fileName: new Date().getTime()
          })
          .then(({ fileUrl }) => {
            dispatch(setFileUrl(fileUrl))
            dispatch(setIsLoading(false))
          })
      })
    }, filepicker)
  }

  const _openImagePickerCamera = () => {
    return imageUploadService.openImagePickerCamera((file) => {
      dispatch(setIsLoading(true))
      dispatch(setFile(file))
      history.push(translateUrl(`/style-seeker/image-crop`))
      imageUploadService.getFileBlob(file).then((blob) => {
        return apiService
          .styleSeekerUpload({
            file: blob,
            fileName: new Date().getTime()
          })
          .then(({ fileUrl }) => {
            dispatch(setFileUrl(fileUrl))
            dispatch(setIsLoading(false))
          })
      })
    })
  }

  return (
    <Wrapper>
      <Head title={translate('PAGE_STYLE_SEEKER')} />
      <AssetIcon svg={StyleSeekerSvg} size={60} />
      <Title>{translate('STYLE_SEEKER_TITLE')}</Title>
      <IntroductionDescription>
        {translate('STYLE_SEEKER_DESCRIPTION')}
      </IntroductionDescription>
      <IntroductionButtons>
        <StyledButton
          buttonType={'primary'}
          onClick={_openImagePickerCamera}
          style={{ marginBottom: 10 }}
        >
          {translate('STYLE_SEEKER_TAKE_PIC')}
        </StyledButton>
        <StyledButton buttonType={'primary'} onClick={_openImagePickerLibrary}>
          {translate('STYLE_SEEKER_UPLOAD')}
        </StyledButton>
        <input
          onChange={async (e) => {
            const file = e.target.files[0]
            const blobUrl = window.URL.createObjectURL(file)
            // dispatch(setFile(blobUrl))
            history.push(translateUrl(`/style-seeker/image-crop`))
          }}
          ref={filepicker}
          type='file'
          accept='image/*'
          style={{ display: 'none' }}
        />
      </IntroductionButtons>
    </Wrapper>
  )
}

export default ProductSearch
