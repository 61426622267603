// redux promise statuses
export const PENDING = 'PENDING';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';

/**
 * @description this middleware fires any actions with the promise property
 * and dispatches the promise lifecycle statuses (PENDING, SUCCESS, FAIL)
 */
export default function promiseMiddleware() {
  return (next) => (action) => {
    const {
      promise,
      type,
      ...rest
    } = action;

    // pass action to next if does not contain a promise
    if (!promise) {
      return next(action);
    }

    /**
      * @description action promise thunk handler
      */
    function promiseThunk(dispatch, getState) {
      // dispatch action promise as pending
      dispatch({
        ...rest,
        type,
        status: PENDING,
      });

      // execute action promise
      return promise(dispatch, getState)
        .then((result) => {
          // dispatch action promise as successful
          dispatch({
            ...rest,
            result,
            type,
            status: SUCCESS,
          });

          return Promise.resolve(result);
        })
        .catch((error) => {
          // dispatch action promise as failed
          dispatch({
            ...rest,
            error,
            errorMessage: error.message,
            type,
            status: FAIL,
          });
          return Promise.reject(error);
        });
    }

    // pass promiseThunk to next to be handles by thunkMiddleware
    return next(promiseThunk);
  };
}