import cookieActions, { getInitialCookiesState }  from './service'

export default (state, action) => {
    switch (action.type) {
        case cookieActions.ALL_COOKIES_ACCEPT: {
            return {
                userSetPreference: true,
                audienceMeasurementCookiesOptedIn: true,
                personalisedAdCookiesOptedIn: true
            }
        }
        case cookieActions.ALL_COOKIES_REJECT: {
            return {
                userSetPreference: true,
                audienceMeasurementCookiesOptedIn: false,
                personalisedAdCookiesOptedIn: false
            }
        }
        case cookieActions.AUDIENCE_MEASUREMENT_TOGGLED: {
            return {
                ...state,
                userSetPreference: true,
                audienceMeasurementCookiesOptedIn: !state.audienceMeasurementCookiesOptedIn
            }
        }
        case cookieActions.PERSONALISED_COOKIES_TOGGLED: {
            return {
                ...state,
                userSetPreference: true,
                personalisedAdCookiesOptedIn: !state.personalisedAdCookiesOptedIn
            }
        }
        case cookieActions.SAVE_PREFERENCES: {
            return {
                ...state,
                savedAt: Date.now()
            }
        }
        case cookieActions.UNDO_CHANGES: {
            return {
                ...getInitialCookiesState(),
                savedAt: state.savedAt || null
            }
        }
        default: {
            return state
        }
    }
}