import styled, { css } from 'styled-components'
import vars from 'src/styling/vars'
import colors from 'src/styling/colors'
import media from 'src/styling/media'

const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);

  max-height: 100vh;
  ${(props) =>
    props.fixedHeight &&
    media.forEach({
      values: props.carouselHeight,
      getStyle: (v) => `height: ${v}px`
    })}
  ${(props) =>
    props.limitWidth &&
    media.greaterThan('lg')`
      width: ${vars.innerContentMaxWidth};
    `}

  ${(props) =>
    props.fullScreen &&
    css`
      width: 100% !important;
      height: 100% !important;
    `}

  /* Slider */
  .slick-slider {
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    ${(props) =>
      props.fullScreen &&
      css`
        width: 100%;
        height: 100%;
      `}
  }

  .slick-list {
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    display: block;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    ${(props) =>
      props.fullScreen &&
      css`
        width: 100%;
        height: 100%;
      `}
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;

    ${(props) =>
      props.fullScreen &&
      css`
        width: 100%;
        height: 100%;
      `}
  }
  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-active.slick-current {
    ${(props) =>
      props.fullScreen &&
      css`
        display: flex;
      `}
  }

  .slick-slide div {
    ${(props) =>
      props.fullScreen &&
      css`
        width: 100%;
        height: 100%;
      `}
  }

  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`

CarouselContainer.defaultProps = {
  fixedHeight: true
}

export default CarouselContainer
