import React, { Fragment, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link, Redirect } from 'src/components/RouterDom'
import qs from 'query-string'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _ from 'lodash'
import { List as Placeholder } from 'react-content-loader'

import Container from 'src/components/Container'
import Tabs from 'src/components/Tabs'
import Page from 'src/components/Page'
import StarSvg from 'src/assets/Star.svg'
import UserSvg from 'src/assets/User.svg'
import media from 'src/styling/media'
import vars from 'src/styling/vars'
import { horizontalMargin } from 'src/styling/styles'
import { attributesSelector } from 'src/store/account/selectors'
import Spacing from 'src/components/Spacing'
import { getFields, getFirstResource, getAssetSrc, getEnableRegistrationLogIn } from 'src/utility'
import { useRequest, useCentre } from 'src/store/resources/hooks'

import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import ResetPasswordForm from 'src/pages/Login/ResetPasswordForm'
import Spinner from 'src/components/Spinner'
import HeaderGradient from 'src/components/HeaderGradient'
import Head from 'src/components/Head'
import { isCordova } from 'src/env'


import { useInternationalisation } from 'src/context'

const Background = styled.div`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  flex: 1 1 auto;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: top center;
  ${media.forEach({
  values: vars.headerHeight,
  getStyle: (x) => `padding-top: ${x}px`
})}
`

const Inner = styled(Container)`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 0;
`

const Box = styled(Container)`
  box-shadow: ${vars.contentShadow};
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 690px;
  ${horizontalMargin('auto')}
`

const SpinnerContainer = styled(Container)`
  height: 620px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const MessageContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 60px;
`

const Title = styled.h1`
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  margin: 40px 0 0;
`

const pageSchema = {
  login: {
    FormComp: LoginForm,
    title: 'Login',
    showTabs: true,
    pageTitle: 'LOGIN'
  },
  'sign-up': {
    FormComp: SignUpForm,
    title: 'Sign Up',
    showTabs: true,
    pageTitle: 'SIGN_UP'
  },
  forgot: {
    FormComp: ForgotPasswordForm,
    title: 'Forgot Password',
    showTabs: false,
    pageTitle: 'FORGOT_PASSWORD'
  },
  reset: {
    FormComp: ResetPasswordForm,
    title: 'Reset Password',
    showTabs: false,
    pageTitle: 'RESET_PASSWORD'
  }
}

const Panel = ({ title, showTabs, formProps, FormComp, pageTitle }) => (
  <Container>
    {showTabs ? null : <Title>{pageTitle}</Title>}
    <Spacing height={40} />
    <FormComp {...formProps} />
  </Container>
)

const Login = (props) => {
  const { location, history } = props
  const { translate, translateUrl, untranslateUrl } = useInternationalisation()
  const [hasMounted, setHasMounted] = useState(false)
  const layoutRequest = useRequest({
    resourceType: 'layout',
    requestKey: 'layout'
  })
  const layoutStatus = _get(layoutRequest, '_status')
  const layout = getFirstResource(layoutRequest)
  const {
    loginBannerMedia
  } = getFields(layout)
  const centre = useCentre()
  const enableRegistrationLogIn = getEnableRegistrationLogIn(centre)
  const loginBanner = getAssetSrc(loginBannerMedia, { width: 2000 })
  const bannerImgSrc = _.defaultTo(loginBanner, 'https://hammerson-plus-mock-assets.s3-eu-west-1.amazonaws.com/login-banner-default.jpeg')

  useEffect(() => {
    // this solves SSR reconciliation issues
    // from using hashes and query strings
    setHasMounted(true)
  }, [])

  const tabsProp = [
    { label: translate('LOGIN'), to: '/login', icon: UserSvg, replace: true },
    {
      label: translate('SIGN_UP'),
      to: '/login?tab=sign-up',
      icon: StarSvg,
      replace: true
    }
  ]

  const { search } = location || {}
  const queries = React.useMemo(() => qs.parse(search), [search])
  const pageTab = queries.tab

  const pathname = untranslateUrl(_get(location, 'pathname', '').slice(1))

  let pageKey = 'login'
  if (pageTab === 'sign-up') pageKey = 'sign-up'
  if (_.startsWith(pathname, 'forgot-password')) pageKey = 'forgot'
  if (_.startsWith(pathname, 'reset-password')) pageKey = 'reset'

  const { FormComp, title, showTabs, pageTitle } = pageSchema[pageKey]

  const redirectTo = queries.redirectTo || translateUrl('/account')
  const redirectAfterLogin = useCallback(() => history.push(redirectTo), [
    redirectTo
  ])
  const attributes = useSelector(attributesSelector())
  if (attributes) return <Redirect to='/account' />

  if (_.startsWith(pathname, 'sign-up')) {
    return <Redirect to='/login?tab=sign-up' />
  }
  const formProps = { redirectAfterLogin }

  if (layoutStatus.pending) {
    return (
      <Page box inner>
        <Placeholder />
      </Page>
    )
  }

  return (
    <Fragment>
      <Head title={translate(pageTitle)} />
      {isCordova && <Spacing height={40} />}
      <Background src={bannerImgSrc}>
        <HeaderGradient />
        <Inner verticalGutter>
          <Box bottomGutter horizontalGutter={false}>
            {hasMounted ? (
              <React.Fragment>
                <Tabs
                  tabs={tabsProp}
                  mobileSelect={false}
                  showTabs={showTabs}
                />
                {enableRegistrationLogIn ? <Panel
                  title={title}
                  showTabs={showTabs}
                  formProps={formProps}
                  FormComp={FormComp}
                  pageTitle={translate(pageTitle)}
                /> : 
                <MessageContainer>
                  {translate('REGISTER_DISABLED_TEXT')}
                </MessageContainer>
                }
              </React.Fragment>
            ) : (
                <SpinnerContainer verticalGutter>
                  <Spinner />
                </SpinnerContainer>
              )}
          </Box>
        </Inner>
      </Background>
    </Fragment>
  )
}

export default Login
