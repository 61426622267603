import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import styled from 'styled-components'
import media from 'src/styling/media'
import vars from 'src/styling/vars'
import colors from 'src/styling/colors'

import Container from 'src/components/Container'
import FeaturedItemsListing from 'src/components/FeaturedItemsListing'

const Content = styled.div`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  flex-direction: column;
  background-color: ${colors.white};
  box-shadow: ${vars.contentShadow};
  margin-top: 30px;
  padding: 20px;
  ${media.greaterThan('lg')`
    flex-direction: row;
    padding: 43px 44px;
  `}
`

const H2 = styled.h2`
  padding-left: 10px;
  padding-right: 10px;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  padding-top: 0;
  padding-bottom: 10px;
  color: ${colors.textColor};
`

const WhatsOn = ({ events, title }) => {
  return (
    <Container>
      <Content itemLength={_size(events)}>
        {title && <H2>{title}</H2>}
        <FeaturedItemsListing items={events} />
      </Content>
    </Container>
  )
}

export default WhatsOn
