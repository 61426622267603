import 'react-app-polyfill/ie11'
import 'core-js/stable'
import '@reach/dialog/styles.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import env, { isCordova } from 'src/env'
import configureStore from 'src/store/configureStore'
import cordovaInit from './cordovaInit'
import { getBestLocale } from 'src/utility'
import hybridService from 'src/services/hybridService'

let hasDeviceReadyFired = false

const onResume = ({ store }) => () => {
  const { dispatch, getState } = store || {}
  if (!env.fakeCordova) {
    window.open = window.cordova.InAppBrowser.open
  }
  if (dispatch) {
    hybridService.syncUserDevice({ state: getState() })
  }
}

const startApp = () => {
  hasDeviceReadyFired = true
  const store = configureStore()
  const onAppResume = onResume({ store })
  if (isCordova) {
    onAppResume()
    cordovaInit({ store })
  }
  document.addEventListener('resume', onAppResume, false)
  ReactDOM.hydrate(
    <App store={store} locale={getBestLocale()} />,
    document.getElementById('root')
  )
}

if (isCordova) {
  document.addEventListener('deviceready', startApp, false)

  setTimeout(() => {
    if (!hasDeviceReadyFired) {
      document.getElementById('root').innerHTML = 'deviceready did not fire'
    }
  }, 10000)
} else {
  startApp()
}
