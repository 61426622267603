import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import * as Yup from 'yup'

import _get from 'lodash/get'
import _map from 'lodash/map'

import cognitoService from 'src/services/cognitoService'
import validators from 'src/validators'

import Form from 'src/components/Form'
import media from 'src/styling/media'
import PasswordField from 'src/components/Fields/Password'

import { useInternationalisation } from 'src/context'

const fieldSchema = [
  {
    name: 'currentPassword',
    label: 'INPUT_CURRENT_PASSWORD',
    autoComplete: 'current-password',
    type: 'password',
    component: PasswordField
  },
  {
    name: 'newPassword',
    label: 'INPUT_NEW_PASSWORD',
    autoComplete: 'new-password',
    type: 'password',
    component: PasswordField
  },
  {
    name: 'confirmPassword',
    label: 'INPUT_CONFIRM_PASSWORD',
    autoComplete: 'new-password',
    type: 'password',
    component: PasswordField
  }
]

const parseError = (error) => {
  if (error.code === 'NotAuthorizedException') {
    return 'PASSWORD_INCORRECT'
  } else {
    return cognitoService.parseError(error)
  }
}

const validationSchema = Yup.object({
  currentPassword: validators.password(),
  newPassword: validators.password(),
  confirmPassword: validators.confirmPassword('newPassword')
})

const FormWrapper = styled.div`
  ${media.forEach({
    values: { md: 650 },
    getStyle: (x) => `max-width: ${x}px;`
  })}
  margin: 0 auto;
  text-align: center;
`

const Password = (props) => {
  const dispatch = useDispatch()
  const { translate } = useInternationalisation()
  const onSubmit = React.useCallback((values) => {
    return cognitoService.changePassword(values)
  }, [])

  return (
    <FormWrapper>
      <Form
        fieldSchema={fieldSchema}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        buttonLabel={translate('INPUT_UPDATE')}
        parseError={parseError}
        successMessage={translate('INPUT_UPDATED_SUCCESS')}
      />
    </FormWrapper>
  )
}

export default Password
