import React from 'react'
import styled from 'styled-components'
import media from 'src/styling/media'
import vars from 'src/styling/vars'
import Container from 'src/components/Container'

const Content = styled(Container)`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  ${({noBox}) => noBox ? '' : `box-shadow: ${vars.contentShadow};`}
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${({ rounded }) => (rounded ? '5px' : '0px')};
  ${media.forEach({
    values: { sm: 20, md: 30, lg: 40 },
    getStyle: (val) => `padding: ${val}px`
  })};
`

const Box = ({ children, ...props }) => <Content {...props}>{children}</Content>

export default Box
