import Home from 'src/pages/Home'
import Root from 'src/pages/Root'
import Job from 'src/pages/Job'
import Listing from 'src/pages/Listing'
import News from 'src/pages/News'
import NotFound from 'src/pages/NotFound'
import Login from 'src/pages/Login'
import Account from 'src/pages/Account'
import Contact from 'src/pages/Contact'
import CritzrCustomerSatPage from 'src/pages/CritzrCustomerSatPage'
import MapPage from 'src/pages/Map'
import Saved from 'src/pages/Saved'
import SavedProduct from 'src/pages/SavedProduct'
import StyleSeeker from 'src/pages/StyleSeeker'
import Parking from 'src/pages/Parking'
import OpeningHours from 'src/pages/OpeningHours'
import ContentPage from 'src/pages/ContentPage'
import Crisis from 'src/pages/Crisis'
import SearchResults from 'src/pages/SearchResults'
import Sitemap from 'src/pages/Sitemap'
import RouteComponent from 'src/pages/RouteComponent'
import { isCordova } from 'src/env'

const rewards = isCordova ? '|rewards' : ''

let routesConfig = [
  {
    component: Root,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home
      },
      {
        path: '/(login|sign-up|reset-password|forgot-password)',
        exact: true,
        component: Login
      },
      {
        path: '/:parentCategory(account)',
        exact: true,
        component: Account
      },
      {
        path: `/:parentCategory(shop|dine|shop-and-dine|events|competitions|promotions|offers${rewards}|magazine|jobs|news)/`,
        exact: true,
        component: Listing
      },
      {
        path: `/:parentCategory(shop|dine|shop-and-dine|events|evenements|competitions|promotions|offers${rewards}|offres|concours|magazine|jobs|news|leisure|activites)/:id`,
        exact: true,
        component: RouteComponent
      },
      {
        path: '/jobs/:id',
        exact: true,
        component: Job
      },
      {
        path: '/(news|magazine)/:id',
        exact: true,
        component: News
      },
      {
        path: '/contact-us',
        exact: true,
        component: Contact
      },
      {
        path: '/feedback',
        exact: true,
        component: CritzrCustomerSatPage
      },
      {
        path: '/parking',
        exact: true,
        component: Parking
      },
      {
        path: '/parking/:id',
        exact: true,
        component: Parking
      },
      {
        path: '/opening-hours',
        exact: true,
        component: OpeningHours
      },

      {
        path: '/search',
        exact: true,
        component: SearchResults
      },
      {
        path: '/sitemap',
        exact: true,
        component: Sitemap
      },
      {
        path: '/crisis-warning',
        exact: true,
        component: Crisis
      },
      {
        path: '/(centre-map|plan-du-centre|map)',
        exact: true,
        component: MapPage
      },
      ...(isCordova
        ? [
          {
            path: '/style-seeker',
            exact: true,
            component: StyleSeeker
          },
          {
            path:
              '/style-seeker/:parentCategory(image-crop|garment-selection|similar-products|product-details)',
            exact: true,
            component: StyleSeeker
          },
          {
            path: '/saved',
            exact: true,
            component: Saved
          },
          {
            path: '/saved/:id',
            exact: true,
            component: SavedProduct
          }
        ]
        : []),
      {
        path: '/:id',
        exact: true,
        component: ContentPage
      },

      {
        component: NotFound
      }
    ]
  }
]

export default routesConfig
