import React from 'react'
import { Route } from "react-router-dom";
/**
 * Allows a component to change the status code of the response during **SSR**.
 * Main use case is for sending a `404` for SEO purposes - which doesn't use client side rendering.
 * @param {object} props
 * @param {number} props.statusCode HTTP Status Code to send to user.
 * @param {object[]} props.children JSX components to render.
 */
const RouteStatus = ({ children, statusCode }) => {
    return (
        <Route render={({ staticContext }) => {
            if (staticContext) {
                staticContext.status = statusCode;
            }
            return children
        }}
        />
    );
}

export default RouteStatus