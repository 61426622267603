// PURPOSE OF INPUTBASE:
// To contain common input styles such as font-size and padding and border
// and add an icon to the left of the input

import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import VisuallyHidden from '@reach/visually-hidden'

import AssetIcon from 'src/components/AssetIcon'
import colors from 'src/styling/colors'
import { iconSize, leftPadding, fontSize, inputHeight } from './vars'
import getInputProps from './getInputProps'

const InputWrapper = styled.div`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  display: flex;
  flex: 1 1 auto;
`

const InputIcon = styled(AssetIcon)`
  color: ${({ theme }) => (theme.color ? theme.color : colors.black)};
  position: absolute;
  left: ${({ theme }) => (theme.pushLeft ? theme.pushLeft : 0)}px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`

const RightAlign = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Input = styled.input`
  display: block;
  font-family: inherit;
  color: ${({ theme }) => (theme.color ? theme.color : colors.black)};
  font-size: ${fontSize}px;
  font-weight: bold;
  height: ${inputHeight}px;
  ${(p) => (p.type === 'date' ? `line-height: ${inputHeight}px;` : '')}
  padding: 0 0 0 ${(p) =>
    p['data-has-icon']
      ? ({ theme }) =>
          theme.pushLeft ? theme.pushLeft + leftPadding : leftPadding
      : 0}px;
  margin: 0 auto;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  border-bottom: 2px solid
    ${(p) =>
      p['aria-invalid']
        ? _get(p, 'theme.errorColor') || colors.error
        : _get(p, 'theme.borderColor') || colors.textColor};
  border-radius: 0;
  background-color: ${({ theme }) =>
    theme.backgroundColor ? theme.backgroundColor : 'auto'};
  caret-color: ${({ theme }) => (theme.caretColor ? theme.caretColor : 'auto')};
  &::-ms-expand {
    display: none;
  }
  &::placeholder {
    color: ${colors.placeholderTextColor};
  }
  &:focus {
    ${({ theme }) =>
      theme.focusColor
        ? `outline: none;
           border: 2px solid rgba(255, 255, 255, 1);`
        : ''};
  }
`

const InputBase = (props) => {
  const { iconSvg, component, inlineRight } = props

  return (
    <React.Fragment>
      <InputWrapper>
        <Input {...getInputProps(props)} as={component} id={props.id} />
        {iconSvg ? <InputIcon svg={iconSvg} size={iconSize} /> : null}
        <RightAlign>
          {inlineRight ? (
            <inlineRight.component {...inlineRight.props} />
          ) : null}
        </RightAlign>
      </InputWrapper>
    </React.Fragment>
  )
}

export default InputBase
