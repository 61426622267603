import React from 'react'
import ContentLoader from 'react-content-loader'

const LoadingPromotion = () => (
  <ContentLoader
    height={300}
    width={300}
    speed={3}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
  >
    <rect x='120' y='5' rx='0' ry='0' width='60' height='20' />
    <rect x='55' y='35' rx='5' ry='10' width='190' height='15' />
    <rect x='35' y='55' rx='5' ry='10' width='220' height='15' />
    <rect x='55' y='75' rx='5' ry='10' width='190' height='15' />
    <rect x='110' y='100' rx='0' ry='0' width='70' height='30' />
    <rect x='55' y='150' rx='5' ry='10' width='190' height='10' />
    <rect x='35' y='165' rx='5' ry='10' width='220' height='10' />
    <rect x='55' y='180' rx='5' ry='10' width='190' height='10' />
    <rect x='110' y='200' rx='0' ry='0' width='70' height='30' />
  </ContentLoader>
)

export default LoadingPromotion
