import React, { Fragment, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'src/components/RouterDom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { useInternationalisation } from 'src/context'

import _ from 'lodash'
import fp from 'lodash/fp'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _slice from 'lodash/slice'
import _head from 'lodash/head'
import _lowerCase from 'lodash/lowerCase'
import _isBoolean from 'lodash/isBoolean'
import { getFields, createFormatTime } from 'src/utility'
import AssetIcon from 'src/components/AssetIcon'
import Show from 'src/components/Show'
import { getCentreConfig } from 'src/config'
import CrowdChecker from './CrowdChecker'
import useOpeningTimes from 'src/hooks/useOpeningTimes'
import {
  selectors as shopperTrakSelectors,
  actions as shopperTrakActions
} from 'src/store/shopperTrak'

import CrisisSvg from 'src/assets/Crisis.svg'
import PinSvg from 'src/assets/Pin.svg'


const { timeFormat = {} } = getCentreConfig() || {}

const BannerContentWrapper = styled.div`
  color: currentColor;
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 20px;
  max-height: 5em;
  overflow-y: auto;
  padding: 3px 7px;
`

const Content = styled.div`
  margin: 0;
  text-align: center;
  padding: 0 0 0 5px;
`


const BannerWrapper = styled(Link)`
  text-decoration: none;
  color: ${fp.get('color')};
  background-color: ${fp.get('backgroundColor')};
`

const OpeningHoursWrapper = styled.div`
  display: inline-block;
  margin-right: 10px;
`

const IconWrapper = styled.div`
  display: block;
  margin: 0 0 auto 0;
`

const getCrisisWarningSpan = fp.get('crisis.title')

const OpeningTimes = ({ centre }) => {
  const { translate, moment, defaultLocale } = useInternationalisation()
  const {
    todayKey,
    openingHoursList,
    openingHoursFirst
  } = useOpeningTimes(centre)

  const getOpeningHoursElement = (fields) => {
    const { title, openingHours, isFeminine } = fields || {}
    const { openingTime, closingTime, closed } = _get(openingHours, todayKey) || {}
    const formatTime = (time) => {
      const factory = createFormatTime({ moment })
      const originFormat = 'HH:mm'
      if (time && _.endsWith(time, '00')) {
        return factory({
          originFormat,
          outputFormat: timeFormat.centreOpeningTimeShort
        })(time)
      } else {
        return factory({
          originFormat,
          outputFormat: timeFormat.centreOpeningTimeLong
        })(time)
      }
    }
    let closedTranslation = 'CLOSED_TODAY'
    if(_isBoolean(isFeminine)) {
      closedTranslation = isFeminine ? 'CLOSED_TODAY_FEMININE' : 'CLOSED_TODAY_MASCULINE'
    }
    return (
      <OpeningHoursWrapper key={title}>
        {closed ? `${title} ${translate(closedTranslation)}` : (
            <>
              {`${translate('OPEN_TODAY')} ${title} `}
              <strong>{`${formatTime(openingTime)} - `}</strong>
              <strong>{formatTime(closingTime)}</strong>
            </>
          )}
      </OpeningHoursWrapper>
    )
  }

  const desktopOpeningHours = _map(openingHoursList, getOpeningHoursElement)
  const mobileOpeningHours = getOpeningHoursElement(openingHoursFirst)
  return (
    <Fragment>
      <Show gt='lg'>{desktopOpeningHours}</Show>
      <Show lt='lg'>{mobileOpeningHours}</Show>
    </Fragment>
  )
}

const BannerContent = ({ showCrisis, showOpeningTimes, crisis, centre }) => {
  if (showCrisis) {
    return (
      <Content>
        {getCrisisWarningSpan({ crisis })}
      </Content>
    )
  } else if (showOpeningTimes) {
    return (
      <Content>
        <OpeningTimes centre={centre} />
      </Content>
    )
  } else {
    return null
  }
}

const getIconButton = ({ showCrisis, showOpeningTimes }) => {
  if (showCrisis) {
    return <AssetIcon aria-label={'Crisis'} size={20} svg={CrisisSvg} overflow={'hidden'} />
  } else {
    return null
  }
}

const Banner = (props) => {
  const dispatch = useDispatch()
  const { crisis, centre } = props
  const {
    todayKey,
    openingHoursList
  } = useOpeningTimes(centre)
  const enableCrowdChecker = _.get(centre, 'fields.enableCrowdChecker', false)

  useEffect(() => {
    enableCrowdChecker && dispatch(shopperTrakActions.readOccupancy())
  }, [enableCrowdChecker])

  const showOpeningTimes = !_.isEmpty(timeFormat)
  let showCrisis = crisis
    && crisis.title
    && crisis.startDateTime
    && crisis.endDateTime

  const crisisProps = {
    backgroundColour: '#CA3746',
    colour: '#FFFFFF',
    link: '/crisis-warning',
  }

  const gettingHereProps = {
    backgroundColour: 'rgba(255,255,255,0.1)',
    colour: null,
    link: '/opening-hours',
  }
  const wrapperProps = showCrisis ? crisisProps : gettingHereProps


  const bannerContentProps = { showCrisis, showOpeningTimes, crisis, centre }
  const iconButton = getIconButton({
    showCrisis,
    showOpeningTimes,
    crisis,
    centre
  })

  const occupancy = useSelector(shopperTrakSelectors.getOccupancy)
  const storeOpen = _.some(openingHoursList, ({ openingHours }) => {
    const { closed, openingTime, closingTime } = _get(openingHours, todayKey) || {}
    const isBeforeOpened = moment().isBefore(moment(openingTime, 'HH:mm'))
    const isAfterClosed = moment().isAfter(moment(closingTime, 'HH:mm'))
    const storeClosed = closed || isBeforeOpened || isAfterClosed
    return !storeClosed
  })
  const storeClosed = !storeOpen
  const crowdCheckerHref = _.get(centre, 'fields.crowdCheckerLinkToPage.fields.urlName')
  const popularThreshold = _.get(centre, 'fields.popularThreshold')
  const busyThreshold = _.get(centre, 'fields.busyThreshold')
  let crowdCheckerStatus = 'QUIET'
  if (occupancy > popularThreshold) crowdCheckerStatus = 'POPULAR'
  if (occupancy > busyThreshold) crowdCheckerStatus = 'BUSY'
  if (storeClosed) crowdCheckerStatus = 'CLOSED'

  return (
    <>
      <BannerWrapper
        backgroundColor={wrapperProps.backgroundColour}
        color={wrapperProps.colour}
        to={wrapperProps.link}
      >
        <BannerContentWrapper>
          <IconWrapper>{iconButton}</IconWrapper>
          <BannerContent {...bannerContentProps} />
        </BannerContentWrapper>
      </BannerWrapper>
      {enableCrowdChecker && !_.isNil(occupancy) && (
        <CrowdChecker
          status={crowdCheckerStatus}
          to={crowdCheckerHref}
        />
      )}
    </>
  )
}

export default React.memo(Banner)
