import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import { useInternationalisation } from 'src/context'

const CookiesToggle = styled.div`
  background-color: ${({ ToggleOn }) => ToggleOn ? 'rgb(0, 151, 31)' : 'rgb(0, 0, 0)'};
  color: white;
  width: 80px;
  height: 30px;
  border-radius: 50px;
  overflow: hidden;
  transition: background-color 0.4s ease-in-out 0s;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
`

const CookiesToggleText = styled.span`
  position: absolute;
  ${({ ToggleOn }) => ToggleOn ? 'left' : 'right'}: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
`

const CookiesToggleInput = styled.input`
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  overflow: visible;
`

const CookiesToggleCircle = styled.span`
  position: absolute;
  background-color: white;
  left: ${({ ToggleOn }) => ToggleOn ? '45px' : '5px'};
  top: 50%;
  transform: translateY(-50%);
  height: 22px;
  width: 30px;
  border-radius: 50px;
  transition: .4s;
`


export const ToggleButton = ({ ToggleOn, handleChange }) => {
  const { translate } = useInternationalisation()
  return (
    <CookiesToggle ToggleOn={ToggleOn}>
      <CookiesToggleText ToggleOn={ToggleOn}>
        {ToggleOn ? translate('TOGGLE_SWITCH_ON_TEXT') : translate('TOGGLE_SWITCH_OFF_TEXT')}
      </CookiesToggleText>
      <CookiesToggleInput
        type="checkbox"
        value={ToggleOn}
        onChange={handleChange}
      />
      <CookiesToggleCircle ToggleOn={ToggleOn} />
    </CookiesToggle>
  )
}
