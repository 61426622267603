import camelCase from 'lodash/camelCase'
import _ from 'lodash'

let isBrowser = false
let isCordova = false
let isMobileDevice = false

if (typeof window !== 'undefined' && window) {
  isBrowser = true
  if (window.cordova) isCordova = true

  if ([ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'BlackBerry', 'webOS', 'Android' ].includes(window.navigator.platform)) {
    isMobileDevice = true
  }
}

let env = {}
for (const key in process.env) {
  const prefix = 'REACT_APP_'
  if (_.startsWith(key, prefix)) {
    env[camelCase(key.slice(prefix.length))] = process.env[key]
  }
}

if (env.fakeCordova && isBrowser) {
  isCordova = true
  window.device = {
    uuid: 'test-uuid-for-fake-cordova',
    platform: 'test-platform-for-fake-cordova',
    version: 'test-version-for-fake-cordova'
  }
  setTimeout(() => {
    document.dispatchEvent(new Event('deviceready'))
  }, 100)
}

export { isBrowser, isCordova, isMobileDevice }
export default env
