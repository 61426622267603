import React, { useCallback } from 'react'
import _get from 'lodash/get'
import _ from 'lodash'
import styled from 'styled-components'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import useWindowSize from 'react-use/lib/useWindowSize'
import { Link, withRouter } from 'src/components/RouterDom'
import { matchRoutes } from 'react-router-config'
import { useSelector, useDispatch } from 'react-redux'

import AccountIcon from 'src/assets/Account.svg'
import AssetIcon from 'src/components/AssetIcon'
import MenuIcon from 'src/assets/Menu.svg'
import MenuSvg from 'src/assets/Menu.svg'
import NavigationOverlay from './NavigationOverlay'
import SearchIcon from 'src/assets/Search.svg'
import SearchSvg from 'src/assets/Search.svg'
import BackArrowSvg from 'src/assets/Arrow-Left.svg'
import UserSvg from 'src/assets/User.svg'
import colors from 'src/styling/colors'
import routesConfig from 'src/routing/routesConfig'
import vars from 'src/styling/vars'
import { breakpoints } from 'src/styling/media'
import { closeNav, openNav, openModal } from 'src/store/ui/actionCreators'

import { isCordova } from 'src/env'
import { usePageTitle } from 'src/store/ui/hooks'
import { attributesSelector } from 'src/store/account/selectors'
import DownArrowSvg from 'src/assets/DropdownArrow.svg'
import { getFields, getAssetSrc, getAssetTitle, getEnableRegistrationLogIn } from 'src/utility'

import Banner from '../Banner'
import appTabsConfig from 'src/routing/appTabsConfig'

import { useInternationalisation } from 'src/context'

const OpeningTimesWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
`

const Nav = styled.div`
  flex: 1 1 auto;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  height: 70px;
`

const Block = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
`

const Edge = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Left = styled(Edge)`
  left: 7px;
`
const Right = styled(Edge)`
  right: 7px;
`
const Center = styled(Block)`
  left: 50%;
  transform: translate(-50%, 0%);
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PageTitle = styled.span`
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  opacity: 1;
  transition: 0.05s linear opacity;
  text-align: center;
  ${(p) => (!p.children && isCordova ? 'opacity: 0;' : '')}
  ${() => {
    // In the app we want to truncate any titlebar text that is too long.
    if (isCordova) {
      return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `
    }
  }
  }
`

const contentHeight = 40

const IconButton = styled.button`
  height: 100%;
  width: ${contentHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border: 0;
  background: transparent;
  color: currentColor;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  ${(p) =>
    p.biggerTapArea
      ? `
    width: ${contentHeight + 20}px;
    margin-left: -10px;
    margin-right: -10px;
  `
      : ''}
`

const LeftIcon = styled(AssetIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.1s linear opacity;
  opacity: ${(p) => (p.show ? 1 : 0)};
`

const LogoLink = styled(Link)`
  height: ${contentHeight}px;
  display: block;
  width: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogoImg = styled.img`
  width: 100%;
  height: auto;
  ${isCordova ? 'filter: invert(1);' : ''}
`

const Container = styled.div`
  min-height: ${vars.headerHeight.sm}px;
  min-height: calc(${vars.headerHeight.sm}px + constant(safe-area-inset-top));
  min-height: calc(${vars.headerHeight.sm}px + env(safe-area-inset-top));
  position: fixed;
  z-index: ${vars.zIndex.header};
  top: 0;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #fff;
  &[data-fixed='true'] {
    transition: 0.3s ease-in-out;
    background-color: #fff;
    color: ${colors.textColor};
    min-height: ${vars.headerHeight.sm - 13}px;
    ${Center} {
      display: none;
    }
    ${OpeningTimesWrapper} {
      background-color: #f3f6f8;
    }
  }
  ${isCordova
    ? `
    background-color: #fff;
    color: ${colors.textColor};
    border-bottom: 1px solid ${colors.lightGrey};
    ${OpeningTimesWrapper} {
      background-color: #fff !important;
    }
  `
    : ''}
`

const MobileHeader = (props) => {
  const pathname = _get(props, 'location.pathname')
  const { translate } = useInternationalisation()
  const [routePath, showMenu] = React.useMemo(() => {
    if (!isCordova) return ['', true]
    const routePath = _get(
      matchRoutes(routesConfig[0].routes, pathname),
      '0.route.path'
    )
    let showMenu = !!_.find(appTabsConfig, (x) => x.to === routePath)

    // Mobile header only - force burger menu if the path is /shop-and-dine
    if (isCordova && pathname === '/shop-and-dine') {
      showMenu = true
    }

    return [routePath, showMenu]
  }, [pathname])

  let pageTitle = usePageTitle()
  if (pageTitle === '') {
    if (_.startsWith(routePath, '/news')) {
      pageTitle = _.upperFirst(translate('NEWS'))
    } else if (_.startsWith(routePath, '/events')) {
      pageTitle = _.upperFirst(translate('EVENT'))
    } else if (_.startsWith(routePath, '/offers')) {
      pageTitle = _.upperFirst(translate('OFFER'))
    } else if (_.startsWith(routePath, '/competitions')) {
      pageTitle = _.upperFirst(translate('COMPETITION'))
    } else if (_.startsWith(routePath, '/promotions')) {
      pageTitle = _.upperFirst(translate('PROMOTION'))
    }
  }

  const { y } = useWindowScroll()

  const fixedOffset = 25
  const isFixed = y > fixedOffset

  const { centre, history, crisis } = props
  const { logo } = getFields(centre)
  const enableRegistrationLogIn = getEnableRegistrationLogIn(centre)

  const updateFixedStatus = useCallback((node) => {
    if (node !== null) {
      node.dataset.fixed = y > fixedOffset
    }
  }, [])

  const dispatch = useDispatch()
  const openOverlay = useCallback(() => {
    dispatch(openNav())
  }, [])

  const search = useCallback(() => {
    dispatch(openModal({ id: 'search' }))
  }, [])

  const { width } = useWindowSize()

  const attributes = useSelector(attributesSelector())

  return (
    <Container ref={updateFixedStatus} data-fixed={isFixed || isCordova}>
      <Banner centre={centre} crisis={crisis} />
      <Nav>
        <Left>
          <IconButton
            onClick={showMenu ? openOverlay : history.goBack}
            biggerTapArea
          >
            <LeftIcon
              aria-label={translate('MENU')}
              size={22}
              svg={MenuSvg}
              show={showMenu}
            />
            <LeftIcon
              aria-label={translate('BACK')}
              svg={DownArrowSvg}
              size={24}
              style={{ transform: `rotate(90deg) translate(-50%, 50%)` }}
              show={!showMenu}
            />
          </IconButton>
        </Left>
        <Center>
          {!isCordova || routePath === '/' ? (
            <LogoLink to={'/'}>
              <LogoImg src={getAssetSrc(logo)} alt={getAssetTitle(logo)} />
            </LogoLink>
          ) : (
              <PageTitle>{pageTitle}</PageTitle>
            )}
        </Center>
        <Right>
          {enableRegistrationLogIn && <IconButton as={Link} to={attributes ? '/account' : '/login'}>
            <AssetIcon
              aria-label={`${translate('LOGIN')} / ${translate('SIGN_UP')}`}
              size={22}
              svg={UserSvg}
            />
          </IconButton>}
          <IconButton onClick={search}>
            <AssetIcon
              aria-label={translate('SEARCH')}
              size={22}
              svg={SearchSvg}
            />
          </IconButton>
        </Right>
      </Nav>
      {(width && width <= breakpoints.header) || isCordova ? (
        <NavigationOverlay />
      ) : null}
    </Container>
  )
}

export default withRouter(MobileHeader)
