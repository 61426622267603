import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Container from './container'
import ReactCrop from 'react-image-crop'

const Cropper = ({ image, crop, setCrop, onImageLoaded, onComplete }) => {
  const [imageHeight, setImageHeight] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)
  return (
    <Container>
      <ReactCrop
        crossorigin={null}
        keepSelection
        src={image}
        crop={crop}
        minWidth={101}
        minHeight={101}
        onChange={(c) => setCrop({ ...c, imageHeight, imageWidth })}
        onImageLoaded={(image) => {
          setImageWidth(image.width)
          setImageHeight(image.height)
          onImageLoaded(image)
        }}
        onComplete={onComplete}
      />
    </Container>
  )
}

export default Cropper
