import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _lowerCase from 'lodash/lowerCase'
import _capitalize from 'lodash/capitalize'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import { useInternationalisation } from 'src/context'
import { createFormatTime } from 'src/utility'
import { getCentreConfig } from 'src/config'

const Time = styled.li`
  color: ${colors.textColor};
  font-size: 16px;
  font-weight: ${(p) => (p.bold ? 700 : 300)};
  line-height: 1.5;
  list-style-type: none;
  padding-bottom: 5px;
`
const Listing = styled.ol`
  padding-bottom: 23px;
`

const getWeekDaysArray = (translate) => [
  { key: 'mon', label: translate('DAY_MONDAY') },
  { key: 'tue', label: translate('DAY_TUESDAY') },
  { key: 'wed', label: translate('DAY_WEDNESDAY') },
  { key: 'thu', label: translate('DAY_THURSDAY') },
  { key: 'fri', label: translate('DAY_FRIDAY') },
  { key: 'sat', label: translate('DAY_SATURDAY') },
  { key: 'sun', label: translate('DAY_SUNDAY') }
]

const OpeningTimes = (props) => {
  const { data } = props
  const { translate, moment, defaultLocale } = useInternationalisation()
  const { timeFormat = {} } = getCentreConfig() || {}

  const weekDays = getWeekDaysArray(translate)
  const formatTime = createFormatTime({ moment })({
    originFormat: 'HH:mm',
    outputFormat: timeFormat.storeOpeningTime
  })
  const todayKey = _lowerCase(moment().locale('en').format('ddd'))
  moment().locale(defaultLocale)
  return (
    <Listing>
      {weekDays.map((day) => {
        const { openingTime, closingTime, closed, allDay } = _get(data, day.key) || {}
        if (!openingTime && !closingTime && !allDay) {
          return null
        }
        let text = [_capitalize(day.label)]
        if (closed) {
          text.push(translate('CLOSED'))
        } else if (allDay) {
          text.push(translate('OPEN_24HOURS'))
        } else {
          text.push(formatTime(openingTime))
          text.push(formatTime(closingTime))
        }
        return (
          <Time
            key={day.key}
            children={text.join(' - ')}
            bold={day.key === todayKey}
          />
        )
      })}
    </Listing>
  )
}

export default OpeningTimes
