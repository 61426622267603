import React, { Fragment } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import _get from 'lodash/get'
import _map from 'lodash/map'

import media from 'src/styling/media'
import vars from 'src/styling/vars'

import NotFound from 'src/pages/NotFound'

import Container from 'src/components/Container'
import Box from 'src/components/Box'
import PageBanner from 'src/components/PageBanner'
import { useRequest } from 'src/store/resources/hooks'
import { fetchResources } from 'src/store/resources/actionCreators'

import BackBar from 'src/components/BackBar'
import Spacing from 'src/components/Spacing'
import { getFields, getIdFromParams, getAssetSrc, getFirstResourceFields, getFirstResource } from 'src/utility'
import contentfulService from 'src/services/contentfulService'
import { ResourceTile } from 'src/components/Tile'
import { useInternationalisation } from 'src/context'
import Head from 'src/components/Head'
import Button from 'src/components/Button'

import { plainList } from 'src/styling/styles'

const marginTop = 167
const tileSize = 265

const Content = styled.div``

const JobDetailItem = styled.li`
  padding-bottom: 20px;
  line-height: 1.2;
  span {
    font-size: 16px;
    &:first-of-type {
      font-weight: 700;
      padding-right: 5px;
    }
    &:last-of-type {
      font-weight: 300;
    }
  }
`

const JobDetails = styled.ul`
  padding-top: 10px;
  padding-bottom: 0;
  padding-left: 0;
  ${plainList}
`

const JobDetailsWrapper = styled.div`
  max-width: 100%;
`

const ShopDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
`

const H2 = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: 27px;
`

const Inner = styled.div`
  width: 100%;
`

const renderJobDetails = (job, { translate, moment }) => {
  const {
    retailUnit,
    jobTitle,
    applicationCloseDate,
    contractType,
    hours,
    salary
  } = getFields(job)

  const detailsArray = [
    { label: translate('JOB_RETAILER'), value: getFields(retailUnit).name },
    { label: translate('JOB_TITLE'), value: jobTitle },
    {
      label: translate('JOB_CLOSING_DATE'),
      value: applicationCloseDate
        ? moment(applicationCloseDate).format('DD/MM/YYYY')
        : null
    },
    { label: translate('JOB_PERM_TEMP'), value: contractType },
    { label: translate('JOB_FULL_PART'), value: hours },
    { label: translate('JOB_SALARY'), value: salary }
  ]
  return (
    <JobDetails>
      {_map(detailsArray, ({ label, value }) => {
        return value && label ? (
          <JobDetailItem key={label}>
            <span>{`${label}${translate('COLON')}`}</span>
            <span>{value}</span>
          </JobDetailItem>
        ) : null
      })}
    </JobDetails>
  )
}

const resourceType = 'job'

const Job = (props) => {
  const { translate, moment } = useInternationalisation()
  const id = getIdFromParams(props)
  const request = useRequest({
    resourceType,
    requestKey: id
  })
  const job = getFirstResource(request)
  const {
    jobTitle,
    retailUnit,
    jobDescription,
    howToApply,
    applicationCloseDate,
    description,
    isJobDownloadAllowed,
    checkAttachmentExists
  } = getFirstResourceFields(request)

  const { headerImage, headerImageMobile } = getFields(retailUnit)
  const isExpired = moment(applicationCloseDate).isBefore(moment())

  if (!job && !request._status.pending) {
    return <NotFound />
  }

  return (
    <Fragment>
      <Head
        resource={job}
        title={jobTitle}
      />
      <PageBanner text={jobTitle} bannerMediaDesktop={headerImage} bannerMediaMobile={headerImageMobile} />
      <Container bottomGutter>
        <BackBar
          link={`/jobs`}
          text={translate('BACK_TO_LISTING', { context: 'job' })}
        />
        <Box>
          <Inner>
            <Content>
              <Spacing height={10} />
              {job ? (
                <React.Fragment>
                  <JobDetailsWrapper>
                    {renderJobDetails(job, { translate, moment })}
                  </JobDetailsWrapper>
                  <Spacing height={30} />
                </React.Fragment>
              ) : null}
              <H2>{translate('JOB_DESCRIPTION')}{translate('COLON')}</H2>
              {contentfulService.renderRichText(jobDescription)}
              {!isExpired ? (
                <React.Fragment>
                  <H2>{translate('JOB_APPLY')}</H2>
                  {contentfulService.renderRichText(howToApply)}
                  {isJobDownloadAllowed && checkAttachmentExists ? (
                    <Button
                      {...job}
                      buttonType='primary'
                      onClick={() => {
                        let ctaDownloadUrl = getAssetSrc(
                          _.get(job, 'fields.jobAttachment')
                        )
                        return window.open(ctaDownloadUrl, '_blank')
                      }}
                    >
                      {translate('JOB_DOWNLOAD')}
                    </Button>
                  ) : null}
                </React.Fragment>
              ) : (
                  <React.Fragment>{translate('JOB_DEADLINE_END')}</React.Fragment>
                )}
              <ShopDetailsWrapper>
                <ResourceTile resource={retailUnit} />
              </ShopDetailsWrapper>
            </Content>
          </Inner>
        </Box>
      </Container>
    </Fragment>
  )
}

Job.getData = async (props) => {
  const { dispatch, host, locale } = props
  const id = getIdFromParams(props)
  return dispatch(
    fetchResources({
      requestKey: id,
      locale,
      where: { 'sys.id': id },
      host,
      resourceType,
      include: 3
    }))
}
Job.identifier = 'JOB'

export default Job