

import { AbstractAuthService } from './abstractAuthService'
import { getCentreConfig } from 'src/config'
import _at from 'lodash/at'

const FACEBOOK_LOG_IN_STATUSES = {
    CONNECTED: 'connected'
}
class FacebookAuthService extends AbstractAuthService {
    initialise = async () => {
        const [appName, faceBookAppId] = _at(getCentreConfig(), ['centreName', 'facebookClientId'])
        if (!window.facebookConnectPlugin) {
            return false
        }
        await new Promise((resolve) => window.facebookConnectPlugin.setApplicationId(faceBookAppId, resolve))
        await new Promise((resolve) => window.facebookConnectPlugin.setApplicationName(appName, resolve))
        this.isInitialised = true
    }
    logIn = async () => {
        await this.attemptLogOut()
        await new Promise((resolve, reject) => {
            window.facebookConnectPlugin.login(['email', 'public_profile'], resolve, reject)
        })
        const token = await new Promise((resolve, reject) => {
            window.facebookConnectPlugin.getAccessToken(resolve, reject)
        })
        return { token }
    }
    /**
     * Facebook can give a weird error if its' connected but you try to log in again.
     */
    attemptLogOut = async () => {
        if (this.isInitialised !== true) {
            return
        }
        const statusResponse = await new Promise((resolve, reject) => {
            const force = true
            window.facebookConnectPlugin.getLoginStatus(force, resolve, reject)
        })
        if (statusResponse.status === FACEBOOK_LOG_IN_STATUSES.CONNECTED) {
            await new Promise((resolve, reject) => {
                window.facebookConnectPlugin.logout(resolve, reject)
            })
        }
    }
    authenticate = this.authenticateHelper(async (session) => {
        return {
            providerId: 'facebook',
            accessToken: session.token
        }
    })
}
export default new FacebookAuthService()
