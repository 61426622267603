import { isCordova } from 'src/env'

import actionTypes from './actionTypes'

const { SET_DEVICE } = actionTypes

let initialState = {}

const deviceReducer = (state, action) => {
  const { type, payload } = action
  if (type === SET_DEVICE) {
    return {
      ...state,
      ...payload
    }
  } else {
    return state || initialState
  }
}

export default deviceReducer
