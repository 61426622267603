import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'

import FieldBase from './common/FieldBase'
import InputBase from './common/InputBase'

const renderField = (props) => {
  return <InputBase {...props} />
}

const Text = (props) => {
  return <FieldBase {...props} renderField={renderField} />
}

export default Text
