import React from 'react'

const usePromiseState = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState()

  const wrapPromise = React.useCallback((promise) => {
    setIsLoading(true)
    return promise
      .then((x) => {
        setIsLoading(false)
        return x
      })
      .catch((err) => {
        setIsLoading(false)
        setError(err)
      })
  }, [])

  return { isLoading, error, wrapPromise }
}

export default usePromiseState
