import React from 'react'
import vars from 'src/styling/vars'
import styled from 'styled-components'

const SectionWrapper = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -${0.5 * vars.tileGutter}px;

  & > li {
    margin: ${0.5 * vars.tileGutter}px;
  }
`

export default SectionWrapper
