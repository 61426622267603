import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { getCentreConfig } from 'src/config'
import env from 'src/env'

import FieldBase from './common/FieldBase'

const siteKeys = {
  local: '6LcTr6sUAAAAAMpQJXW6Om4fhzilGXks0wAIXjpW',
  qa: '6LfqrqsUAAAAAG7KCfaoBG3kNiaEmeMkM-HrPM-_',
  uat: '6LfqrqsUAAAAAG7KCfaoBG3kNiaEmeMkM-HrPM-_',
  staging: '6LfqrqsUAAAAAG7KCfaoBG3kNiaEmeMkM-HrPM-_',
  prod: '6LfqrqsUAAAAAG7KCfaoBG3kNiaEmeMkM-HrPM-_'
}

const RecaptchaThing = (props) => {
  const { setFieldValue, name } = props
  const [mounted, setMounted] = React.useState(false)
  React.useEffect(() => setMounted(true), [])

  const siteKey = siteKeys[env.envKey]
  return mounted ? (
    <ReCAPTCHA
      sitekey={siteKey}
      onChange={(value) => {
        if (value !== null) {
          setFieldValue(name, value)
        } else {
          setFieldValue(name, '')
        }
      }}
    />
  ) : null
}

const renderField = (props) => {
  return <RecaptchaThing {...props} />
}

const RecaptchaField = (props) => {
  return <FieldBase {...props} renderField={renderField} />
}

export default RecaptchaField
