import _ from 'lodash'
import apiService from 'src/services/apiService'
import env, { isBrowser, isCordova } from 'src/env'

class ImageUploadService {
  _imageTypes = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif'
  }

  _loadBlobWeb = (localImageURL) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', localImageURL, true)
      xhr.responseType = 'blob'
      xhr.onload = () => resolve(xhr.response)
      xhr.onerror = () => reject(xhr.statusText)
      xhr.send()
    })
  }

  _loadBlobCordova = (localImageURL) => {
    return new Promise((resolve, reject) => {
      window.resolveLocalFileSystemURL(localImageURL, (fileEntry) => {
        fileEntry.file((file) => {
          var reader = new FileReader()
          reader.onloadend = function() {
            var blob = new Blob([new Uint8Array(this.result)], {
              type: 'image/jpeg'
            })
            resolve(blob)
          }
          reader.readAsArrayBuffer(file)
        })
      })
    })
  }

  getFileBlob = (localImageURL) => {
    if (env.fakeCordova) {
      return this._loadBlobWeb(localImageURL)
    }
    return this._loadBlobCordova(localImageURL)
  }

  openImagePickerLibrary = (fileSelectedCordova, filepicker) => {
    if (isCordova && !env.fakeCordova) {
      return navigator.camera.getPicture(fileSelectedCordova, _.noop, {
        quality: 25,
        destinationType: window.Camera.DestinationType.FILE_URI,
        sourceType: window.Camera.PictureSourceType.PHOTOLIBRARY,
        encodingType: window.Camera.EncodingType.JPEG,
        mediaType: window.Camera.MediaType.PICTURE,
        allowEdit: true,
        correctOrientation: true,
        targetWidth: 400,
        targetHeight: 400
      })
    } else {
      const webInput = _.get(filepicker, 'current')
      if (webInput) {
        webInput.click()
      }
    }
  }

  openImagePickerCamera = (fileSelectedCordova) => {
    if (isCordova && !env.fakeCordova) {
      return navigator.camera.getPicture(fileSelectedCordova, _.noop, {
        quality: 25,
        destinationType: window.Camera.DestinationType.FILE_URI,
        sourceType: window.Camera.PictureSourceType.CAMERA,
        encodingType: window.Camera.EncodingType.JPEG,
        mediaType: window.Camera.MediaType.PICTURE,
        allowEdit: true,
        correctOrientation: true,
        targetWidth: 400,
        targetHeight: 400
      })
    }
  }
}

export default new ImageUploadService()
