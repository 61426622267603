import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _pick from 'lodash/pick'

import FieldBase from './common/FieldBase'
import {
  leftPadding,
  verticalPadding,
  fontSize,
  lineHeight
} from './common/vars'
import getInputProps from './common/getInputProps'
import colors from 'src/styling/colors'

const Wrapper = styled.div`
  text-align: left;
  padding: 13px 0 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
`

const outerCircleSize = 30
const innerCircleSize = 20

const InnerCircle = styled.div`
  width: ${innerCircleSize}px;
  height: ${innerCircleSize}px;
`

const OuterCircle = styled.div`
  width: ${outerCircleSize}px;
  height: ${outerCircleSize}px;
  margin-top: ${-0.5 * (outerCircleSize - lineHeight)}px;
  margin-bottom: ${-0.5 * (outerCircleSize - lineHeight)}px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${colors.darkGrey};
  margin-right: ${leftPadding - outerCircleSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  & > div {
    opacity: 0;
  }
  input:checked ~ & > div {
    opacity: 1;
  }
`

const CheckboxInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  :focus ~ ${OuterCircle} {
    outline: 3px solid #7aacfe;
  }
`

const Label = styled.span`
  font-size: ${fontSize}px;
  font-weight: bold;
  line-height: ${lineHeight}px;
  text-transform: uppercase;
  flex-shrink: 1;
`

const renderField = (props) => {
  const { label, name } = props
  const inputProps = getInputProps(props)
  const { value, ...restInputProps } = inputProps

  const radioProps = {
    ...restInputProps,
    type: 'radio',
    id: name,
    name,
    checked: value
  }
  return (
    <Wrapper>
      <CheckboxInput {...radioProps} />
      <OuterCircle>
        <InnerCircle>
          <svg width='30px' height='30px'>
            <circle cx='10' cy='10' r='10' fill={colors.darkGrey} />
          </svg>
        </InnerCircle>
      </OuterCircle>
      <Label>{label}</Label>
    </Wrapper>
  )
}

const Radio = (props) => {
  return <FieldBase {...props} noLabel renderField={renderField} />
}

export default Radio
