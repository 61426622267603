import React from 'react'
import { Link } from 'src/components/RouterDom'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import media from 'src/styling/media'
import SmallArrowLeft from 'src/assets/SmallArrowLeft.svg'
import { useInternationalisation } from 'src/context'

const BackBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  ${media.forEach({
    values: { sm: 20, md: 20, lg: 25 },
    getStyle: (val) => `padding: ${val}px 0`
  })};
`

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.textColor};
`

const ArrowLeft = styled(SmallArrowLeft)`
  margin-right: 5px;
`

const BackBar = ({ link, text }) => {
  const { translate } = useInternationalisation()
  return (
    <BackBarContainer>
      {link && (
        <BackLink to={link}>
          <ArrowLeft />
          {text || translate('BACK')}
        </BackLink>
      )}
    </BackBarContainer>
  )
}

export default BackBar
