import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import { useInternationalisation } from 'src/context'
import PlusLogoLightSvg from 'src/assets/PlusLogoLight.svg'
import DateTime from 'src/components/DateTime'
import AssetIcon from 'src/components/AssetIcon'

const Badge = styled.div`
  position: absolute;
  padding: 8px;
  font-size: ${({ size }) => size || 12}px;
  font-weight: 500;
  letter-spacing: -0.43px;
  line-height: 1.25;
  color: ${colors.white};
  background-color: ${({ transparent }) =>
    transparent ? colors.darkGreyTransparent : colors.darkGrey};
`

export const TopLeftBadge = styled(Badge)`
  top: 10px;
  left: 0;
`

export const BottomRightBadge = styled(Badge)`
  bottom: 10px;
  right: 10px;
  position: ${({ position }) =>
    position === 'relative' ? 'relative' : 'absolute'};
`

const PlusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > div {
    margin: 0px 5px 0px 10px;
  }
`

export const DateTimeBadge = (props) => {
  return (
    <TopLeftBadge>
      <DateTime {...props} />
    </TopLeftBadge>
  )
}

export const PlusExclusiveBadge = ({ size = 15, position, transparent }) => {
  const { translate } = useInternationalisation()

  return (
    <BottomRightBadge size={size} position={position}>
      <PlusWrapper>
        {translate('PLUS_EXCLUSIVE')}
        <AssetIcon svg={PlusLogoLightSvg} size={size} />
      </PlusWrapper>
    </BottomRightBadge>
  )
}
