import React from 'react'
import _get from 'lodash/get'
import contentfulService from 'src/services/contentfulService'
import { getFields } from 'src/utility'
import Box from 'src/components/Box'
import styled, { ThemeProvider } from 'styled-components'
import H2 from 'src/components/H2'
import { darkTheme } from 'src/styling/themes'
import { useInternationalisation } from 'src/context'
import Button from 'src/components/Button'

const ScrollArea = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
  height: 60vh;
`

const Title = styled.div`
  padding-right: 10px;
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  button {
    margin: 10px 10px 0px;
  }
`

const TermsAndConditions = ({ resource, onAccept, onDecline }) => {
  const { competitionTcsLink: termsAndConditionsPage = {} } = getFields(
    resource
  )
  const { translate } = useInternationalisation()
  const content = getFields(termsAndConditionsPage)
  return (
    <ThemeProvider theme={darkTheme}>
      <React.Fragment>
        <Title>
          <H2>{translate('TERMS_AND_CONDITIONS')}</H2>
        </Title>
        <Box rounded>
          <ScrollArea>
            {contentfulService.renderRichText(content.body)}
          </ScrollArea>
          <Buttons>
            {[
              {
                title: translate('DECLINE'),
                buttonType: 'secondary',
                onClick: onDecline
              },
              {
                title: translate('ACCEPT'),
                buttonType: 'primary',
                onClick: onAccept
              }
            ].map(({ title, ...props }) => {
              return <Button {...props}>{title}</Button>
            })}
          </Buttons>
        </Box>
      </React.Fragment>
    </ThemeProvider>
  )
}

export default TermsAndConditions
