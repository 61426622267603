import _ from 'lodash'

import key from './key'

const eventsAwaitingUploadSelector = _.once(() => (state) => {
  return _.get(state, [key, 'events', 'awaitingUpload']) || []
})
const eventsUploadedSelector = _.once(() => (state) => {
  return _.get(state, [key, 'events', 'uploaded']) || []
})

export { eventsAwaitingUploadSelector, eventsUploadedSelector }
