import styled from 'styled-components'
import media from 'src/styling/media'
import colors from 'src/styling/colors'

const H2 = styled.h2`
  width: 100%;
  max-width: 100%;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  padding-top: 0;
  padding-bottom: 10px;
  color: ${({ theme }) => (theme ? theme.color : colors.textColor)};
  ${media.greaterThan('md')`
   font-size:  ${({ small }) => (small ? '20px' : '45px')};
    letter-spacing: -1.7px;
  `}
  ${media.greaterThan('lg')`
    font-size: ${({ small }) => (small ? '25px' : '50px')};;
    letter-spacing: -1.7px;
  `}
`

export default H2
