import actionTypes from './actionTypes'

const setFile = (payload) => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_FILE
    dispatch({ type, payload })
  }
}

const setFileUrl = (payload) => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_FILE_URL
    dispatch({ type, payload })
  }
}

const setCategory = (payload) => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_CATEGORY
    dispatch({ type, payload })
  }
}

const setWeights = (payload) => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_WEIGHTS
    dispatch({ type, payload })
  }
}

const setCrop = (payload) => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_CROP
    dispatch({ type, payload })
  }
}

const setSimilar = (payload) => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_SIMILAR_PRODUCTS
    dispatch({ type, payload })
  }
}

const clear = () => {
  return (dispatch, getState) => {
    const type = actionTypes.CLEAR
    dispatch({ type })
  }
}

const setIsCropped = () => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_IS_CROPPED
    dispatch({ type })
  }
}

const openFiltersMenu = () => {
  return (dispatch, getState) => {
    const type = actionTypes.IS_FILTERS_MENU_OPEN
    dispatch({ type, payload: { isFiltersMenuOpen: true } })
  }
}

const closeFiltersMenu = () => {
  return (dispatch, getState) => {
    const type = actionTypes.IS_FILTERS_MENU_OPEN
    dispatch({
      type,
      payload: { isFiltersMenuOpen: false }
    })
  }
}

const setIsLoading = (payload) => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_IS_LOADING
    dispatch({ type, payload })
  }
}

const setSelectedProduct = (payload) => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_SELECTED_PRODUCT
    dispatch({ type, payload })
  }
}

const setCurrentPath = (payload) => {
  return (dispatch, getState) => {
    const type = actionTypes.SET_CURRENT_PATH
    dispatch({ type, payload })
  }
}

export {
  setFile,
  setFileUrl,
  setCategory,
  setWeights,
  setCrop,
  setSimilar,
  setIsLoading,
  setSelectedProduct,
  clear,
  setIsCropped,
  openFiltersMenu,
  closeFiltersMenu,
  setCurrentPath
}
