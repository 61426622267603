import { useEffect } from 'react'
import _ from 'lodash'
import { getCentreConfig } from 'src/config'
import { isBrowser } from 'src/env'
import { logTrace } from 'src/utility'

const useFacebookPixel = (run = false) => {
  if (!isBrowser) {
    return false
  }
  const centreConfig = getCentreConfig() || {}
  const pixelId = _.get(centreConfig, 'facebookPixelId')
  if (!pixelId || _.isEmpty(pixelId)) {
    return false
  }

  useEffect(() => {
    if (!isBrowser) {
      return false
    }
    // due to an issue with this module loading the window in ssr
    // we import dynamically here:
    import('react-facebook-pixel')
      .then(module => module.default)
      .then(ReactPixel => {
        const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: true, // enable logs
        };
        ReactPixel.init(pixelId, advancedMatching, options)
        logTrace(`initialising Facebook Pixel with id ${pixelId}`)

        if (run) {
          logTrace(`consent granded for Facebook Pixel with id ${pixelId}`)
          ReactPixel.grantConsent()
          ReactPixel.pageView() // For tracking page view
        } else {
          logTrace(`consent revoked for Facebook Pixel with id ${pixelId}`)
          ReactPixel.revokeConsent()
        }
    })

  }, [isBrowser, run])
  return run
}

export default useFacebookPixel
