import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import vars from 'src/styling/vars'
import colors from 'src/styling/colors'
import Spinner from 'src/components/Spinner'
import Head from 'src/components/Head'
import { useInternationalisation } from 'src/context'

const LoadingScreen = (props) => {
  const { translate } = useInternationalisation()

  return (
    <>
      <Head
        title={translate('PAGE_STYLE_SEEKER')}
      />
      <div
        isLoading={true}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: `calc(100vh - 15px - ${vars.appTabBarHeight}px)`,
          minWidth: 0,
          verticalAlign: 'bottom',
          transition: 'width 0.2s, opacity 0.5s'
        }}
      >
        <div>
          <Spinner size={40} color={colors.textColor} />
        </div>
      </div>
    </>
  )
}

export default LoadingScreen
