import _pick from 'lodash/pick'
import uiConstants from './constants'
import uiKey from './key'

const initialState = {
  [uiConstants.MODAL_ID]: null,
  [uiConstants.IS_MODAL_OPEN]: false,
  [uiConstants.IS_NAV_OPEN]: false,
  [uiConstants.NAV_TAB_INDEX]: 0,
  [uiConstants.SHOW_ONBOARDING]: true,
  [uiConstants.PUSH_PROMPT_CHECKED]: false
}

const uiConstantsArr = Object.values(uiConstants)

const uiReducer = (state, action) => {
  const { type, ...payload } = action
  if (type === uiKey) {
    const changedState = _pick(payload, uiConstantsArr)
    return { ...state, ...changedState }
  } else {
    return state || initialState
  }
}

export default uiReducer
