import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import get from 'lodash/get'
import { getCentreConfig } from 'src/config'
import _get from 'lodash/get'
import { isBrowser } from '../env'
import { ssrParse, ssrStringify, getBestLocale, logTrace } from 'src/utility'

const withPageData = (Component) => (props) => {
  const { match, location, staticContext, dispatch } = props
  const dataFromStaticContext = get(staticContext, [
    'data',
    Component.identifier
  ])

  let dataFromWindow
  try {
    if (isBrowser && window.__REACT_DATA__) {
      const allWindowData = ssrParse(window.__REACT_DATA__)
      dataFromWindow = allWindowData[Component.identifier]
      if (dataFromWindow) delete allWindowData[Component.identifier]
      window.__REACT_DATA__ = Object.keys(allWindowData).length
        ? ssrStringify(allWindowData)
        : undefined
    }
  } catch (err) {
    console.log(err)
  }

  const initialData = dataFromStaticContext || dataFromWindow

  const [data, setData] = useState(initialData)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()
  // useful for knowing if getData is being called for the 1st or 2nd+ time
  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(
    () => {
      if (Component.getData && !initialData) {
        logTrace(`getting data... [${Component.identifier}]`)
        setIsLoading(true)
        setData(undefined)
        Component.getData({
          match,
          location,
          dispatch,
          hasLoaded,
          locale: 'en-GB'
        })
          .then((dataResult) => {
            setData(dataResult)
            setIsLoading(false)
            setHasLoaded(true)
          })
          .catch((err) => {
            logTrace(`failed to get data [${Component.identifier}]`, err)
            setError(err)
            setIsLoading(false)
          })
      }
    },
    Component.getData && Component.getData.length > 0
      ? [match && match.url, match.path !== '/' ? location.search : '']
      : []
  )

  const dataProps = { data, isLoading, error }
  return <Component {...dataProps} {...props} />
}

export default compose(
  connect(),
  withPageData
)
