import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import uiConstants from './constants'
import uiSelector from './selector'
import { setPageTitle, setPageMeta } from './actionCreators'
import { useDeepCompareEffect } from 'react-use'
import _isObject from 'lodash/isObject'
import fp from 'lodash/fp'
import { useInternationalisation } from 'src/context'

export const usePageTitle = () =>
  useSelector(uiSelector(uiConstants.PAGE_TITLE))

export const usePageMeta = () => useSelector(uiSelector(uiConstants.META))

export const setFactory = (setHook) => (value) => {
  const dispatch = useDispatch()
  const useEffect = _isObject(value) ? useDeepCompareEffect : React.useEffect
  useEffect(() => {
    dispatch(setHook(value))
  }, [value])
}

export const useSetPageTitle = setFactory(setPageTitle)
export const useSetPageMeta = setFactory(setPageMeta)


export const useInternationalisedAppStoreAssets = () => {
  const internationalisation = useInternationalisation()
  const localeCode = fp.flow(
    fp.get('locale'),
    fp.split('-'),
    fp.first
  )(internationalisation)
  const [data, setData] = useState({});
  const assets = {
    AppStorePNG: 'apple-store-badge.png',
    GooglePlayPNG: 'google-play-badge.png'
  }
  useEffect(() => {
    (async () => {
      const fulfilledAssets = {}
      for (const asset in assets) {
        const vendorAsset = assets[asset]
        try {
          fulfilledAssets[asset] = (await import(`../../assets/appStore/${localeCode}/${vendorAsset}`)).default
        } catch (error) {
          fulfilledAssets[asset] = (await import(`../../assets/appStore/en/${vendorAsset}`)).default
        }
      }
      setData(fulfilledAssets)
    })()
  }, [localeCode])

  return data
}

export const useMapJourneyDuration = () =>
useSelector(uiSelector(uiConstants.MAP_JOURNEY_DURATION))