import React from 'react'
import _get from 'lodash/get'
import styled from 'styled-components'
import { withRouter } from 'src/components/RouterDom'
import { matchRoutes } from 'react-router-config'

import Mobile from './Mobile'
import Desktop from './Desktop'
import media from 'src/styling/media'
import Show from 'src/components/Show'
import { isCordova } from 'src/env'

const Header = (props) => {
  return (
    <header>
      {isCordova ? (
        <Mobile {...props} />
      ) : (
        <React.Fragment>
          <Show gt='header'>
            <Desktop {...props} />
          </Show>
          <Show lt='header'>
            <Mobile {...props} />
          </Show>
        </React.Fragment>
      )}
    </header>
  )
}

export default withRouter(Header)
