import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'
/**
 * @type {function(): void} Install the Critizr Web SDK to display Web button "Send Feedback"
 * [reference](https://developers.critizr.com/docs/web-button-send-feedback#installation)
 */
const CritizrEmbed = (props) => {
    const { critizrApiKey } = props
    return (
        <Helmet 
            script={[{ 
                type: 'text/javascript', 
                innerHTML: `(function (i, s, o, g, r, a, m) {
                    i[r] = i[r], a = s.createElement(o), m = s.getElementsByTagName(o)[0];
                    a.async = 1;
                    a.src = g;
                    m.parentNode.insertBefore(a, m)
                })(window, document, 'script', 'https://static.critizr.com/widgets/${critizrApiKey}.js', 'cz');`
            }]} 
        />
    )
}

export default CritizrEmbed