import _ from 'lodash';

import { SUCCESS, PENDING, FAIL } from '../reduxPromise';

class ShopperTrakReducers {
  readOccupancy = (state, action) => {
    if (action.status !== SUCCESS || !action.result) {
      return { ...state }
    }
    return {
      ...state,
      ...action.result
    }
  }
}

export default new ShopperTrakReducers()