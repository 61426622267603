import renderRichText from './renderRichText'
import { fetchResource, fetchResources } from './resources'

const contentfulService = {
  renderRichText,
  fetchResource,
  fetchResources
}

export default contentfulService
