import React from 'react'
import styled, { keyframes } from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _flow from 'lodash/flow'
import _reject from 'lodash/reject'
import _uniqueId from 'lodash/uniqueId'
import _pickBy from 'lodash/pickBy'
import _upperFirst from 'lodash/upperFirst'
import media from 'src/styling/media'
import colors from 'src/styling/colors'
import {
  getFirstResourceFields,
  getFields,
  getUrl,
  getAssetSrc,
  getParentCategoryAlias
} from 'src/utility'
import _groupBy from 'lodash/groupBy'

import { Link } from 'src/components/RouterDom'
import PromotionCard from 'src/components/PromotionCard'

import _castArray from 'lodash/castArray'
import _camelCase from 'lodash/camelCase'
import _find from 'lodash/find'
import _size from 'lodash/size'
import _isEmpty from 'lodash/isEmpty'
import _ from 'lodash'
import { useRequest } from 'src/store/resources/hooks'
import { useNav } from 'src/pages/Root/Header/hooks'

const promotionKeyPrefix = 'navigationPromotedContent'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  ${media.greaterThan('md')`
    flex-direction: row;
  `}
`

const Content = styled.div`
  width: 100%;
  ${media.greaterThan('md')`
    width: 50%;
 `}
`

const Categories = styled(Content)`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`

const CategoriesInner = styled.div`
  margin: auto;
`

const Promotions = styled(Content)`
  height: 100%;
  display: none;
  ${media.greaterThan('md')`
    display: flex;
 `}
`

const CategoryList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none outside;
  text-align: center;
  & + & {
    &:before {
      content: '';
      display: block;
      max-width: 235px;
      width: 100%;
      height: 4px;
      background-color: ${colors.primary};
      overflow: hidden;
      font-size: 0;
      line-height: 0;
      margin: 0 auto;
    }
  }
  li:first-child {
    padding-top: 20px;
  }
  li:last-child {
    padding-bottom: 20px;
  }
`

const placeHolderShimmer = keyframes`
  0%{ background-position: 100% 0 }
  100%{ background-position: -100% 0 }
`

const CategoryLink = styled(Link)`
  display: block;
  font-weight: 700;
  color: ${colors.primary};
  font-size: 32px;
  text-decoration: none;
  padding: 5px;
  &:before {
    content: '';
    display: none;
    animation: 2.5s ${placeHolderShimmer} forwards infinite;
    background-image: linear-gradient(
      to right,
      #f6f6f6 8%,
      #f0f0f0 18%,
      #f6f6f6 33%
    );
    background-color: #f6f6f6;
    background-size: 200% 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
  &:hover {
    color: ${colors.red};
    ${media.greaterThan('md')`
      [data-image], &:before {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
        }
      `}
  }
  [data-image] {
    display: none;
    z-index: 2;
    span {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`

const PromotionsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none outside;
  width: 100%;
  li {
    display: flex;
    flex: 1 1 auto;
  }
`

const renderList = ({ list, closeOverlay, key }) => {
  return (
    <CategoryList key={key}>
      {_map(list, (item) => {
        const { title, image, link, isExternal } = item
        return (
          <li key={link}>
            <CategoryLink
              as={isExternal && 'a'}
              href={link}
              to={link}
              onClick={closeOverlay}
            >
              {title}
              {image && (
                <span data-image>
                  <span
                    style={{ backgroundImage: `url(${getAssetSrc(image)})` }}
                  />
                </span>
              )}
            </CategoryLink>
          </li>
        )
      })}
    </CategoryList>
  )
}

const NavigationCategory = (props) => {
  const { category, closeOverlay } = props
  if (!category) return null
  const categories = useNav()
  const request = useRequest({
    resourceType: 'layout',
    requestKey: 'layout'
  })
  const layout = getFirstResourceFields(request)

  const promotions = _pickBy(layout, (value, key) =>
    _.startsWith(key, promotionKeyPrefix)
  )
  if (!category || !_size(categories)) return null
  const currentCategory = _find(categories, {
    key: _upperFirst(getParentCategoryAlias(category))
  })

  return (
    <Container>
      <Categories>
        <CategoriesInner>
          {_map(
            _reject(
              [
                _get(currentCategory, 'subcategories'),
                _get(currentCategory, 'pages')
              ],
              _isEmpty
            ),
            (list, index) => {
              return renderList({
                list,
                closeOverlay,
                key: `${index}-${category}`
              })
            }
          )}
        </CategoriesInner>
      </Categories>
      <Promotions>{renderPromotions(promotions, category)}</Promotions>
    </Container>
  )
}

export default NavigationCategory

const renderPromotions = (promotions, category) => {
  const promotionsForCategory = _map(
    promotions[promotionKeyPrefix + _upperFirst(category)],
    getFields
  )

  return (
    <PromotionsList>
      {_map(promotionsForCategory, (promotion = {}) => {
        const link = promotion.externalLink || getUrl(promotion.subject)
        return (
          <li key={link}>
            <PromotionCard
              title={promotion.title}
              description={promotion.description}
              image={promotion.image}
              buttonLabel={promotion.button}
              buttonLink={link}
            />
          </li>
        )
      })}
    </PromotionsList>
  )
}
