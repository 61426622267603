import React, { Fragment } from 'react'
import _ from 'lodash'
import styled from 'styled-components'

import { centres as centresConfig } from 'src/config'
import { useInternationalisation } from 'src/context'
import Spacing from 'src/components/Spacing'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 40px;
`

const Tile = styled.div`
  padding: 15px;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.25);

  h2 {
    color: #666;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 3px;
  }
`

const Link = styled.a`
  font-size: 12px;
  display: inline-block;
  background-color: #ddd;
  color: #888;
  border-radius: 2px;
  padding: 4px;
  line-height: 1;
  text-decoration: none;
  margin-top: 7px;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: ${(p) => (p.href ? 1 : 0.4)};
  cursor: ${(p) => (p.href ? 'pointer' : 'not-allowed')};
`

const CentresSitesDirectory = (props) => {
  const { translate } = useInternationalisation()
  return (
    <Wrapper>
      <Tile>
        <h2>{'CMS Guide'}</h2>
        <Link
          href={'https://www.dropbox.com/s/kusywddd5wsbnhi/Hammerson%20CMS%20Guide.docx?dl=0'}
          children={'guide'}
        />
      </Tile>
      {_.chain(centresConfig)
        // Filter a center if it's been completely archived.
        .omitBy((environmentConfigs) => {
          return _.every(environmentConfigs, 'archived')
        })
        .map((value, key) => {
        return (
      <Tile key={key}>
        <h2>{key}</h2>
        {['qa', 'uat', 'prod'].map((envKey) => {
          const host = _.castArray(_.get(value, [envKey, 'host']))[0]
          let href = host && '//' + host
          if (envKey === 'prod' && host && !_.startsWith(host, 'www.')) {
            href = '//www.' + host
          }
          return (
            <Link key={envKey} href={href} children={envKey + ' site'} />
          )
        })}
      </Tile>
      )
      }).value()}
    </Wrapper>
  )
}

export default CentresSitesDirectory
