import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'src/components/RouterDom'
import qs from 'query-string'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _ from 'lodash'
import { List as Placeholder } from 'react-content-loader'

import { useInternationalisation } from 'src/context'
import { getFields, getFirstResource, getAssetSrc } from 'src/utility'
import { useRequest } from 'src/store/resources/hooks'

import Button from 'src/components/Button'
import Page from 'src/components/Page'
import Container from 'src/components/Container'
import LockSvg from 'src/assets/Lock.svg'
import MenuLink from 'src/components/MenuLink'
import PageBanner from 'src/components/PageBanner'
import SettingSvg from 'src/assets/Setting.svg'
import Spinner from 'src/components/Spinner'
import Tabs from 'src/components/Tabs'
import UserSvg from 'src/assets/User.svg'
import MyEventsSvg from 'src/assets/MyEvents.svg'
import MyCompetitionsSvg from 'src/assets/MyCompetitions.svg'
import MyRewardsSvg from 'src/assets/MyRewards.svg'
import accountActionTypes from 'src/store/account/actionTypes'
import { useBreakpoint } from 'src/styling/media'
import vars from 'src/styling/vars'
import { isCordova, isBrowser } from 'src/env'
import { restore, logout } from 'src/store/account/actionCreators'
import Head from 'src/components/Head'

import {
  statusSelector,
  attributesSelector,
  actionTypeSelector,
  providerSelector
} from 'src/store/account/selectors'

import Details from './Details'
import Preferences from './Preferences'
import Password from './Password'
import Listing from 'src/pages/Listing'
import { withRouter } from 'src/components/RouterDom'
import withPageData from 'src/routing/withPageData'

const Box = styled.div`
  box-shadow: ${vars.contentShadow};
  background-color: white;
  overflow-x: hidden;
`

const SectionTitle = styled.h2`
  font-size: 40px;
  line-height: 1.1;
  text-align: center;
  font-weight: bold;
  margin: 0 0 50px;
  ${isCordova ? 'display: none;' : ''}
`

const StyledSpinner = styled(Spinner)`
  margin: 0 auto;
`

const LogoutButton = styled(Button)`
  margin: 0;
`
const Panel = ({ label, Comp }) => (
  <Container verticalGutter>
    <SectionTitle>{label}</SectionTitle>
    <Comp />
  </Container>
)

const DesktopAccount = (props) => {
  const { section, attributes, isLoading, gt, tabsProp, sections } = props
  const { component: Comp, label } = section || {}
  const defaultTabKey = React.useMemo(() => {
    const defaultTab = _.find(sections, (section) => !!section.default)
    return (defaultTab || sections[0]).key
  }, [sections])
  return (
    <Container verticalGutter>
      {isLoading ? (
        <StyledSpinner />
      ) : !section ? (
        isBrowser && gt('md') ? (
          <Redirect to={`/account?tab=${defaultTabKey}`} />
        ) : null
      ) : (
            <Box>
              <Tabs
                tabs={tabsProp}
                panel={<Panel label={label} Comp={Comp} />}
                mobileSelect={false}
              />
            </Box>
          )}
    </Container>
  )
}

const MobileAccount = (props) => {
  const dispatch = useDispatch()
  const { translate } = useInternationalisation()
  const { section, attributes, isLoading, sections } = props
  const { component: Comp, label } = section || {}
  const { given_name, family_name } = attributes || {}
  const name = [given_name, family_name].filter(Boolean).join(' ')
  const logOut = React.useCallback(() => {
    dispatch(logout())
  }, [])
  const logOutText = name ? `(${translate('LOGOUT')})` : translate('LOGOUT')
  return isLoading ? (
    <Container verticalGutter>
      <StyledSpinner />
    </Container>
  ) : !section ? (
    <React.Fragment>
      {sections.map((section) => {
        const { key, label } = section
        return (
          <MenuLink key={key} to={`/account?tab=${key}`} children={label} />
        )
      })}
      <Container verticalGutter>
        <LogoutButton fullWidth buttonType='primary' onClick={logOut}>
          {name} {logOutText}
        </LogoutButton>
      </Container>
    </React.Fragment>
  ) : (
        <Container verticalGutter>
          <SectionTitle>{label}</SectionTitle>
          <Comp />
        </Container>
      )
}

const ListingWithHOCs = withRouter(withPageData(Listing))

const Account = (props) => {
  const dispatch = useDispatch()
  const { translate } = useInternationalisation()
  const status = useSelector(statusSelector())
  const actionType = useSelector(actionTypeSelector())
  const attributes = useSelector(attributesSelector())
  const provider = useSelector(providerSelector())
  const { lt, gt } = useBreakpoint()
  const layoutRequest = useRequest({
    resourceType: 'layout',
    requestKey: 'layout'
  })
  const layoutStatus = _get(layoutRequest, '_status')
  const layout = getFirstResource(layoutRequest)
  const {
    accountBannerMedia
  } = getFields(layout)
  const accountBanner = getAssetSrc(accountBannerMedia, { width: 2000 })
  const bannerImgSrc = _.defaultTo(accountBanner, 'https://hammerson-plus-mock-assets.s3-eu-west-1.amazonaws.com/account-banner-default.jpeg')

  React.useEffect(() => {
    dispatch(restore()).catch(() => { })
  }, [])

  const { sections, sectionsHashmap, tabsProp } = React.useMemo(() => {
    const sections = [
      {
        key: 'details',
        component: Details,
        label: translate('ACCOUNT_DETAILS'),
        icon: UserSvg
      },
      {
        key: 'preferences',
        component: Preferences,
        label: translate('PREFERENCES'),
        icon: SettingSvg,
        default: true
      }
    ]
    if (provider === 'cognito') {
      sections.push({
        key: 'password',
        component: Password,
        label: translate('INPUT_PASSWORD'),
        icon: LockSvg
      })
    }
    sections.push(
      {
        key: 'events',
        component: ListingWithHOCs,
        label: translate('MY_EVENTS'),
        icon: MyEventsSvg
      },
      {
        key: 'competitions',
        component: ListingWithHOCs,
        label: translate('MY_COMPETITIONS'),
        icon: MyCompetitionsSvg
      }
    )

    if (isCordova) {
      sections.push({
        key: 'rewards',
        component: ListingWithHOCs,
        label: translate('MY_REWARDS'),
        icon: MyRewardsSvg
      })
    }
    const sectionsHashmap = sections.reduce((acc, section) => {
      acc[section.key] = section
      return acc
    }, {})
    const tabsProp = sections.map((section) => {
      const { key, label, icon } = section
      return { label, to: `/account?tab=${key}`, icon }
    })
    return { sections, sectionsHashmap, tabsProp }
  }, [provider])

  const { location } = props
  const { tab: sectionKey } = qs.parse(_get(location, 'search'))
  const section = sectionsHashmap[sectionKey]
  const { label } = section || {}
  const isLoading = status.pending && actionType !== accountActionTypes.UPDATE

  const passedProps = {
    attributes,
    section,
    isLoading,
    lt,
    gt,
    tabsProp,
    sections
  }

  if (layoutStatus.pending) {
    return (
      <Page box inner>
        <Placeholder />
      </Page>
    )
  }

  return (
    <React.Fragment>
      <Head title={label || translate('ACCOUNT')} />
      <PageBanner
        hide={isCordova}
        text={translate('WELCOME_BACK')}
        src={bannerImgSrc}
      />
      {attributes ? (
        isBrowser ? (
          gt('md') ? (
            <DesktopAccount {...passedProps} />
          ) : (
              <MobileAccount {...passedProps} />
            )
        ) : null
      ) : isLoading ? null : (
        <Redirect to='/login' />
      )}
    </React.Fragment>
  )
}

export default Account
