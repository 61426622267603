import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'src/components/RouterDom'
import qs from 'query-string'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _ from 'lodash'
import { TabList, Tab, Tabs, TabPanels, TabPanel } from '@reach/tabs'
import Spacing from 'src/components/Spacing'
import AssetIcon from 'src/components/AssetIcon'
import Box from 'src/components/Box'
import colors from 'src/styling/colors'
import Show from 'src/components/Show'
import SelectField from 'src/components/Fields/Select'
import media from 'src/styling/media'
import vars from 'src/styling/vars'
import { useInternationalisation } from 'src/context'

const iconSize = 28
const ImageIcon = styled.img`
  width: ${iconSize}px;
  height: ${iconSize}px;
`
const SelectFieldWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`
const getSearchQuery = ({ search, key = 'tab' }) => {
  const query = qs.parse(search)
  return _get(query, key, '')
}

const TabListComp = (props) => {
  const {
    mobileSelect,
    showTabs,
    staticContext,
    panel,
    inputType,
    ...rest
  } = props
  return <TabList {...rest} />
}

const TabListStyle = styled(TabListComp)`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const TabLink = (props) => {
  //excluded these props to prevent console warnings
  const {
    children,
    selectedIndex,
    _selectedPanelRef,
    _onFocusPanel,
    _onSelectTab,
    staticContext,
    inputType,
    mobileSelect,
    ...rest
  } = props

  return <span {...rest}>{children}</span>
}

const TabLinkStyle = styled(TabLink)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  z-index: 1;
  height: 70px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 19px;
  text-decoration: none;
  background-color: ${colors.lighterGrey};
  &[data-selected] {
    background-color: ${colors.white};
  }
  cursor: pointer;
`

const TabWrapper = (props) => {
  const { isSelected, children } = props
  return (
    <Tab {...props} as={TabLinkStyle}>
      {children}
    </Tab>
  )
}

const renderTabs = ({ pathname, tabs, tabId }) =>
  _map(tabs, (tab, i) => {
    const { label, icon } = tab
    const isIconProbablyAnSvgAsset = _.isFunction(icon)

    return (
      <TabWrapper key={`${i}|to`}>
        {isIconProbablyAnSvgAsset ? (
          <AssetIcon svg={icon} size={iconSize} />
        ) : (
          <ImageIcon src={icon} />
        )}
        <Spacing height={10} />
        <span>{label}</span>
      </TabWrapper>
    )
  })

const renderTabPanels = ({ panel, tabs, tabIndex, ...rest }) => {
  return _map(tabs, (tab, i) => {
    return tabIndex === i ? (
      <TabPanel style={{ width: '100%' }} key={i}>
        {panel}
      </TabPanel>
    ) : (
      <Fragment />
    )
  })
}

const MobileComp = (props) => <Mobile {...props} />

const MobileDropdown = (props) => {
  return (
  <SelectFieldWrapper>
    <SelectField {...props} />
  </SelectFieldWrapper>
)}

const Mobile = (props) => {
  const {
    mobileSelect,
    staticContext,
    panel,
    pathname,
    tabId,
    tabs,
    ...rest
  } = props
  return (
    <Fragment>
      <Show gt='lg'>
        <TabListStyle {...rest}>
          {renderTabs({ pathname, tabs, tabId, ...rest })}
        </TabListStyle>
      </Show>
      <Box noBox>
        <Show lt='lg' style={{ width: '100%' }}>
          {mobileSelect && <MobileDropdown {...mobileSelect} />}
        </Show>
        <TabPanels style={{ width: '100%' }} {...rest}>
          {renderTabPanels({ panel, tabs, ...rest })}
        </TabPanels>
      </Box>
    </Fragment>
  )
}

const DesktopPanels = (props) => {
  const {
    mobileSelect,
    showTabs,
    staticContext,
    panel,
    pathname,
    tabs,
    tabId,
    ...rest
  } = props
  return (
    <Fragment>
      <TabListStyle {...rest}>
        {renderTabs({ pathname, tabs, tabId, ...rest })}
      </TabListStyle>
      <TabPanels {...rest}>
        {renderTabPanels({ panel, tabs, ...rest })}
      </TabPanels>
    </Fragment>
  )
}
const Desktop = (props) => <DesktopPanels {...props} />

function TabsComponent(props) {
  //expects tabs prop to be array of objects with properties of {label, to, icon}
  //expects panel prop to be a single functional component, the main content of the panel
  //since the way we are populating the tab panel is to fetch data for selected tab
  //expects mobileSelect prop to be false or object with properties {options, values, onChange}
  const {
    mobileSelect = false,
    showTabs = true,
    location,
    history,
    match,
    ...rest
  } = props
  const { translateUrl, untranslateUrl } = useInternationalisation()

  const { pathname, search } = location || {}
  const [tabIndex, setTabIndex] = useState(0)

  React.useEffect(() => {
    // set initial tab based on url
    // if this isn't here then refreshing the page will go
    // to the wrong tab
    const index = _.findIndex(rest.tabs, (tab) => {
      if (tab && tab.to) {
        const isMatch = tab.to === pathname + search
        return isMatch
      } else {
        return false
      }
    })
    if (index > -1) setTabIndex(index)
  }, [rest.tabs])

  const pageParamsTabQuery = getSearchQuery({ search })
  const pageParamsId = _get(match, 'params.id')
  // tabId can be either the 'tab' query parameter or a param ID e.g parking/:id
  const tabId = pageParamsTabQuery
    ? pageParamsTabQuery
    : pageParamsId

  const renderLogic = (props) => {
    const { mobileSelect, showTabs, ...rest } = props
    return (
      <Tabs
        style={{ width: '100%' }}
        index={tabIndex}
        onChange={(index, ...args) => {
          setTabIndex(index)
          const to = _.get(rest, ['tabs', index, 'to'])
          if (to) history.replace(translateUrl(to))
        }}
      >
        {mobileSelect ? (
          MobileComp({ mobileSelect, ...rest })
        ) : showTabs ? (
          Desktop({ ...rest })
        ) : (
          <Fragment />
        )}
      </Tabs>
    )
  }
  return renderLogic({
    mobileSelect,
    showTabs,
    pathname,
    tabId,
    tabIndex,
    ...rest
  })
}
export default withRouter(TabsComponent)
