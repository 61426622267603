import React, { Fragment, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'src/components/RouterDom'
import * as Yup from 'yup'
import _ from 'lodash'
import { useInternationalisation } from 'src/context'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { login, signUp } from 'src/store/account/actionCreators'
import validators from 'src/validators'
import { titles, getCentreConfig } from 'src/config'
import VisuallyHidden from '@reach/visually-hidden'

import SelectField from 'src/components/Fields/Select'
import DateField from 'src/components/Fields/Date'
import CheckboxField from 'src/components/Fields/Checkbox'
import Form from 'src/components/Form'
import UserSvg from 'src/assets/User.svg'
import EmailSvg from 'src/assets/Email.svg'
import PhoneSvg from 'src/assets/Phone.svg'
import LockSvg from 'src/assets/Lock.svg'
import cognitoService from 'src/services/cognitoService'
import PasswordField from 'src/components/Fields/Password'
import BigLabel from 'src/components/Fields/common/BigLabel'
import FieldsWrapper from './FieldsWrapper'
import SocialLogin from './SocialLogin'
import airshipService from 'src/services/airshipService'
import { renderURLInText } from 'src/utility'

import { isCordova } from 'src/env'

const CommunicationPermissionsHeading = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-top: 10px;
  &:after {
    content: "${(p) => p.label}";
  }
`

const validationSchema = Yup.object({
  title: validators.requiredString(),
  given_name: validators.requiredString(),
  family_name: validators.requiredString(),
  email: validators.email(),
  password: validators.password(),
  birthdate: validators.date(),
  phone_number: validators.requiredMobileNumber('sms_communications_permitted')
})

const SignUpForm = (props) => {
  const { redirectAfterLogin } = props
  const { locale, isFrenchCentre, translate } = useInternationalisation()
  const { centreName = '', centreAddress = '' } = getCentreConfig() || {}
  const dispatch = useDispatch()
  useEffect(() => {
    airshipService.syncPushNotificationSettingsWithOs({ dispatch })
  }, [])

  const TermsConditions = (props) => {
    const { label } = props
    if (isFrenchCentre) {
      const label = translate('SIGN_UP_TERMS_AND_CONDITIONS_ADDITIONAL_FR', {
        centreAddress
      })
      const links = _.split(label, '</link>')
      return (
        <>
          <p>
            {renderURLInText({
              text: translate('SIGN_UP_TERMS_AND_CONDITIONS_FR', {
                centreAddress
              }),
              url: '/privacy-policy'
            })}
          </p>
          <p>
            {renderURLInText({ text: links[0], url: '/terms-conditions' })}
            {renderURLInText({ text: links[1], url: '/privacy-policy' })}
          </p>
        </>
      )
    }
    return <p>{renderURLInText({ text: label, url: '/privacy-policy' })}</p>
  }


  const onSubmit = useCallback((values) => {
    return dispatch(signUp(values))
      .then(() => dispatch(login(values)))
      .then(redirectAfterLogin)
  }, [])

  const communicationPermissionHeadingLabel = useMemo(() => {
    if (isFrenchCentre) {
      return [translate('COMMUNICATION_PERMISSIONS_HEADING_FR', { centreName })]
    } else {
      return 'COMMUNICATION_PERMISSIONS_HEADING'
    }
  }, [isFrenchCentre, centreName])

  const emailPermissionLabel = useMemo(() => {
    if (isFrenchCentre) {
      return 'COMMUNICATION_PERMISSION_EMAIL_FR'
    } else {
      return 'COMMUNICATION_PERMISSION_EMAIL'
    }
  }, [isFrenchCentre])

  const smsPermissionLabel = useMemo(() => {
    if (isFrenchCentre) {
      return 'COMMUNICATION_PERMISSION_SMS_FR'
    } else {
      return 'COMMUNICATION_PERMISSION_SMS'
    }
  }, [isFrenchCentre])

  const pushPermissionLabel = useMemo(() => {
    if (isFrenchCentre) {
      return 'COMMUNICATION_PERMISSION_NOTIFICATIONS_FR'
    } else {
      return 'COMMUNICATION_PERMISSION_NOTIFICATIONS'
    }
  }, [isFrenchCentre])

  const formProps = {
    onSubmit,
    buttonLabel: 'BUTTON_LABEL_SUBMIT',
    fieldSchema: [
      {
        name: 'title',
        label: 'INPUT_TITLE',
        autoComplete: 'honorific-prefix',
        component: SelectField,
        options: titles[locale],
        required: true
      },
      {
        name: 'given_name',
        label: 'INPUT_FIRST_NAME',
        autoComplete: 'given-name',
        iconSvg: UserSvg,
        required: true
      },
      {
        name: 'family_name',
        label: 'INPUT_LAST_NAME',
        autoComplete: 'family-name',
        iconSvg: UserSvg,
        required: true
      },
      {
        name: 'email',
        label: 'INPUT_EMAIL',
        autoComplete: 'email',
        type: 'email',
        iconSvg: EmailSvg,
        required: true
      },
      {
        name: 'password',
        label: 'INPUT_PASSWORD',
        autoComplete: 'new-password',
        type: 'password',
        iconSvg: LockSvg,
        required: true,
        component: PasswordField
      },
      {
        name: 'phone_number',
        label: 'INPUT_MOBILE_NUMBER',
        autoComplete: 'tel',
        iconSvg: PhoneSvg
      },
      {
        name: 'birthdate',
        label: 'INPUT_BIRTHDAY',
        autoComplete: 'bday',
        component: DateField,
        helpText: 'INPUT_BIRTHDAY_HELP_TEXT'
      },
      {
        label: communicationPermissionHeadingLabel,
        component: CommunicationPermissionsHeading
      },
      {
        name: 'email_communications_permitted',
        label: emailPermissionLabel,
        component: CheckboxField
      },
      {
        name: 'sms_communications_permitted',
        label: smsPermissionLabel,
        component: CheckboxField
      },
      ...(isCordova
        ? [
          {
            name: 'push_notifications_permitted',
            label: pushPermissionLabel,
            component: CheckboxField,
            onClick: airshipService.openNotificationSettings
          }
        ]
        : []),
      {
        label: 'SIGN_UP_TERMS_AND_CONDITIONS',
        component: TermsConditions
      }
    ],
    validationSchema,
    parseError: cognitoService.parseError
  }

  return (
    <React.Fragment>
      <VisuallyHidden><h1>{translate('SIGN_UP')}</h1></VisuallyHidden>
      <FieldsWrapper children={<Form {...formProps} />} />
      <SocialLogin redirectAfterLogin={redirectAfterLogin} isSignUp />
    </React.Fragment>
  )
}

export default SignUpForm
