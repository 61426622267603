import styled from 'styled-components'

const BigLabel = styled.div`
  font-size: 35px;
  line-height: 55px;
  font-weight: bold;
  text-align: ${(p) => (p.leftAlign ? 'left' : 'center')};
`

export default BigLabel
