import React, { Fragment, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'src/components/RouterDom'
import { isCordova } from 'src/env'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _ from 'lodash'
import * as Yup from 'yup'

import { statusSelector, attributesSelector } from 'src/store/account/selectors'
import { update } from 'src/store/account/actionCreators'
import Form from 'src/components/Form'
import validators from 'src/validators'
import cognitoService from 'src/services/cognitoService'
import CheckboxField from 'src/components/Fields/Checkbox'
import airshipService from 'src/services/airshipService'

import { useInternationalisation } from 'src/context'

const FormWrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;
`

const CommunicationPermissionsHeading = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-top: 10px;
  &:after {
    content: "${(p) => p.label}";
  }
`

const Preferences = (props) => {
  const dispatch = useDispatch()
  const { translate, isFrenchCentre } = useInternationalisation()
  React.useEffect(() => {
    airshipService.syncPushNotificationSettingsWithOs({ dispatch })
  }, [])

  const emailPermissionLabel = useMemo(() => {
    if (isFrenchCentre) {
      return 'COMMUNICATION_PERMISSION_EMAIL_FR'
    } else {
      return 'COMMUNICATION_PERMISSION_EMAIL'
    }
  }, [isFrenchCentre])

  const smsPermissionLabel = useMemo(() => {
    if (isFrenchCentre) {
      return 'COMMUNICATION_PERMISSION_SMS_FR'
    } else {
      return 'COMMUNICATION_PERMISSION_SMS'
    }
  }, [isFrenchCentre])

  const pushPermissionLabel = useMemo(() => {
    if (isFrenchCentre) {
      return 'COMMUNICATION_PERMISSION_NOTIFICATIONS_FR'
    } else {
      return 'COMMUNICATION_PERMISSION_NOTIFICATIONS'
    }
  }, [isFrenchCentre])

  const validationSchema = Yup.object({
    sms_communications_permitted: validators.requiredMobileNumberOnCheckBox('phone_number')
  })
  const getFieldSchema = (isCordova, translate) => [
    {
      label: 'COMMUNICATION_PERMISSIONS_HEADING',
      component: CommunicationPermissionsHeading
    },
    {
      name: 'email_communications_permitted',
      label: emailPermissionLabel,
      component: CheckboxField
    },
    {
      name: 'sms_communications_permitted',
      label: smsPermissionLabel,
      component: CheckboxField
    },
    ...(isCordova
      ? [
        {
          name: 'push_notifications_permitted',
          label: pushPermissionLabel,
          component: CheckboxField,
          onClick: airshipService.openNotificationSettings
        }
      ]
      : []),
    {
      name: 'phone_number',
      label: translate('INPUT_MOBILE_NUMBER'),
      type: 'hidden'
    }
  ]
  const fieldSchema = React.useMemo(
    () => getFieldSchema(isCordova, translate),
    [isCordova, translate]
  )
  const attributes = useSelector(attributesSelector())
  const initialValues = React.useMemo(() => {
    return _.pick(attributes, fieldSchema.map((x) => x.name))
  }, [attributes])
  const onSubmit = React.useCallback((values) => {
    return dispatch(update(values))
  }, [])

  return (
    <FormWrapper>
      <Form
        fieldSchema={fieldSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        parseError={cognitoService.parseError}
        validationSchema={validationSchema}
        buttonLabel={translate('INPUT_UPDATE')}
        successMessage={translate('INPUT_UPDATED_SUCCESS')}
      />
    </FormWrapper>
  )
}

export default Preferences
