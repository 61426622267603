import _ from 'lodash'
import { setDevice } from 'src/store/device/actionCreators'
import apiService from 'src/services/apiService'

const startPointr = ({ dispatch }) => {
  window.PointrPlugin.startPointr(
    function () {
      console.log('Pointr started')
      getDeviceUUID({ dispatch })
    },
    function (error) {
      console.log(
        'Pointr engine failed to start. The reason is : '.concat(error)
      )
    }
  )
}

const getDeviceUUID = ({ dispatch }) => {
  window.PointrPlugin.getDeviceUUID(function (pointrId) {
    if (pointrId) {
      console.log('Pointr id is:'.concat(pointrId))
      dispatch(setDevice({ pointrId }))
    } else {
      console.log('No pointr ID avaliable yet.')
    }
  })
}

const registerToGeofenceManagerEnterCallback = ({ getState }) => {
  window.PointrPlugin.registerToGeofenceManagerEnterCallback(async function (facilityId) {
    if (facilityId) {
      console.log('facility entered with Id:'.concat(facilityId))
      const state = getState()
      const pointrId = _.get(state, 'device.uuid')
      const userId = _.get(state, 'account.attributes.id')
      if (pointrId && userId) {
        await apiService.createUserCentreEvent({
          deviceTimeStamp: Date.now(),
          pointrId,
          userId
        })
      }
    }
  })
}

const showMap = () => {
  window.PointrPlugin.showMap()
}

const setPreferredLanguage = ({ language }) => {
  window.PointrPlugin.setPreferredLanguage(language)
}

const pointrService = {
  startPointr,
  getDeviceUUID,
  registerToGeofenceManagerEnterCallback,
  showMap,
  setPreferredLanguage
}

export default pointrService
