import * as constants from './constants'
import reducers from './reducers'

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.READ_OCCUPANCY:
      return reducers.readOccupancy(state, action)
    default: 
      return state
  }
}