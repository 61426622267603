import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import _omit from 'lodash/omit'

import FieldBase from './common/FieldBase'
import {
  leftPadding,
  verticalPadding,
  fontSize,
  lineHeight
} from './common/vars'
import getInputProps from './common/getInputProps'
import TickSvg from 'src/assets/Tick.svg'
import AssetIcon from 'src/components/AssetIcon'
import colors from 'src/styling/colors'
import uiSelector from 'src/store/ui/selector'
import uiConstants from 'src/store/ui/constants'
import { useSelector, useDispatch } from 'react-redux'
import { isCordova } from 'src/env'

const boxSize = 30
const Box = styled.div`
  width: ${boxSize}px;
  height: ${boxSize}px;
  margin-top: ${-0.5 * (boxSize - lineHeight)}px;
  margin-bottom: ${-0.5 * (boxSize - lineHeight)}px;
  border: 2px solid
    ${(p) => (p['aria-invalid'] ? colors.error : colors.textColor)};
  margin-right: ${leftPadding - boxSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  & > div {
    opacity: 0;
  }
  input:checked ~ & > div {
    opacity: 1;
  }
`
const CheckboxInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  :focus ~ ${Box} {
    outline: 3px solid #7aacfe;
  }
`
const Wrapper = styled.div`
  text-align: left;
  padding: 13px 0 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
`

const TickIcon = styled(AssetIcon)``

const Label = styled.span`
  font-size: ${fontSize}px;
  font-weight: bold;
  line-height: ${lineHeight}px;
  flex-shrink: 1;
`

const renderField = (props) => {
  const { label, name, onClick } = props

  const inputProps = getInputProps(props)
  const { value, ...restInputProps } = inputProps
  const boxProps = _pick(inputProps, ['aria-invalid'])
  const checkPushPrompt = () => {
    const { PUSH_PROMPT_CHECKED } = uiConstants
    const isPushPromptChecked = useSelector(uiSelector(PUSH_PROMPT_CHECKED))
    return isPushPromptChecked
  }
  const checkboxProps = {
    ...(label ? _omit(restInputProps, ['aria-label']) : restInputProps),
    type: 'checkbox',
    id: name,
    name,
    checked:
      isCordova && name === 'push_notifications_permitted'
        ? checkPushPrompt()
        : value,
    onClick: () => onClick && onClick(value)
  }
  return (
    <Wrapper>
      <CheckboxInput {...checkboxProps} />
      <Box {...boxProps}>
        <TickIcon svg={TickSvg} size={20} />
      </Box>
      <Label>{label}</Label>
    </Wrapper>
  )
}

const Checkbox = (props) => {
  return <FieldBase {...props} renderField={renderField} noLabel />
}

export default Checkbox
