import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'

import colors from 'src/styling/colors'
import { inputHeight } from './vars'

const StyledSelect = styled.select`
  border-radius: 0;
  line-height: ${inputHeight}px;
  ${(p) => (p.value ? '' : `color: ${colors.placeholderTextColor};`)}
`

const Option = styled.option`
  color: ${colors.textColor};
`

const SelectInput = (props) => {
  const {
    options,
    className,
    placeholder,
    value,
    allowNullValue,
    ...rest
  } = props
  return (
    <StyledSelect className={className} value={value || ''} {...rest}>
      {placeholder ? (
        <Option
          value=''
          disabled={!allowNullValue}
          hidden={!allowNullValue}
          colors={{ textColor: '#262a30' }}
        >
          {placeholder}
        </Option>
      ) : null}
      {_map(options, (option) => {
        if (!option) return null
        let label, value
        if (typeof option === 'string') {
          label = value = option
        } else {
          label = option.label
          value = option.value
        }
        return (
          <Option key={value} value={value} colors={{ textColor: '#262a30' }}>
            {label}
          </Option>
        )
      })}
    </StyledSelect>
  )
}

export default SelectInput
