import _ from 'lodash'

import actionTypes from './actionTypes'

const setDevice = (payload) => {
  return {
    type: actionTypes.SET_DEVICE,
    payload
  }
}

export { setDevice }
