import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'src/components/RouterDom'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import styled from 'styled-components'
import Spacing from 'src/components/Spacing'

import _get from 'lodash/get'

import { isFiltersMenuOpenSelector } from 'src/store/styleSeeker/selectors'

import { useInternationalisation } from 'src/context'

import { clear, closeFiltersMenu } from 'src/store/styleSeeker/actionCreators'

import CloseIcon from 'src/assets/Close.svg'
import colors from 'src/styling/colors'
import vars from 'src/styling/vars'

import FilterCategory from './FilterCategory'
import RefineBy from './RefineBy'

const Container = styled.div``

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: ${colors.white};
  padding-top: env(safe-area-inset-top);
`

const CloseButton = styled.button`
  display: flex;
  width: 44px;
  height: 44px;
  background: none;
  border: 0;
  padding: 0;
  color: ${colors.darkGrey};
  align-items: center;
`

const FiltersMenu = (props) => {
  const dispatch = useDispatch()
  const isFiltersMenuOpen = useSelector(isFiltersMenuOpenSelector())
  const closeOverlay = useCallback(() => {
    dispatch(closeFiltersMenu())
  }, [])

  return (
    <Container>
      <DialogOverlay
        style={{
          zIndex: vars.zIndex.navigationOverlay,
          paddingTop: 'env(safe-area-inset-top)'
        }}
        isOpen={isFiltersMenuOpen}
      >
        <DialogContent
          style={{
            width: '100%',
            height: 'auto',
            margin: 0,
            padding: 0,
            color: colors.darkGrey,
            background: colors.white
          }}
        >
          <CloseButtonWrapper>
            <CloseButton onClick={closeOverlay}>
              <CloseIcon aria-label={'Close'} />
            </CloseButton>
          </CloseButtonWrapper>
          <Spacing height={70} />
          <RefineBy />
          <FilterCategory />
        </DialogContent>
      </DialogOverlay>
    </Container>
  )
}

export default withRouter(FiltersMenu)
