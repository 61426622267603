import React from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet-async'

import { usePageMeta, useSetPageMeta, usePageTitle, useSetPageTitle } from 'src/store/ui/hooks'
import { isCordova, isBrowser } from 'src/env'
import { getFields , prepareCanonicalUrl } from 'src/utility'
import { useCentre } from 'src/store/resources/hooks'

/**
 * Can be used to input tags in to the `head` section of the page's HTML.
 * Such as page title, facebook opengraph data, description for SEO, etc.
 * @param {object} props
 */
const Head = (props) => {
  const metaFromRedux = usePageMeta()
  const meta = _.size(props) ? props : metaFromRedux

  useSetPageMeta(props)
  useSetPageTitle(props.title || '')
  // If cordova, we only want to set the page title.
  if (isCordova) return null

  const { resource, description, title } = meta || {}

  //DESCIPTION
  const pageDescription = description || getFieldWithDefaults({
    resource,
    fields: [
      'description',
      'summary'
    ]
  })

  const metaItems = []
  if (pageDescription) {
    metaItems.push(
      <meta
        key='description'
        name='description'
        content={pageDescription}
      />,
      <meta
        property='og:description'
        content={pageDescription}
      />
    )
  }

  // CANONICAL LINK
  if (isBrowser) {
    const path = _.get(window, 'location.href')
    metaItems.push(
      <link
        key='link'
        rel='canonical'
        href={prepareCanonicalUrl({ path })}
      />)
  }


  // TITLE
  let pageTitle = getFieldWithDefaults({
    resource,
    fields: ['pageTitle']
  })
  const centre = useCentre()
  const centreName = getFields(centre).name
  if (!pageTitle) {
    // We do not want to duplicate the centre name in the page title.
    // For example Brent Cross - Brent Cross.
    if (title === centreName) {
      pageTitle = centreName
    } else {
      pageTitle = title ? `${title} - ${centreName || ''}` : ''
    }
  }
  metaItems.push(
    <title>
      {pageTitle}
    </title>
  )

  if (_.isEmpty(metaItems)) {
    return null
  }

  return (
    <Helmet>
      {metaItems}
    </Helmet>
  )
}

/**
 * Retrieves value at path within the fields of contentful object.
 * Takes an array of field names with preference awarded to earliest item in array.
 * @param {object} arguments
 * @param {object} resource A contentful resource.
 * @param {string[]} fields Paths ordered by desired value.
 */
const getFieldWithDefaults = ({ resource, fields }) => _.find(_.at(getFields(resource), fields))

export default Head
