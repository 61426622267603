import React from 'react'
import popupService from 'src/services/popupService'
import styled from 'styled-components'
import colors from 'src/styling/colors'

const Link = styled.a`
  color: ${colors.textColor};
  & > div {
    transition: transform 0.5s;
  }
  &:hover > div,
  &:focus > div {
    transform: scale(1.1);
  }
`

const WebShareButton = ({ url, children, target }) => {
  return (
    <Link
      href={url}
      target={target}
      onClick={(e) => {
        e.preventDefault()
        popupService.open(url, { width: 555, height: 500 })
      }}
    >
      {children}
    </Link>
  )
}

export default WebShareButton
