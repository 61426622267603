import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import _find from 'lodash/find'
import Cropper from 'src/components/Cropper'
import Button from 'src/components/Button'
import Spacing from 'src/components/Spacing'
import H2 from 'src/components/H2'
import vars from 'src/styling/vars'
import { useDispatch } from 'react-redux'
import { useInternationalisation } from 'src/context'

import {
  setCrop,
  setIsCropped,
  setCurrentPath
} from 'src/store/styleSeeker/actionCreators'

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 8%
    ${(p) => (p.padBottom ? 'calc(85px + env(safe-area-inset-bottom))' : 0)} 8%;
`

const Title = styled(H2)`
  font-size: 22px;
  padding: 15px;
`

const GarmentSelectionButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  padding: 15px 15px calc(${vars.appTabBarHeight}px + 15px + 10px) 15px;
  background-color: white;
`

const StyledButton = styled(Button)`
  margin: 0;
  height: 70px;
`

const CropImage = (props) => {
  const { file, setCroppedImage, setImage, setCropCallback } = props || {}

  const dispatch = useDispatch()
  const { translate } = useInternationalisation()

  const [imageCrop, setImageCrop] = useState({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    unit: 'px',
    imageHeight: 0,
    imageWidth: 0
  })

  const disabled = imageCrop.imageHeight === 0

  return (
    <Wrapper padBottom>
      <Cropper
        image={file}
        crop={imageCrop}
        setCrop={setImageCrop}
        onImageLoaded={setImage}
      />
      <Spacing height={15} />
      <Title>{translate('STYLE_SEEKER_CROP_IMAGE')}</Title>
      <GarmentSelectionButtons>
        <StyledButton
          disabled={disabled}
          buttonType={'primary'}
          onClick={() => setCropCallback(imageCrop)}
          style={{ flex: '1 1 auto' }}
        >
          {translate('STYLE_SEEKER_FIND_SIMILAR')}
        </StyledButton>
      </GarmentSelectionButtons>
    </Wrapper>
  )
}

export default CropImage
