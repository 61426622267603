import React from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import Slider from 'react-slick'
import VisuallyHidden from '@reach/visually-hidden'
import styled from 'styled-components'
import colors from 'src/styling/colors'
import media from 'src/styling/media'
import LeftArrowSvg from 'src/assets/SlideArrowLeft.svg'
import RightArrowSvg from 'src/assets/SlideArrowRight.svg'
import { getAssetSrc } from 'src/utility'
import { useInternationalisation } from 'src/context'

import CarouselContainer from './CarouselContainer'
import Placeholder from 'src/assets/Placeholder.png'

const Image = styled.div`
  background-image: ${(props) => `url(${props.image}),`} url(${Placeholder});
  background-size: ${(p) => (p.contain ? 'contain' : 'cover')};
  background-position: center;
  background-repeat: no-repeat;
  max-height: 100vh;
  ${(props) =>
    media.forEach({
      values: props.carouselHeight,
      getStyle: (v) => `height: ${v}px`
    })}
`

const Arrow = styled.button`
  width: 80px;
  height: 80px;
  border: none;
  background: none;
  cursor: pointer;
`

const LeftArrow = styled((props) => {
  const { translate } = useInternationalisation()
  return (
    <Arrow
      className={props.className}
      {..._omit(props, ['currentSlide', 'slideCount'])}
    >
      <VisuallyHidden>
        {translate('CAROUSEL_ARROW_PREVIOUS_LABEL')}
      </VisuallyHidden>
      <LeftArrowSvg />
    </Arrow>
  )
})`
  width: 15px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translate(0, -50%);
  z-index: 9;
  fill: ${(p) => p.color || colors.white};
  &.slick-disabled {
    fill: ${colors.lightGrey};
  }
  ${(props) =>
    props.limitWidth &&
    media.greaterThan('lg')`
    fill: ${colors.black};
    top: 50%;
    left: -55px;
    transform: translate(0,-50%);
  `}
`

const RightArrow = styled((props) => {
  const { translate } = useInternationalisation()
  return (
    <Arrow
      className={props.className}
      {..._omit(props, ['currentSlide', 'slideCount'])}
    >
      <VisuallyHidden>{translate('CAROUSEL_ARROW_NEXT_LABEL')}</VisuallyHidden>
      <RightArrowSvg />
    </Arrow>
  )
})`
  width: 15px;
  height: 30px;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translate(0, -50%);
  fill: ${(p) => p.color || colors.white};
  &.slick-disabled {
    fill: ${colors.lightGrey};
  }
  ${(props) =>
    props.limitWidth &&
    media.greaterThan('lg')`
    fill: ${colors.black};
    top: 50%;
    right: -55px;
    transform: translate(0,-50%);
  `}
`

const defaultSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <LeftArrow />,
  nextArrow: <RightArrow />
}

const Carousel = ({ images, carouselHeight, contain, arrowColor, ...rest }) => {
  const { limitWidth } = rest
  const containerProps = { carouselHeight, limitWidth }
  const arrowProps = { limitWidth, color: arrowColor }
  const sliderProps = {
    ...defaultSettings,
    prevArrow: <LeftArrow {...arrowProps} />,
    nextArrow: <RightArrow {...arrowProps} />,
    ...rest
  }
  return (
    <CarouselContainer {...containerProps}>
      <Slider {...sliderProps}>
        {_map(images, (image, index) => (
          <Image
            image={getAssetSrc(image)}
            key={_get(image, 'sys.id') || index}
            carouselHeight={carouselHeight}
            contain={contain}
          />
        ))}
      </Slider>
    </CarouselContainer>
  )
}

Carousel.defaultProps = {
  carouselHeight: { sm: 206, md: 300, lg: 400 }
}

export default Carousel
