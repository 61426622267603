import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _map from 'lodash/map'
import _toLower from 'lodash/toLower'
import _ from 'lodash'
import { getCentreConfig } from 'src/config'
import { logTrace } from 'src/utility'
import { isBrowser } from 'src/env'
import { getAccessToken } from './apiService'

const ACI = require('amazon-cognito-identity-js')

const { UserPoolId, UserPoolClientId } = getCentreConfig() || {}

let cognitoPool
if (UserPoolClientId && UserPoolId) {
  try {
    cognitoPool = new ACI.CognitoUserPool({
      UserPoolId,
      ClientId: UserPoolClientId
    })
  } catch (err) {
    console.error(err)
  }
}
const missingCognitoConfigError = new Error(`missing cognito config`)

const _parseSession = (userSession) => {
  const accessToken = _get(userSession, 'accessToken.jwtToken')
  const idToken = _get(userSession, 'idToken.jwtToken')
  const email = _get(userSession, 'idToken.payload.email')
  const cognitoUsername = _get(userSession, 'idToken.payload.cognito:username')
  const authTime = _get(userSession, 'idToken.payload.auth_time')
  // const expireTime = _get(userSession, 'idToken.payload.exp')
  const datetimeNow = Math.round(new Date().getTime() / 1000)
  const datetimeToExpire = authTime + 3600 // 1 hour

  let keepSession = false

  if (isBrowser) {
    keepSession = !!localStorage.getItem('keepCognitoSession')
  }

  const isExpired = keepSession ? false : datetimeNow > datetimeToExpire

  return { accessToken, idToken, email, cognitoUsername, isExpired }
}

const requestPasswordReset = (args) => {
  logTrace('cognitoService.requestPasswordReset')
  return new Promise((resolve, reject) => {
    if (!cognitoPool) reject(missingCognitoConfigError)
    const { email } = args
    const user = new ACI.CognitoUser({
      Username: _toLower(email),
      Pool: cognitoPool
    })
    user.forgotPassword({
      onSuccess: (result) => {
        resolve(result)
      },
      onFailure: (error) => {
        reject(error)
      }
    })
  })
}

const login = (args) => {
  logTrace('cognitoService.login')
  return new Promise((resolve, reject) => {
    if (!cognitoPool) reject(missingCognitoConfigError)
    const { email, password } = args
    const user = new ACI.CognitoUser({
      Username: _toLower(email),
      Pool: cognitoPool
    })
    const authDetails = new ACI.AuthenticationDetails({
      Username: _toLower(email),
      Password: password
    })
    user.setAuthenticationFlowType('USER_PASSWORD_AUTH')
    user.authenticateUser(authDetails, {
      onSuccess: (userSession) => {
        resolve(_parseSession(userSession))
      },
      onFailure: (err) => {
        if (err.code === 'PasswordResetRequiredException') {
          requestPasswordReset({ email })
            .then(() => reject(err))
            .catch((err) => reject(err))
        } else {
          reject(err)
        }
      },
      newPasswordRequired: (userAttributes) => {
        delete userAttributes.email_verified
        throw new Error('new password required')
        // user.completeNewPasswordChallenge('Ch4ngeme!', userAttributes)
      }
    })
  })
}

const signUp = (args) => {
  logTrace('cognitoService.signUp')
  return new Promise((resolve, reject) => {
    if (!cognitoPool) reject(missingCognitoConfigError)
    const { email, password, ...otherAttributes } = args
    const customDataAttribute = JSON.stringify(
      _pick(otherAttributes, [
        'title',
        'phone_number',
        'email_communications_permitted',
        'sms_communications_permitted',
        'push_notifications_permitted'
      ])
    )
    const attributes = {
      email: _toLower(email),
      ..._pick(otherAttributes, ['birthdate', 'given_name', 'family_name']),
      'custom:data': customDataAttribute
    }
    const attributeList = _map(attributes, (val, key) => {
      return new ACI.CognitoUserAttribute({ Name: key, Value: val })
    })

    cognitoPool.signUp(
      _toLower(email),
      password,
      attributeList,
      null,
      (error, result) => {
        if (error) {
          reject(error)
        } else {
          resolve(result)
        }
      }
    )
  })
}

const restore = () => {
  logTrace('cognitoService.restore')
  return new Promise((resolve, reject) => {
    if (!cognitoPool) reject(missingCognitoConfigError)
    const currentUser = cognitoPool.getCurrentUser()
    if (currentUser) {
      currentUser.getSession((error, session) => {
        if (error) {
          reject(error)
        } else {
          const userSession = _parseSession(session)
          const { isExpired } = userSession
          if (isExpired) {
            reject(new Error('session expired'))
          } else {
            resolve(userSession)
          }
        }
      })
    } else {
      resolve(null)
    }
  })
}

const getToken = async () => {
  logTrace('cognitoService.getToken')
  const cognitoSession = await restore()
  const token = _get(cognitoSession, 'accessToken') || getAccessToken()
  return token
}

const logout = () => {
  logTrace('cognitoService.logout')
  return new Promise((resolve, reject) => {
    if (!cognitoPool) reject(missingCognitoConfigError)
    const currentUser = cognitoPool.getCurrentUser()
    if (currentUser) {
      currentUser.signOut()
    }
    resolve()
  })
}

const resetPassword = (args) => {
  logTrace('cognitoService.resetPassword')
  return new Promise((resolve, reject) => {
    if (!cognitoPool) reject(missingCognitoConfigError)
    const { email, verificationCode, newPassword } = args
    const user = new ACI.CognitoUser({
      Username: _toLower(email),
      Pool: cognitoPool
    })
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: (result) => {
        resolve(result)
      },
      onFailure: (error) => {
        reject(error)
      }
    })
  })
}

const changePassword = (args) => {
  logTrace('cognitoService.changePassword')
  return new Promise((resolve, reject) => {
    if (!cognitoPool) reject(missingCognitoConfigError)
    const { currentPassword, newPassword } = args
    const currentUser = cognitoPool.getCurrentUser()
    if (currentUser) {
      currentUser.getSession((err) => {
        if (err) {
          reject(err)
        } else {
          currentUser.changePassword(
            currentPassword,
            newPassword,
            (err, result) => {
              if (err) {
                reject(err)
              } else {
                resolve(result)
              }
            }
          )
        }
      })
    } else {
      reject(new Error(`no current user`))
    }
  })
}

const parseError = (error) => {
  if (error.code === 'InvalidParameterException') {
    return 'INVALID_VALUES'
  } else if (error.code === 'InvalidPasswordException') {
    return 'INVALID_PASSWORD'
  } else if (error.code === 'UsernameExistsException') {
    return 'EMAIL_EXISTS'
  } else if (
    error.code === 'NotAuthorizedException' ||
    error.code === 'UserNotFoundException'
  ) {
    return 'INCORRECT_LOGIN_DETAILS'
  } else if (error.code === 'LimitExceededException') {
    return 'TOO_MANY_FAILED_ATTEMPTS'
  } else if (error.code === 'PasswordResetRequiredException') {
    return 'PASSWORD_RESET'
  } else if (error.code === 'UserLambdaValidationException') {
    if (error.message && _.isString(error.message)) {
      const delimiter = '[ham-api]'
      const delimiterIndex = error.message.indexOf(delimiter)
      if (delimiterIndex) {
        // get everything after the delimiter and space
        let apiErrorMsg = error.message.slice(
          delimiterIndex + delimiter.length + 1
        )
        // remove the fullstop from the end
        apiErrorMsg = apiErrorMsg.slice(0, apiErrorMsg.length - 1)
        if (apiErrorMsg) {
          if (apiErrorMsg === 'user exists') {
            return 'USER_EXISTS'
          } else if (apiErrorMsg === 'maximum login attempts exceeded') {
            return 'MAXIMUM_LOGIN_ATTEMPTS_EXCEEDED'
          }
        }
      }
    }
  }
  return 'ERROR_GENERIC'
}

const cognitoService = {
  changePassword,
  login,
  logout,
  parseError,
  requestPasswordReset,
  resetPassword,
  restore,
  getToken,
  signUp
}

export default cognitoService
