// please refer to https://redux-resource.js.org/ for more
// information about how we're using resources in redux.

import keyBy from 'lodash/keyBy'

const resourceTypes = [
  'centre',
  'featured',
  'news',
  'promotion',
  'category',
  'shop',
  'job',
  'eatery',
  'event',
  'navigation',
  'page',
  'carpark',
  'shopCategory',
  'jobCategory',
  'retailUnit',
  'retailUnitCategory',
  'layout',
  'crisis',
  'dine',
  'shop',
  'leisure'
]
export const resourceTypesHashmap = keyBy(resourceTypes, (x) => x)

export default resourceTypes
