import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { PersistGate } from 'redux-persist/integration/react'

import globalStyles from 'src/styling/global'
import Router from 'src/routing/Router'
import EnvironmentWrapper from 'src/components/EnvironmentWrapper'
import { isCordova, isBrowser } from 'src/env'
import apiService from 'src/services/apiService'

import { InternationalisationProvider } from 'src/context'
import CrashScreen from 'src/pages/CrashScreen'
import { analyticEventTypes } from 'src/config'

const GlobalStyle = createGlobalStyle`${globalStyles}`

class ErrorBoundary extends React.Component {
  state = { error: null, initialError: null }
  componentDidCatch(error, info) {
    console.log('componentDidCatch', error)
    const { initialError } = this.state
    this.setState({ error })
    const isErrorSameAsInitial = !!(
      error &&
      initialError &&
      error.message === initialError.message
    )
    if (isCordova && !isErrorSameAsInitial) {
      apiService.createAnalyticEvents({
        payload: {
          type: analyticEventTypes.APP_CRASH,
          meta: { errorMessage: error && error.message, info },
          event_date: new Date().toISOString()
        }
      })
    }
    if (!initialError) this.setState({ initialError: error })
  }
  clearError = () => {
    this.setState({ error: null })
  }
  render() {
    const { error } = this.state
    const { children, store } = this.props
    if (error) {
      return (
        <CrashScreen
          clearError={this.clearError}
          dispatch={this.props.store.dispatch}
        />
      )
    } else {
      return children
    }
  }
}

const App = (props) => {
  const { store, locale, host, helmetContext, ...rest } = props
  const PersistComp = isBrowser ? PersistGate : React.Fragment
  const persistProps = isBrowser && store ? { persistor: store.persistor } : {}
  return (
    <ErrorBoundary store={store}>
      <EnvironmentWrapper>
        <PersistComp {...persistProps}>
          <HelmetProvider context={helmetContext}>
            <InternationalisationProvider locale={locale} host={host}>
              <Provider store={store}>
                <GlobalStyle />
                <Router host={host} {...rest} />
              </Provider>
            </InternationalisationProvider>
          </HelmetProvider>
        </PersistComp>
      </EnvironmentWrapper>
    </ErrorBoundary>
  )
}

export default App
