import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _get from 'lodash/get'
import { isCordova } from 'src/env'
import Head from 'src/components/Head'
import { useInternationalisation } from 'src/context'

import { attributesSelector } from 'src/store/account/selectors'

import {
  fileSelector,
  fileUrlSelector,
  categorySelector,
  cropSelector,
  weightsSelector,
  productSelector,
  isLoadingSelector,
  currentPathSelector
} from 'src/store/styleSeeker/selectors'

import {
  setCategory,
  setSimilar,
  setSelectedProduct,
  setIsLoading,
  setCrop,
  setIsCropped,
  setCurrentPath,
  setFile
} from 'src/store/styleSeeker/actionCreators'

import { createSaved, removeSaved } from 'src/store/account/actionCreators'
import apiService from 'src/services/apiService'

import Spinner from 'src/components/Spinner'

import imageUploadService from './ImageUploadService'

import PageBanner from 'src/components/PageBanner'
import ProductSearch from './Components/ProductSearch'
import CropImage from './Components/CropImage'
import GarmentSelection from './Components/GarmentSelection'
import SimilarProducts from './Components/SimilarProducts'
import ProductDetails from './Components/ProductDetails'
import LoadingScreen from './Components/LoadingScreen'

import vars from 'src/styling/vars'
import colors from 'src/styling/colors'

const StyleSeeker = (props) => {
  const { match, history } = props
  const { parentCategory } = match.params

  const { translate, translateUrl } = useInternationalisation()
  const dispatch = useDispatch()

  const [croppedImage, setCroppedImage] = useState(null)
  const [image, setImage] = useState(null)

  const file = useSelector(fileSelector())
  const fileUrl = useSelector(fileUrlSelector())
  const category = useSelector(categorySelector())
  const crop = useSelector(cropSelector())
  const weights = useSelector(weightsSelector())
  const userAttributes = useSelector(attributesSelector())
  const selectedProduct = useSelector(productSelector())
  const isLoading = useSelector(isLoadingSelector())
  const currentPath = useSelector(currentPathSelector())

  const handleOnSelectGarment = useCallback(({ category }) => {
    dispatch(setCategory(category))
  })

  const handleOnFindSimilar = useCallback(() => {
    dispatch(setIsLoading(true))
    history.push(translateUrl(`/style-seeker/similar-products`))
    return apiService
      .styleSeekerSimilar({
        fileUrl,
        categoryAlias: category,
        crop,
        weights,
        content_id: null
      })
      .then(({ similarProducts, categoryAlias }) => {
        dispatch(setSimilar(similarProducts))
        dispatch(setCategory(categoryAlias))
        dispatch(setIsLoading(false))
      })
      .catch((err) => {
        console.log(err)
        dispatch(setIsLoading(false))
      })
  })

  const setCropCallback = useCallback((imageCrop) => {
    dispatch(setCrop(imageCrop))
    dispatch(setIsCropped())
    history.push(translateUrl(`/style-seeker/garment-selection`))
  })

  const handleOnProductSave = useCallback(
    ({ savedId, content_id, content_type, content_category }) => {
      if (!userAttributes) history.push(translateUrl('/saved'))
      if (!savedId) {
        dispatch(
          createSaved({
            content_id,
            content_type,
            content_category
          })
        )
      } else {
        dispatch(
          removeSaved({
            id: savedId
          })
        )
      }
    },
    []
  )

  const handleOnSelectProduct = useCallback((product) => {
    dispatch(setSelectedProduct(product))
  }, [])

  const renderScreen = (parentCategory, props) => {
    if (isLoading) {
      return <LoadingScreen {...props} />
    } else {
      switch (parentCategory) {
        case 'image-crop':
          return (
            <CropImage
              {...props}
              setCroppedImage={setCroppedImage}
              setImage={setImage}
              file={file}
              setCropCallback={setCropCallback}
            />
          )
        case 'garment-selection':
          return (
            <GarmentSelection
              {...props}
              file={image}
              category={category}
              onFindSimilar={handleOnFindSimilar}
              onSelectGarment={handleOnSelectGarment}
            />
          )
        case 'similar-products':
          return (
            <SimilarProducts
              {...props}
              onProductClick={handleOnSelectProduct}
              onProductSave={handleOnProductSave}
            />
          )
        case 'product-details':
          return (
            <ProductDetails
              {...props}
              product={selectedProduct}
              onProductSave={handleOnProductSave}
            />
          )
        default:
          return <ProductSearch {...props} />
      }
    }
  }

  return (
    <div style={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
      <Head title={translate('PAGE_STYLE_SEEKER')} />
      <PageBanner hide={isCordova} />
      {renderScreen(parentCategory, props)}
    </div>
  )
}

export default StyleSeeker
