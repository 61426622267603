import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'src/components/RouterDom'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _ from 'lodash'
import * as Yup from 'yup'
import CheckboxField from 'src/components/Fields/Checkbox'

import { useInternationalisation } from 'src/context'

import { statusSelector, attributesSelector } from 'src/store/account/selectors'
import { update } from 'src/store/account/actionCreators'
import Form from 'src/components/Form'
import SelectField from 'src/components/Fields/Select'
import DateField from 'src/components/Fields/Date'
import Message from 'src/components/Message'
import UserSvg from 'src/assets/User.svg'
import EmailSvg from 'src/assets/Email.svg'
import PhoneSvg from 'src/assets/Phone.svg'
import { titles } from 'src/config'
import validators from 'src/validators'
import cognitoService from 'src/services/cognitoService'

const validationSchema = Yup.object({
  title: validators.requiredString(),
  given_name: validators.requiredString(),
  family_name: validators.requiredString(),
  email: validators.email(),
  birthdate: validators.date(),
  phone_number: validators.requiredMobileNumber('sms_communications_permitted')
})

const FormWrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;
`

const getFieldSchema = (translate, locale, showEmailField) => {
  return [
    {
      name: 'title',
      label: translate('INPUT_TITLE'),
      autoComplete: 'honorific-prefix',
      component: SelectField,
      options: titles[locale]
    },
    {
      name: 'given_name',
      label: translate('INPUT_FIRST_NAME'),
      autoComplete: 'given-name',
      iconSvg: UserSvg,
      width: { sm: 1, md: 0.5 }
    },
    {
      name: 'family_name',
      label: translate('INPUT_LAST_NAME'),
      autoComplete: 'family-name',
      iconSvg: UserSvg,
      width: { sm: 1, md: 0.5 }
    },
    {
      name: 'email',
      label: translate('INPUT_EMAIL'),
      autoComplete: 'email',
      type: showEmailField ? 'email' : 'hidden',
      readOnly: true,
      iconSvg: showEmailField ? EmailSvg : '',
    },
    {
      name: 'phone_number',
      label: translate('INPUT_MOBILE_NUMBER'),
      autoComplete: 'tel',
      iconSvg: PhoneSvg
    },
    {
      name: 'sms_communications_permitted',
      label: translate('COMMUNICATION_PERMISSION_SMS'),
      type: 'hidden'
    },
    {
      name: 'birthdate',
      label: translate('INPUT_BIRTHDAY'),
      autoComplete: 'bday',
      component: DateField,
      helpText: 'INPUT_BIRTHDAY_HELP_TEXT'
    }
  ]
}

const Details = (props) => {
  const { translate, locale } = useInternationalisation()
  const dispatch = useDispatch()
  const attributes = useSelector(attributesSelector())
  const showEmailField = _.get(attributes, 'email_type') !== 'anonymous'

  const fieldSchema = React.useMemo(() => getFieldSchema(translate, locale, showEmailField), [
    translate
  ])
  const initialValues = React.useMemo(() => {
    return _.pick(attributes, fieldSchema.map((x) => x.name))
  }, [attributes])
  const onSubmit = React.useCallback((values) => {
    return dispatch(update(values))
  }, [])

  return (
    <FormWrapper>
      <Form
        fieldSchema={fieldSchema}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        parseError={cognitoService.parseError}
        buttonLabel={translate('INPUT_UPDATE')}
        successMessage={translate('INPUT_UPDATED_SUCCESS')}
      />
    </FormWrapper>
  )
}

export default Details
