import actionTypes from './actionTypes'
import { isRegExp } from 'util'
import _ from 'lodash'
const {
  LOGIN,
  RESTORE,
  LOGOUT,
  UPDATE,
  CREATE_SAVED,
  REMOVE_SAVED,
  CREATE_USER_EVENT,
  CREATE_USER_PROMOTION,
  FETCH_SAVED_PRODUCTS
} = actionTypes

const initialState = {}

const _mapProductResource = (product, savedId) => {
  const id = _.get(product, 'id')
  return {
    savedId,
    id,
    content_type: 'product',
    sys: {
      id,
      contentType: {
        sys: {
          id: 'product'
        }
      }
    },
    fields: {
      title: _.get(product, 'metadata.title'),
      summary: _.get(product, 'metadata.description'),
      brand: _.get(product, 'metadata.brandName'),
      retailer: _.get(product, 'metadata.Retailer'),
      currency: _.get(product, 'metadata.Currency'),
      rawPrice: _.get(product, 'metadata.price'),
      price: _.get(product, 'metadata.price')
        ? new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: _.get(product, 'metadata.Currency'),
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          }).format(_.get(product, 'metadata.price'))
        : null,
      listImage: {
        fields: {
          file: {
            url: _.get(product, 'imageUrl').replace('https:', '')
          }
        }
      },
      publishOnWebsite: true,
      publishOnPlusApp: true
    }
  }
}

const accountReducer = (state, action) => {
  const { type, attributes, error, status, provider } = action

  if (
    type === LOGIN ||
    type === LOGOUT ||
    type === UPDATE ||
    type === RESTORE ||
    type === CREATE_USER_EVENT ||
    type === CREATE_USER_PROMOTION
  ) {
    const newState = { ...state, error, status, actionType: type }
    if (attributes || attributes === null) {
      newState.attributes = attributes

      if (attributes && state.attributes) {
        const newSaveds = _.map(_.get(attributes, 'saveds'), (newAttribute) => {
          const foundSaved = _.find(
            _.get(state, 'attributes.saveds', []),
            (saved) => {
              return saved.id === newAttribute.id
            }
          )
          if (foundSaved) {
            return foundSaved
          }
          return newAttribute
        })
        newState.attributes.saveds = newSaveds
      }
    }
    return newState
  } else if (type === CREATE_SAVED) {
    if (attributes && state.attributes) {
      const newState = { ...state }
      newState.attributes.saveds = [
        ...(state.attributes.saveds || []),
        {
          content_id: attributes.content_id,
          content_type: attributes.content_type,
          id: attributes.id,
          user_id: attributes.user_id
        }
      ]
      return newState
    }
    return state || initialState
  } else if (type === REMOVE_SAVED) {
    if (attributes && state.attributes) {
      let newState = { ...state }
      const newSaveds = [...(state.attributes.saveds || [])].filter(
        (saved) => saved.content_id !== attributes.content_id
      )
      newState.attributes.saveds = newSaveds
      return newState
    }
    return state || initialState
  } else if (type === FETCH_SAVED_PRODUCTS) {
    if (attributes && state.attributes) {
      let newState = { ...state }
      const newSaveds = _.map(
        _.get(state, 'attributes.saveds', []),
        (saved) => {
          const newSavedProduct = _.find(attributes, (attributeSaved) => {
            return attributeSaved.id === saved.content_id
          })
          if (newSavedProduct) {
            return {
              ...saved,
              productResource: _mapProductResource(newSavedProduct, saved.id)
            }
          }
          return { ...saved }
        }
      )
      if (!newState) {
        newState = initialState
      }
      if (!newState.attributes) {
        newState.attributes = {}
      }
      newState.attributes.saveds = newSaveds
      return newState
    }
    return state || initialState
  } else {
    return state || initialState
  }
}

export default accountReducer
