// please refer to https://redux-resource.js.org/ for more
// information about how we're using resources in redux.

// specifically about resource actions:
// https://redux-resource.js.org/requests/request-actions

import { actionTypes } from 'redux-resource'
import _ from 'lodash'
import contentService from 'src/services/contentfulService'
import { logTrace } from 'src/utility'
import resourceTypes, { resourceTypesHashmap } from './resourceTypes'
import { requestSelector } from './selectors'
import reducerKey from './key'
import { getCentreId, getCentreKey } from 'src/config'

const fetchResourceFactory = ({ singular }) => {
  return ({
    resourceType,
    requestKey,
    contentTypes,
    id,
    where,
    select,
    order,
    skip,
    limit,
    next,
    include,
    host,
    centreId = getCentreId({ host }),
    locale,
    augmentWithApi = false
  }) => {

    // We want to always lowercase `urlName` for comparison and matching with contentful.
    // We also want the user of the site to be able to enter any case they want.
    const urlName = _.get(where, 'fields.urlName')
    if (urlName) {
      _.set(where, 'fields.urlName', _.toLower(urlName))
    }
    // We want to escape any characters such as ampersands the user may have entered before
    // fetching from contentful.
    const query = _.get(where, 'fields.query')
    if (query) {
      _.set(where, 'fields.query', _.escape(query))
    }
    const requestPropertiesFromAction = {
      id,
      where,
      select,
      order,
      skip,
      limit,
      next,
      include,
      centreId,
      locale,
      augmentWithApi
    }

    return (dispatch, getState) => {
      const dispatchError = (error) => {
        console.error(error)
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          resourceType,
          resources: [id],
          requestKey,
          requestProperties: {
            errorMessage: actionError && actionError.message,
            ...requestPropertiesFromAction
          }
        })
      }

      let actionError
      if (!resourceTypesHashmap[resourceType]) {
        actionError = new Error(
          `resourceType "${resourceType}" is invalid.` +
          `resourceType must be one of: ${resourceTypes.join(', ')}`
        )
      }

      if (!singular && !centreId && resourceType !== 'centre') {
        actionError = new Error(`no centre id`)
      }

      if (actionError) {
        return dispatchError(actionError)
      }

      const existingRequest = requestSelector({ resourceType, requestKey })(
        getState()
      )
      const { total } = existingRequest || {}

      // set pending state
      dispatch({
        type: actionTypes.READ_RESOURCES_PENDING,
        resourceType,
        resources: singular ? [id] : undefined,
        requestKey,
        requestProperties: { total, ...requestPropertiesFromAction }
      })

      const fetchFunction = singular
        ? contentService.fetchResource
        : contentService.fetchResources

      if (next) {
        // work out skip value to get next results
        const { resources, limit: existingLimit } = existingRequest || {}
        skip = _.get(resources, 'length')
        if (!limit && existingLimit) limit = existingLimit
      }

      return fetchFunction({
        resourceType,
        requestKey,
        contentTypes,
        id,
        where,
        select,
        order,
        centreId,
        centreKey: getCentreKey({ host }),
        skip,
        limit,
        include,
        locale,
        augmentWithApi
      })
        .then((result) => {
          let newResourcesValue = []
          let requestProperties = requestPropertiesFromAction

          if (!result) {
            throw new Error(`no result`)
          }

          // we need to do this because the mergeResources functionality built into redux-resource
          // only merges top-level keys, and we need to merge resource.field objects
          const state = getState()
          const selectExistingResource = (id) =>
            _.get(state, [reducerKey, resourceType, 'resources', id])
          const prepareResource = (newResource) => {
            if (newResource && newResource.id) {
              newResource = {
                ...newResource,
                fields: _.omit(newResource.fields, ['centre'])
              }
              const oldResource = selectExistingResource(newResource.id)
              if (oldResource) {
                const mergedResource = {
                  ...newResource,
                  fields: {
                    ...oldResource.fields,
                    ...newResource.fields
                  }
                }
                logTrace(`a ${resourceType} resource was re-fetched`, {
                  newResource,
                  oldResource,
                  mergedResource,
                  requestKey
                })
                return mergedResource
              } else {
                return newResource
              }
            } else {
              return newResource
            }
          }

          if (singular) {
            const singleResource = result
            newResourcesValue = [prepareResource(singleResource)]
          } else {
            const { resources, ...rest } = result
            if (requestKey) {
              requestProperties = { ...requestPropertiesFromAction, ...rest }
            }
            if (!next) {
              newResourcesValue = _.map(resources, prepareResource)
            } else {
              // append resources from next page to existing resources
              newResourcesValue = [
                ..._.get(existingRequest, 'resources', []),
                ..._.map(resources, prepareResource)
              ]
            }
          }

          dispatch({
            type: actionTypes.READ_RESOURCES_SUCCEEDED,
            resourceType,
            resources: newResourcesValue,
            requestKey,
            requestProperties,
            mergeResources: false
          })
        })
        .catch((error) => {
          dispatchError(error)
        })
    }
  }
}

const fetchResource = fetchResourceFactory({ singular: true })
const fetchResources = fetchResourceFactory({ singular: false })

export { fetchResource, fetchResources }
