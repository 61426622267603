import React, { createRef, useEffect } from 'react'

/**
 * Fixes a bug with React Slick Library in that a user can still scroll the window vertically when using the horizontal carousel.
 * [React Slick issue #1240]{@link https://github.com/akiran/react-slick/issues/1240}
 * @param {object} props
 * @param {Array} props.children
 */
const HorizontalScrollDisabler = ({ children }) => {
    let firstClientX, clientX;

    const preventTouch = e => {
        const minValue = 5; // threshold
        clientX = e.touches[0].clientX - firstClientX;
        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;

            return false;
        }
    };

    const touchStart = e => {
        firstClientX = e.touches[0].clientX;
    };


    let containerRef = createRef();

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("touchstart", touchStart);
            containerRef.current.addEventListener("touchmove", preventTouch, {
                passive: false
            });
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("touchstart", touchStart);
                containerRef.current.removeEventListener("touchmove", preventTouch, {
                    passive: false
                });
            }
        };
    });

    return (
        <div ref={containerRef}>
            {children}
        </div>
    )
}

export default HorizontalScrollDisabler