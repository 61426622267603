import forEach from 'lodash/map'
import reduce from 'lodash/reduce'
import _get from 'lodash/get'
import _ from 'lodash'
import { centres } from '@redant/hammerson-plus-config'

import env, { isBrowser, isCordova } from 'src/env'

const hostHashmap = reduce(
  centres,
  (acc, environments, centreKey) => {
    forEach(
      environments,
      ({ host: hostConfig, centreId, centreConfig, pointrId }, environment) => {
        if (hostConfig) {
          _.castArray(hostConfig).forEach((host) => {
            acc[host] = {
              centreKey,
              environment,
              centreId,
              centreConfig,
              host: hostConfig,
              pointrId
            }
          })
        }
      }
    )
    return acc
  },
  {}
)

const getPropertyFromHost = (key) => (host) => {
  let sanitizedHost = (host || '').replace('www.', '')
  const properties = hostHashmap[sanitizedHost] || {}
  const { environment } = properties
  if (environment === env.envKey) return properties[key]
}

export const getProperty = (key) => ({ host } = {}) => {
  let property
  if (env.centreKey) {
    if (key === 'centreKey') {
      property = env.centreKey
    } else {
      property = _get(centres, [env.centreKey, env.envKey, key])
    }
  } else {
    if (isBrowser) {
      property = getPropertyFromHost(key)(window.location.host)
    } else {
      property = getPropertyFromHost(key)(host)
    }
  }

  return property
}

export const getCentreId = getProperty('centreId')
export const getPointrId = getProperty('pointrId')
export const getCentreConfig = getProperty('centreConfig')
export const getCentreKey = getProperty('centreKey')
export const getHost = () => _.castArray(getProperty('host')())[0]

export const getMapsAPIKey = ({ host } = {}) => {
  const centreConfig = getCentreConfig({ host }) || {}
  const { ios, android, web } = centreConfig.GoogleMapsAPIKey || {}
  const APIKey = isCordova
    ? _get(window, 'device.platform') === 'iOS'
      ? ios
      : android
    : web
  return APIKey
}

export { centres }
