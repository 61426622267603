import React, { Fragment } from 'react'
import styled from 'styled-components'
import media from 'src/styling/media'
import colors from 'src/styling/colors'
import { closeNav } from 'src/store/ui/actionCreators'
import { getAssetSrc } from 'src/utility'
import { useDispatch } from 'react-redux'

import { Link } from 'src/components/RouterDom'

const Container = styled.div`
  display: flex;
  width: 100%;
  background-image: ${(props) => `url(${props.src})`};
  background-color: #eaeaea;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 25px;
  justify-content: center;
  &:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.6s background-color;
  }
  &:hover {
    &:before {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
`

const Content = styled.div`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 25px;
  width: 100%;
  max-width: 550px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    width: 100%;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 700;
    margin: 0 0 20px 0;
    ${media.greaterThan('md')`
      font-size: 40px;
    `}
    ${media.greaterThan('lg')`
      font-size: 55px;
    `}
  }
  p {
    width: 100%;
    display: none;
    font-weight: 300;
    line-height: 1.4;
    margin: 0 0 25px 0;
    ${media.greaterThan('lg')`
      display: block;
    `}
    @media (max-height: 767px) {
      display: none;
    }
  }
`
const Button = styled(Link)`
  font-weight: 700;
  padding: 20px 30px;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  background: #fff;
  color: ${colors.textColor};
  line-height: 1;
  &:hover,
  &:active,
  &:focus {
  }
`

const RenderLink = (props) => {
  if (/^(http|https)?:\/\//.test(props.to)) {
    props = { ...props, href: props.to, as: 'a' }
  }
  return <Button {...props}>{props.children}</Button>
}

const PromotionCard = (props) => {
  const { title, description, image, buttonLabel, buttonLink } = props
  const dispatch = useDispatch()
  return (
    <Container src={getAssetSrc(image)}>
      <Content>
        <h2>{title}</h2>
        <p>{description}</p>
        <RenderLink to={buttonLink} onClick={() => dispatch(closeNav())}>
          {buttonLabel}
        </RenderLink>
      </Content>
    </Container>
  )
}

export default PromotionCard
