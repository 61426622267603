import React from 'react'
import styled from 'styled-components'
import AssetIcon from 'src/components/AssetIcon'
import HiddenText from 'src/components/HiddenText'
import ArrowSvg from 'src/assets/Arrow.svg'
import { Link } from 'src/components/RouterDom'

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const H2 = styled.h2`
  font-size: 22px;
  font-weight: 600;
`

const FeaturesCarouselHeading = (props) => {
  const { heading, to } = props
  return (
    <Wrapper>
      <H2>{heading}</H2>
      <Link to={to}>
        <AssetIcon svg={ArrowSvg} size={30} />
        <HiddenText>{heading}</HiddenText>
      </Link>
    </Wrapper>
  )
}

export default FeaturesCarouselHeading
