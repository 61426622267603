import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'

const HoverBold = styled.span`
  font-weight: normal;

  &:hover, &:active {
    font-weight: bold;
  }

  ${(p) => (p.active ? `font-weight: bold;` : '')}

  &::after {
    display: block;
    content: "${(p) => p.children}";
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`

export default HoverBold
