import React from 'react'
import { SkipNavLink } from '@reach/skip-nav'
import styled from 'styled-components'
import vars from 'src/styling/vars'
import colors from 'src/styling/colors'
import { useInternationalisation } from 'src/context'

const StyledSkipNavLink = styled(SkipNavLink)`
  height: 1px;
  left: -999px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
  z-index: -999;
  color: transparent;

  &:focus,
  &:active {
    background-color: white;
    color: ${colors.textColor};
    height: auto;
    left: 10px;
    padding: 10px;
    position: fixed;
    top: 10px;
    width: auto;
    z-index: ${vars.zIndex.skipNav};
  }
`

export default () => {
  const { translate } = useInternationalisation()
  return <StyledSkipNavLink>{translate('SKIP_NAV_LINK')}</StyledSkipNavLink>
}
