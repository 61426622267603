import React, { useEffect } from 'react'
import Page, { getPageData } from 'src/components/Page'
import { useDispatch } from 'react-redux'
import RouteStatus from 'src/components/RouteStatus'

const NotFound = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    getPageData({ ...props, dispatch }, { urlName: 'not-found' })
  }, [])

  return (
    <React.Fragment>
      <RouteStatus statusCode={404}>
        <Page box inner requestKey={'not-found'} />
      </RouteStatus>
    </React.Fragment>
  )
}

NotFound.identifier = 'NOT_FOUND'
NotFound.getData = (props) => {
  return getPageData(props, { urlName: 'not-found' })
}

export default NotFound
