import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'src/components/RouterDom'
import _ from 'lodash'
import AssetIcon from 'src/components/AssetIcon'
import HeartOutlineSvg from 'src/assets/Heart.svg'
import HeartFilledSvg from 'src/assets/HeartFill.svg'

import colors from 'src/styling/colors'

const HeartWrapper = styled.button`
  background: ${(p) => p.color || '#262A30'};
  border-radius: 100%;
  height: ${(p) => p.size || 30}px;
  width: ${(p) => p.size || 30}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${(p) => p.position || 'absolute'};
  right: 0;
  top: ${(p) => p.top || 0}px;
  margin: 10px;
  z-index: 10;
  color: ${(p) => p.iconColor || '#FFFFFF'};
`

const PulseAssetIcon = styled(AssetIcon)`
  animation: 0.8s ${keyframes`
    0% { transform: scale(1); }
    25% { transform: scale(1.2); }
    40% { transform: scale(1); }
    60% { transform: scale(1.2); }
    100% { transform: scale(1); }
  `} 2;
`

const ShakeAssetIcon = styled(AssetIcon)`
  animation: 0.8s ${keyframes`
    10%, 90% { transform: translate3d(-1px, 0, 0); }
    20%, 80% { transform: translate3d(2px, 0, 0); }
    30%, 50%, 70% { transform: translate3d(-3px, 0, 0);  }
    40%, 60% { transform: translate3d(3px, 0, 0);  }
  `} 1;
`

const SavedHeart = ({
  saved,
  size,
  handleOnHeartClick,
  position,
  top,
  iconColor,
  wrapperColor
}) => {
  const [animation, setAnimation] = React.useState(null)
  const AnimatedAssetIcon = animation
    ? animation === 'pulse'
      ? PulseAssetIcon
      : ShakeAssetIcon
    : AssetIcon

  const handleOnHeartWrapperClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    handleOnHeartClick()
    if (!saved) {
      setAnimation('pulse')
    } else {
      setAnimation('shake')
    }
  }

  return (
    <HeartWrapper
      position={position}
      size={size}
      color={wrapperColor}
      top={top}
      iconColor={iconColor || '#ffffff'}
      onClick={handleOnHeartWrapperClick}
    >
      <AnimatedAssetIcon
        size={size * 0.7 || 30 * 0.7}
        svg={saved ? HeartFilledSvg : HeartOutlineSvg}
      />
    </HeartWrapper>
  )
}

export default SavedHeart
