import React, { useMemo, useState, useEffect, useCallback } from 'react'
import qs from 'query-string'
import _flow from 'lodash/flow'
import _get from 'lodash/get'
import _defaults from 'lodash/defaults'
import _toNumber from 'lodash/toNumber'
import _isNaN from 'lodash/isNaN'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _has from 'lodash/has'
import _size from 'lodash/size'
import _ from 'lodash'
import { useRequest } from 'src/store/resources/hooks'
import { fetchResources } from 'src/store/resources/actionCreators'
import { getIdFromParams, getFirstResource, getResourceId, getEnableRegistrationLogIn } from 'src/utility'
import contentfulService from 'src/services/contentfulService'
import DetailsPage from 'src/components/DetailsPage'
import NotFound from 'src/pages/NotFound'
import { scan } from 'src/services/appServices/qrService'
import { attributesSelector } from 'src/store/account/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { isCordova } from 'src/env'
import { createUserPromotion } from 'src/store/account/actionCreators'
import { useInternationalisation } from 'src/context'
import { useContentTypeCustomisations } from './hooks'
import usePromiseState from 'src/hooks/usePromiseState'
import Button from 'src/components/Button'
import DefaultModal from 'src/components/Modal/DefaultModal'
import TermsAndConditions from './termsAndConditions'
import Head from 'src/components/Head'
import RedemptionError from './redemptionError'
import Spacing from 'src/components/Spacing'
import LoadingPromotion from './loading'
import PlusPromoTile from 'src/components/PlusPromoTile'
import { getCentreConfig } from 'src/config'

const resourceType = 'promotion'

const Promotion = (props) => {
  const { translate, moment } = useInternationalisation()
  const id = getIdFromParams(props)
  const [isModalOpen, setModal] = useState(false)
  const [agreedToTerms, setTermsAgreement] = useState(null)
  const [isRedeemedCode, setIsRedeemedCode] = useState(false)
  const [redemptionError, setRedemptionError] = useState(null)
  const toggleModal = () => setModal(!isModalOpen)
  const onAccept = () => {
    setTermsAgreement(true)
    toggleModal()
  }
  const onDecline = () => {
    setTermsAgreement(false)
    toggleModal()
  }
  const request = useRequest({
    resourceType,
    requestKey: id
  })
  const dispatch = useDispatch()
  const { isLoading, error, wrapPromise } = usePromiseState()
  const {
    isLoading: isLoadingScan,
    wrapPromise: wrapPromiseScan
  } = usePromiseState()
  const user = useSelector(attributesSelector())
  const pathname = _get(props, 'location.pathname')
  const enableRegistrationLogIn = getEnableRegistrationLogIn(props)

  const resource = getFirstResource(request)
  const {
    categoryType,
    totalEntries,
    title,
    startDate,
    isPlusExclusive,
    offerCategories,
    competitionCategories
  } = _get(resource, 'fields', {})
  const totalRedemptions = _toNumber(
    _get(resource, 'fields.totalRedemptions', null)
  )
  const contentTypeCustomisations = useContentTypeCustomisations({
    categoryType
  })
  const promotionId = getResourceId(resource)
  const redemptionDetails = _defaults(
    {},
    _get(resource, 'fields.redemptionDetails', {}),
    {
      isRedeemable: false,
      visitsRequiredToCompleteReward: null,
      limitTotal: null,
      limitTotalType: ''
    }
  )

  const plusRedeemable = redemptionDetails.isRedeemable && isCordova
  const isBeforeStartDate = moment().isBefore(moment(startDate))

  const enterWithTCs =
    _has(resource, 'fields.competitionTcsLink') &&
    contentTypeCustomisations.interactionType === 'enter'
  const {
    count,
    hasRedeemedToday,
    hasRedeemedAll,
    hasRedeemedAllUniversally,
    remaining
  } = useMemo(() => {
    const personalRedeemRemaining = (() => {
      const remainingField = _get(
        {
          Reward: 'visitsRequiredToCompleteReward',
          Offer: 'limitPerCustomer'
        },
        categoryType
      )
      if (redemptionDetails[remainingField] === null) return undefined
      const number = _toNumber(redemptionDetails[remainingField])
      if (_isNaN(number)) {
        return undefined
      }
      return number
    })()
    let hasRedeemedAll = false
    let hasRedeemedToday = false
    let count = 0
    let remaining = null
    let hasRedeemedAllUniversally = false
    if (user) {
      const promotions = _filter(user.user_promotions, {
        promotion_id: promotionId
      })
      count = _size(promotions)
      hasRedeemedToday = !!_find(promotions, ({ createdAt }) =>
        moment(createdAt).isSame(moment(), 'date')
      )
      hasRedeemedAll = count >= personalRedeemRemaining || agreedToTerms

      if (categoryType === 'Competition') {
        hasRedeemedAll = !!count
        hasRedeemedToday = false
      }
      if (categoryType === 'Offer') {
        const total = _toNumber(redemptionDetails.limitTotal)
        hasRedeemedAllUniversally = totalRedemptions >= total
        if (!redemptionDetails.limitPerCustomer && totalRedemptions) {
          redemptionDetails.limitPerCustomer = 1
        }
        if (redemptionDetails.limitPerCustomer) {
          const total = _toNumber(redemptionDetails.limitPerCustomer) || 1
          hasRedeemedAll = _toNumber(count || 0) >= total
        }
        if (total && !hasRedeemedAllUniversally) {
          remaining = total - totalRedemptions
        }
        if (hasRedeemedAllUniversally && hasRedeemedToday) {
          hasRedeemedAll = true
        }
      }
      if (categoryType === 'Reward') {
        remaining = personalRedeemRemaining
      }
    }

    return {
      count,
      hasRedeemedToday,
      hasRedeemedAll,
      remaining,
      hasRedeemedAllUniversally
    }
  }, [resource, user, agreedToTerms, isRedeemedCode])

  useEffect(() => {
    const agreedToTermsOnly = !plusRedeemable && enterWithTCs && agreedToTerms
    const scannedOnly = !enterWithTCs && plusRedeemable && isRedeemedCode
    const scanWithTerms =
      enterWithTCs && plusRedeemable && agreedToTerms && isRedeemedCode
    if (scannedOnly || agreedToTermsOnly || scanWithTerms) {
      wrapPromise(
        dispatch(
          createUserPromotion({
            interaction_type: contentTypeCustomisations.interactionType,
            promotion_id: promotionId,
            category: offerCategories || competitionCategories || null
          })
        )
      )
    }
  }, [agreedToTerms, isRedeemedCode])

  const onClick = useCallback(
    async (event) => {
      event.preventDefault()
      try {
        if (plusRedeemable) {
          if (redemptionError) {
            setRedemptionError(null)
          }

          const resolvedScan = await wrapPromiseScan(scan())
          if (!resolvedScan) {
            throw new Error('CHECK_PERMISSIONS')
          }
          const cancelled = _.get(resolvedScan, 'cancelled')
          const code = _.get(resolvedScan, 'text')
          if (!cancelled && !code) {
            throw new Error('TRY_AGAIN')
          }
          if (cancelled) {
            return
          }
          const storeCode = _get(
            resource,
            'fields.retailUnit.fields.qrNumerical'
          )

          if (_toNumber(code) !== storeCode) {
            throw new Error('INVALID_QR_CODE')
          }
          setIsRedeemedCode(true)
        }

        if (enterWithTCs) {
          toggleModal()
        }
      } catch (error) {
        toggleModal()
        setRedemptionError({
          title: translate(`${error.message}_ERROR_TITLE`),
          body: translate(`${error.message}_ERROR_BODY`)
        })
      }
    },
    [resource]
  )

  const buttonProps = useMemo(() => {
    return user
      ? {
        text: translate(
          (() => {
            switch (true) {
              case isBeforeStartDate:
                return contentTypeCustomisations.buttonBeforeStartDate
              case hasRedeemedAll:
                return contentTypeCustomisations.buttonRedeemed
              case hasRedeemedToday:
                return contentTypeCustomisations.buttonRedeemedToday
              case hasRedeemedAllUniversally:
                return contentTypeCustomisations.buttonRedeemedUniversally
              case remaining > 0:
                return contentTypeCustomisations.buttonRemaining
              case isLoading:
                return contentTypeCustomisations.buttonLoading
              case error:
                return contentTypeCustomisations.buttonError
              default:
                return contentTypeCustomisations.buttonRedeem
            }
          })(),
          {
            count: _toNumber(count) + 1,
            limit: _toNumber(remaining || 0),
            startDate: moment(startDate).format('DD MMM')
          }
        ),
        onClick
      }
      : {
        text: translate(
          isBeforeStartDate
            ? contentTypeCustomisations.buttonBeforeStartDate
            : contentTypeCustomisations.buttonSignUp,
          {
            startDate: moment(startDate).format('DD MMM')
          }
        ),
        to: isBeforeStartDate
          ? null
          : `/login?${qs.stringify({
            redirectTo: pathname
          })}`
      }
  }, [resource, contentTypeCustomisations])

  if (!resource && !request._status.pending) {
    return <NotFound />
  }
  return (
    <DetailsPage
      request={request}
      LoadingPlaceholder={LoadingPromotion}
      isPlusExclusive={isPlusExclusive}
    >
      <Head
        resource={resource}
        title={isCordova ? _.upperFirst(translate(_.toUpper(categoryType))) : title}
      />
      {isModalOpen && (
        <DefaultModal
          onClose={!redemptionError && toggleModal}
          isModalOpen={isModalOpen}
        >
          {!!redemptionError ? (
            <RedemptionError
              title={redemptionError.title}
              onClose={toggleModal}
            >
              {redemptionError.body}
            </RedemptionError>
          ) : (
              <TermsAndConditions
                resource={resource}
                onAccept={onAccept}
                onDecline={onDecline}
              />
            )}
        </DefaultModal>
      )}
      {isPlusExclusive && !isCordova ? (
        <PlusPromoTile />
      ) : (
          (plusRedeemable || enterWithTCs) && enableRegistrationLogIn && (
            <Button
              buttonType='primary'
              isLoading={isLoading || isLoadingScan}
              disabled={
                isBeforeStartDate ||
                isLoading ||
                isLoadingScan ||
                hasRedeemedAll ||
                hasRedeemedToday ||
                hasRedeemedAllUniversally ||
                error
              }
              {...buttonProps}
            >
              {buttonProps.text}
            </Button>
          )
        )}
      <Spacing height={20} />
      {contentfulService.renderRichText(_get(resource, 'fields.about'))}
    </DetailsPage>
  )
}

Promotion.getData = async (props) => {
  const id = getIdFromParams(props)
  const { augmentWithApi = true } = getCentreConfig() || {}
  const { dispatch, host, locale } = props
  return dispatch(
    fetchResources({
      locale,
      where: { 'fields.urlName': id },
      resourceType,
      requestKey: id,
      host,
      augmentWithApi,
      include: 2
    })
  )
}
Promotion.identifier = 'promotion'

export default Promotion
