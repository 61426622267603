import React from 'react'
import styled from 'styled-components'
import { isCordova } from 'src/env'

const StyledIframe = styled.iframe`
${() => isCordova ? `position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;` : ''}
height: ${() => isCordova ? '100%' : '50vh'};
width: 100%;
${({ setMinHeight }) => setMinHeight ? 'min-height: 600px;' : ''}
${({ customStyle }) => customStyle ? customStyle : ''}
padding: 30px;
`
const IFrameMap = React.forwardRef((props, ref) => {
    return <StyledIframe
        allow="geolocation"
        title={props.title}
        name="iframe_map"
        ref={ref}
        scrolling="no"
        allowtransparency="1"
        src={props.src}
        frameBorder="0"
        fillMap={props.fillMap}
        setMinHeight={!isCordova}
        customStyle={props.customStyle}
    />
})

export default IFrameMap