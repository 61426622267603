import React, { Fragment } from 'react'
import styled from 'styled-components'
import { getAssetSrc } from 'src/utility'
import contentfulService from 'src/services/contentfulService'
import Spinner from 'src/components/Spinner'
import Container from 'src/components/Container'
import ContentWithButtons from 'src/components/ContentWithButtons'
import OpeningTimes from 'src/components/OpeningTimes'
import Spacing from 'src/components/Spacing'

import InformationBar from './InformationBar'

const SpinnerContainer = styled(Container)`
  height: 620px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Panel = ({
  status,
  carparkLocation,
  currentCarpark,
  currentCarparkId,
  openingTimes,
  tariffInformation,
  usefulInformation,
  translate,
  showParkingAttachmentCTA,
  attachmentExists
}) => {
  return status.pending ? (
    <SpinnerContainer verticalGutter>
      <Spinner />
    </SpinnerContainer>
  ) : (status.succeeded && carparkLocation) && (
    <Fragment>
      <InformationBar {...currentCarpark} />
      <Spacing height={30} />
      {openingTimes ? (
        <ContentWithButtons
          id={`opening-hours-container`}
          title={translate('OPENING_HOURS')}
          buttons={[
            {
              link: `/opening-hours`,
              title: translate('CENTRE_OPENING_HOURS'),
              type: 'primary',
              width: 313
            }
          ]}
        >
          <OpeningTimes data={openingTimes} />
        </ContentWithButtons>
      ) : null}
      <Spacing height={30} />
      {tariffInformation ? (
        <ContentWithButtons
          title={translate('TARIFF')}
          id={`parking-tariff-container`}
        >
          {contentfulService.renderRichText(tariffInformation)}
        </ContentWithButtons>
      ) : null}
      {usefulInformation && showParkingAttachmentCTA && attachmentExists ? (
        <ContentWithButtons
          {...currentCarpark}
          title={translate('USEFUL_INFORMATION')}
          id={`useful-information-container`}
          buttons={[
            {
              onClick: () => {
                let ctaDownloadUrl = getAssetSrc(
                  currentCarpark.fields.attachment
                )
                return window.open(ctaDownloadUrl, '_blank')
              },
              title: translate('PARKING_DOWNLOAD'),
              type: 'primary',
              width: 313
            }
          ]}
        >
          {contentfulService.renderRichText(usefulInformation)}
        </ContentWithButtons>
      ) : usefulInformation ? (
        <ContentWithButtons
          {...currentCarpark}
          title={translate('USEFUL_INFORMATION')}
          id={`useful-information-container`}
        >
          {contentfulService.renderRichText(usefulInformation)}
        </ContentWithButtons>
      ) : null}
    </Fragment>
  )
}

export default Panel