import React, { Fragment } from 'react'
import _includes from 'lodash/includes'
import styled from 'styled-components'

import vars from 'src/styling/vars'
import media from 'src/styling/media'
import colors from 'src/styling/colors'

const gradientHeight = { sm: 177, md: 170, lg: 170 }

const HeaderGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  ${media.forEach({
    values: gradientHeight,
    getStyle: (val) => `height: ${val}px`
  })};
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`

export default HeaderGradient
