import _map from 'lodash/map'
import _flatMap from 'lodash/flatMap'
import _find from 'lodash/find'
import _flow from 'lodash/flow'
import _groupBy from 'lodash/groupBy'
import _reject from 'lodash/reject'
import _filter from 'lodash/filter'
import _compact from 'lodash/compact'
import _isEmpty from 'lodash/isEmpty'
import _mapValues from 'lodash/mapValues'
import _forEach from 'lodash/forEach'
import _isNull from 'lodash/isNull'
import _toLower from 'lodash/toLower'
import { fetchResources } from 'src/store/resources/actionCreators'
import { useRequest } from 'src/store/resources/hooks'
import { manyRequestSelector } from 'src/store/resources/selectors'
import { useSelector } from 'react-redux'
import { useNav } from 'src/pages/Root/Header/hooks'
import { getFirstResourceFields, getUrl, getFields, getSys } from 'src/utility'
import { getCentreId } from 'src/config'

export const models = {
  retailUnits: {
    contentType: 'retailUnit'
  }
}

export const fetchSitemapResources = ({ host, dispatch, locale }) => {
  const centreId = getCentreId({ host })
  return Promise.all(
    _map(models, ({ contentType }) => {
      return dispatch(
        fetchResources({
          locale,
          resourceType: contentType,
          requestKey: contentType,
          host,
          centreId
        })
      )
    })
  )
}

export const useSitemapResources = () => {
  const nav = useNav()
  const layout = useRequest({
    resourceType: 'layout',
    requestKey: 'layout'
  })

  const categories = useSelector(
    manyRequestSelector(
      _mapValues(models, ({ contentType }) => {
        return {
          resourceType: contentType,
          requestKey: contentType
        }
      })
    )
  )
  return {
    nav,
    categories,
    layout
  }
}

export const generateMap = ({ categoriesReq, nav, layoutReq, match }) => {
  const linksRegistry = new Map()
  const categories = _mapValues(categoriesReq, (category) => {
    if (linksRegistry.has(category.link)) return []
    linksRegistry.set(category.link, {
      updatedAt: null,
      priority: 1
    })
    return _groupBy(
      _flatMap(category.resources, (resource) => {
        const fields = getFields(resource)
        return _map(fields.categories, (category) => {
          const link = getUrl(resource)
          linksRegistry.set(link, {
            updatedAt: category.sys.updatedAt,
            priority: 0.6
          })
          return {
            link,
            label: fields.name || fields.title,
            category: getFields(category).title
          }
        })
      }),
      'category'
    )
  })

  const navCategories = []
  _forEach(nav, (navItem) => {
    if (linksRegistry.has(navItem.link)) return null
    linksRegistry.set(navItem.link, {
      updatedAt: navItem.updatedAt,
      priority: 1
    })

    _forEach(navItem.pages, (page) => {
      linksRegistry.set(page.link, { updatedAt: page.updatedAt, priority: 0.5 })
    })

    if (navItem.subcategories) {
      navCategories.push({
        ...navItem,
        subcategories: (() => {
          return _reject(
            _map(
              navItem.subcategories || categories[_toLower(navItem.label)],
              (subcategory = {}) => {
                if (linksRegistry.has(subcategory.link)) return null
                linksRegistry.set(subcategory.link, {
                  priority: 0.7,
                  updatedAt: null
                })
                return {
                  ...subcategory,
                  pages: (categories[_toLower(navItem.label)] ||
                    categories['retailUnits'])[subcategory.title]
                }
              }
            ),
            _isNull
          )
        })()
      })
      return
    }

    const flattenCategories = _flatMap(categories[_toLower(navItem.label)])

    navCategories.push({
      ...navItem,
      pages: [...(navItem.pages || []), ...flattenCategories]
    })
  })

  const layout = getFirstResourceFields(layoutReq)

  const footerPages = _filter(
    _map(
      [
        ...(layout.footerPrimaryLinks || []),
        ...(layout.footerSecondaryLinks || [])
      ],
      (page) => {
        const link = getUrl(page)
        const fields = getFields(page)
        const sys = getSys(page)
        return {
          link,
          label: fields.title,
          externalUrl: fields.externalUrl,
          updatedAt: sys.updatedAt
        }
      }
    ),
    (linkItem) => {
      const { link, externalUrl, updatedAt } = linkItem
      if (externalUrl) return false
      if (linksRegistry.has(link)) return false
      if (link === match.path) return false
      linksRegistry.set(link, { updatedAt, priority: 0.2 })
      return true
    }
  )
  linksRegistry.delete(undefined)
  return {
    hierarchical: navCategories.concat(footerPages),
    flat: linksRegistry
  }
}
