import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _range from 'lodash/range'
import _concat from 'lodash/concat'
import VisuallyHidden from '@reach/visually-hidden'
import { isCordova } from 'src/env'

import FieldBase from './common/FieldBase'
import SelectInput from './common/SelectInput'
import InputBase from './common/InputBase'
import { useInternationalisation } from 'src/context'
import DropdownSvg from 'src/assets/DropdownArrow.svg'

const padNumber = (digits) => (x) => `0000${x}`.slice(-1 * digits)
const pad2 = padNumber(2)
const pad4 = padNumber(4)

const dayOptions = (defaultOptions) =>
  _concat(
    [defaultOptions],
    _range(1, 32).map((x) => ({
      value: x,
      label: pad2(x)
    }))
  )
const monthOptions = (defaultOptions) =>
  _concat(
    [defaultOptions],
    _range(1, 13).map((x) => ({
      value: x,
      label: pad2(x)
    }))
  )
const yearOptions = ({ defaultOptions, moment }) =>
  _concat(
    [defaultOptions],
    _range(1900, moment().year() + 1)
      .map((x) => ({ value: x, label: `${x}` }))
      .reverse()
  )

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    flex: 1 1 auto;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
`

const DateInput = (props) => {
  const { value, onChange, setFieldValue, name, errorMessage } = props
  const { translate, moment } = useInternationalisation()
  const [yearValue, monthValue, dayValue] = React.useMemo(() => {
    const emptyValue = ['', '', '']
    if (!value || typeof value !== 'string') return emptyValue
    const [yearValue, monthValue, dayValue] = value.split('-')
    return [Number(yearValue), Number(monthValue), Number(dayValue)]
  }, [value])

  const handleChange = (year, month, day) => {
    let newValue = `${pad4(year)}-${pad2(month)}-${pad2(day)}`
    if (newValue === '0000-00-00') {
      newValue = undefined
    }
    if (setFieldValue) {
      setFieldValue(name, newValue)
    } else if (onChange) {
      onChange({ target: { value: newValue } })
    }
  }

  const handleChangeDay = (e) => {
    handleChange(yearValue, monthValue, e.target.value)
  }
  const handleChangeMonth = (e) => {
    handleChange(yearValue, e.target.value, dayValue)
  }
  const handleChangeYear = (e) => {
    handleChange(e.target.value, monthValue, dayValue)
  }

  const handleNativeChange = (moment) => (e) => {
    const momentValue = moment(e.target.value, 'YYYY-MM-DD')
    const nativeYear = momentValue.year()
    const nativeMonth = momentValue.month() + 1
    const nativeDay = momentValue.date()
    handleChange(nativeYear, nativeMonth, nativeDay)
  }

  const commonProps = {
    component: SelectInput,
    iconSvg: DropdownSvg,
    errorMessage
  }

  if (isCordova) {
    const nativeInputProps = {
      iconSvg: DropdownSvg,
      errorMessage,
      onChange: handleNativeChange(moment),
      type: 'date',
      value
    }
    return <InputBase {...nativeInputProps} />
  }

  return (
    <InputsWrapper>
      <label for={translate('DATE_INPUT_LABEL_DAY')}>
        <VisuallyHidden>{translate('DATE_INPUT_LABEL_DAY')}</VisuallyHidden>
      </label>
      <InputBase
        options={dayOptions({
          value: '',
          label: translate('DATE_DEFAULT_INPUT_VALUE_DAY')
        })}
        value={dayValue}
        onChange={handleChangeDay}
        id={translate('DATE_INPUT_LABEL_DAY')}
        date={true}
        {...commonProps}
      />
      <label for={translate('DATE_INPUT_LABEL_MONTH')}>
        <VisuallyHidden>{translate('DATE_INPUT_LABEL_MONTH')}</VisuallyHidden>
      </label>
      <InputBase
        options={monthOptions({
          value: '',
          label: translate('DATE_DEFAULT_INPUT_VALUE_MONTH')
        })}
        value={monthValue}
        onChange={handleChangeMonth}
        id={translate('DATE_INPUT_LABEL_MONTH')}
        date={true}
        {...commonProps}
      />
      <label for={translate('DATE_INPUT_LABEL_YEAR')}>
        <VisuallyHidden>{translate('DATE_INPUT_LABEL_YEAR')}</VisuallyHidden>
      </label>
      <InputBase
        options={yearOptions({
          defaultOptions: {
            value: '',
            label: translate('DATE_DEFAULT_INPUT_VALUE_YEAR')
          },
          moment
        })}
        value={yearValue}
        onChange={handleChangeYear}
        id={translate('DATE_INPUT_LABEL_YEAR')}
        date={true}
        {...commonProps}
      />
    </InputsWrapper>
  )
}

const renderField = (props) => {
  return <DateInput {...props} />
}

const Date = (props) => {
  return (
    <FieldBase {...props} renderField={renderField} showLabel date={true} />
  )
}

export default Date
