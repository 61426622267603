import React, { useEffect } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'

import Head from 'src/components/Head'
import { useSelector, useDispatch } from 'react-redux'
import { currentPathSelector } from 'src/store/styleSeeker/selectors'
import vars from 'src/styling/vars'
import { restore, fetchSavedProducts } from 'src/store/account/actionCreators'
import { savedSelector, attributesSelector } from 'src/store/account/selectors'
import { useResources, useRequest } from 'src/store/resources/hooks'
import { fetchResources } from 'src/store/resources/actionCreators'
import { useInternationalisation } from 'src/context'

import Spacing from 'src/components/Spacing'
import FeaturesCarousel from 'src/components/FeaturesCarousel'
import FeaturesCarouselHeading from 'src/components/FeaturesCarousel/FeaturesCarouselHeading'
import H2 from 'src/components/H2'
import Text from 'src/components/Text'
import Button from 'src/components/Button'

import AssetIcon from 'src/components/AssetIcon'
import HeartOutlineSvg from 'src/assets/Heart.svg'

const NoSavedItemsWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 8%;
`

const SavedTitle = styled(H2)`
  font-size: 22px;
`

const SavedDescription = styled(Text)`
  font-size: 16px;
`

const defineRequest = (params) => {
  const { resourceType, requestKey } = params
  return {
    use: () => useRequest({ resourceType, requestKey }),
    fetch: (fetchParams) => {
      const { dispatch, ...rest } = fetchParams
      return dispatch(
        fetchResources({
          ...rest,
          ...params
        })
      )
    }
  }
}

const reduceItems = (saved, contentType) => {
  return saved.reduce((acc, item) => {
    if (item.content_type === contentType) {
      return [...acc, { ...item }]
    }
    return acc
  }, [])
}

const now = new Date().toISOString()

const _promotionsRequest = defineRequest({
  resourceType: 'promotion',
  requestKey: 'promotion',
  where: {
    'fields.publishOnPlusApp': true,
    'fields.endDate[gte]': now
  }
})

const _newsRequest = defineRequest({
  resourceType: 'news',
  requestKey: 'news',
  where: {
    'fields.displayFrom[lte]': now
  }
})

const _eventsRequest = defineRequest({
  resourceType: 'event',
  requestKey: 'event',
  where: {
    'fields.endDate[gte]': now
  }
})

const Saved = (props) => {
  const { translate } = useInternationalisation()
  const dispatch = useDispatch()
  const userAttributes = useSelector(attributesSelector())
  const userAuthenticated = userAttributes && userAttributes.id
  const currentPath = useSelector(currentPathSelector())

  const saved = useSelector(savedSelector())
  const promotions = useResources({
    ids: reduceItems(saved, 'promotion'),
    resourceType: 'promotion'
  }).map((r) => r.resource)
  const news = useResources({
    ids: reduceItems(saved, 'news'),
    resourceType: 'news'
  }).map((r) => r.resource)
  const events = useResources({
    ids: reduceItems(saved, 'event'),
    resourceType: 'event'
  }).map((r) => r.resource)

  const products = reduceItems(saved, 'product')
  const productResources = _map(products, (product) => {
    return _get(product, 'productResource', {})
  })
  const productIds = _map(products, (product) => product.content_id)
  useEffect(() => {
    console.log('dispatching fetchSavedProducts:', productIds.length)
    dispatch(fetchSavedProducts(productIds))
  }, [productIds.length])

  let showPromotions = promotions && promotions.length > 0
  let showNews = news && news.length > 0
  let showEvents = events && events.length > 0
  let showProducts = false // products && products.length > 0

  const savedItems = () => {
    return (
      <>
        {showProducts && (
          <>
            <FeaturesCarouselHeading
              heading={'Wishlist'}
              to={'/style-seeker'}
            />
            <FeaturesCarousel resources={productResources} />
          </>
        )}

        {showPromotions && (
          <>
            <FeaturesCarouselHeading
              heading={translate('PAGE_PROMOTIONS')}
              to={'/promotions'}
            />
            <FeaturesCarousel resources={promotions} />
          </>
        )}

        {showNews && (
          <>
            <FeaturesCarouselHeading
              heading={translate('PAGE_NEWS')}
              to={'/news'}
            />
            <FeaturesCarousel resources={news} />
          </>
        )}

        {showEvents && (
          <>
            <FeaturesCarouselHeading
              heading={translate('PAGE_EVENTS')}
              to={'/events'}
            />
            <FeaturesCarousel resources={events} />
          </>
        )}
      </>
    )
  }

  const noSavedItems = (userAuthenticated) => {
    return (
      <NoSavedItemsWrapper>
        <AssetIcon svg={HeartOutlineSvg} size={60} />
        <SavedTitle>{translate('SAVED_CONTENT_EMPTY')}</SavedTitle>
        {!userAuthenticated ? (
          <SavedDescription>
            {translate('SAVED_CONTENT_DESCRIPTION')}
            <br />
            {translate('SAVED_CONTENT_LOGIN_REQUIRED')}
          </SavedDescription>
        ) : (
            <SavedDescription>
              {translate('SAVED_CONTENT_DESCRIPTION')}
            </SavedDescription>
          )}
        {!userAuthenticated && (
          <Button buttonType='primary' to='/login'>
            {`${translate('LOGIN')} / ${translate('SIGN_UP')}`}
          </Button>
        )}
      </NoSavedItemsWrapper>
    )
  }

  return (
    <div style={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
      <Head title={translate('PAGE_SAVED')} />
      <Spacing height={vars.headerHeight.sm + 50} />
      {!showPromotions && !showNews && !showEvents && !showProducts
        ? noSavedItems(userAuthenticated)
        : savedItems()}
    </div>
  )
}

Saved.getData = async (props) => {
  const { host, dispatch } = props
  return Promise.all([
    dispatch(restore()),
    ...[_promotionsRequest, _newsRequest, _eventsRequest].map(({ fetch }) => {
      return fetch({ dispatch, host })
    })
  ])
}
Saved.identifier = 'Saved'

export default Saved
