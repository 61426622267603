import _compact from 'lodash/compact'

import HomeSvg from 'src/assets/Home.svg'
import MapSvg from 'src/assets/Map.svg'
import HeartSvg from 'src/assets/Heart.svg'
import StyleSeekerSvg from 'src/assets/Styleseeker.svg'
import ShopSvg from 'src/assets/Shop.svg'

import { getCentreConfig } from 'src/config'

const appTabsConfig = [
  { label: 'PAGE_HOME', icon: HomeSvg, to: '/' },
  { label: 'PAGE_SAVED', icon: HeartSvg, to: '/saved' },
  {
    label: 'PAGE_STYLE_SEEKER',
    icon: StyleSeekerSvg,
    emphasise: true,
    to: '/style-seeker'
  },
  { label: 'PAGE_SHOP_AND_DINE', icon: ShopSvg, to: '/shop-and-dine' },
  { label: 'PAGE_MAP', icon: MapSvg, to: '/map' }
]

export const getAppTabs = (translate) => {
  const { hasStyleSeeker } = getCentreConfig() || {}
  return _compact([
    { label: translate('PAGE_HOME'), icon: HomeSvg, to: '/' },
    { label: translate('PAGE_SAVED'), icon: HeartSvg, to: '/saved' },
    hasStyleSeeker
      ? {
          label: translate('PAGE_STYLE_SEEKER'),
          icon: StyleSeekerSvg,
          emphasise: true,
          to: '/style-seeker'
        }
      : null,
    {
      label: translate('PAGE_SHOP_AND_DINE'),
      icon: ShopSvg,
      to: '/shop-and-dine'
    },
    { label: translate('PAGE_MAP'), icon: MapSvg, to: '/map' }
  ])
}

export default appTabsConfig
