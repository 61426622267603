import React from 'react'
import _flow from 'lodash/flow'
import _toUpper from 'lodash/toUpper'
import _ from 'lodash'
import { getCentreId } from 'src/config'
import { useRequest } from 'src/store/resources/hooks'
import { fetchResources } from 'src/store/resources/actionCreators'
import { getIdFromParams, getFirstResource, getFields } from 'src/utility'
import contentfulService from 'src/services/contentfulService'
import Head from 'src/components/Head'
import DetailsPage from 'src/components/DetailsPage'
import NotFound from 'src/pages/NotFound'
import { useInternationalisation } from 'src/context'
import { isCordova } from 'src/env'

const resourceType = 'news'
const News = (props) => {
  const id = getIdFromParams(props)
  const request = useRequest({
    resourceType,
    requestKey: id
  })
  const resource = getFirstResource(request)
  const { about, title = '' } = getFields(resource)
  const { translate } = useInternationalisation()
  if (request._status.pending) return null

  if (!resource) {
    return <NotFound />
  }
  return (
    <DetailsPage resource={resource} inner>
      <Head
        resource={resource}
        title={isCordova ? _.upperFirst(translate(_toUpper(resourceType))) : title}
      />
      {contentfulService.renderRichText(about)}
    </DetailsPage >
  )
}

News.getData = async (props) => {
  const id = getIdFromParams(props)
  const { dispatch, host, locale } = props
  return dispatch(
    fetchResources({
      locale,
      where: { 'fields.urlName': id },
      resourceType,
      requestKey: id,
      host,
      include: 2
    })
  )
}
News.identifier = 'News'

export default News
