import _ from 'lodash'
import cognitoService from 'src/services/cognitoService'
import apiService from 'src/services/apiService'
import deviceKey from 'src/store/device/key'
import { getRegistrationId, getPointrId } from 'src/store/device/selectors'
import { getEmail } from 'src/store/account/selectors'
import { logTrace } from 'src/utility'

export const syncUserDevice = async ({ state }) => {
    const email = getEmail(state)
    if (!email) {
        return
    }
    const token = await cognitoService.getToken()
    const deviceStore = _.get(state, deviceKey) || {}
    const payload = _.pickBy({
        device_id: deviceStore.uuid,
        device_push_token: getRegistrationId(state),
        platform_type: deviceStore.platform,
        platform_version: deviceStore.version,
        pointr_id: getPointrId(state),
        app_version: process.env.REACT_APP_VERSION,
        airship_channel_id: deviceStore.airshipChannelId
    }, _.identity)
    logTrace('REGISTERING DEVICE FOR PUSH NOTIFICATIONS')

    return apiService.createUserDevice({ token, payload })
}

export default {
    syncUserDevice
}