import React from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import styled from 'styled-components'
import media from 'src/styling/media'

import contentfulService from 'src/services/contentfulService'
import { useInternationalisation } from 'src/context/'
import ContentWithAddressLookup from 'src/components/ContentWithAddressLookup'
import ContentWithButtons from 'src/components/ContentWithButtons'
import ImageWithText from 'src/components/ImageWithText'
import { getFields, getAssetSrc, getUrl } from 'src/utility'
import { useResource } from 'src/store/resources/hooks'
import Container from 'src/components/Container'
import Spacing from 'src/components/Spacing'
import { useInternationalisedAppStoreAssets } from 'src/store/ui/hooks'

const BlockInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  ${media.greaterThan('md')`
  flex-direction: ${(p) => (p.reverse ? 'row-reverse' : 'row')};
  `}
`

const BlockContentWrapper = styled.div`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 1;
`

const BlockImageWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  ${media.greaterThan('md')`
    width: 40%;
  `}
`

const Block = ({ blockContent, blockImage, reverse }) => {
  return (
    <Container>
      <BlockInner reverse={reverse}>
        <BlockContentWrapper>{blockContent}</BlockContentWrapper>
        <Spacing height={30} width={{ md: 30, lg: 50 }} />
        <BlockImageWrapper>{blockImage}</BlockImageWrapper>
      </BlockInner>
    </Container>
  )
}

const AppStoreButtonsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  li:last-of-type {
    margin-left: 15px;
  }
  ${media.greaterThan('lg')`
    li:last-of-type { margin-left: 30px; }
  `}
`

const ButtonLink = styled.a`
  flex: 1 1 auto;
`

const Img = styled.img`
  width: 100%;
`

export const DefaultBlock = ({
  id,
  reverse,
  title,
  imageTitle,
  image,
  content,
  buttonLink1,
  buttonLinkText1,
  buttonLink2,
  buttonLinkText2
}) => {
  const blockButtons = [
    {
      link: getUrl(buttonLink1),
      title: buttonLinkText1,
      type: 'primary',
      fullWidth: true
    },
    {
      link: getUrl(buttonLink2),
      title: buttonLinkText2,
      type: 'primary',
      fullWidth: true
    }
  ]

  const blockContent = (
    <ContentWithButtons
      title={title}
      contentPage={'home'}
      align={'left'}
      buttons={blockButtons}
    >
      {contentfulService.renderRichText(content)}
    </ContentWithButtons>
  )

  const blockImage = (
    <ImageWithText title={imageTitle} src={getAssetSrc(image)} />
  )

  const blockProps = { reverse, blockContent, blockImage }
  return <Block {...blockProps} />
}

export const DirectionsBlock = ({
  id,
  reverse,
  title,
  imageTitle,
  image,
  content,
  history
}) => {
  const blockContent = (
    <ContentWithAddressLookup
      title={title}
      contentPage={'home'}
      align={'left'}
      history={history}
    >
      {contentfulService.renderRichText(content)}
    </ContentWithAddressLookup>
  )

  const blockImage = (
    <ImageWithText title={imageTitle} src={getAssetSrc(image)} />
  )

  const blockProps = { reverse, blockContent, blockImage }
  return <Block {...blockProps} />
}

export const ApplicationBlock = ({
  id,
  reverse,
  title,
  imageTitle,
  image,
  content,
  centreId
}) => {
  const { appleStoreUrl, googlePlayUrl } = getFields(
    useResource({ resourceType: 'centre', id: centreId })
  )
  const { AppStorePNG = '', GooglePlayPNG = '' } = useInternationalisedAppStoreAssets()
  const { translate } = useInternationalisation()
  const blockContent = (
    <ContentWithButtons title={title} contentPage={'home'} align={'left'}>
      {contentfulService.renderRichText(content)}
      {appleStoreUrl && googlePlayUrl && (
        <AppStoreButtonsWrapper>
          <li>
            <ButtonLink href={appleStoreUrl} target='_blank'>
              <Img src={AppStorePNG} alt={translate('APPLE_STORE')} />
            </ButtonLink>
          </li>
          <li>
            <ButtonLink href={googlePlayUrl} target='_blank'>
              <Img src={GooglePlayPNG} alt={translate('GOOGLE_STORE')} />
            </ButtonLink>
          </li>
        </AppStoreButtonsWrapper>
      )}
    </ContentWithButtons>
  )

  const blockImage = (
    <ImageWithText title={imageTitle} src={getAssetSrc(image)} />
  )

  const blockProps = { reverse, blockContent, blockImage }
  return <Block {...blockProps} />
}
