import actionTypes from './actionTypes.js'
import { isRegExp } from 'util'
const {
  CLEAR,
  SET_FILE,
  SET_FILE_URL,
  SET_CATEGORY,
  SET_WEIGHTS,
  SET_SIMILAR_PRODUCTS,
  SET_SELECTED_PRODUCT,
  SET_CROP,
  SET_IS_CROPPED,
  SET_IS_LOADING,
  IS_FILTERS_MENU_OPEN,
  SET_CURRENT_PATH
} = actionTypes

const initialState = {
  file: null,
  fileUrl: null,
  category: null,
  weights: {},
  crop: null,
  similarProducts: null,
  selectedProduct: null,
  isCropped: false,
  isFiltersMenuOpen: false,
  isLoading: false,
  currentPath: '/'
}

const styleSeekerReducer = (state, action) => {
  const { type, attributes, error, status, provider } = action

  if (type === SET_FILE) {
    return { ...state, file: action.payload }
  } else if (type === SET_FILE_URL) {
    return { ...state, fileUrl: action.payload }
  } else if (type === SET_CATEGORY) {
    return { ...state, category: action.payload }
  } else if (type === SET_WEIGHTS) {
    return { ...state, weights: action.payload }
  } else if (type === SET_CROP) {
    return { ...state, crop: action.payload }
  } else if (type === SET_SIMILAR_PRODUCTS) {
    return { ...state, similarProducts: action.payload }
  } else if (type === SET_SELECTED_PRODUCT) {
    return { ...state, selectedProduct: action.payload }
  } else if (type === SET_IS_CROPPED) {
    return { ...state, isCropped: true }
  } else if (type === SET_IS_LOADING) {
    return { ...state, isLoading: action.payload }
  } else if (type === IS_FILTERS_MENU_OPEN) {
    return { ...state, ...action.payload }
  } else if (type === SET_CURRENT_PATH) {
    return { ...state, currentPath: action.payload }
  } else if (type === CLEAR) {
    return { ...initialState }
  } else {
    return state || initialState
  }
}

export default styleSeekerReducer
