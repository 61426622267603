import { requestStatuses } from 'redux-resource'
import _get from 'lodash/get'

// this is a rewrite of redux-resource.getStatus that doesn't suck
const getStatus = (statusOrObjectContainingStatus, path) => {
  const status = path
    ? _get(statusOrObjectContainingStatus, path)
    : statusOrObjectContainingStatus
  return {
    pending:
      status !== requestStatuses.FAILED && status !== requestStatuses.SUCCEEDED,
    failed: status === requestStatuses.FAILED,
    succeeded: status === requestStatuses.SUCCEEDED
  }
}

export default getStatus
