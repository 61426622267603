import React from 'react'
import _ from 'lodash'
import { List as Placeholder } from 'react-content-loader'
import contentfulService from 'src/services/contentfulService'
import Page from 'src/components/Page'
import { useCrisis } from 'src/store/resources/hooks'
import styled from 'styled-components'
import { useInternationalisation } from 'src/context'

const CrisisDescription = styled.p`
  white-space: pre-wrap;
`
const PublishedDate = styled.span`
  color: rgba(38, 42, 48, 0.6);
  font-size: 14px;
`

const useDescription = ({ descriptionRichtext, description, translate }) => {
  switch (true) {
    case !_.isNil(descriptionRichtext):
      return contentfulService.renderRichText(descriptionRichtext)
    case !_.isNil(description):
      return <CrisisDescription>{description}</CrisisDescription>
    default:
      return translate('NO_CRISIS')
  }
}

const Crisis = (props) => {
  const crisis = useCrisis() || {}
  const { moment, translate } = useInternationalisation() || {}
  const { title, startDateTime, description, descriptionRichtext } = crisis
  if (_.get(crisis, '_status.pending')) {
    return (
      <Page box inner>
        <Placeholder />
      </Page>
    )
  }
  return (
    <Page box inner propsTitle={title || translate('NO_CRISIS_TITLE')}>
      <>
        {useDescription({ descriptionRichtext, description, translate })}
        {startDateTime ? (
          <PublishedDate>
            <br />
            <br />
            {translate('PUBLISHED')}:{' '}
            {moment(startDateTime).format('DD/MM/YYYY')}
          </PublishedDate>
        ) : null}
      </>
    </Page>
  )
}

export default Crisis
