import React from 'react'
import _get from 'lodash/get'
import _includes from 'lodash/includes'

import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

import styled from 'styled-components'
import media from 'src/styling/media'
import colors from 'src/styling/colors'
import { breakWords } from 'src/styling/styles'

import WebViewLink from 'src/components/WebViewLink'

import { getAssetSrc, getAssetType, getAssetTitle } from 'src/utility'

const H2 = styled.h2`
  width: 100%;
  max-width: 100%;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  color: ${colors.textColor};
  padding-top: 0;
  padding-bottom: 10px;
  ${media.greaterThan('md')`
    font-size: 60px;
    letter-spacing: -1.7px;
  `}
  ${media.greaterThan('lg')`
    font-size: 75px;
    letter-spacing: -1.7px;
  `}
`

const H3 = styled.h3`
  width: 100%;
  max-width: 100%;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1.2;
  color: ${colors.darkGrey};
  ${media.greaterThan('lg')`
    font-size: 25px;
    margin-left: 8px;
  `}
`

const H4 = styled.h4`
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
`

const P = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: ${colors.darkGrey};
  margin-bottom: 25px;
`

const Image = styled.img`
  max-width: 100%;
`

const Media = styled.video`
  max-width: 100%;
`

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;

  & iframe,
  & object,
  & embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const DangerousHtmlWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Heading2 = ({ children, id }) => <H2 id={id}>{children}</H2>
const Heading3 = ({ children, id }) => <H3 id={id}>{children}</H3>
const Heading4 = ({ children, id }) => <H4 id={id}>{children}</H4>
const Paragraph = ({ children }) => <P>{children}</P>
const Img = ({ children, alt }) => <Image src={children} alt={alt} />
const Video = ({ children, assetType }) => (
  <Media controls>
    <source src={children} type={assetType} />
    <p>Your browser doesnt support HTML5 video.</p>
  </Media>
)

const IframeComp = ({ src, id, title, ...rest }) => (
  <IframeContainer>
    <iframe
      title={title}
      width='853'
      height='480'
      src={src}
      frameBorder='0'
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      {...rest}
    />
  </IframeContainer>
)

const stringToElementId = (value) => value.replace(/[\W_]+/g, '-').toLowerCase()

const getHyperlinkObject = (hyperlinkNode) => {
  const youtubeUriHead = 'https://www.youtube.com/watch?v='
  const youtubeEmbedHead = 'https://www.youtube.com/embed/'

  const title = _get(hyperlinkNode, 'content.0.value', '')
  const uri = _get(hyperlinkNode, 'data.uri')

  const isYoutube = uri.includes(youtubeUriHead)

  return isYoutube
    ? {
        title,
        type: 'iframe',
        platform: 'youtube',
        src: uri.replace(youtubeUriHead, youtubeEmbedHead)
      }
    : { title, type: 'hyperlink', src: uri }
}

const options = {
  renderMark: {
    [MARKS.CODE]: (content) => (
      <DangerousHtmlWrapper dangerouslySetInnerHTML={{__html: content}} />
    )
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading2 id={stringToElementId(node.content[0].value)}>
        {children}
      </Heading2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading3 id={stringToElementId(node.content[0].value)}>
        {children}
      </Heading3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Heading4 id={stringToElementId(node.content[0].value)}>
        {children}
      </Heading4>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    'embedded-asset-block': (node, children) => {
      const [assetType] = getAssetType(node.data.target).split('/')
      if (assetType === 'video') {
        return (
          <Video assetType={getAssetType(node.data.target)}>
            {getAssetSrc(node.data.target)}
          </Video>
        )
      }
      if (assetType === 'image') {
        return (
          <Img alt={getAssetTitle(node.data.target)}>
            {getAssetSrc(node.data.target)}
          </Img>
        )
      }
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const hyperlinkObj = getHyperlinkObject(node)
      switch (hyperlinkObj.type) {
        case 'iframe':
          return <IframeComp {...hyperlinkObj} />
        default:
          return (
            <WebViewLink url={node.data.uri} {...hyperlinkObj}>
              {children}
            </WebViewLink>
          )
      }
    }
  }
}

export default options
