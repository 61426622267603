import _get from 'lodash/get'
import _find from 'lodash/find'
import _once from 'lodash/once'

import key from './key'

const fileSelector = () => (state) => _get(state, 'styleSeeker.file')
const fileUrlSelector = () => (state) => _get(state, 'styleSeeker.fileUrl')
const categorySelector = () => (state) => _get(state, 'styleSeeker.category')
const weightsSelector = () => (state) => _get(state, 'styleSeeker.weights')
const cropSelector = () => (state) => _get(state, 'styleSeeker.crop')
const similarProductsSelector = () => (state) =>
  _get(state, 'styleSeeker.similarProducts')
const isCroppedSelector = () => (state) => _get(state, 'styleSeeker.isCropped')
const isFiltersMenuOpenSelector = () => (state) =>
  _get(state, 'styleSeeker.isFiltersMenuOpen')
const isLoadingSelector = () => (state) => _get(state, 'styleSeeker.isLoading')
const productSelector = () => (state) =>
  _get(state, 'styleSeeker.selectedProduct')
const currentPathSelector = () => (state) =>
  _get(state, 'styleSeeker.currentPath')

export {
  fileSelector,
  fileUrlSelector,
  categorySelector,
  weightsSelector,
  cropSelector,
  similarProductsSelector,
  productSelector,
  isCroppedSelector,
  isFiltersMenuOpenSelector,
  isLoadingSelector,
  currentPathSelector
}
