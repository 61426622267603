import { renderRoutes } from 'react-router-config'
import _ from 'lodash'

import routesConfig from './routesConfig'
import withPageData from './withPageData'

import { replaceWords } from 'src/utility'
import { getUrlTranslators } from 'src/context/internationalisation'

const getRoutesWithPageData = (routes, host) => {
  const { translateUrl } = getUrlTranslators({ host })
  const translatePath = (path) => {
    return replaceWords({
      input: path,
      separators: ['/', '|', '(', ')'],
      replacer: (word) => {
        if (_.startsWith(word, ':')) return word // ignore params
        return translateUrl(word)
      }
    })
  }

  const mungedRoutes = (routes || []).map((route) => {
    const { component, routes, path } = route
    return {
      ...route,
      path: translatePath(path),
      component: withPageData(component),
      routes: routes && getRoutesWithPageData(routes, host)
    }
  })
  return mungedRoutes
}
const getRoutes = (host) => getRoutesWithPageData(routesConfig, host)

export default (host) => renderRoutes(getRoutes(host))
