import _get from 'lodash/get'
import _find from 'lodash/find'
import _once from 'lodash/once'
import _ from 'lodash'

import key from './key'
import getStatus from '../getStatus'

const emptyArr = []

const attributesSelector = _once(() => (state) =>
  _get(state, [key, 'attributes'])
)
const sessionSelector = _once(() => (state) =>
  _get(attributesSelector()(state), ['session'])
)
const providerSelector = _once(() => (state) =>
  _get(sessionSelector()(state), ['provider'])
)
const statusSelector = _once(() => (state) =>
  getStatus(_get(state, [key, 'status']))
)
const actionTypeSelector = _once(() => (state) =>
  _get(state, [key, 'actionType'])
)
const savedSelector = _once(() => (state) =>
  _get(state, [key, 'attributes', 'saveds'], emptyArr)
)

const savedProductSelector = _.memoize((id) => (state) => {
  const saved = _get(state, [key, 'attributes', 'saveds'], [])
  return _find(saved, ['content_id', id])
})

const getEmail = (state) => _.get(state, [key, 'attributes', 'id'])

export {
  attributesSelector,
  sessionSelector,
  providerSelector,
  statusSelector,
  actionTypeSelector,
  savedSelector,
  savedProductSelector,
  getEmail
}
