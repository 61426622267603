import React, { Fragment } from 'react'
import qs from 'query-string'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _capitalize from 'lodash/capitalize'
import styled from 'styled-components'
import { getCentreId } from 'src/config'
import {
  getFirstResource,
  getResourceId,
  getFields,
  getAssetSrc
} from 'src/utility'

import contentfulService from 'src/services/contentfulService'

import { fetchResources } from 'src/store/resources/actionCreators'
import { useResource, useRequest } from 'src/store/resources/hooks'

import Page, { getPageData } from 'src/components/Page'

import Box from 'src/components/Box'
import Tabs from 'src/components/Tabs'
import ContentWithButtons from 'src/components/ContentWithButtons'
import OpeningTimes from 'src/components/OpeningTimes'
import SelectField from 'src/components/Fields/Select'
import media from 'src/styling/media'
import colors from 'src/styling/colors'
import { useInternationalisation } from 'src/context'

const SelectFieldWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`

const H2 = styled.h2`
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  color: ${colors.textColor};
  padding-bottom: 33px;

  ${media.greaterThan('lg')`
    font-size: 65px;
    letter-spacing: -1.7px;
  `}
`

const getSearchQuery = ({ location, key }) => {
  const { search } = location || {}
  const query = qs.parse(search)
  return _get(query, key, '')
}

const Panel = ({ title, openingHours, additionalInformation }) =>
  openingHours ? (
    <React.Fragment>
      <ContentWithButtons id={`opening-hours-container`}>
        <H2>{title}</H2>
        <OpeningTimes data={openingHours} />
        {contentfulService.renderRichText(additionalInformation)}
      </ContentWithButtons>
    </React.Fragment>
  ) : null

const OpeningHours = (props) => {
  const { location, history, host } = props
  const { pathname } = location || {}

  const { translateUrl } = useInternationalisation()

  const _getTab = ({ title, id, icon }) => ({
    label: title,
    to: `${translateUrl('/opening-hours')}?${qs.stringify({ tab: id })}`,
    icon: getAssetSrc(icon)
  })

  const _getSelect = ({ title, id, icon }) => ({
    label: title,
    value: `${translateUrl('/opening-hours')}?${qs.stringify({ tab: id })}`
  })

  const openingHoursId = getSearchQuery({ location, key: 'tab' })

  const centreId = getCentreId({ host })

  const requestCentre = useResource({ resourceType: 'centre', id: centreId })
  const { _status } = requestCentre
  const { centreOpeningHours } = getFields(requestCentre)

  const openingHoursList = _map(centreOpeningHours, (resource) => ({
    id: getResourceId(resource),
    ...getFields(resource)
  }))

  const selectedOpeningHours = openingHoursId
    ? _find(openingHoursList, ['id', openingHoursId])
    : openingHoursList[0]

  const selectedOpeningHoursId = _get(selectedOpeningHours, 'id')
  // set #openingHoursId if there is no openingHoursId on url
  if (!openingHoursId && selectedOpeningHoursId) {
    history.replace({
      pathname: translateUrl(pathname),
      search: `?${qs.stringify({ tab: selectedOpeningHoursId })}`
    })
  }

  const { title, additionalInformation, openingHours, icon } =
    selectedOpeningHours || {}

  const tabsProp = _map(openingHoursList, _getTab)
  const selectProp = _map(openingHoursList, _getSelect)

  const compactTabChange = (e) => {
    const link = _get(e, 'target.value')
    history.replace(translateUrl(link))
  }

  const mobileSelect = {
    options: selectProp,
    value: `${pathname}?${qs.stringify({ tab: openingHoursId })}`,
    onChange: compactTabChange
  }

  return (
    <Page>
      <Tabs
        tabs={tabsProp}
        panel={
          <Panel
            title={title}
            openingHours={openingHours}
            additionalInformation={additionalInformation}
          />
        }
        mobileSelect={mobileSelect}
      />
    </Page>
  )
}

OpeningHours.getData = async (props) => {
  const pagePromise = getPageData(props)

  return Promise.all([pagePromise])
}

OpeningHours.identifier = 'OPENING_HOURS'

export default OpeningHours
