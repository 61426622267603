import TagManager from 'react-gtm-module'

export default (state) => {
    switch (true) {
        // ALL COOKIES REJECTED
        case state.audienceMeasurementCookiesOptedIn === false && state.personalisedAdCookiesOptedIn === false:
            TagManager.dataLayer({ dataLayer: { event: 'all_cookies_rejected', Activegroup: '0001' } })
            break
        // ALL COOKIES ACCEPTED
        case state.audienceMeasurementCookiesOptedIn === true && state.personalisedAdCookiesOptedIn === true:
            TagManager.dataLayer({ dataLayer: { event: 'all_cookies_accepted', Activegroup: '0002' } })
            break
        // ONLY AUDIENCE MEASUREMENT COOKIES
        case state.audienceMeasurementCookiesOptedIn === true && state.personalisedAdCookiesOptedIn === false:
            TagManager.dataLayer({ dataLayer: { event: 'only_analytics-cookies_accepted', Activegroup: '0003' } })
            break
        // ONLY PERSONALISED COOKIES
        case state.audienceMeasurementCookiesOptedIn === false && state.personalisedAdCookiesOptedIn === true:
            TagManager.dataLayer({ dataLayer: { event: 'only_marketing-cookies_accepted', Activegroup: '0004' } })
            break
        default:
            break
    }
}