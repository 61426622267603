import key from './key'

const actionTypes = {
  IS_FILTERS_MENU_OPEN: `${key}/IS_FILTERS_MENU_OPEN`,
  SET_FILE: `${key}/SET_FILE`,
  SET_FILE_URL: `${key}/SET_FILE_URL`,
  SET_CATEGORY: `${key}/SET_CATEGORY`,
  SET_WEIGHTS: `${key}/SET_WEIGHTS`,
  SET_CROP: `${key}/SET_CROP`,
  SET_SIMILAR_PRODUCTS: `${key}/SET_SIMILAR_PRODUCTS`,
  SET_IS_CROPPED: `${key}/SET_IS_CROPPED`,
  SET_IS_LOADING: `${key}/SET_IS_LOADING`,
  SET_SELECTED_PRODUCT: `${key}/SET_SELECTED_PRODUCT`,
  SET_CURRENT_PATH: `${key}/SET_CURRENT_PATH`,
  CLEAR: `${key}/CLEAR`
}

export default actionTypes
