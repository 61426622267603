import { isBrowser } from 'src/env'
import { uploadEvents } from './actionCreators'

const subscriber = ({ dispatch }) => {
  if (isBrowser) {
    setInterval(() => {
      dispatch(uploadEvents())
    }, 10000)
  }
}

export default subscriber
