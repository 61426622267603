import React from 'react'
import { Link, withRouter } from 'src/components/RouterDom'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _slice from 'lodash/slice'
import _isNaN from 'lodash/isNaN'
import _lowerCase from 'lodash/lowerCase'
import _forEach from 'lodash/forEach'
import qs from 'query-string'

import styled from 'styled-components'
import media from 'src/styling/media'
import colors from 'src/styling/colors'

import HoverBold from 'src/components/HoverBold'

const alphabet = '#abcdefghijklmnopqrstuvwxyz'

const AtoZ = alphabet.split('')

const LetterContainer = styled.ol`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  ${media.forEach({
    values: { sm: 'none', md: 'none', lg: 'flex' },
    getStyle: (val) => `display: ${val}`
  })}
`

const LetterLink = styled.li`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  // cursor: pointer;
`

const Letter = styled(HoverBold)`
  font-size: 22px;
  line-height: 22px;
  opacity: ${(props) => (props['disabled'] ? 0.2 : 1)};
  pointer-events: ${(props) => (props['disabled'] ? 'none' : 'auto')};
  cursor: ${(props) => (props['disabled'] ? 'not-allowed' : 'pointer')};
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.textColor};
  ${media.greaterThan('md')`
    margin: 0;
    padding: 5px;
  `}
  ${media.greaterThan('lg')`
    padding: 5px;
    margin: 5px;
  `}
`

const AtoZFilter = (props) => {
  const { location, allValues, history } = props
  const { letter: activeLetter } = qs.parse(_get(location, 'search'))

  const availableLetters = React.useMemo(() => {
    let retval = {}
    _forEach(allValues, (value) => {
      const firstLetter = _lowerCase(_slice(value, 0, 1))
      const key = _isNaN(parseInt(firstLetter, 10)) ? firstLetter : '#'
      retval[key] = true
    })
    return retval
  }, [allValues])

  return (
    <LetterContainer>
      {_map(AtoZ, (letter) => {
        const disabled = availableLetters ? !availableLetters[letter] : false
        const selected = activeLetter === letter
        return (
          <LetterLink key={letter}>
            <Letter
              onClick={() =>
                history.replace(
                  '?' + (selected ? '' : qs.stringify({ letter }))
                )
              }
              data-selected={selected}
              active={selected}
              disabled={disabled}
              replace
              as='button'
            >
              {letter}
            </Letter>
          </LetterLink>
        )
      })}
    </LetterContainer>
  )
}

export default withRouter(AtoZFilter)
