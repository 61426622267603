import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'

import FieldBase from './common/FieldBase'
import InputBase from './common/InputBase'
import AssetIcon from 'src/components/AssetIcon'
import VisuallyHidden from '@reach/visually-hidden'

import ShowEyeSvg from 'src/assets/Eye.svg'
import HideEyeSvg from 'src/assets/HideEye.svg'
import { useInternationalisation } from 'src/context'

import { iconSize } from './common/vars'

const ShowHidePasswordButton = (props) => {
  const { translate } = useInternationalisation()
  return (
    <button {...props} aria-pressed={props.inputType !== 'password'}>
      <VisuallyHidden>
        {props.inputType === 'password'
          ? translate('BUTTON_LABEL_PASSWORD_SHOW')
          : translate('BUTTON_LABEL_PASSWORD_HIDE')}
      </VisuallyHidden>
      <AssetIcon
        svg={props.inputType === 'password' ? ShowEyeSvg : HideEyeSvg}
        size={iconSize}
        width={38}
        marginLeft={8}
      />
    </button>
  )
}

const renderField = (props) => {
  const [type, setType] = React.useState('password')

  return (
    <InputBase
      {...props}
      inlineRight={{
        component: ShowHidePasswordButton,
        props: {
          type: 'button',
          onClick: () => setType(type === 'password' ? 'text' : 'password'),
          tabIndex: -1, // prevents focusing on the eye button while tabbing through fields
          inputType: type
        }
      }}
      type={type}
    />
  )
}

const Text = (props) => {
  return <FieldBase {...props} renderField={renderField} />
}

export default Text
