import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _findIndex from 'lodash/findIndex'
import _capitalize from 'lodash/capitalize'
import {
  fileSelector,
  fileUrlSelector,
  categorySelector,
  cropSelector,
  weightsSelector,
  idLoadingSelector
} from 'src/store/styleSeeker/selectors'

import { useInternationalisation } from 'src/context'
import apiService from 'src/services/apiService'
import { getCentreConfig } from 'src/config'

import {
  setCategory,
  closeFiltersMenu,
  setSimilar,
  setIsLoading,
  clear
} from 'src/store/styleSeeker/actionCreators'
import imageUploadService from 'src/pages/StyleSeeker/ImageUploadService'
import styleSeekerCategories from 'src/pages/StyleSeeker/categoryConfig.json'
import colors from 'src/styling/colors'

import CategoryItem from './CategoryItem'

const CategoriesGroupsList = styled.ul``

const GroupHeader = styled.h4`
  font-size: 16px;
  font-weight: 700;
  padding: 20px 22px 20px 22px;
  text-transform: uppercase;
  border-top: 1px solid ${colors.lighterGrey};
`
const GroupsList = styled.li`
  color: ${(props) => (props.isActive ? colors.red : colors.white)};
`

const CategoryGroupName = styled.h4`
  font-size: 16px;
  font-weight: 500;
  padding: 20px 22px 20px 22px;
  text-transform: uppercase;
  color: ${colors.darkGrey};
  background-color: ${colors.lighterGrey};
`

const ItemsGroupList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CategoryGroup = ({ label, items, isActive }) => {
  const dispatch = useDispatch()
  const { defaultLocale } = getCentreConfig() || {}

  const file = useSelector(fileSelector())
  const fileUrl = useSelector(fileUrlSelector())
  const category = useSelector(categorySelector())
  const weights = useSelector(weightsSelector())
  const crop = useSelector(cropSelector())

  const findSimilar = (newCategory) => {
    dispatch(setIsLoading(true))
    dispatch(closeFiltersMenu())
    dispatch(setCategory(newCategory))
    return apiService
      .styleSeekerSimilar({
        fileUrl,
        categoryAlias: newCategory,
        crop,
        weights,
        content_id: null
      })
      .then(({ similarProducts, categoryAlias }) => {
        dispatch(setSimilar(similarProducts))
        dispatch(setCategory(categoryAlias))
        dispatch(setIsLoading(false))
      })
      .catch((err) => {
        console.log(err)
        dispatch(setIsLoading(false))
      })
  }

  return (
    <GroupsList key={label} isActive={isActive}>
      <CategoryGroupName>{_capitalize(label)}</CategoryGroupName>
      <ItemsGroupList>
        {_map(items, (item, index) => {
          const { alias, english_category, french_category } = item || {}
          const categoryLabel =
            defaultLocale === 'fr' ? french_category : english_category
          return (
            <CategoryItem
              key={alias}
              label={_capitalize(categoryLabel)}
              isActive={alias === category}
              onClick={() => findSimilar(alias)}
            />
          )
        })}
      </ItemsGroupList>
    </GroupsList>
  )
}

const FilterCategory = () => {
  const { translate } = useInternationalisation()
  const { defaultLocale } = getCentreConfig() || {}
  const category = useSelector(categorySelector())
  const groupByGender = _groupBy(
    styleSeekerCategories,
    defaultLocale === 'fr' ? 'french_gender' : 'english_gender'
  )
  const groupByStatus = useMemo(() => {
    return _groupBy(
      _map(groupByGender, (items, index) => ({
        name: index,
        active: _findIndex(items, ['alias', category]) > -1
      })),
      'name'
    )
  }, [category])

  return (
    <>
      <GroupHeader>{translate('STYLE_SEEKER_CATEGORY')}</GroupHeader>
      <CategoriesGroupsList>
        {_map(groupByGender, (item, index) => {
          if (index && index !== 'undefined') {
            return (
              <CategoryGroup
                key={`catgroup_${index}`}
                label={index}
                items={item}
                isActive={_get(groupByStatus[index], '0.active', false)}
              />
            )
          }
        })}
      </CategoriesGroupsList>
    </>
  )
}

export default FilterCategory
