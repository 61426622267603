// please refer to https://redux-resource.js.org/ for more
// information about how we're using resources in redux.

import { combineReducers } from 'redux'
import { resourceReducer } from 'redux-resource'

import resourceTypes from './resourceTypes'
import { RESET } from './constants'

const combinedReducers = combineReducers(
  resourceTypes.reduce((acc, resourceType) => {
    acc[resourceType] = resourceReducer(resourceType)
    return acc
  }, {})
)

const contentReducer = (state, action) => {
  if (action.type === RESET) return {}
  return combinedReducers(state, action)
}

export default contentReducer
