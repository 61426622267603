// PURPOSE OF FIELDBASE:
// To add a label and an error message, and spread the 'field' prop
// that comes from Formik

import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import _mapValues from 'lodash/mapValues'
import VisuallyHidden from '@reach/visually-hidden'
import colors from 'src/styling/colors'
import Spacing from 'src/components/Spacing'

import media from 'src/styling/media'
import { useInternationalisation } from 'src/context'
import BigLabel from './BigLabel'

const ErrorMessage = styled.span`
  display: block;
  font-size: 0.8em;
  line-height: 1.5em;
  overflow: hidden;
  text-align: left;
  text-transform: uppercase;
  color: ${colors.error};
`

const Wrapper = styled.label`
  display: block;
  ${(p) => {
    const w = p['data-width']
    let values
    if (typeof w === 'object') {
      values = w
    } else {
      values = { sm: w || 1 }
    }
    return media.forEach({
      values,
      getStyle: (x) => `width: ${100 * x}%;`
    })
  }}
`

const HelpText = styled.div`
  margin-bottom: 0.25em;
  text-align: center;
`

const FieldBase = (props) => {
  const {
    field,
    form,
    label,
    renderField,
    showLabel,
    leftAlign,
    width,
    date,
    noLabel,
    noErrorMessage,
    helpText,
    ...rest
  } = props

  const { translate } = useInternationalisation()

  const { name } = field || {}
  const { errors, touched, setFieldValue } = form || {}

  const errorMessage =
    touched && touched[name] && errors && errors[name] ? errors[name] : ''

  const fieldProps = {
    ..._pick(rest, [
      'value',
      'onChange',
      'options',
      'type',
      'autoComplete',
      'hidePlaceholder',
      'component',
      'iconSvg',
      'name',
      'readOnly',
      'disabled',
      'inlineRight',
      'rows',
      'allowNullValue',
      'autoFocus',
      'onKeyPress',
      'required',
      'onClick'
    ]),
    errorMessage,
    label,
    setFieldValue,
    ...field
  }
  return (
    <React.Fragment>
      <Wrapper
        htmlFor={name}
        data-width={width}
        as={date ? 'fieldset' : 'label'}
      >
        {showLabel ? (
          <BigLabel leftAlign={leftAlign} as={date ? 'legend' : 'div'}>
            {label}
          </BigLabel>
        ) : noLabel ? null : (
          <VisuallyHidden>{label}</VisuallyHidden>
        )}
        {helpText ? <HelpText>{helpText}</HelpText> : null}
        {renderField ? renderField(fieldProps) : null}
        {form && !noErrorMessage ? (
          <ErrorMessage role={'alert'}>{translate(errorMessage)}</ErrorMessage>
        ) : null}
      </Wrapper>
    </React.Fragment>
  )
}

export { Wrapper }
export default FieldBase
