import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import qs from 'query-string'
import _ from 'lodash'
import { requestSelector } from 'src/store/resources/selectors'
import { getCentreId } from 'src/config'
import {
  getResourceId,
  getResourceName,
  getFields,
  getFirstResource
} from 'src/utility'
import { fetchResources } from 'src/store/resources/actionCreators'
import Show from 'src/components/Show'
import Filters from 'src/components/Filters'
import Spacing from 'src/components/Spacing'
import { InfiniteTiles, ResourceTile } from 'src/components/Tile'
import Page, { getPageData } from 'src/components/Page'
import TextField from 'src/components/Fields/Text'
import RadioField from 'src/components/Fields/Radio'
import SelectField from 'src/components/Fields/Select'
import vars from 'src/styling/vars'
import SearchSvg from 'src/assets/Search.svg'
import { useInternationalisation } from 'src/context'

import {
  setSearchResults,
  getSearchResults,
  getSearchFilters
} from 'src/utility'

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #bdbdbd;
`

const SearchResults = ({ location }) => {
  const { search: searchString, filter: filterString } =
    qs.parse(_.get(location, 'search')) || {}

  const { locale, translate } = useInternationalisation()

  const { list: filtersList, models, getFiltersOptions } = getSearchFilters()

  const {
    searchResources,
    groupedResources,
    status,
    message,
    total,
    results
  } = getSearchResults({ searchString, filterString })

  const getSearchSchema = () => {
    return [
      {
        component: TextField,
        props: { label: translate('SEARCH'), iconSvg: SearchSvg },
        key: 'search',
        align: 'right',
        debounce: 300
      }
    ]
  }

  const getFilterSchema = ({ groupResults, allResults }) => {
    const filtersOptions = getFiltersOptions({
      filters: filtersList,
      groupResults,
      allResults
    })

    return _.map(filtersOptions, ({ label, key }) => ({
      component: RadioField,
      props: { label },
      widthPercentage: 100 / _.size(filtersOptions),
      queryKey: 'filter',
      key
    }))
  }

  const getSelectSchema = ({ groupResults, allResults }) => {
    return [
      {
        name: 'filters',
        label: 'Filters',
        key: 'filter',
        component: SelectField,
        props: {
          options: getFiltersOptions({
            filters: filtersList,
            groupResults,
            allResults
          })
        }
      }
    ]
  }

  const searchSchema = React.useMemo(() => {
    return getSearchSchema()
  }, [])

  const filterSchema = React.useMemo(() => {
    return getFilterSchema({
      groupResults: groupedResources,
      allResults: searchResources
    })
  }, [groupedResources, searchResources])

  const selectSchema = React.useMemo(() => {
    return getSelectSchema({
      groupResults: groupedResources,
      allResults: searchResources
    })
  }, [groupedResources, searchResources])

  const loadMore = React.useCallback(() => {
    return _.map(models, ({ contentType }) => {
      return fetchResources({
        locale,
        resourceType: contentType,
        requestKey: `search/${contentType}`,
        where: { query: searchString },
        next: true,
        centreId: getCentreId()
      })
    })
  }, [])

  const { helpfulLinks } = getFields(
    getFirstResource(
      useSelector(
        requestSelector({
          resourceType: 'layout',
          requestKey: 'layout'
        })
      )
    )
  )

  return (
    <Page>
      {total > 0 && (
        <React.Fragment>
          <Show gt='lg'>
            <Filters schema={filterSchema} />
            <Spacing height={28} />
            <Line />
          </Show>

          <Show lt='lg'>
            <Filters schema={selectSchema} />
          </Show>
        </React.Fragment>
      )}
      <Spacing height={vars.pageGutter.md} />
      <Filters schema={searchSchema} key={'search'} status={message} />
      <Spacing height={vars.pageGutter.md} />
      {(status.pending || status.succeeded) && (
        <InfiniteTiles
          total={total}
          loadMore={loadMore}
          hasMore={false}
          status={status}
          helpfulLinks={helpfulLinks}
        >
          {_.map(results, (resource) => {
            return (
              <ResourceTile resource={resource} key={getResourceId(resource)} />
            )
          })}
        </InfiniteTiles>
      )}
    </Page>
  )
}

SearchResults.getData = async (props) => {
  const { host, dispatch, location, locale } = props
  const { search } = qs.parse(_.get(location, 'search')) || {}
  await getPageData(props)
  return setSearchResults({
    dispatch,
    query: search,
    locale,
    host
  })
}

SearchResults.identifier = 'SEARCH_RESULTS'

export default SearchResults
