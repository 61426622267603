// please refer to https://redux-resource.js.org/ for more
//   information about how we're using resources in redux.

import _isBoolean from 'lodash/isBoolean'
import _camelCase from 'lodash/camelCase'
import _map from 'lodash/map'

import { createSelector, createSelectorCreator } from 'reselect'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _forEach from 'lodash/forEach'
import map from 'lodash/map'
import _pick from 'lodash/pick'
import _mapValues from 'lodash/mapValues'
import once from 'lodash/once'
import _ from 'lodash'
import fp from 'lodash/fp'
import reducerKey from './key'
import getStatus from '../getStatus'
// import fastMemoize from 'fast-memoize'
import qs from 'query-string'

// const createBoundlessCacheSelector = createSelectorCreator(fastMemoize)

export const resourcesSelector = createSelector(
  [(args) => args.resourceType, (args) => args.ids],
  (resourceType, ids) => {
    return createSelector(
      [
        (state) => _get(state, [reducerKey, resourceType, 'resources']),
        (state) => _get(state, [reducerKey, resourceType, 'meta'])
      ],
      (resourceData, _metaData) => {
        const idsContentIds = ids.map((id) => id.content_id)
        const resources = _pick(resourceData, idsContentIds)
        const _metas = _pick(_metaData, idsContentIds)
        const combined = Object.keys(resources).reduce((acc, item) => {
          acc[item] = {
            resource: {
              ...resources[item],
              savedId: _.find(ids, (id) => id.content_id === item).id,
              content_id: item,
              content_type: resourceType
            },
            _meta: _metas[item],
            _status: getStatus(_metas[item], 'readStatus')
          }
          return acc
        }, {})
        return Object.values(combined)
      }
    )
  }
)

export const resourceSelector = createSelector(
  [(args) => args.resourceType, (args) => args.id],
  (resourceType, id) => {
    return createSelector(
      [
        (state) => _get(state, [reducerKey, resourceType, 'resources', id]),
        (state) => _get(state, [reducerKey, resourceType, 'meta', id])
      ],
      (resource, _meta) => {
        const _status = getStatus(_meta, 'readStatus')
        return { ...resource, _meta, _status }
      }
    )
  }
)

export const requestSelector = createSelector(
  [(args) => args.resourceType, (args) => args.requestKey],
  (resourceType, requestKey) => {
    return createSelector(
      [
        (state) =>
          _get(state, [reducerKey, resourceType, 'requests', requestKey]),
        (state) => _get(state, [reducerKey, resourceType, 'resources'])
      ],
      (request, allResourcesOfSameType) => {
        const { ids, status, ...requestRest } = request || {}
        const resources = map(ids, (id) => allResourcesOfSameType[id])
        const _status = getStatus(status)
        return { ...requestRest, resources, _status }
      }
    )
  }
)

export const manyRequestSelector = (requests) => {
  return (state) =>
    _mapValues(requests, (request) => {
      return requestSelector(request)(state)
    })
}

export const centreSelector = once(() => {
  return createSelector(
    requestSelector({ resourceType: 'centre', requestKey: 'centre' }),
    (centreRequest) => {
      const { resources, _status } = centreRequest
      const centre = { ..._get(resources, 0), _status }
      return centre
    }
  )
})

export const crisisSelector = once(() => {
  return createSelector(
    requestSelector({ resourceType: 'crisis', requestKey: 'crisis' }),
    (crisisRequest) => {
      const { resources, _status } = crisisRequest
      const crisis = {
        ..._get(Object.values(resources), '[0].fields'),
        _status
      }
      return crisis
    }
  )
})
/**
 * A selector for SEO purposes. Builds a queried string using contentful resources for links that have a default query applied, such as parking and the first tab.
 * If the accessed value is an object then the path is returned only.
 * If the param value contains `resources` and `fields` then the key is ignored to stop contentful IDs leaking.
 * @param {string} resourceType
 * @param {string} requestKey
 * @param {string} path The part of the after the domain, i.e "/parking". The `/` is already included.
 * @param {object} parameters A QS object with a the value as a contentful resource path i.e. `resources.0.fields.carParks.0.sys.id`.
 * @returns {function(state):string} a selector that when called will produce a query string'd path i.e `/parking?tab=4rx36Z2hGlMRcRrUlcj3Ra`.
 */
const queriedLinkSelector = (resourceType, requestKey, path, parameters) => createSelector([
  requestSelector({ resourceType, requestKey })
], (resource) => {
  const queryObject = fp.compose(
    fp.omitBy((paramValue) => {
      return _.includes(paramValue, 'resources') && _.includes(paramValue, 'fields')
    }),
    fp.mapValues((path) => _.get(resource, path, path))
  )(parameters)
  /** @type {boolean} Does the generated query object only contain primitives to guard against [object Object] */
  const isValidQueryObject = _.every(fp.negate(fp.isObject), queryObject)
  if (!isValidQueryObject) {
    return `/${path}`
  }
  return `/${path}?${qs.stringify(queryObject)}`
})
/**
 * A selector for SEO purposes. Builds a path string using contentful resources for links that have a default urlName applied, such as parking and the first tab.
 * If the accessed value is an object then the path is returned only.
 * If the param value contains `resources` and `fields` then the key is ignored to stop contentful IDs leaking.
 * @param {string} resourceType
 * @param {string} requestKey
 * @param {string} path The part of the after the domain, i.e "/parking". The `/` is already included.
 * @param {object} parameters A QS object with a the value as a contentful resource path i.e. `resources.0.fields.carParks.0.sys.id`.
 * @returns {function(state):string} a selector that when called will produce a query string'd path i.e `/getting-here?tab=4rx36Z2hGlMRcRrUlcj3Ra`.
 */
const urlNameLinkSelector = (resourceType, requestKey, path, parameters) => createSelector([
  requestSelector({ resourceType, requestKey })
], (resource) => {
  const resourceURLName = _.get(resource, parameters)
  return `/${path}/${resourceURLName}`
})

/**
 * A partially applied link selector for referencing the layout resource.
 * @see {@link urlNameLinkSelector}
 */
export const layoutURLNameLinkSelector = _.partial(urlNameLinkSelector, 'layout', 'layout')
/**
 * A partially applied link selector for referencing the layout resource.
 * @see {@link queriedLinkSelector}
 */
export const layoutLinkSelector = _.partial(queriedLinkSelector, 'layout', 'layout')
/**
 * A partially applied link selector for referencing the centre resource.
 * @see {@link queriedLinkSelector}
 */
export const centreLinkSelector = _.partial(queriedLinkSelector, 'centre', 'centre')
/** Generates a link to the parking page's first tab.*/
export const parkingLinkSelector = layoutURLNameLinkSelector('parking', 'resources.0.fields.carParks.0.fields.urlName')
/**
 * Generates a look up selector of all fully applied `queriedLinkSelectors`
 * This primarily for the nav selector which in turn powers the navigation menus and sitemap.
 */
export const queriedLinkLookUpSelector = createSelector([parkingLinkSelector], (parkingLink) => {
  return {
    '/parking': parkingLink
  }
})

