import React from 'react'
import TagManager from 'react-gtm-module'
import _ from 'lodash'
import { getCentreConfig } from 'src/config'
import { logTrace } from 'src/utility'
import env, { isBrowser, isCordova } from 'src/env'

const useGTM = () => {
  React.useEffect(() => {
    if (isBrowser) {
      const centreConfig = getCentreConfig() || {}
      const gtmIds = _.get(centreConfig, 'gtmIds', {})
      const centreKey = _.get(env, 'centreKey')
      const deviceKey = isCordova || env.fakeCordova ? 'cordova' : 'browser'
      const deviceGtmIds = _.get(gtmIds, deviceKey, [])
      TagManager.dataLayer({
        dataLayer: {
          centreKey
        }
      })
      logTrace(
        `initialising GTM for ${_.size(deviceGtmIds)} ids: ${_.join(
          deviceGtmIds,
          ', '
        )}`
      )
      _.forEach(deviceGtmIds, (gtmId) => {
        TagManager.initialize({ gtmId })
      })
    }
  }, [])
}

export default useGTM
