import React, { useMemo } from 'react'
import _map from 'lodash/map'
import _flatMap from 'lodash/flatMap'
import _find from 'lodash/find'
import _flow from 'lodash/flow'
import _groupBy from 'lodash/groupBy'
import _reject from 'lodash/reject'
import _filter from 'lodash/filter'
import _compact from 'lodash/compact'
import _isEmpty from 'lodash/isEmpty'
import _mapValues from 'lodash/mapValues'
import _forEach from 'lodash/forEach'
import _isNull from 'lodash/isNull'

import Page, { getPageData } from 'src/components/Page'

import { Link } from 'src/components/RouterDom'

import styled from 'styled-components'
import colors from 'src/styling/colors'
import media from 'src/styling/media'

import {
  fetchSitemapResources,
  useSitemapResources,
  generateMap
} from './utils'

const SiteMap = styled.ul`
  list-style: none outside;
  > li {
    border-top: 1px solid ${colors.lightTextColor};
    padding-top: 20px;
  }
  > li:first-child {
    border-top: none;
    padding-top: 0;
  }
  a {
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: ${colors.red};
    }
  }
`

const ParentCategoryList = styled.div`
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    > li {
      width: 100%;
      padding: 0 10px 0 0;
      ${media.greaterThan('md')`
        max-width: 33%;
      `}
      ${media.greaterThan('lg')`
        max-width: 25%;
      `}
    }
  }
  > ul > li > ul {
    margin-bottom: 15px;
  }
`

const ParentCategoryHeading = styled(Link)`
  display: block;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  color: ${colors.textColor};
  padding-bottom: 20px;

  ${media.greaterThan('lg')`
    font-size: 35px;
    letter-spacing: -1.7px;
  `}
`

const Subcategory = styled(Link)`
  display: block;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  color: ${colors.textColor};
  padding: 0 0 5px 0;
  ${media.greaterThan('lg')`
    font-size: 22px;
    letter-spacing: -1.7px;
  `}
`

const Item = styled(Link)`
  display: block;
  font-weight: 300;
  color: ${colors.primary};
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 3px;
`

const ContentPage = (props) => {
  const { match } = props
  const {
    categories: categoriesReq,
    layout: layoutReq,
    nav
  } = useSitemapResources()

  const { hierarchical: linkHierarchy } = useMemo(() => {
    return generateMap({ categoriesReq, layoutReq, nav, match })
  }, [categoriesReq, layoutReq, nav])

  return (
    <article>
      <Page>
        <SiteMap>{linkHierarchy.map(generateParentCategoryLists)}</SiteMap>
      </Page>
    </article>
  )
}

const generateList = (navItem) => {
  const { label, link, title, pages, subcategories } = navItem
  return (
    <li>
      {!_isEmpty(pages) || !_isEmpty(subcategories) ? (
        <React.Fragment>
          <Subcategory to={link}>{title || label}</Subcategory>
          <ul>{_map(pages, generateList)}</ul>
        </React.Fragment>
      ) : (
        <Item to={link}>{title || label}</Item>
      )}
    </li>
  )
}

const generateParentCategoryLists = (navItem) => {
  const { pages, subcategories } = navItem
  return (
    <li>
      {!_isEmpty(pages) || !_isEmpty(subcategories) ? (
        <React.Fragment>
          <ParentCategoryHeading to={navItem.link}>
            {navItem.label}
          </ParentCategoryHeading>
          <ParentCategoryList>
            {_map(_compact([pages, subcategories]), (list) => {
              return <ul>{_map(list, generateList)}</ul>
            })}
          </ParentCategoryList>
        </React.Fragment>
      ) : (
        <ParentCategoryHeading to={navItem.link}>
          {navItem.label}
        </ParentCategoryHeading>
      )}
    </li>
  )
}

ContentPage.identifier = 'SITEMAP'
ContentPage.getData = async (props) => {
  const { dispatch, host, locale } = props
  await getPageData(props)
  return fetchSitemapResources({ host, dispatch, locale })
}

export default ContentPage
