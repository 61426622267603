import React from 'react'
import styled from 'styled-components'
import { Link } from 'src/components/RouterDom'

import colors from 'src/styling/colors'
import { useInternationalisation } from 'src/context'
import AssetIcon from 'src/components/AssetIcon'
import FilledQuestionSvg from 'src/assets/FilledQuestion.svg'
import PeopleSvg from 'src/assets/People.svg'

const CrowdCheckerWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
`

const CrowdCheckerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 13px;
  max-width: 95%;
  flex-wrap: wrap;
`

const StatusLozenge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ status }) => status === 'CLOSED' ? colors.black : colors.purple};
  padding: 2px 8px;
  border-radius: 8px;
`

const LozengeText = styled.div`
  font-size: 13px;
  margin-left: 4px;
  font-weight: bold;
  color: white;
  text-align: center;
`

const StatusText = styled.span`
  margin: 0 10px;
  text-align: center;
`

const StatusTextArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const FindUsIcon = styled(AssetIcon)`
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: 2px;
`

const CrowdChecker = ({ status, to }) => {
  const { translate, translateUrl } = useInternationalisation()
  return (
    <CrowdCheckerWrapper to={translateUrl(`/${to}`)}>
      <CrowdCheckerContainer>
        <StatusLozenge status={status}>
          <AssetIcon aria-label={'Find us'} size={14} svg={PeopleSvg} overflow={'hidden'} color={'white'} />
          <LozengeText>
            {translate(`CROWD_CHECKER_LOZENGE_${status === 'CLOSED' ? status : 'LIVE'}`)}
          </LozengeText>
        </StatusLozenge>
        <StatusTextArea>
          <StatusText>
            {translate(`CROWD_CHECKER_${status}`)}
            <FindUsIcon aria-label={'Find us'} size={14} svg={FilledQuestionSvg} overflow={'hidden'} />
          </StatusText>
        </StatusTextArea>
      </CrowdCheckerContainer>
    </CrowdCheckerWrapper>
  )
}

export default CrowdChecker
