import _ from 'lodash'
import { isCordova } from 'src/env'

import actionTypes from './actionTypes'

const { CREATE_EVENT, UPLOAD_EVENTS } = actionTypes

let initialState = { events: { uploaded: [], awaitingUpload: [] } }

const analyticsReducer = (state, action) => {
  const { type, payload } = action
  if (type === CREATE_EVENT) {
    return {
      ...state,
      events: {
        ...state.events,
        awaitingUpload: [..._.get(state, 'events.awaitingUpload', []), payload]
      }
    }
  } else if (type === UPLOAD_EVENTS) {
    return {
      ...state,
      events: {
        ...state.events,
        uploaded: [..._.get(state, 'events.awaitingUpload', [])],
        awaitingUpload: []
      }
    }
  } else {
    return state || initialState
  }
}

export default analyticsReducer
