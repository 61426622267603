import React, { Fragment, useState } from 'react'
import { Link } from 'src/components/RouterDom'
import InfiniteScroll from 'react-infinite-scroller'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _noop from 'lodash/noop'
import styled from 'styled-components'
import { getFields } from 'src/utility'
import colors from 'src/styling/colors'
import media from 'src/styling/media'
import vars from 'src/styling/vars'
import { useInternationalisation } from 'src/context'

import TilesWrapper from './TilesWrapper'
import LoadingTiles from './LoadingTiles'

const spinnerSize = 40

const SpinnerWrapper = styled.div`
  height: ${spinnerSize}px;
  width: ${spinnerSize}px;
  margin: ${vars.tileGutter}px auto 0;
`

const NoResultsContainer = styled.div`
  ${media.forEach({
    values: { sm: 10, md: 30, lg: 50 },
    getStyle: (v) => `padding: ${v}px 0;`
  })}
`

const NoResults = styled.p`
  font-size: ${({ embedded }) => (embedded ? '18px' : '25px')};
  ${({ embedded }) => (embedded ? 'text-align: center;' : '')}
  font-weight: 600;
  color: ${colors.darkGrey};
`

const HelpfulLinksTitle = styled.h4`
  font-size: 40px;
  font-weight: 600;
  color: ${colors.darkGrey};
  margin-bottom: 40px;
`
const HelpfulLinks = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const HelpfulLink = styled.li`
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 10px 0;
  border-bottom: 2px solid ${colors.darkGrey};
  > a {
    text-decoration: none;
  }
`

const InfiniteTiles = ({
  children,
  total,
  loadMore,
  hasMore,
  status,
  helpfulLinks,
  noResultText
}) => {
  const { translate } = useInternationalisation()
  const noResult = translate(noResultText || 'NO_RESULTS_FOUND')

  return (
    <Fragment>
      {status.succeeded ? (
        // don't just check for falsey total because
        // we don't want no results to show if total is undefined
        total === 0 ? (
          _size(helpfulLinks) > 0 ? (
            <NoResultsContainer>
              <HelpfulLinksTitle>
                {translate('SEARCH_HELPFUL_LINKS')}
              </HelpfulLinksTitle>
              <HelpfulLinks>
                {_map(helpfulLinks, (link) => {
                  const { title, urlName } = getFields(link)
                  return (
                    <HelpfulLink key={urlName}>
                      <Link to={urlName}>{title}</Link>
                    </HelpfulLink>
                  )
                })}
              </HelpfulLinks>
            </NoResultsContainer>
          ) : (
            <NoResultsContainer>
              <NoResults embedded={!!noResultText}>{noResult}</NoResults>
            </NoResultsContainer>
          )
        ) : (
          <InfiniteScroll
            loadMore={status.pending ? _noop : loadMore}
            hasMore={hasMore}
            element={TilesWrapper}
          >
            {children}
          </InfiniteScroll>
        )
      ) : null}
      {status.pending ? <LoadingTiles /> : null}
    </Fragment>
  )
}

export default InfiniteTiles
