import styled from 'styled-components'
import vars from 'src/styling/vars'
import media from 'src/styling/media'
import { horizontalPadding, verticalPadding } from 'src/styling/styles'
import { isCordova } from 'src/env'

const Container = styled.div`
  margin: 0 auto;
  white-space: pre-wrap;
  max-width: ${vars.maxWidth}px;
  width: 100%;
  &:empty {
    display: none
  }
  ${p => p.edgeToEdge ?
    `flex: 1;
   ${isCordova ? 'display: flex;' : ''}
  `
    : ''}
  ${(p) =>
    p.horizontalGutter !== false && p.edgeToEdge !== true
      ? media.forEach({
        values: vars.pageGutter,
        getStyle: (x) => horizontalPadding(x + 'px')
      })
      : ''}
  ${(p) =>
    p.verticalGutter && p.edgeToEdge !== true
      ? media.forEach({
        values: vars.pageGutter,
        getStyle: (x) => verticalPadding(x + 'px')
      })
      : ''}
  ${(p) =>
    p.bottomGutter && p.edgeToEdge !== true
      ? media.forEach({
        values: vars.pageGutter,
        getStyle: (x) => `padding-bottom: ${x}px`
      })
      : ''}
  ${(p) =>
    p.topGutter && p.edgeToEdge !== true
      ? media.forEach({
        values: vars.pageGutter,
        getStyle: (x) => `padding-top: ${x}px`
      })
      : ''}
`

export default Container
