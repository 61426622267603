import React from 'react'
import { getHost, getCentreConfig } from 'src/config'
import _ from 'lodash'
import IframeMap from './IframeMap'

const ViadirectWebMap = () => {
  const ref = React.createRef()
  
  const host = getHost()
  const config = getCentreConfig({ host })
  const destinationId = window.location.search && window.location.search.split("=")[1]
  
  return (
    <IframeMap
      ref={ref}
      title={'Viadirect Map'}
      src={`${config.viadirectLink}${destinationId && `#destination/${destinationId}`}`}
      customStyle={`padding: 0`}
    />
  )
}

export default ViadirectWebMap
