import { api, errors, analyticEventTypes, jwt } from '@redant/hammerson-plus-config'

const titles = {
  en: ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx'],
  'en-GB': ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx'],
  fr: ['Mr', 'Mme'],
  'fr-FR': ['Mr', 'Mme']
}

export * from './centres'

export { api, errors, titles, analyticEventTypes, jwt }
