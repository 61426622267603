import React, { Fragment, useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link, Redirect, withRouter } from 'src/components/RouterDom'
import qs from 'query-string'
import * as Yup from 'yup'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { login } from 'src/store/account/actionCreators'
import cognitoService from 'src/services/cognitoService'
import validators from 'src/validators'

import EmailSvg from 'src/assets/Email.svg'
import LockSvg from 'src/assets/Lock.svg'
import Form from 'src/components/Form'
import PasswordField from 'src/components/Fields/Password'
import FieldsWrapper from './FieldsWrapper'

const fieldSchema = [
  {
    name: 'email',
    label: 'INPUT_EMAIL',
    readOnly: true,
    iconSvg: EmailSvg
  },
  {
    name: 'newPassword',
    label: 'INPUT_NEW_PASSWORD',
    autoComplete: 'new-password',
    type: 'password',
    iconSvg: LockSvg,
    component: PasswordField
  },
  {
    name: 'confirmPassword',
    label: 'INPUT_CONFIRM_PASSWORD',
    autoComplete: 'off',
    type: 'password',
    iconSvg: LockSvg,
    component: PasswordField
  }
]

const validationSchema = Yup.object({
  newPassword: validators.password(),
  confirmPassword: validators.confirmPassword('newPassword')
})

const ResetPasswordForm = (props) => {
  const { redirectAfterLogin, match, location } = props
  const qsParams = qs.parse(_get(location, 'search'))
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    (values) => {
      const { newPassword } = values
      const { email, code: verificationCode } = qsParams
      return cognitoService
        .resetPassword({
          email,
          verificationCode,
          newPassword
        })
        .then(() => dispatch(login({ password: newPassword, email })))
        .then(redirectAfterLogin)
    },
    [qsParams]
  )

  const initialValues = { email: qsParams.email }

  const formProps = {
    onSubmit,
    buttonLabel: 'BUTTON_LABEL_SUBMIT',
    fieldSchema,
    validationSchema,
    initialValues,
    parseError: cognitoService.parseError
  }
  return <FieldsWrapper children={<Form {...formProps} />} />
}

export default withRouter(ResetPasswordForm)
