import React, { Fragment } from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import styled from 'styled-components'

import media from 'src/styling/media'
import colors from 'src/styling/colors'
import Container from 'src/components/Container'
import Spacing from 'src/components/Spacing'

import TilesWrapper from './TilesWrapper'
import ResourceTile from './ResourceTile'
import { getResourceId } from 'src/utility'

const H2 = styled.h2`
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.1;
  padding-top: 0;
  padding-bottom: 10px;
  color: ${colors.textColor};
  text-align: center;
  ${media.greaterThan('md')`
    font-size: 60px;
    letter-spacing: -1.7px;
  `}
  ${media.greaterThan('lg')`
    font-size: 70px;
    letter-spacing: -1.7px;
  `}
`

const FeaturedTiles = ({ title, resources }) => {
  return (
    <Container horizontalGutter={false} bottomGutter>
      <H2>{title}</H2>
      <Spacing height={48} />
      <TilesWrapper>
        {_map(resources, (resource) => {
          return (
            <ResourceTile resource={resource} key={getResourceId(resource)} />
          )
        })}
      </TilesWrapper>
    </Container>
  )
}

export default FeaturedTiles
