import React from 'react'
import { ThemeProvider } from 'styled-components'
import BaseModal from 'src/components/Modal/BaseModal'
import SafeImage from 'src/components/SafeImage'

const fullImageModalTheme = {
  backgroundColor: 'transparent',
  borderColor: 'white',
  caretColor: 'white',
  color: 'white',
  error: 'white',
  focusColor: 'white'
}

const FullImageModal = (props) => {
  const { id, imageSrc } = props || {}
  return (
    <ThemeProvider theme={fullImageModalTheme}>
      <BaseModal id={id}>
        <SafeImage
          src={imageSrc}
          height={'100%'}
          width={'100%'}
          alt='Product'
        />
      </BaseModal>
    </ThemeProvider>
  )
}

export default FullImageModal
