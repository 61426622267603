import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reduxLogger from 'redux-logger'
import reduxPromise from './reduxPromise'
import Flatted from 'flatted'

import env, { isBrowser, isCordova } from 'src/env'

import registerDeviceMiddleware from './middleware/registerDevice'

import resourcesReducer from './resources/reducer'
import resourcesKey from './resources/key'

import uiReducer from './ui/reducer'
import uiKey from './ui/key'

import accountReducer from './account/reducer'
import accountKey from './account/key'

import deviceReducer from './device/reducer'
import deviceKey from './device/key'

import styleSeekerReducer from './styleSeeker/reducer'
import styleSeekerKey from './styleSeeker/key'

import analyticsReducer from './analytics/reducer'
import analyticsKey from './analytics/key'
import analyticsSubscriber from './analytics/subscriber'

import { store as shopperTrackStore } from './shopperTrak'

import { RESET_STORE } from './constants'

import { ssrParse } from 'src/utility'

const combinedReducers = combineReducers({
  [resourcesKey]: resourcesReducer,
  [uiKey]: uiReducer,
  [accountKey]: accountReducer,
  [deviceKey]: deviceReducer,
  [styleSeekerKey]: styleSeekerReducer,
  [analyticsKey]: analyticsReducer,
  shopperTrak: shopperTrackStore
})

const middlewares = [
  reduxPromise,
  thunkMiddleware
]
if (isCordova) {
  if (env.envKey !== 'prod' && !env.fakeCordova) {
    middlewares.push(reduxLogger)
  }
  middlewares.push(registerDeviceMiddleware)
}

const enhancer = composeWithDevTools(applyMiddleware(...middlewares))

export const transformCircular = createTransform(
  (inboundState, _key) => Flatted.stringify(inboundState),
  (outboundState, _key) => Flatted.parse(outboundState),
)

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ['account', 'ui']
}

const subscribers = [analyticsSubscriber]
const registerSubscribers = (store) => {
  subscribers.forEach((subscriber) => {
    const { getState, dispatch } = store
    subscriber({ getState, dispatch })
  })
}

const configureStore = (args) => {
  let initialState = undefined
  if (isBrowser && window.__REDUX_DATA__) {
    try {
      // get initial state from data fetched on server-side
      initialState = ssrParse(window.__REDUX_DATA__)
      delete window.__REDUX_DATA__
    } catch (err) {
      console.error(err)
    }
  }
  const rootReducer = (state, action) => {
    if (action.type === RESET_STORE) return initialState
    return combinedReducers(state, action)
  }
  const storeReducer = isBrowser
    ? persistReducer(persistConfig, rootReducer)
    : rootReducer
  const store = createStore(storeReducer, initialState, enhancer)
  if (isBrowser) {
    const persistor = persistStore(store)
    store.persistor = persistor
  }
  registerSubscribers(store)
  return store
}

export default configureStore
