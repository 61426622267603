import React from 'react'
import { useSelector } from 'react-redux'
import { getFields, getResourceType, getResourceName } from 'src/utility'
import { manyRequestSelector } from 'src/store/resources/selectors'
import _ from 'lodash'
import { fetchResources } from 'src/store/resources/actionCreators'
import { useInternationalisation } from 'src/context'

import { isCordova } from 'src/env'

// SEARCH UTILS

const searchContentTypes = [
  'promotion',
  'news',
  'event',
  'page',
  'retailUnit',
  'job'
]

const _getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}

const getSearchFilters = () => {
  const { translate } = useInternationalisation()
  const configList = [
    {
      label: translate('FILTER_ALL'),
      key: 'all',
      group: 'all',
      display: true
    },
    {
      label: translate('FILTER_SHOPS'),
      contentType: 'retailUnit',
      key: 'shop',
      group: 'shop',
      display: true
    },
    {
      label: translate('FILTER_DINING'),
      contentType: 'retailUnit',
      key: 'food outlet',
      group: 'food outlet',
      display: true
    },
    {
      label: translate('FILTER_LEISURE'),
      contentType: 'retailUnit',
      key: 'leisure',
      group: 'leisure',
      display: true
    },
    {
      label: translate('FILTER_NEWS'),
      contentType: 'news',
      key: 'news',
      group: 'news',
      display: true
    },
    {
      label: translate('FILTER_EVENTS'),
      contentType: 'event',
      group: 'event',
      key: 'event',
      display: true
    },
    {
      label: translate('FILTER_PROMOTIONS'),
      contentType: 'promotion',
      key: 'promotion',
      group: 'promotion',
      display: true
    },
    {
      label: 'Pages',
      contentType: 'page',
      key: 'page',
      group: 'other',
      display: false
    },
    {
      label: 'Retail Units',
      contentType: 'retailUnit',
      key: 'retail unit',
      group: 'other',
      display: false
    },
    {
      label: translate('FILTER_OTHER'),
      key: 'other',
      group: 'other',
      display: true
    }
  ]

  return {
    filters: configList,
    keys: _.keyBy(configList, 'key'),
    list: _.filter(configList, ['display', true]),
    models: _.omit(_.keyBy(configList, 'contentType'), undefined),
    getFiltersOptions: ({ groupResults, allResults }) => {
      const groups = groupResults
      const all = allResults
      return _.pickBy(
        _.map(configList, ({ label, key }) => {
          const totalResults =
            key === 'all' ? _.size(all) : groups[key] ? _.size(groups[key]) : 0
          return {
            label: `${label} (${totalResults})`,
            key,
            value: key,
            total: totalResults
          }
        }),
        ({ total }) => total > 0
      )
    }
  }
}

const setSearchResults = ({ dispatch, query = '', limit, locale, host }) => {
  if (_.isString(query) && _.size(query) > 2) {
    return _.map(searchContentTypes, (contentType) => {
      return dispatch(
        fetchResources({
          locale,
          resourceType: contentType,
          requestKey: `search/${contentType}`,
          limit,
          where: {
            query,
            ...(contentType === 'page'
              ? isCordova
                ? {
                    'fields.publishOnPlusApp': true,
                    'fields.urlName[ne]': 'not-found'
                  }
                : {
                    'fields.publishOnWebsite': true,
                    'fields.urlName[ne]': 'not-found'
                  }
              : {}),
            ...((contentType === 'event' || contentType === 'promotion') && {
                  'fields.endDate[gte]': new Date().toISOString()
              }),
            ...(contentType === 'job' && {
              'fields.applicationCloseDate[gte]': new Date().toISOString()
            })
          },
          host
        })
      )
    })
  }
}

const getSearchResults = ({ searchString = '', filterString = '' }) => {
  const { translate } = useInternationalisation()

  const _sortByKeywordInTitle = ({ resources }) =>
    _.sortBy(resources, (resource) => {
      const { positionOfSearchQueryInTitle } = resource
      if (positionOfSearchQueryInTitle !== -1) {
        return positionOfSearchQueryInTitle
      }
    })

  const _sortByKeywordOccurrence = ({ resources }) =>
    _.sortBy(resources, ['keywordOccurrence']).reverse()

  const _filterResults = ({ resources }) =>
    _.filter(resources, (resource) => {
      const { keywordOccurrence, positionOfSearchQueryInTitle } = resource
      if (keywordOccurrence > 0 || positionOfSearchQueryInTitle !== -1)
        return resource
    })

  const _getStatus = (results) =>
    _.reduce(_.flatMap(_.mapValues(results, '_status')), (source, target) =>
      Object.assign(target, source)
    )

  const _getGroupedResources = (_getGroupedResources) =>
    React.useMemo(
      () =>
        _.groupBy(searchResources, (resource) => {
          const fields = getFields(resource)
          const type = getResourceType(resource)
          const { retailerType } = fields || {}
          const key = _.toLower(retailerType || type)
          return (filtersKeys[key] || {}).group
        }),
      [searchResources]
    )

  const _getTitleKey = (categoryType) =>
    ({
      job: 'jobTitle',
      retailUnit: 'name',
      page: 'title'
    }[categoryType] || 'title')

  const _getSearchResources = (results) =>
    _.flatMap(
      _.mapValues(results, ({ resources }) => {
        return _.map(resources, (resource) => {
          const fields = getFields(resource)
          const categoryType = _.get(resource, 'sys.contentType.sys.id')
          const resourceTitle = _.get(fields, _getTitleKey(categoryType))
          const stringFields = JSON.stringify(fields, _getCircularReplacer())
          // assign a search string occurrence count on each resource
          const keywordOccurrence = (
            stringFields.match(new RegExp(searchString, 'g')) || []
          ).length
          const positionOfSearchQueryInTitle = _.toLower(resourceTitle).indexOf(
            _.toLower(searchString)
          )
          return {
            ...resource,
            keywordOccurrence,
            positionOfSearchQueryInTitle
          }
        })
      })
    )

  const _getSearchMessage = ({ status, total, searchString }) => {
    const messages = {
      found: translate('SEARCH_RESULTS', {
        string: searchString
      }),
      notFound: translate('SEARCH_NO_RESULTS', {
        string: searchString
      })
    }

    const hasResults = total > 0

    return searchString
      ? !status.pending
        ? hasResults
          ? messages.found
          : messages.notFound
        : messages.found
      : ' '
  }

  const isAllowed = _.size(searchString) > 2

  const rawResults = useSelector(
    manyRequestSelector(
      _.mapValues(searchContentTypes, (contentType) => {
        return {
          resourceType: contentType,
          requestKey: `search/${contentType}`
        }
      })
    )
  )

  const status = _getStatus(rawResults)

  const { keys: filtersKeys } = getSearchFilters()

  const searchResources = _getSearchResources(rawResults)
  const groupedResources = _getGroupedResources(searchResources)

  let results = []

  results = React.useMemo(() => {
    return _sortByKeywordOccurrence({
      resources:
        filterString && filterString !== 'all'
          ? groupedResources[filterString]
          : searchResources
    })
  }, [filterString, groupedResources, searchResources])

  results = React.useMemo(() => {
    return _sortByKeywordInTitle({ resources: results })
  }, [filterString, groupedResources, searchString, searchResources])

  // results = _filterResults({ resources: results })

  const total = React.useMemo(() => _.size(results), [results])

  return {
    searchResources,
    groupedResources,
    status,
    message: _getSearchMessage({ status, total, searchString }),
    total,
    results: results,
    topResults: results.slice(0, 4)
  }
}

export { getSearchFilters, setSearchResults, getSearchResults }
