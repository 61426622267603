import React, { Fragment } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Link } from 'src/components/RouterDom'

import AssetIcon from 'src/components/AssetIcon'
import Container from 'src/components/Container'
import FacebookSvg from 'src/assets/Facebook.svg'
import InstagramSvg from 'src/assets/Instagram.svg'
import Spacing from 'src/components/Spacing'
import { useInternationalisation } from 'src/context'
import TwitterSvg from 'src/assets/Twitter.svg'
import PinSvg from 'src/assets/Pin.svg'
import media from 'src/styling/media'
import vars from 'src/styling/vars'
import {
  getFields,
  getFirstResource,
  getAssetSrc,
  getAssetTitle
} from 'src/utility'
import { useRequest } from 'src/store/resources/hooks'
import { verticalPadding, horizontalPadding } from 'src/styling/styles'
import NewsletterSignUp from '../NewsletterSignUp/widget'
import { queriedLinkLookUpSelector } from 'src/store/resources/selectors'
import { useSelector } from 'react-redux'
import _get from 'lodash/get'

const FooterWrapper = styled.footer`
  margin: auto 0 0;
  background-color: #484848;
  color: white;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`

const Line = styled.div`
  border-top: 1px solid white;
  height: 0px;
  width: 100%;
  margin: 0;
`

const TopContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  ${media.greaterThan('lg')`flex-direction: row;`}
  align-items: center;
  justify-content: space-around;
  ${verticalPadding('23px')}
  margin-bottom: ${vars.pageGutter.sm}px;
  ${media.greaterThan('lg')`margin-bottom: 0;`}

  & > div {
    flex: 1 1 0;
    max-width: 440px;
    margin-top: ${vars.pageGutter.sm}px;
    ${media.greaterThan('lg')`margin-top: 0;`}
  }

  & > div:nth-child(1) {
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -17px;
      ${media.greaterThan('lg')`margin-top: 0;`}
    }
    a {
      display: block;
      font-size: 16px;
      line-height: 50px;
      text-transform: uppercase;
      ${horizontalPadding('20px')}
      text-decoration: none;
    }
  }

  & > div:nth-child(2) {
  }

  & > div:last-child {
    text-align: center;

    div.logo-container {
      width: 220px;
      margin: 0 auto;

      img.logo {
        width: 100%;
        height: auto;
      }

    }


    div.address {
      text-transform: uppercase;
      font-size: 16px;
      line-height: 30px;
      & > a { display: inline-block; }
      & > p:first-of-type { display: inline-block; }
    }

    ul.social {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 230px;
      margin: 0 auto;
    }
  }
`

const BottomContainer = styled(Container)`
  ul {
    display: flex;
    flex-direction: column;
    ${media.greaterThan('lg')`flex-direction: row;`}
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 35px 0;
    ${media.greaterThan('lg')`padding: 0;`}
  }

  a {
    display: inline-block;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    text-decoration: none;
  }
`

const renderLinkList = (footerLinks) => {
  const queriedLinks = useSelector(queriedLinkLookUpSelector)
  return (
    <ul>
      {_.compact(footerLinks || []).map(({ fields }) => {
        if (!fields || fields.publishOnWebsite === false) return null
        const { title, urlName, externalUrl } = fields
        return (
          <li key={title}>
            {externalUrl ? (
              <a href={externalUrl}>{title}</a>
            ) : (
                <Link to={_get(queriedLinks, `/${urlName}`, `/${urlName}`)}>{title}</Link>
              )}
          </li>
        )
      })}
    </ul>
  )
}

const Footer = (props) => {
  const { centre } = props
  const { twitterUrl, facebookUrl, instagramUrl, address, logo } = getFields(
    centre
  )
  const enableNewsletterSignUp = _get(centre, 'fields.enableNewsletterSignUp', true)
  const { translate } = useInternationalisation()
  const socialNetworks = _.filter(
    [
      {
        url: twitterUrl,
        image: TwitterSvg,
        alt: translate('TWITTER_ICON_ALT_TEXT')
      },
      {
        url: facebookUrl,
        image: FacebookSvg,
        alt: translate('FACEBOOK_ICON_ALT_TEXT')
      },
      {
        url: instagramUrl,
        image: InstagramSvg,
        alt: translate('INSTAGRAM_ICON_ALT_TEXT')
      }
    ],
    'url'
  )

  const { footerPrimaryLinks, footerSecondaryLinks } = _.flow([
    useRequest,
    getFirstResource,
    getFields
  ])({
    resourceType: 'layout',
    requestKey: 'layout'
  })
  const queriedLinks = useSelector(queriedLinkLookUpSelector)
  return (
    <FooterWrapper>
      <TopContainer>
        <div>{renderLinkList(footerPrimaryLinks)}</div>
        {enableNewsletterSignUp && <div>
          <NewsletterSignUp />
        </div>}
        <div>
          <div className='logo-container'>
            <img
              src={getAssetSrc(logo)}
              alt={getAssetTitle(logo)}
              className={'logo'}
            />
          </div>
          <Spacing height={29} />
          <div className={'address'}>
            {address && (
              <Link to='/getting-here'>
                <AssetIcon
                  svg={PinSvg}
                  alt={translate('FOOTER_GETTING_HERE_PIN_ALT_TEXT')}
                  size={22}
                  style={{
                    display: 'inline-block',
                    marginRight: '5px',
                    lineHeight: '2.6'
                  }}
                />
              </Link>
            )}
            {_.map((address || '').match(/\S.*?(?![^,\n]),?/g), (x, i) => (
              <p key={i}>{x}</p>
            ))}
          </div>
          {socialNetworks.length !== 0 && (
            <Fragment>
              <Spacing height={24} />
              <ul className={'social'}>
                {_.map(socialNetworks, ({ url, image, alt }, index) => {
                  return (
                    <li key={index}>
                      <a href={url} target={'_blank'}>
                        <AssetIcon svg={image} size={40} alt={alt} />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </Fragment>
          )}
        </div>
      </TopContainer>
      <Line />
      <BottomContainer>{renderLinkList(footerSecondaryLinks)}</BottomContainer>
    </FooterWrapper>
  )
}

export default Footer
