import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'src/components/RouterDom'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _ from 'lodash'

import Container from 'src/components/Container'
import Spacing from 'src/components/Spacing'
import AssetIcon from 'src/components/AssetIcon'
import colors from 'src/styling/colors'

import PinSvg from 'src/assets/Pin.svg'
import CarSvg from 'src/assets/Car.svg'
import ListSvg from 'src/assets/Grid.svg'
import WalletSvg from 'src/assets/Wallet.svg'
import CinemaSvg from 'src/assets/Cinema.svg'
import { getFields, getUrl, getAssetSrc } from 'src/utility'

const Wrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  width: 100%;
  height: 110px;
  padding: 0 8px;
  -webkit-overflow-scrolling: touch;

  scrollbar-color: transparent;
  &::-webkit-scrollbar {
    display: none;
  }
`

const StoryWrapper = styled.div`
  flex-shrink: 0;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  margin: 0 12px;
  width: 64px;
`

const IconDisc = styled(Link)`
  width: 62px;
  height: 62px;
  background-color: ${colors.mediumGrey};
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${colors.lightGrey};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
`

const IconImage = styled.img`
  width: 44px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Story = (props) => {
  const { label, icon, to, src } = props
  return (
    <StoryWrapper>
      <IconDisc to={to}>{src ? <IconImage src={src} /> : null}</IconDisc>
      <Spacing height={10} />
      <span>{label}</span>
    </StoryWrapper>
  )
}

const Stories = (props) => {
  const { links } = props
  const stories = React.useMemo(() => {
    return _map(links, (resource) => {
      const { name, title, appHomeIcon } = getFields(resource)
      const to = getUrl(resource)
      const src = getAssetSrc(appHomeIcon)
      const storyProps = { label: name || title, to, src }
      return <Story {...storyProps} key={to} />
    })
  }, [links])
  return <Wrapper>{stories}</Wrapper>
}

export default Stories
