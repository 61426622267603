import React from 'react'
import _ from 'lodash'
import { List as Placeholder } from 'react-content-loader'
import Page, { getPageData } from 'src/components/Page'
import WebMap from 'src/components/WebMap'
import { useRequest } from 'src/store/resources/hooks'
import { getIdFromParams, getFirstPath } from 'src/utility'
import { useCentre } from 'src/store/resources/hooks'
function MapPage (props) {
  const request = useRequest({
    resourceType: 'page',
    requestKey: getIdFromParams(props) || getFirstPath(props)
  })
  const centre = useCentre()
  const useDynamicCentreMap = _.get(centre, 'fields.enableDynamicCentreMap', true)
  const options = {
    box: true
  }

  if (request._status.pending) {
    return (
      <Page box inner>
        <Placeholder />
      </Page>
    )
  }
  return (
    <>
      <Page {...options} />
      {useDynamicCentreMap && <WebMap fillMap={true} />}
    </>
  )
}

MapPage.identifier = 'MAP'

MapPage.getData = async (props) => {
  return getPageData(props)
}

export default MapPage
