import React, { memo } from 'react'
import _omit from 'lodash/omit'
import styled from 'styled-components'
import { Link } from 'src/components/RouterDom'
import _ from 'lodash'

import colors from 'src/styling/colors'
import media from 'src/styling/media'
import Spinner from 'src/components/Spinner'
import AssetIcon from 'src/components/AssetIcon'
import FacebookSvg from 'src/assets/FacebookLogo.svg'
import TwitterSvg from 'src/assets/TwitterLogo.svg'
import AppleSvg from 'src/assets/AppleLogo.svg'
import { isCordova } from 'src/env'
import popupService from 'src/services/popupService'

const ContainerComp = (props) => {
  const { to, ...rest } = props
  let linkProps
  let Comp = 'button'
  if (to) {
    if (_.startsWith(to, 'http')) {
      Comp = 'a'
      linkProps = {}
      if (isCordova) {
        linkProps.onClick = () => {
          popupService.open(to)
        }
      } else {
        linkProps.href = to
        linkProps.target = '_blank'
      }
    } else {
      Comp = Link
      linkProps = { to }
    }
  }
  // warning in console unless we do this
  return (
    <Comp
      {..._omit(rest, [
        'backgroundColor',
        'textColor',
        'borderColor',
        'fullWidth',
        'customWidth',
        'isLoading'
      ])}
      {...linkProps}
    />
  )
}

const innerHeight = 24
const spinnerSize = 24
const spinnerLeftPadding = 10
const buttonHeight = 70

const SpinnerWrapper = styled.div`
  height: ${innerHeight}px;
  display: inline-block;

  min-width: 0;
  vertical-align: bottom;
  transition: width 0.2s, opacity 0.5s;

  ${(p) =>
    p.isLoading
      ? `
  width: ${spinnerLeftPadding + spinnerSize}px;
  opacity: 1;`
      : `
  opacity: 0;
  width: 0;`}

  & > div {
    height: ${innerHeight}px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-left: ${spinnerLeftPadding}px;
  }
`

const Button = styled(ContainerComp)`
  margin: 12px 0; // todo: rm this

  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  text-decoration: none;

  overflow: hidden;
  background-color: ${(p) => p.backgroundColor};
  color: ${(p) => p.textColor};
  border: 1px solid ${(p) => p.borderColor || 'transparent'};
  -webkit-color: none;
  width: ${(p) => (p.fullWidth ? '100%' : p.customWidth ? '100%' : `initial`)};
  height: ${buttonHeight}px;
  ${media.greaterThan('md')`
    width: ${(p) =>
      p.fullWidth ? '100%' : p.customWidth ? `${p.customWidth}px` : `initial`};
`}
  &:hover,
  &:active,
  &:focus {
    opacity: 0.9;
  }
  ${(p) =>
    p.disabled
      ? `
  ${p.isLoading ? '' : `opacity: 0.5`};
  pointer-events: none;
  cursor: not-allowed;`
      : ''}
`

const IconBox = styled.div`
  height: ${buttonHeight}px;
  width: ${buttonHeight}px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonInner = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  line-height: ${innerHeight}px;
`

const colorConfig = {
  primary: {
    backgroundColor: colors.primary,
    textColor: colors.white
  },
  secondary: {
    backgroundColor: colors.white,
    textColor: colors.darkGrey,
    borderColor: colors.secondary
  },
  supporting: {
    backgroundcolor: colors.supporting,
    textcolor: colors.white
  },
  facebook: {
    backgroundColor: '#3C589E',
    textColor: colors.white
  },
  twitter: {
    backgroundColor: '#1DA1F2',
    textColor: colors.white
  },
  apple: {
    backgroundColor: '#000000',
    textColor: colors.white
  },
  red: {
    backgroundColor: colors.red,
    textColor: colors.white
  }
}

const icons = {
  facebook: FacebookSvg,
  twitter: TwitterSvg,
  apple: AppleSvg
}

const CustomButton = ({
  buttonType,
  children,
  disabled,
  isLoading,
  innerButtonStyling = {},
  ...rest
}) => {
  const colorProps = colorConfig[buttonType] || {}
  if (isLoading) disabled = true
  const icon = icons[buttonType]
  return (
    <Button {...colorProps} {...rest} disabled={disabled} isLoading={isLoading}>
      {icon ? (
        <IconBox>
          <AssetIcon svg={icon} size={18} />
        </IconBox>
      ) : (<ButtonInner style={innerButtonStyling}>
        {children}
        <SpinnerWrapper isLoading={isLoading} aria-hidden={!isLoading}>
          <div>
            <Spinner size={spinnerSize} color={colorProps.textColor} />
          </div>
        </SpinnerWrapper>
      </ButtonInner>)
      }
    </Button>
  )
}

export default memo(CustomButton)
