import Cookie from 'universal-cookie'
import { isCordova } from 'src/env'
export const cookies = new Cookie()

export const cookieIds = {
    __RA_PERSONALISED_ADS_COOKIES_OPT_IN__: '__RA_PERSONALISED_ADS_COOKIES_OPT_IN__',
    __RA_AUDIENCE_MEASUREMENT_COOKIES_OPT_IN__: '__RA_AUDIENCE_MEASUREMENT_COOKIES_OPT_IN__',
    __RA_COOKIES_HAS_SET_PREF__: '__RA_COOKIES_HAS_SET_PREF__',
}

export const getCookieValues = () => ({
    userPreference: isCordova || cookies.get(cookieIds.__RA_COOKIES_HAS_SET_PREF__),
    personalisedAds: isCordova || cookies.get(cookieIds.__RA_PERSONALISED_ADS_COOKIES_OPT_IN__),
    audienceMeasurement: isCordova || cookies.get(cookieIds.__RA_AUDIENCE_MEASUREMENT_COOKIES_OPT_IN__)
})

export const getInitialCookiesState = () => ({
    userSetPreference: getCookieValues().userPreference === 'true',
    personalisedAdCookiesOptedIn: getCookieValues().personalisedAds === 'true',
    audienceMeasurementCookiesOptedIn: getCookieValues().audienceMeasurement === 'true',
    savedAt: null
})

export const oneYear = 60 * 60 * 24 * 365

export default {
    SAVE_PREFERENCES: 'SAVE_PREFERENCES',
    ALL_COOKIES_ACCEPT: 'ALL_COOKIES_ACCEPT',
    ALL_COOKIES_REJECT: 'ALL_COOKIES_REJECT',
    AUDIENCE_MEASUREMENT_TOGGLED: 'AUDIENCE_MEASUREMENT_TOGGLED',
    PERSONALISED_COOKIES_TOGGLED: 'PERSONALISED_COOKIES_TOGGLED',
    UNDO_CHANGES: 'UNDO_CHANGES'
}